import { bool, string } from 'prop-types';
import React from 'react';

const ToggleIcon = (props) => {
  const { className = '', open } = props;
  return open ? (
    <svg
      className={className}
      width='12'
      height='24'
      viewBox='0 0 12 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 13L6 9L2 13'
        stroke='#4779CE'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ) : (
    <svg
      className={className}
      width='12'
      height='24'
      viewBox='0 0 12 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 11L6 15L10 11'
        stroke='#4779CE'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

ToggleIcon.propTypes = {
  className: string,
  open: bool.isRequired,
};

export default ToggleIcon;
