import { GoogleOAuthProvider } from '@react-oauth/google';
import { isKeyHotkey } from 'is-hotkey';
import { capitalize, extend, get, isEmpty } from 'lodash';
import React, { Component } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-resizable/css/styles.css';
import 'react-splitter-layout/lib/index.css';
import history from 'sora-client/constants/history';
import * as routes from 'sora-client/constants/routes';
import AnalyticsContext from 'sora-client/contexts/AnalyticsContext';
import ConfirmationContext from 'sora-client/contexts/ConfirmationContext';
import LoadingContext from 'sora-client/contexts/LoadingContext';
import MessagesContext from 'sora-client/contexts/MessagesContext';
import ModalContext from 'sora-client/contexts/ModalContext';
import PreviewContext from 'sora-client/contexts/PreviewContext';
import { getCurrentPageName } from 'sora-client/helpers';

import '../static/css/app.css';
import AppSession from './AppSession';
import AuthPage from './AuthPage';
import FastSwitcherNavigationModal from './FastSwitcherNavigationModal';
import TaskCompletePage from './TaskCompletePage';
import ErrorBoundary from './common/ErrorBoundary';
import Messages from './common/Messages';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const isFastSwitchModalKey = isKeyHotkey('mod+k');

const MESSAGE_TIMEOUT = 5000;

class App extends Component {
  constructor(props) {
    super(props);

    this.pathnameRef = React.createRef();
  }

  state = {
    loading: false,
    messages: [],
    addMessage: (message) => this.addMessage(message),
    removeMessage: (args) => this.removeMessage(args),
    confirmation: null,
    showConfirmation: (conf) => this.showConfirmation(conf),
    modalOptions: null,
    showModal: (conf) => this.showModal(conf),
    closeModal: () => this.closeModal(),
    setLoading: (loading) => this.setLoading(loading),
    track: (...data) => this.track(...data),
    group: (...data) => this.group(...data),
    identify: (...data) => this.identify(...data),
    getCount: (name) => this.getCount(name),
    reset: () => this.resetAnalytics(),
    increment: (name) => this.increment(name),
    previewingAsEmployee: null,
    setPreviewingEmployee: (previewingAsEmployee) =>
      this.setPreviewingAsEmployee(previewingAsEmployee),
  };

  componentWillMount() {
    this.pathnameRef.current = window.location.pathname;
    this.unlistenForRouteChanges = history.listen((location) => {
      // This listener will detect route changes at the query parameter level, which
      // we don't want. Track the current pathname in state so that we can register
      // page view events only when the pathname changes
      if (location.pathname !== this.pathnameRef.current) {
        // use this to exit from PreviewContext = true
        // in the case that a user is previewing a dashboard card
        // at 'settings/dashboard-cards' and clicks on any other page.
        // Without this, the app will stay in Preview mode
        // and none of the buttons or links will work
        if (window.location.pathname !== routes.SETTINGS_DASHBOARD_CARDS) {
          this.setPreviewingAsEmployee(null);
        }
        this.page();
        this.pathnameRef.current = location.pathname;
      }
    });
  }

  componentDidMount() {
    window.addEventListener('message', this.thirdPartyCookiesCheck);
    // @ts-ignore
    window.addMessage = this.addMessage;
    // @ts-ignore
    window.removeMessage = this.removeMessage;
    history.listen(() => {
      this.state.closeModal();
    });

    document.addEventListener('keydown', this.onKeyDown);

    /*
     * Prevent scrolling inside a number input from
     * changing the number up and down. This also ensures
     * we keep the default expected user behavior which
     * is to keep scrolling the page behind the element
     */
    document.addEventListener('wheel', function () {
      if (document.activeElement?.type === 'number') {
        document.activeElement.blur();
      }
    });

    // for now turn off service worker
    // registerServiceWorker(this.addMessage);
    (!['development', 'test'].includes(process.env.NODE_ENV) ||
      // To enable Segment in dev, add REACT_APP_IS_SEGMENT_ENABLED=true to client/.env.development
      process.env.REACT_APP_IS_SEGMENT_ENABLED === 'true') &&
      this.loadAnalytics();
  }
  componentWillUnmount() {
    window.removeEventListener('message', this.thirdPartyCookiesCheck);
    document.removeEventListener('keydown', this.onKeyDown);
    this.unlistenForRouteChanges();
  }

  thirdPartyCookiesCheck = (event) => {
    if (
      /"error":"Cookies are not enabled in current environment."/.test(
        event.data,
      )
    ) {
      this.addMessage({
        body: 'If logging in with Google, you must allow third-party cookies in your browser before you can log in.',
        type: 'warning',
        timeout: -1,
      });
    }
  };

  onKeyDown = (event) => {
    const eventIsFromSlateEditor = get(event, 'target.dataset.slateEditor');
    if (isFastSwitchModalKey(event) && !eventIsFromSlateEditor) {
      if (get(this.state.modalOptions, 'key', '') === 'fast-switcher-modal') {
        this.closeModal();
      } else {
        this.hideConfirmation();
        this.showModal({
          key: 'fast-switcher-modal',
          children: <FastSwitcherNavigationModal />,
        });
      }
    } else if (event.keyCode === 27) {
      // Dismiss on Esc
      this.hideConfirmation();
    }
  };

  // loading context
  setLoading = (loading) => this.setState({ loading });

  // conformation context
  showConfirmation = (confirmation) => this.setState({ confirmation });
  hideConfirmation = () => {
    if (!isEmpty(this.state.confirmation)) {
      this.setState({
        confirmation: extend(this.state.confirmation, {
          closingConfirmation: true,
        }),
      });
      setTimeout(() => {
        this.setState({ confirmation: null });
      }, 50);
    }
  };

  // modal context
  showModal = (modalOptions) => this.setState({ modalOptions });

  closeModal = () => {
    if (!isEmpty(this.state.modalOptions)) {
      this.setState({
        modalOptions: extend(this.state.modalOptions, { closingModal: true }),
      });
      setTimeout(() => {
        this.setState({ modalOptions: null });
      }, 50);
    }
  };

  // analytics context functions
  loadAnalytics = () => {
    const segmentKey = process.env.REACT_APP_SEGMENT_API_KEY;

    // @ts-ignore
    var analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked)
        window.console &&
          console.error &&
          console.error('Segment snippet included twice.');
      else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
        ];
        analytics.factory = function (t) {
          return function () {
            var e = Array.prototype.slice.call(arguments);
            e.unshift(t);
            analytics.push(e);
            return analytics;
          };
        };
        for (var t = 0; t < analytics.methods.length; t++) {
          var e = analytics.methods[t];
          analytics[e] = analytics.factory(e);
        }
        analytics.load = function (t, e) {
          var n = document.createElement('script');
          n.type = 'text/javascript';
          n.async = !0;
          n.src =
            'https://cdn.segment.com/analytics.js/v1/' +
            t +
            '/analytics.min.js';
          var a = document.getElementsByTagName('script')[0];
          a.parentNode.insertBefore(n, a);
          analytics._loadOptions = e;
        };
        analytics.SNIPPET_VERSION = '4.16.1';
        analytics.load(segmentKey);
      }
    // @ts-ignore
    window.analytics.ready(() => {
      // @ts-ignore
      this.analytics = window.analytics;
      // @ts-ignore
      this.mixpanel = window.mixpanel;

      // Register initial page view event. Subsequent page view events are detected
      // automatically in the route change listener registered in componentWillMount
      this.page();
    });
  };

  increment = (name) => {
    this.mixpanel && this.mixpanel.people.increment(`${name} Count`);
    this.analytics &&
      this.analytics.identify({ [`Last ${name}`]: new Date().toISOString() });
  };

  track = (event, props) =>
    this.analytics &&
    this.analytics.track(event, {
      Page: getCurrentPageName(window.location.pathname),
      ...props,
    });

  getCount = (name) => {
    if (this.analytics) {
      this.user = this.user || this.analytics.user();
      return (this.user.traits()[`${name} Count`] || 0) + 1;
    }
    return 1;
  };

  // Page view event is not added to App.state or AnalyticsContext the way the
  // rest of the tracking events are because page should be called automatically
  // by the route change listener in this component. We shouldn't ever need to call
  // page manually from other components
  page = () => {
    // Derive page category from the pathname such that ie /employees/recent
    // receives the category `Employees`
    const category =
      capitalize(window.location.pathname.substr(1).split('/')[0]) || 'Home';
    this.analytics && this.analytics.page(category);
  };

  identify = (...data) => this.analytics && this.analytics.identify(...data);

  group = (id, traits) => {
    if (this.analytics) {
      this.analytics.group(id, traits);
      this.analytics.identify(traits);
    }
  };

  resetAnalytics = () => this.analytics && this.analytics.reset();

  // message context functions
  addMessage = (message) => {
    if (message.body) {
      const id = `${new Date().getTime()}${message.body}`;
      this.setState(
        (prevState) => ({
          messages: [
            // Prevent message duplication
            ...prevState.messages.filter(
              (existingMessage) => existingMessage.body !== message.body,
            ),
            { ...message, id },
          ],
        }),
        () => {
          if (message.timeout === -1) return;
          setTimeout(
            () =>
              this.setState((prevState) => ({
                messages: [
                  ...prevState.messages.filter(
                    (existingMessage) => existingMessage.id !== id,
                  ),
                ],
              })),
            message.timeout || MESSAGE_TIMEOUT,
          );
        },
      );
      return id;
    }
  };

  /**
   * Remove messages by id or body
   *
   */
  removeMessage = ({ id, body }) => {
    this.setState((prevState) => ({
      ...prevState,
      messages: prevState.messages.filter((m) =>
        id ? m.id !== id : m.body !== body,
      ),
    }));
  };
  setPreviewingAsEmployee = (previewingAsEmployee) => {
    this.setState((prevState) => ({
      ...prevState,
      previewingAsEmployee: previewingAsEmployee,
    }));
  };

  render() {
    const { messages, confirmation, modalOptions, loading } = this.state;
    return (
      <Router history={history}>
        <AnalyticsContext.Provider value={this.state}>
          <PreviewContext.Provider value={this.state}>
            <ConfirmationContext.Provider value={this.state}>
              <ModalContext.Provider value={this.state}>
                <MessagesContext.Provider value={this.state}>
                  <LoadingContext.Provider value={this.state}>
                    <QueryParamProvider ReactRouterRoute={Route}>
                      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                        <div id='app'>
                          <Messages
                            messages={messages}
                            removeMessage={this.removeMessage}
                          />
                          <Switch>
                            {/* Routes that don't require user to be logged in live here */}
                            <Route
                              exact
                              path={routes.SIGN_UP}
                              render={({ history, location }) => (
                                <ErrorBoundary key={location.pathname}>
                                  <AuthPage
                                    authType='signUp'
                                    history={history}
                                    location={location}
                                  />
                                </ErrorBoundary>
                              )}
                            />
                            <Route
                              exact
                              path={routes.LOG_IN}
                              render={({ history, location }) => (
                                <ErrorBoundary key={location.pathname}>
                                  <AuthPage
                                    authType='logIn'
                                    history={history}
                                    location={location}
                                  />
                                </ErrorBoundary>
                              )}
                            />
                            <Route
                              exact
                              path={routes.NEW_HIRE_TASK_COMPLETE}
                              render={({ match, location }) => (
                                <ErrorBoundary key={location.pathname}>
                                  <TaskCompletePage id={match.params.id} />
                                </ErrorBoundary>
                              )}
                            />
                            {/* These are the routes that require an authenticated user */}
                            <Route
                              render={() => (
                                <ErrorBoundary>
                                  <AppSession
                                    loading={loading}
                                    confirmation={confirmation}
                                    onConfirmationDismiss={
                                      this.hideConfirmation
                                    }
                                    modalOptions={modalOptions}
                                    onModalDismiss={this.closeModal}
                                  />
                                </ErrorBoundary>
                              )}
                            />
                          </Switch>
                          <div id='flyouts'></div>
                          <div id='poppers'></div>
                        </div>
                      </GoogleOAuthProvider>
                    </QueryParamProvider>
                  </LoadingContext.Provider>
                </MessagesContext.Provider>
              </ModalContext.Provider>
            </ConfirmationContext.Provider>
          </PreviewContext.Provider>
        </AnalyticsContext.Provider>
      </Router>
    );
  }
}

export default App;
