import { get, isEmpty, isUndefined } from 'lodash';
import { Column } from 'react-base-table';

export const createErrorsMap = ({ rows, changedRowsMap }) => {
  return rows.reduce((acc, row) => {
    if (!isEmpty(row.errors)) {
      acc[row.id] = row.errors.filter((e) =>
        isUndefined(get(changedRowsMap, [row.id, e.key])),
      );
    }
    return acc;
  }, {});
};

export const createWarningsMap = ({ rows, changedRowsMap }) => {
  return rows.reduce((acc, row) => {
    if (!isEmpty(row.warnings)) {
      acc[row.id] = row.warnings.filter((e) =>
        isUndefined(get(changedRowsMap, [row.id, e.key])),
      );
    }
    return acc;
  }, {});
};

export const generateColumns = (fields) => {
  return fields.map((cf) => {
    return cf.name === 'Last name'
      ? {
          title: cf.name,
          errorsExistMark: true,
          frozen: Column.FrozenDirection.LEFT,
        }
      : { title: cf.name };
  });
};
