const fieldGroupSubscriptionHandlers = {
  'onFieldGroupCreated.getFieldGroupsQuery': ({
    client,
    subscriptionData,
    query,
    variables,
  }) => {
    const fieldGroup = subscriptionData.data.fieldGroupCreated;

    const fromCache = client.readQuery({
      query,
      variables,
    });

    client.writeQuery({
      query,
      variables,
      data: {
        ...fromCache,
        fieldGroups: [
          ...fromCache.fieldGroups.filter((fg) => fg.id !== fieldGroup.id),
          fieldGroup,
        ],
      },
    });
  },
  'onFieldGroupDeleted.getFieldGroupsQuery': ({
    client,
    subscriptionData,
    query,
    variables,
  }) => {
    const fieldGroupId = subscriptionData.data.fieldGroupDeleted;

    const fromCache = client.readQuery({
      query,
      variables,
    });

    client.writeQuery({
      query,
      variables,
      data: {
        ...fromCache,
        fieldGroups: [
          ...fromCache.fieldGroups.filter((fg) => fg.id !== fieldGroupId),
        ],
      },
    });
  },
};

export default fieldGroupSubscriptionHandlers;
