import classNames from 'classnames';
import { node, oneOf, string } from 'prop-types';
import React from 'react';

/**
 * Renders a rectangular div with rounded edges, if a background or border class is passed in.
 * Useful for displaying "tags".
 */
const Tag = ({
  children,
  className = null,
  size = 'default',
  ...restProps
}) => {
  return (
    <div
      className={classNames('d-inline-block rounded-large', className, {
        'padding-y-1 padding-x-3': size === 'default',
        'padding-y-2 padding-x-3': size === 'large',
      })}
      {...restProps}
    >
      {children}
    </div>
  );
};

Tag.propTypes = {
  className: string,
  children: node.isRequired,
  size: oneOf(['default', 'large']),
};

export { Tag };
