import { any, bool, object } from 'prop-types';
import React from 'react';

import Page from 'sora-client/components/common/Page';

import EmployeesNav from './EmployeesNav';

const EmployeeWrapperPage = ({ session, hideNav, children }) => {
  return (
    <Page id='employees-page' category='Employees' layout='flush'>
      {!hideNav && <EmployeesNav session={session} />}
      {children}
    </Page>
  );
};

EmployeeWrapperPage.propTypes = {
  session: object.isRequired,
  hideNav: bool,
  children: any.isRequired,
};

export default EmployeeWrapperPage;
