import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  DEFAULT_INTEGRATION,
} from '../../common';

export const SORA_INTEGRATION_ID = 10;

export const SORA_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: SORA_INTEGRATION_ID,
  name: 'sora',
  label: 'Sora',
  description: 'The Sora platform',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/sora.png`,
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  configurable: () => false,
  configSchema: {},
};
