import { useMutation, useQuery } from '@apollo/react-hooks';
import { compact, every, noop, orderBy } from 'lodash';
import { bool, func, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ApiErrorMessage } from 'sora-client/components/common/ApiErrorMessage';
import Button from 'sora-client/components/common/Button';
import Loading from 'sora-client/components/common/Loading';
import NoticeBox from 'sora-client/components/common/NoticeBox';
import history from 'sora-client/constants/history';
import {
  EMPLOYEES_DATA_MANAGEMENT,
  SUPER_ADMIN_COMPANY_BASE,
} from 'sora-client/constants/routes';
import { ConfirmationContext } from 'sora-client/contexts';

import { WorkflowStageAssociationRow } from './WorkflowStageAssociationRow';
import {
  COPY_WORKFLOW_TEMPLATE,
  CREATE_NEW_TEMPLATE,
  GET_WORKFLOW_STAGE_FIELDS_AND_REPLACEMENT_FIELDS,
} from './queries';

export const WorkflowStageAssociationModal = ({
  isCreatingTemplate = false,
  onClose = noop,
  workflowId,
}) => {
  const [workflowStages, setWorkflowStages] = useState([]);

  const { showConfirmation } = useContext(ConfirmationContext);

  const { data, loading } = useQuery(
    GET_WORKFLOW_STAGE_FIELDS_AND_REPLACEMENT_FIELDS,
    {
      variables: {
        timelineId: workflowId,
        forTemplateCompany: isCreatingTemplate,
      },
    },
  );

  const [copyTemplate, { loading: copyInProgress, error: copyError }] =
    useMutation(COPY_WORKFLOW_TEMPLATE);

  const [
    createTemplate,
    { loading: createTemplateInProgress, error: createTemplateError },
  ] = useMutation(CREATE_NEW_TEMPLATE);

  useEffect(() => {
    if (!data?.workflowStages) return;
    const nonSurveyFieldOrCalendarWorkflowStages = data.workflowStages.filter(
      (workflowStage) => {
        return (
          !workflowStage.calendarDate && !workflowStage.triggerField?.surveyId
        );
      },
    );

    const hasAddedToWorkflowStage = nonSurveyFieldOrCalendarWorkflowStages.some(
      (workflowStage) => !workflowStage.fieldId && !workflowStage.calendarDate,
    );

    if (!hasAddedToWorkflowStage) {
      // Prepend list with "Added to workflow" stage if it doesn't exist, since
      // the copy mechanism will create one for all new workflows always
      nonSurveyFieldOrCalendarWorkflowStages.push({
        id: -1,
        name: 'Added to workflow',
        description: null,
        fieldId: null,
        triggerField: null,
      });
    }

    setWorkflowStages(
      orderBy(nonSurveyFieldOrCalendarWorkflowStages, 'name').map(
        (workflowStage) => {
          return {
            id: workflowStage.id,
            fieldId: workflowStage.fieldId,
            name: workflowStage.name,
            replacementFieldId:
              data?.companyFields.find(
                // If any field exists in the target company with the same name
                // as the workflow stage, auto-select it (can still be modified by user)
                (field) => field.name === workflowStage.name,
              )?.id || null,
          };
        },
      ),
    );
  }, [data]);

  if (loading)
    return (
      <div className='padding-y-5 padding-x-4' style={{ width: 700 }}>
        <Loading />
      </div>
    );

  // Transform company fields into available field options for dropdown
  const availableFieldOptions = [
    {
      value: null,
      label: 'None',
      className: 'text-muted',
    },
  ].concat(
    orderBy(data?.companyFields, 'name').map((field) => ({
      className: null,
      value: field.id,
      label: field.name,
    })),
  );

  // Keep track of fields that have already been selected as replacement fields,
  // since we only allow fields to be used as a replacement field for a SINGLE
  // stage
  const fieldIdsInUse = compact(
    workflowStages.map((ws) => ws.replacementFieldId),
  );

  return (
    <>
      <div className='padding-y-5 padding-x-4' style={{ width: 700 }}>
        <h3 className='margin-top-0'>Select workflow dates</h3>
        <p>
          This template generates tasks based on the following date fields. Use
          the dropdowns to select which of your own Sora fields you&apos;d like
          to use for each step. You can use the{' '}
          <Link to={EMPLOYEES_DATA_MANAGEMENT}>data management page</Link> to
          create any missing fields before returning to this page. The selected
          field mappings will replace all instances of each date field in the
          workflow.
        </p>
        <p>Each date field may be selected exactly once.</p>
        <div className='d-flex margin-y-4 flex-direction-column align-items-center'>
          {workflowStages.map((workflowStage, idx) => (
            <WorkflowStageAssociationRow
              availableFieldOptions={availableFieldOptions}
              fieldIdsInUse={fieldIdsInUse}
              workflowStage={workflowStage}
              key={workflowStage.id}
              onSelect={({ targetFieldId }) =>
                setWorkflowStages(
                  workflowStages.map((workflowStage, wsIdx) => {
                    if (wsIdx === idx)
                      return {
                        ...workflowStage,
                        replacementFieldId: targetFieldId,
                      };
                    return workflowStage;
                  }),
                )
              }
              value={workflowStage.replacementFieldId}
            />
          ))}
        </div>
        <NoticeBox padding='padding-3' className='margin-bottom-3'>
          By clicking &quot;Copy template workflow&quot; a new inactive workflow
          will be created from this template. You must then activate the
          workflow for employees to be enrolled and for tasks to trigger.
        </NoticeBox>
        <div className='d-flex justify-content-end'>
          <Button
            label='Copy template workflow'
            disabled={
              !every(
                workflowStages.map(
                  // require all workflow stages that have a field ID (ie, everything
                  // besides "Added to workflow") to have a replacementFieldId selected
                  (ws) => !ws.fieldId || ws.replacementFieldId,
                ),
              )
            }
            category='action'
            loading={copyInProgress || createTemplateInProgress}
            onClick={async () => {
              const fieldReplacements = workflowStages.reduce(
                (replacementsObj, workflowStage) => {
                  if (!workflowStage.fieldId) return replacementsObj;
                  replacementsObj[workflowStage.fieldId] =
                    workflowStage.replacementFieldId;
                  return replacementsObj;
                },
                {},
              );
              try {
                if (isCreatingTemplate) {
                  await createTemplate({
                    variables: {
                      id: workflowId,
                      fieldReplacements,
                    },
                  });
                  showConfirmation({
                    type: 'info',
                    // eslint-disable-next-line react/display-name
                    BodyComponent: () => (
                      <div>
                        <div className='font-weight-500 text-large margin-y-3'>
                          Your template is being created. Please check the{' '}
                          templates company in a few moments for your new
                          workflow template.
                        </div>
                      </div>
                    ),
                    confirmButtonText: `Go to template company`,
                    onConfirm: () => {
                      window.location.pathname = SUPER_ADMIN_COMPANY_BASE;
                    },
                  });
                  onClose();
                } else {
                  const { data } = await copyTemplate({
                    variables: {
                      id: workflowId,
                      fieldReplacements,
                    },
                  });
                  history.push(`/workflow/${data.copyTemplate.id}/configure`);
                }
              } catch (err) {
                console.error(err);
              }
            }}
          />
        </div>
        {(copyError || createTemplateError) && (
          <ApiErrorMessage
            className='margin-top-2'
            error={copyError || createTemplateError}
          />
        )}
      </div>
    </>
  );
};

WorkflowStageAssociationModal.propTypes = {
  isCreatingTemplate: bool,
  onClose: func,
  workflowId: string,
};
