import gql from 'graphql-tag';

export const GET_CURRENT_USER = gql`
  query getCurrentUserOperation {
    currentUser {
      id
      email
      employeeId
      companyId
      company {
        id
        name
        logoUrl
        brandColors {
          navBarColor
          navLinkColor
        }
        ownerId
        timezone
        demo
        enablePremiumFeatures
        isWorkflowTemplatesCompany
      }
      hasSoraAccount
      hasFullUIAccess
      hasPremiumAccess
      allGrants {
        id
        resource
        action
        level
        condition
      }
      permissionGroups {
        id
        name
      }
      employee {
        id
        name
        imageUrl
      }
      # Need directReportsIds for permissions checks
      directReportsIds
      # Need invitedTimelines for workflow collaborator permission checks
      invitedTimelines
    }
  }
`;
