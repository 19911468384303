import { object, string } from 'prop-types';
import React, { useContext } from 'react';
import { useApolloClient } from 'react-apollo-hooks';
import { Link } from 'react-router-dom';

import CompanyLogo from 'sora-client/components/CompanyLogo';
import FastSwitcherNavigationModal from 'sora-client/components/FastSwitcherNavigationModal';
import TalkToSalesModal from 'sora-client/components/TalkToSalesModal';
import Avatar from 'sora-client/components/common/Avatar';
import Button from 'sora-client/components/common/Button';
import StaticDropdown from 'sora-client/components/common/StaticDropdown';
import {
  ExternalLinkSvg,
  MenuSwitcherSvg,
  SettingsSvg,
} from 'sora-client/components/common/Svg';
import * as routes from 'sora-client/constants/routes';
import { ModalContext } from 'sora-client/contexts';
import { getEmployeeLink } from 'sora-client/helpers';
import { userAuthorization } from 'sora-client/helpers/authorization';

import {
  getNavBarColor,
  getNavLinkBorderColor,
  getNavLinkColor,
  getUserAccountNavItems,
  resetAdminToken,
} from '../utilities';
import NavItem from './NavItem';
import NavItems from './NavItems';
import NotMobileUserProfile from './NotMobileUserProfile';

const COMPANY_LOGO_WIDTH_HEIGHT = 44;

const NotMobileNavigation = ({ session, currentNavItemCategory }) => {
  const { ua } = session;
  const ac = userAuthorization(session.currentUser);
  const userAccountNavItems = getUserAccountNavItems({
    session,
  });

  const switchBackToken = localStorage.getItem('switchBackToken');
  const client = useApolloClient();
  const { showModal } = useContext(ModalContext);

  if (switchBackToken) {
    userAccountNavItems.unshift({
      html: (
        <div
          className='dropdown-item bg-sunrise'
          onClick={() => resetAdminToken({ client })}
        >
          Switch back to admin account
        </div>
      ),
    });
  }

  userAccountNavItems.unshift(
    {
      closeOnClick: true,
      html: (
        <Link
          key='profile'
          className='d-flex align-items-center padding-x-4 mobile-padding-x-3 padding-y-2 text-default bg-hover-gray'
          to={getEmployeeLink(session.currentUser.employee)}
        >
          <Avatar
            imageSrc={session.currentUser.employee.imageUrl}
            widthHeight={48}
            employeeName={session.currentUser.employee.name}
            employeeId={session.currentUser.employee.id}
          />
          <div className='margin-left-3'>
            <div className='font-weight-500'>
              {session.currentUser.employee.name}
            </div>
            <div className='text-midnight-navy-75 margin-top-1'>
              View profile
            </div>
          </div>
        </Link>
      ),
    },
    {
      html: <hr className='fade-40' />,
    },
  );

  const navBarColor = getNavBarColor({ session });
  const navLinkColor = getNavLinkColor({ session });
  const navLinkBorderColor = getNavLinkBorderColor({ session });

  // for non-active users
  if (!ua.isActive) {
    return (
      <div
        id='site-nav'
        className='d-flex align-items-center padding-x-5 padding-y-3'
        style={{ backgroundColor: navBarColor }}
      >
        <div className='d-flex justify-content-center text-default'>
          <CompanyLogo
            session={session}
            widthHeight={COMPANY_LOGO_WIDTH_HEIGHT}
          />
        </div>
      </div>
    );
  }

  const canEditNotificationsSettings = ac.can({
    resource: 'Company',
    action: 'EDIT',
    instance: { id: session.currentUser.companyId },
  });

  const canEditIntegrationsSettings = ac.can({
    resource: 'Integration',
    action: 'EDIT',
    instance: { companyId: session.currentUser.companyId },
  });

  const hasPremiumAccess = session.currentUser.hasPremiumAccess;
  const isWorkflowTemplatesCompany =
    session.currentUser.company.isWorkflowTemplatesCompany;

  return (
    <>
      <div
        id='site-nav'
        className='d-flex align-items-center padding-x-5'
        style={{ backgroundColor: navBarColor }}
      >
        <Link to={routes.HOME} style={{ height: COMPANY_LOGO_WIDTH_HEIGHT }}>
          <div className='d-flex justify-content-center text-default'>
            <CompanyLogo
              session={session}
              widthHeight={COMPANY_LOGO_WIDTH_HEIGHT}
            />
          </div>
        </Link>
        <div className='margin-left-3' />
        <div className='flex-grow-1 d-flex align-items-center'>
          <NavItems
            session={session}
            showIcons={false}
            currentNavItemCategory={currentNavItemCategory}
            titleStyle={{ color: navLinkColor }}
            selectedBorderStyle={{ backgroundColor: navLinkBorderColor }}
            activeTitleClassname='padding-y-1'
            inactiveTitleClassname='fade-70 padding-y-1'
          />
        </div>
        <div className='d-inline-flex align-items-center'>
          {!hasPremiumAccess && (
            <Button
              className='margin-right-3'
              label='Upgrade account'
              category='primary'
              onClick={() => {
                showModal({
                  children: <TalkToSalesModal />,
                });
              }}
            />
          )}
          <div className='d-inline-flex align-items-center'>
            <NavItem
              navItemRoute={{
                pathname: 'https://help.sora.co/',
              }}
              target='_blank'
              icon={<ExternalLinkSvg />}
              title='Help'
              navItemCategory='Help'
              currentNavItemCategory={currentNavItemCategory}
              showIcon={false}
              titleStyle={{ color: navLinkColor }}
              selectedBorderStyle={{ backgroundColor: navLinkBorderColor }}
              activeTitleClassname='padding-y-1'
              inactiveTitleClassname='fade-70 padding-y-1'
            />
          </div>
          {!isWorkflowTemplatesCompany &&
            (canEditNotificationsSettings || canEditIntegrationsSettings) && (
              <div className='margin-right-3'>
                <NavItem
                  navItemRoute={
                    canEditNotificationsSettings
                      ? routes.SETTINGS_NOTIFICATIONS
                      : routes.SETTINGS_INTEGRATIONS
                  }
                  icon={<SettingsSvg />}
                  title='Settings'
                  navItemCategory='Settings'
                  currentNavItemCategory={currentNavItemCategory}
                  showIcon={false}
                  titleStyle={{ color: navLinkColor }}
                  selectedBorderStyle={{ backgroundColor: navLinkBorderColor }}
                  activeTitleClassname='padding-y-1'
                  inactiveTitleClassname='fade-70 padding-y-1'
                />
              </div>
            )}
          <StaticDropdown
            dropdownToggle={<NotMobileUserProfile session={session} />}
            popperClassname='not-mobile-user-popup margin-top-3'
            placement='bottom-end'
            dropdownItems={userAccountNavItems}
            otherInnerDropdownClasses='desktop-dropdown margin-left-2'
          />
          {session.currentUser.hasFullUIAccess &&
            !session.currentUser.company.demo && (
              <div className='margin-left-4 svg-parent-align-text'>
                <MenuSwitcherSvg
                  className='nav-fast-switcher-invoker'
                  style={{ stroke: navLinkColor }}
                  onClick={() => {
                    showModal({
                      key: 'fast-switcher-modal',
                      children: <FastSwitcherNavigationModal />,
                    });
                  }}
                />
              </div>
            )}
        </div>
      </div>
    </>
  );
};

NotMobileNavigation.propTypes = {
  session: object.isRequired,
  currentNavItemCategory: string,
};

export default NotMobileNavigation;
