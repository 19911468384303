import gql from 'graphql-tag';

/*
 * It is VITAL to keep the checkCurrentUserTokenNoRefresh
 * name so the server knows NOT to refresh the token on
 * the call to get current user. Otherwise it will refresh
 * and the timeout will not work.
 */
export const GET_CURRENT_USER = gql`
  query checkCurrentUserTokenNoRefresh {
    currentUser {
      id
    }
  }
`;

/**
 * Note the suffix `WithRefresh` is meaningless; it's just
 * semantic to differentiate it from the `NoRefresh` call
 * above which explicitly excludes the token from being
 * refreshed.
 */
export const GET_CURRENT_USER_WITH_REFRESH = gql`
  query checkCurrentUserTokenWithRefresh {
    currentUser {
      id
    }
  }
`;
