import { any, object } from 'prop-types';
import React from 'react';

import Page from 'sora-client/components/common/Page';

const NewHireTaskWrapperPage = ({ session, children }) => {
  return (
    <Page id='new-hire-task-page' category='Tasks' layout='flush'>
      <h2 className='font-p22-mackinac margin-top-5 padding-x-5 mobile-padding-x-3'>
        Tasks
      </h2>
      {children}
    </Page>
  );
};

NewHireTaskWrapperPage.propTypes = {
  session: object.isRequired,
  children: any.isRequired,
};

export default NewHireTaskWrapperPage;
