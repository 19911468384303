import classNames from 'classnames';
import { any, bool, func, string } from 'prop-types';
import React from 'react';

const svgDefaultProps = {
  viewBox: `0 0 24 24`,
};

export const Svg = ({ noChangeOnHover = false, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox={props.viewBox}
    onClick={props.onClick}
    {...props}
    className={classNames('sora-svg', props.className, {
      disabled: props.disabled,
      'no-hover-change': noChangeOnHover,
    })}
  >
    {props.children}
  </svg>
);

Svg.propTypes = {
  viewBox: string,
  onClick: func,
  className: string,
  children: any,
  disabled: bool,
  noChangeOnHover: bool,
};
Svg.defaultProps = svgDefaultProps;

export const EmailSvg = (props) => (
  <Svg {...props}>
    <path d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z' />
    <polyline points='22,6 12,13 2,6' />
  </Svg>
);

export const XSvg = (props) => (
  <Svg {...props}>
    <line x1='18' y1='6' x2='6' y2='18' />
    <line x1='6' y1='6' x2='18' y2='18' />
  </Svg>
);

XSvg.defaultProps = svgDefaultProps;

export const SearchSvg = (props) => (
  <Svg {...props}>
    <circle cx='10.5' cy='10.5' r='7.5' />
    <line x1='21' y1='21' x2='15.8' y2='15.8' />
  </Svg>
);

SearchSvg.defaultProps = svgDefaultProps;

export const TagSvg = (props) => (
  <Svg {...props}>
    <path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z' />
    <line x1='7' y1='7' x2='7' y2='7' />
  </Svg>
);

TagSvg.defaultProps = svgDefaultProps;

export const CornerDownRightSvg = (props) => (
  <Svg {...props}>
    <polyline points='15 10 20 15 15 20' />
    <path d='M4 4v7a4 4 0 0 0 4 4h12' />
  </Svg>
);

export const CreditCardSvg = (props) => (
  <Svg {...props}>
    <rect x='1' y='4' width='22' height='16' rx='2' ry='2' />
    <line x1='1' y1='10' x2='23' y2='10' />
  </Svg>
);

export const LeftArrowSvg = (props) => (
  <Svg {...props}>
    <line x1='19' y1='12' x2='5' y2='12' />
    <polyline points='12 19 5 12 12 5' />
  </Svg>
);

LeftArrowSvg.defaultProps = svgDefaultProps;

export const RightArrowSvg = (props) => (
  <Svg {...props}>
    <line x1='5' y1='12' x2='19' y2='12' />
    <polyline points='12 5 19 12 12 19' />
  </Svg>
);

RightArrowSvg.defaultProps = svgDefaultProps;

export const UpArrowSvg = (props) => (
  <Svg {...props}>
    <polyline points='18 15 12 9 6 15' />
  </Svg>
);

UpArrowSvg.defaultProps = svgDefaultProps;

export const DownArrowSvg = (props) => (
  <Svg {...props}>
    <polyline points='6 9 12 15 18 9' />
  </Svg>
);

DownArrowSvg.defaultProps = svgDefaultProps;

export const UpDownArrowSvg = (props) => (
  <Svg {...props}>
    <polyline points='18 6 12 1 6 6' />
    <line x1='12' y1='1' x2='12' y2='21' />
    <polyline points='6 16 12 21 18 16' />
  </Svg>
);

UpDownArrowSvg.defaultProps = svgDefaultProps;

export const LoadingSvg = (props) => (
  <Svg {...props} style={{ cursor: 'default', ...props.style }}>
    <path
      id='spinner'
      d='M40,72C22.4,72,8,57.6,8,40C8,22.4,22.4,8,40,8c17.6,0,32,14.4,32,32c0,1.1-0.9,2-2,2s-2-0.9-2-2c0-15.4-12.6-28-28-28S12,24.6,12,40s12.6,28,28,28c1.1,0,2,0.9,2,2S41.1,72,40,72z'
    >
      <animateTransform
        attributeType='xml'
        attributeName='transform'
        type='rotate'
        from='0 40 40'
        to='360 40 40'
        dur='0.6s'
        repeatCount='indefinite'
      />
    </path>
  </Svg>
);

LoadingSvg.defaultProps = {
  viewBox: `0 0 80 80`,
};

export const RepeatSvg = (props) => (
  <Svg {...props}>
    <polyline points='17 1 21 5 17 9'></polyline>
    <path d='M3 11V9a4 4 0 0 1 4-4h14'></path>
    <polyline points='7 23 3 19 7 15'></polyline>
    <path d='M21 13v2a4 4 0 0 1-4 4H3'></path>
  </Svg>
);

export const MaxSvg = (props) => (
  <Svg {...props}>
    <polyline points='15 3 21 3 21 9' />
    <polyline points='9 21 3 21 3 15' />
    <line x1='21' y1='3' x2='14' y2='10' />
    <line x1='3' y1='21' x2='10' y2='14' />
  </Svg>
);

MaxSvg.defaultProps = svgDefaultProps;

export const Max2Svg = (props) => (
  <Svg {...props}>
    <path d='M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3'></path>
  </Svg>
);

MaxSvg.defaultProps = svgDefaultProps;

export const MinSvg = (props) => (
  <Svg {...props}>
    <polyline points='4 14 10 14 10 20'></polyline>
    <polyline points='20 10 14 10 14 4'></polyline>
    <line x1='14' y1='10' x2='21' y2='3'></line>
    <line x1='3' y1='21' x2='10' y2='14'></line>
  </Svg>
);

export const PlusSvg = (props) => (
  <Svg {...props}>
    <line x1='12' y1='5' x2='12' y2='19' />
    <line x1='5' y1='12' x2='19' y2='12' />
  </Svg>
);

export const PlusCircleSvg = (props) => (
  <Svg {...props}>
    <circle cx='12' cy='12' r='10'></circle>
    <line x1='12' y1='8' x2='12' y2='16'></line>
    <line x1='8' y1='12' x2='16' y2='12'></line>
  </Svg>
);

export const SquareSvg = (props) => (
  <Svg {...props}>
    <rect x='3' y='3' width='18' height='18' rx='2' ry='2' />
  </Svg>
);

export const CheckedSquareSvg = (props) => (
  <Svg {...props}>
    <polyline points='9 11 12 14 22 4' />
    <path d='M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11' />
  </Svg>
);

export const BackArrowSvg = (props) => (
  <Svg {...props}>
    <line x1='19' y1='12' x2='5' y2='12' />
    <polyline points='12 19 5 12 12 5' />
  </Svg>
);

export const ForwardArrowSvg = (props) => (
  <Svg {...props}>
    <line x1='5' y1='12' x2='19' y2='12' />
    <polyline points='12 5 19 12 12 19' />
  </Svg>
);

export const SettingsSvg = ({ fillColor = undefined, ...props }) => (
  <Svg {...props}>
    <path
      fill={fillColor}
      d='M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z'
    />
    <circle cx='12' cy='12' r='3' fill={fillColor} />
  </Svg>
);

SettingsSvg.propTypes = {
  fillColor: string,
};

export const ShieldSvg = (props) => (
  <Svg {...props}>
    <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z' />
  </Svg>
);

export const SidebarSvg = (props) => (
  <Svg {...props}>
    <rect x='3' y='3' width='18' height='18' rx='2' ry='2' />
    <line x1='9' y1='3' x2='9' y2='21' />
  </Svg>
);

export const LeftSidebarFilledSvg = ({
  fillColor = undefined,
  stroke = undefined,
  ...props
}) => (
  <Svg {...props}>
    <rect x='3' y='3' width='18' height='18' rx='2' ry='2' stroke={stroke} />
    <rect
      x='4'
      y='4'
      width='7'
      height='16'
      rx='0'
      ry='0'
      fill={fillColor}
      strokeWidth={0}
    />
  </Svg>
);

export const RightSidebarFilledSvg = ({
  fillColor = undefined,
  stroke = undefined,
  ...props
}) => (
  <Svg {...props}>
    <rect x='3' y='3' width='18' height='18' rx='2' ry='2' stroke={stroke} />
    <rect
      x='13'
      y='4'
      width='7'
      height='16'
      rx='0'
      ry='0'
      fill={fillColor}
      strokeWidth={0}
    />
  </Svg>
);

export const UsersSvg = (props) => (
  <Svg {...props}>
    <path d='M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2' />
    <circle cx='9' cy='7' r='4' />
    <path d='M23 21v-2a4 4 0 0 0-3-3.87' />
    <path d='M16 3.13a4 4 0 0 1 0 7.75' />
  </Svg>
);

export const TrendingUpSvg = (props) => (
  <Svg {...props}>
    <polyline points='23 6 13.5 15.5 8.5 10.5 1 18' />
    <polyline points='17 6 23 6 23 12' />
  </Svg>
);

export const TimelineSvg = (props) => (
  <Svg {...props}>
    <circle cx='18' cy='18' r='3' />
    <circle cx='6' cy='6' r='3' />
    <path d='M13 6h3a2 2 0 0 1 2 2v7' />
    <line x1='6' y1='9' x2='6' y2='21' />
  </Svg>
);

export const HelpQuestionSvg = (props) => (
  <Svg {...props}>
    <circle cx='12' cy='12' r='10'></circle>
    <path d='M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3'></path>
    <line x1='12' y1='17' x2='12.01' y2='17'></line>
  </Svg>
);

export const BellSvg = (props) => (
  <Svg {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      d='M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0'
    />
  </Svg>
);

export const UserPlusSvg = (props) => (
  <Svg {...props}>
    <path d='M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2'></path>
    <circle cx='8.5' cy='7' r='4'></circle>
    <line x1='20' y1='8' x2='20' y2='14'></line>
    <line x1='23' y1='11' x2='17' y2='11'></line>
  </Svg>
);

export const UserCheckSvg = (props) => (
  <Svg {...props}>
    <path d='M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2' />
    <circle cx='8.5' cy='7' r='4' />
    <polyline points='17 11 19 13 23 9' />
  </Svg>
);

export const CheckSvg = (props) => (
  <Svg {...props}>
    <polyline points='20 6 9 17 4 12'></polyline>
  </Svg>
);

export const ExternalLinkSvg = (props) => (
  <Svg {...props}>
    <path d='M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6' />
    <polyline points='15 3 21 3 21 9' />
    <line x1='10' y1='14' x2='21' y2='3' />
  </Svg>
);

export const ClockSvg = (props) => (
  <Svg {...props}>
    <circle cx='12' cy='12' r='10'></circle>
    <polyline points='12 6 12 12 16 14'></polyline>
  </Svg>
);

export const UserSvg = (props) => (
  <Svg {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2'
    />
    <circle xmlns='http://www.w3.org/2000/svg' cx='12' cy='7' r='4' />
  </Svg>
);

export const EditSvg = (props) => (
  <Svg {...props}>
    <polygon data-testid='edit' points='16 3 21 8 8 21 3 21 3 16 16 3' />
  </Svg>
);

export const EditWithPaperSvg = (props) => (
  <Svg {...props}>
    <path d='M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34' />
    <polygon points='18 2 22 6 12 16 8 16 8 12 18 2' />
  </Svg>
);

export const FileSvg = (props) => (
  <Svg {...props}>
    <path d='M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z' />
    <polyline points='14 2 14 8 20 8' />
    <line x1='16' y1='13' x2='8' y2='13' />
    <line x1='16' y1='17' x2='8' y2='17' />
    <polyline points='10 9 9 9 8 9' />
  </Svg>
);

export const CircleSvg = (props) => (
  <Svg {...props}>
    <circle cx='12' cy='12' r='10' />
  </Svg>
);

export const CheckedCircleSvg = (props) => (
  <Svg {...props}>
    <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14' />
    <polyline points='22 4 12 14.01 9 11.01' />
  </Svg>
);

export const MinusSvg = (props) => (
  <Svg {...props}>
    <line x1='5' y1='12' x2='19' y2='12'></line>
  </Svg>
);

export const MinusCircleSvg = (props) => (
  <Svg {...props}>
    <circle cx='12' cy='12' r='10' />
    <line x1='8' y1='12' x2='16' y2='12' />
  </Svg>
);

export const TrashSvg = (props) => (
  <Svg {...props}>
    <polyline points='3 6 5 6 21 6' />
    <path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2' />
  </Svg>
);

export const ArchiveSvg = (props) => (
  <Svg {...props}>
    <polyline points='21 8 21 21 3 21 3 8' />
    <rect x='1' y='3' width='22' height='5' />
    <line x1='10' y1='12' x2='14' y2='12' />
  </Svg>
);

export const WarningSvg = (props) => (
  <Svg {...props}>
    <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z' />
    <line x1='12' y1='9' x2='12' y2='13' />
    <line x1='12' y1='17' x2='12' y2='17' />
  </Svg>
);

export const WarningCircleSvg = (props) => (
  <Svg {...props}>
    <circle cx='12' cy='12' r='10' />
    <line x1='12' y1='8' x2='12' y2='12' />
    <line x1='12' y1='16' x2='12.01' y2='16' />
  </Svg>
);

export const InfoSvg = ({ stroke = undefined, ...props }) => (
  <Svg {...props}>
    <circle stroke={stroke} cx='12' cy='12' r='10' />
    <line stroke={stroke} x1='12' y1='16' x2='12' y2='12' />
    <line stroke={stroke} x1='12' y1='8' x2='12' y2='8' />
  </Svg>
);

export const BoldSvg = (props) => (
  <Svg {...props}>
    <path d='M6 4h8a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z' />
    <path d='M6 12h9a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z' />
  </Svg>
);

export const ItalicSvg = (props) => (
  <Svg {...props}>
    <line x1='19' y1='4' x2='10' y2='4' />
    <line x1='14' y1='20' x2='5' y2='20' />
    <line x1='15' y1='4' x2='9' y2='20' />
  </Svg>
);

export const UnderlineSvg = (props) => (
  <Svg {...props}>
    <path d='M6 3v7a6 6 0 0 0 6 6 6 6 0 0 0 6-6V3' />
    <line x1='4' y1='21' x2='20' y2='21' />
  </Svg>
);

export const CodeSvg = (props) => (
  <Svg {...props}>
    <polyline points='16 18 22 12 16 6' />
    <polyline points='8 6 2 12 8 18' />
  </Svg>
);

export const ListSvg = (props) => (
  <Svg {...props}>
    <line x1='8' y1='6' x2='21' y2='6' />
    <line x1='8' y1='12' x2='21' y2='12' />
    <line x1='8' y1='18' x2='21' y2='18' />
    <line x1='3' y1='6' x2='3' y2='6' />
    <line x1='3' y1='12' x2='3' y2='12' />
    <line x1='3' y1='18' x2='3' y2='18' />
  </Svg>
);

export const NumberedListSvg = (props) => (
  <Svg {...props}>
    <line x1='8' y1='6' x2='21' y2='6' />
    <line x1='8' y1='12' x2='21' y2='12' />
    <line x1='8' y1='18' x2='21' y2='18' />
    <text x='1' y='8'>
      1
    </text>
    <text x='1' y='14'>
      2
    </text>
    <text x='1' y='20'>
      3
    </text>
  </Svg>
);

export const AlignCenterSvg = (props) => (
  <Svg {...props}>
    <line x1='18' y1='10' x2='6' y2='10' />
    <line x1='21' y1='6' x2='3' y2='6' />
    <line x1='21' y1='14' x2='3' y2='14' />
    <line x1='18' y1='18' x2='6' y2='18' />
  </Svg>
);

export const AlignLeftSvg = (props) => (
  <Svg {...props}>
    <line x1='17' y1='10' x2='3' y2='10' />
    <line x1='21' y1='6' x2='3' y2='6' />
    <line x1='21' y1='14' x2='3' y2='14' />
    <line x1='17' y1='18' x2='3' y2='18' />
  </Svg>
);

export const AlignRightSvg = (props) => (
  <Svg {...props}>
    <line x1='21' y1='10' x2='7' y2='10' />
    <line x1='21' y1='6' x2='3' y2='6' />
    <line x1='21' y1='14' x2='3' y2='14' />
    <line x1='21' y1='18' x2='7' y2='18' />
  </Svg>
);

export const LinkSvg = (props) => (
  <Svg {...props}>
    <path d='M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71' />
    <path d='M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71' />
  </Svg>
);

export const ButtonSvg = (props) => (
  <Svg {...props} viewBox='0 0 22 10'>
    <path d='M3 1H19C20.1 1 21 1.9 21 3V7C21 8.1 20.1 9 19 9H3C1.9 9 1 8.1 1 7V3C1 1.9 1.9 1 3 1Z' />
    <line x1='7' y1='5' x2='15' y2='5' />
  </Svg>
);

export const ColumnSvg = (props) => (
  <Svg {...props}>
    <path d='M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18'></path>
  </Svg>
);

export const DividerSvg = (props) => (
  <Svg {...props}>
    <line
      x1='1'
      y1='9'
      x2='21'
      y2='9'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11 5L11 1'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9 3L11 1L13 3'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11 13L11 17'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13 15L11 17L9 15'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export const ImageSvg = (props) => (
  <Svg {...props}>
    <rect x='3' y='3' width='18' height='18' rx='2' ry='2' />
    <circle cx='8.5' cy='8.5' r='1.5' />
    <polyline points='21 15 16 10 5 21' />
  </Svg>
);

export const PaperclipSvg = (props) => (
  <Svg {...props}>
    <path d='M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48'></path>
  </Svg>
);

export const QuoteSvg = (props) => (
  <Svg {...props} viewBox='0 0 64 64'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      d='M14.1933422,9.4116497c-7.8260994,0-14.1922989,6.3662004-14.1922989,14.1924   c0,7.5498009,5.9247999,13.7420998,13.3690996,14.169899c0.1288996,1.3916016,0.0321999,5.1797028-3.5977001,10.4491997   C9.4980431,48.6206512,9.547843,49.1567497,9.888648,49.497551c1.4853945,1.4853973,2.4033947,2.4208984,3.0458946,3.0751991   c0.8408995,0.8554993,1.2247,1.2461014,1.7861996,1.7559013c0.1904001,0.1727982,0.4306002,0.259697,0.6719055,0.259697   c0.2342949,0,0.4676943-0.0819969,0.6561956-0.2450981c6.3251991-5.5038986,13.3515987-16.8759995,12.3349991-30.8115005   C27.7881413,15.3501501,21.820343,9.4116497,14.1933422,9.4116497z M15.4023428,52.2221489   c-0.2723999-0.2684975-0.5830002-0.5848999-1.0410004-1.0508003c-0.5565996-0.5672989-1.3203001-1.3446999-2.4784994-2.5067978   c4.4053001-6.7881012,3.5731993-11.6230011,3.2089996-12.3164024c-0.1729002-0.3290977-0.5274-0.5507965-0.8985004-0.5507965   c-6.7225995,0-12.1922989-5.4697018-12.1922989-12.1933022c0-6.7227001,5.4696999-12.1924,12.1922989-12.1924   c6.5489006,0,11.6777992,5.1582012,12.1963062,12.2646008C27.5322418,39.3501511,18.2168427,49.5268517,15.4023428,52.2221489z'
    />
    <path
      xmlns='http://www.w3.org/2000/svg'
      d='M63.9004402,23.5317497v-0.0009995C63.302742,15.3501501,57.3340416,9.4116497,49.7090416,9.4116497   c-7.8261986,0-14.1933937,6.3662004-14.1933937,14.1924c0,7.5498009,5.9257927,13.7420998,13.3710938,14.169899   c0.1289062,1.3906021,0.0312004,5.1767006-3.5996017,10.4491997c-0.2743988,0.3975029-0.2245979,0.9336014,0.1162033,1.2744026   c1.4794998,1.4794998,2.3955002,2.4130974,3.0380974,3.0663986c0.8446999,0.8613014,1.2304993,1.2538986,1.7949028,1.7656021   c0.1903992,0.1718979,0.4315987,0.2587967,0.6718979,0.2587967c0.2344055,0,0.4678001-0.0819969,0.6562004-0.2460976   C57.8896484,48.8383484,64.9160385,37.4663506,63.9004402,23.5317497z M50.917942,52.2221489   c-0.2743988-0.2705002-0.5877991-0.5887985-1.0498009-1.0594978c-0.5565987-0.5665016-1.3172989-1.3418007-2.4706993-2.4981003   c4.4053001-6.7891006,3.5742989-11.6230011,3.2109985-12.3164024c-0.1728973-0.3280983-0.5282974-0.5507965-0.8993988-0.5507965   c-6.7237015,0-12.1933937-5.4697018-12.1933937-12.1933022c0-6.7227001,5.4696922-12.1924,12.1933937-12.1924   c6.5477982,0,11.6777,5.1582012,12.1972008,12.2656002v-0.0009995   C63.0478401,39.3481483,53.7324409,49.5268517,50.917942,52.2221489z'
    />
  </Svg>
);

export const MenuSvg = (props) => (
  <Svg {...props}>
    <line x1='3' y1='6' x2='21' y2='6' />
    <line x1='3' y1='12' x2='21' y2='12' />
    <line x1='3' y1='18' x2='21' y2='18' />
  </Svg>
);

export const MenuSwitcherSvg = (props) => (
  <Svg {...props}>
    <line x1='5' y1='6' x2='21' y2='6' />
    <line x1='11' y1='12' x2='21' y2='12' />
    <line x1='8' y1='18' x2='21' y2='18' />
  </Svg>
);

export const MoreVerticalSvg = (props) => (
  <Svg {...props}>
    <circle cx='12' cy='12' r='1'></circle>
    <circle cx='12' cy='5' r='1'></circle>
    <circle cx='12' cy='19' r='1'></circle>
  </Svg>
);

export const MoreHorizontalSvg = (props) => (
  <Svg {...props}>
    <circle cx='12' cy='12' r='1'></circle>
    <circle cx='19' cy='12' r='1'></circle>
    <circle cx='5' cy='12' r='1'></circle>
  </Svg>
);

export const HomeSvg = (props) => (
  <Svg {...props}>
    <path d='M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z' />
    <polyline points='9 22 9 12 15 12 15 22' />
  </Svg>
);

export const XCircleSvg = (props) => (
  <Svg {...props}>
    <circle cx='12' cy='12' r='10' />
    <line x1='15' y1='9' x2='9' y2='15' />
    <line x1='9' y1='9' x2='15' y2='15' />
  </Svg>
);

export const LockSvg = (props) => (
  <Svg {...props}>
    <rect x='3' y='11' width='18' height='11' rx='2' ry='2' />
    <path d='M7 11V7a5 5 0 0 1 10 0v4' />
  </Svg>
);

export const UnlockSvg = (props) => (
  <Svg {...props}>
    <rect x='3' y='11' width='18' height='11' rx='2' ry='2' />
    <path d='M15 11V7a5 5 0 0 1 10 0v4' />
  </Svg>
);

export const ChevronsLeftSvg = (props) => (
  <Svg {...props}>
    <polyline points='11 17 6 12 11 7' />
    <polyline points='18 17 13 12 18 7' />
  </Svg>
);

export const ChevronsRightSvg = (props) => (
  <Svg {...props}>
    <polyline points='13 17 18 12 13 7' />
    <polyline points='6 17 11 12 6 7' />
  </Svg>
);

export const EnterSvg = (props) => (
  <Svg {...props}>
    <polyline points='9 10 4 15 9 20' />
    <path d='M20 4v7a4 4 0 0 1-4 4H4' />
  </Svg>
);

export const NetworkSvg = (props) => (
  <Svg {...props}>
    <path d=' M 9 2 L 9 8 L 15 8 L 15 2 L 9 2 Z ' />
    <path d=' M 12 8 L 12 12 M 12 12 L 5 12 L 5 16 M 12 12 L 19 12 L 19 16' />
    <path d=' M 8 16 L 2 16 L 2 22 L 8 22 L 8 16 Z ' />
    <path d=' M 16 16 L 16 22 L 22 22 L 22 16 L 16 16 Z ' />
  </Svg>
);

export const ChevronLeftSvg = (props) => (
  <Svg {...props}>
    <polyline points='15 18 9 12 15 6' />
  </Svg>
);

export const ChevronRightSvg = (props) => (
  <Svg {...props}>
    <polyline points='9 18 15 12 9 6' />
  </Svg>
);

export const FilterSvg = (props) => (
  <Svg {...props}>
    <polygon points='22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3'></polygon>
  </Svg>
);

export const RefreshSvg = (props) => (
  <Svg {...props}>
    <polyline points='1 4 1 10 7 10'></polyline>
    <polyline points='23 20 23 14 17 14'></polyline>
    <path d='M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15'></path>
  </Svg>
);

export const UndoSvg = (props) => (
  <Svg {...props}>
    <polyline points='1 4 1 10 7 10'></polyline>
    <path d='M3.51 15a9 9 0 1 0 2.13-9.36L1 10'></path>
  </Svg>
);

export const RedoSvg = (props) => (
  <Svg {...props}>
    <polyline points='23 4 23 10 17 10'></polyline>
    <path d='M20.49 15a9 9 0 1 1-2.12-9.36L23 10'></path>
  </Svg>
);

export const ColumnsSvg = (props) => (
  <Svg {...props}>
    <path d='M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18'></path>
  </Svg>
);

export const ShareSvg = (props) => (
  <Svg {...props}>
    <circle cx='18' cy='5' r='3'></circle>
    <circle cx='6' cy='12' r='3'></circle>
    <circle cx='18' cy='19' r='3'></circle>
    <line x1='8.59' y1='13.51' x2='15.42' y2='17.49'></line>
    <line x1='15.41' y1='6.51' x2='8.59' y2='10.49'></line>
  </Svg>
);

export const PlaySvg = (props) => (
  <Svg {...props}>
    <polygon points='5 3 19 12 5 21 5 3'></polygon>
  </Svg>
);

export const FlagSvg = ({
  fillColor = undefined,
  stroke = undefined,
  ...props
}) => (
  <Svg {...props}>
    <path
      stroke={stroke}
      fill={fillColor}
      d='M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z'
    ></path>
    <line stroke={stroke} x1='4' y1='22' x2='4' y2='15'></line>
  </Svg>
);

export const BarChartSvg = (props) => (
  <Svg {...props}>
    <line x1='18' y1='20' x2='18' y2='10'></line>
    <line x1='12' y1='20' x2='12' y2='4'></line>
    <line x1='6' y1='20' x2='6' y2='14'></line>
  </Svg>
);

export const H1Svg = (props) => (
  <Svg {...props}>
    <line x1='3' y1='6' x2='3' y2='18' />
    <line x1='3' y1='12' x2='12' y2='12' />
    <line x1='12' y1='6' x2='12' y2='18' />
    <text x='16' y='13' strokeWidth='1'>
      1
    </text>
  </Svg>
);

export const H2Svg = (props) => (
  <Svg {...props}>
    <line x1='3' y1='6' x2='3' y2='18' />
    <line x1='3' y1='12' x2='12' y2='12' />
    <line x1='12' y1='6' x2='12' y2='18' />
    <text x='16' y='13' strokeWidth='1'>
      2
    </text>
  </Svg>
);

export const CopySvg = ({ stroke = undefined, ...props }) => (
  <Svg {...props}>
    <rect
      stroke={stroke}
      x='9'
      y='9'
      width='13'
      height='13'
      rx='2'
      ry='2'
    ></rect>
    <path
      stroke={stroke}
      d='M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1'
    ></path>
  </Svg>
);

export const ZapSvg = (props) => (
  <Svg {...props}>
    <polygon points='13 2 3 14 12 14 11 22 21 10 12 10 13 2'></polygon>
  </Svg>
);

export const ColorSvg = (props) => (
  <Svg {...props}>
    <g id='Icons' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Outlined' transform='translate(-202.000000, -2014.000000)'>
        <g id='Editor' transform='translate(100.000000, 1960.000000)'>
          <g
            id='Outlined-/-Editor-/-format_color_fill'
            transform='translate(102.000000, 54.000000)'
          >
            <g>
              <polygon id='Path' points='0 0 24 0 24 24 0 24'></polygon>
              <path
                d='M16.56,8.94 L7.62,0 L6.21,1.41 L8.59,3.79 L3.44,8.94 C2.85,9.53 2.85,10.48 3.44,11.06 L8.94,16.56 C9.23,16.85 9.62,17 10,17 C10.38,17 10.77,16.85 11.06,16.56 L16.56,11.06 C17.15,10.48 17.15,9.53 16.56,8.94 Z M5.21,10 L10,5.21 L14.79,10 L5.21,10 Z M19,11.5 C19,11.5 17,13.67 17,15 C17,16.1 17.9,17 19,17 C20.1,17 21,16.1 21,15 C21,13.67 19,11.5 19,11.5 Z'
                fill='#1D1D1D'
              ></path>
              <polygon fill='#D0D0D0' points='0 20 24 20 24 24 0 24'></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Svg>
);

export const BoxSvg = (props) => (
  <Svg {...props}>
    <path d='M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z'></path>
    <polyline points='3.27 6.96 12 12.01 20.73 6.96'></polyline>
    <line x1='12' y1='22.08' x2='12' y2='12'></line>
  </Svg>
);

export const CalendarSvg = (props) => (
  <Svg {...props}>
    <rect x='3' y='4' width='18' height='18' rx='2' ry='2'></rect>
    <line x1='16' y1='2' x2='16' y2='6'></line>
    <line x1='8' y1='2' x2='8' y2='6'></line>
    <line x1='3' y1='10' x2='21' y2='10'></line>
  </Svg>
);

export const EyeSvg = (props) => (
  <Svg {...props}>
    <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z'></path>
    <circle cx='12' cy='12' r='3'></circle>
  </Svg>
);

export const EyeOffSvg = (props) => (
  <Svg {...props}>
    <path d='M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24'></path>
    <line x1='3' y1='3' x2='21' y2='21'></line>
  </Svg>
);

export const BranchSvg = (props) => (
  <Svg {...props}>
    <circle cx='12' cy='5' r='3'></circle>
    <circle cx='5' cy='19' r='3'></circle>
    <circle cx='19' cy='19' r='3'></circle>
    <line x1='10.41' y1='8.51' x2='6.59' y2='15.49'></line>
    <line x1='13.59' y1='8.51' x2='17.42' y2='15.49'></line>
  </Svg>
);

export const ArrowDownSvg = (props) => (
  <Svg {...props}>
    <line x1='12' y1='5' x2='12' y2='19'></line>
    <polyline points='19 12 12 19 5 12'></polyline>
  </Svg>
);

export const CollapsibleSvg = ({ fillColor = undefined, ...props }) => (
  <Svg viewBox='0 0 16 16' {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      fill={fillColor}
      d='M4 13.5h.5v-11H4A1.5 1.5 0 002.5 4v8A1.5 1.5 0 004 13.5zm1.5-11v11H12a1.5 1.5 0 001.5-1.5V4A1.5 1.5 0 0012 2.5H5.5zM12 15H4a3 3 0 01-3-3V4a3 3 0 013-3h8a3 3 0 013 3v8a3 3 0 01-3 3zM10.214 4.985a.617.617 0 01.839.904L8.826 7.954l2.236 2.166a.617.617 0 11-.858.886L7.5 8.386a.617.617 0 01.01-.894l2.703-2.507z'
      strokeWidth={0.25}
    />
  </Svg>
);

export const LoadingSkeletonSvg = (props) => (
  <Svg
    viewBox='0 0 520 75'
    xmlns='http://www.w3.org/2000/svg'
    style={{ margin: 5, width: '30%', height: '10px' }}
    {...props}
  >
    <rect
      x='20'
      y='15'
      width='500'
      height='40'
      fill='#dadada'
      rx='20'
      ry='20'
    />
  </Svg>
);

export const LineChartSvg = (props) => (
  <Svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    fill='#000000'
    width='1200px'
    height='1200px'
    viewBox='-3 0 32 32'
    version='1.1'
  >
    <path d='M23.36 9.32c-1.32 0-2.36 1.080-2.36 2.36 0 0.28 0.040 0.56 0.12 0.8l-4.8 4.080c-0.32-0.2-0.72-0.28-1.16-0.28s-0.88 0.12-1.24 0.36l-2.72-2.2c0.080-0.24 0.12-0.44 0.12-0.72 0-1.32-1.080-2.36-2.36-2.36-1.32 0-2.36 1.080-2.36 2.36 0 0.36 0.080 0.68 0.2 0.96l-3.44 3.44c-0.28-0.12-0.64-0.2-0.96-0.2-1.32 0-2.36 1.080-2.36 2.36 0 1.32 1.080 2.36 2.36 2.36s2.36-1.080 2.36-2.36c0-0.36-0.080-0.68-0.2-0.96l3.44-3.44c0.28 0.12 0.64 0.2 0.96 0.2 0.44 0 0.88-0.12 1.24-0.36l2.76 2.12c-0.080 0.24-0.080 0.44-0.080 0.72 0 1.32 1.080 2.36 2.36 2.36s2.36-1.080 2.36-2.36c0-0.28-0.040-0.56-0.12-0.8l4.8-4.080c0.32 0.2 0.72 0.28 1.16 0.28 1.32 0 2.36-1.080 2.36-2.36-0.040-1.2-1.16-2.28-2.44-2.28zM2.36 21c-0.36 0-0.68-0.32-0.68-0.68 0-0.4 0.32-0.68 0.68-0.68s0.68 0.32 0.68 0.68c0 0.36-0.28 0.68-0.68 0.68zM8.24 13.76c0-0.4 0.32-0.68 0.68-0.68s0.68 0.32 0.68 0.68-0.32 0.68-0.68 0.68c-0.36 0-0.68-0.32-0.68-0.68zM15.2 19.28c-0.4 0-0.68-0.32-0.68-0.68s0.32-0.68 0.68-0.68 0.68 0.32 0.68 0.68c-0.040 0.4-0.28 0.68-0.68 0.68zM23.36 12.36c-0.36 0-0.68-0.32-0.68-0.68 0-0.4 0.32-0.68 0.68-0.68 0.4 0 0.68 0.32 0.68 0.68 0 0.4-0.32 0.68-0.68 0.68z' />
  </Svg>
);

export const ExportSvg = (props) => (
  <Svg {...props}>
    <path d='m16 5-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z' />
  </Svg>
);
