import gql from 'graphql-tag';
import { object, oneOf, string } from 'prop-types';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';

import Loading from 'sora-client/components/common/Loading';
import useQuery from 'sora-client/components/common/useQueryWrapper';
import { EMPLOYEES } from 'sora-client/constants/routes';
import ConfirmationContext from 'sora-client/contexts/ConfirmationContext';
import { sortFieldForImport } from 'sora-client/helpers/employeeCsvImport';

import ImportEmployeesCheckStep from './ImportEmployeesCheckStep';
import ImportFieldsMappingStep from './ImportFieldsMappingStep';

const GET_IMPORT_FIELDS = gql`
  query getImportFieldsExtended {
    importFields {
      id
      name
      type
      required
      allowAutomaticTagValues
    }
  }
`;

export const steps = {
  mapFields: 'mapFields',
  checkEmployees: 'checkEmployees',
};

const stepComponentMap = {
  [steps.mapFields]: ImportFieldsMappingStep,
  [steps.checkEmployees]: ImportEmployeesCheckStep,
};

const EmployeeCsvImportPage = ({
  uploadId,
  history,
  startingStep = steps.mapFields,
}) => {
  const { showConfirmation } = useContext(ConfirmationContext);

  const [currentStep, setStep] = useState(startingStep);
  const { data, loading } = useQuery(GET_IMPORT_FIELDS);

  const cancelImport = () => {
    history.replace(EMPLOYEES);
  };
  const moveToNextStep = () => {
    if (currentStep === steps.mapFields) {
      setStep(steps.checkEmployees);
      history.push(`${EMPLOYEES}/import/${uploadId}/validate`);
    } else if (currentStep === steps.checkEmployees) {
      showConfirmation({
        type: 'info',
        // eslint-disable-next-line react/display-name
        BodyComponent: () => (
          <div>
            <div className='font-weight-500 text-large margin-y-3'>
              Your employee CSV upload is being processed. Please check on the
              employees page in a few moments to view the updated employees
              list.
            </div>
          </div>
        ),
        confirmButtonText: `Go to employees`,
        onConfirm: () => {
          history.replace(`${EMPLOYEES}?import=${uploadId}`);
        },
      });
    }
  };

  const moveBackToFieldMapping = () => {
    if (currentStep === steps.checkEmployees) {
      setStep(steps.mapFields);
      history.push(`${EMPLOYEES}/import/${uploadId}`);
    }
  };

  const CurrentStep = stepComponentMap[currentStep];

  if (loading) {
    return <Loading />;
  }

  const companyFields = sortFieldForImport(data?.importFields);

  return (
    <CurrentStep
      uploadId={uploadId}
      // @ts-ignore
      companyFields={companyFields}
      onCancel={cancelImport}
      onNext={moveToNextStep}
      onBack={moveBackToFieldMapping}
    />
  );
};

EmployeeCsvImportPage.propTypes = {
  uploadId: string.isRequired,
  history: object.isRequired,
  startingStep: oneOf(Object.values(steps)),
};

// @ts-ignore
export default withRouter(EmployeeCsvImportPage);
