import classNames from 'classnames';
import { omit } from 'lodash';
import { any, bool, node, string } from 'prop-types';
import React from 'react';

const FormLabel = (props) => (
  <div
    className={classNames({
      'margin-top-4': !props.noMargin,
    })}
  >
    <label
      {...omit(props, ['name', 'className', 'noMargin', 'errorMessage'])}
      className={classNames(
        'form-label text-uppercase text-midnight-navy-75 text-small font-weight-500',
        props.className,
        {
          'text-danger': props.errorMessage,
        },
      )}
    >
      {props.name}
      {props.errorMessage && (
        <div className='text-lowercase'>{props.errorMessage}</div>
      )}
      {props.children}
    </label>
  </div>
);

FormLabel.propTypes = {
  name: any.isRequired,
  noMargin: bool,
  className: string,
  errorMessage: string,
  htmlFor: string,
  children: node,
};

export default FormLabel;
