import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import React, { useState } from 'react';

import Button from 'sora-client/components/common/Button';
import {
  WarningCircleSvg,
  WarningSvg,
  XSvg,
} from 'sora-client/components/common/Svg';
import { useInterval } from 'sora-client/components/common/hooks';

import { CountdownTimer } from './components/CountdownTimer';
import { FlashWarningInTitle } from './components/FlashWarningInTitle';
import {
  EXPIRATION_WARNING_PERIOD,
  VERIFY_AUTH_INTERVAL,
  WARNING_SVG_CLASSNAMES,
} from './constants';
import { GET_CURRENT_USER, GET_CURRENT_USER_WITH_REFRESH } from './queries';

export const CheckSession = () => {
  const [secondsUntilExpiration, setSecondsUntilExpiration] = useState(-1);
  const [showError, setShowError] = useState(false);
  const [isModalDismissed, setModalDismissed] = useState(false);

  /**
   * Poll for current user at a regular interval. An unauthorized
   * response will auto-redirect the user to the login page. See
   * the `errorLink` function in client/src/index.js for how that
   * is handled
   */
  useQuery(GET_CURRENT_USER, {
    pollInterval: VERIFY_AUTH_INTERVAL,
  });

  const checkTokenExpiration = () => {
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    if (tokenExpiration) {
      /**
       * tokenExpiration is set in seconds from Epoch;
       * convert the current date to match by dividing
       * by 1000
       */
      setSecondsUntilExpiration(Number(tokenExpiration) - Date.now() / 1000);
    }
  };

  /**
   * Every 5 seconds check the time remaining on the user's
   * token. This localStorage item is set via authLink/forward
   * in client/src/index.js
   *
   * Also note that it's not possible to retrieve headers
   * from a useQuery response (eg using the useQuery call
   * above), which is why it's implemented via authLink.
   */
  useInterval(() => {
    checkTokenExpiration();
  }, 5000);

  const [getCurrentUserWithRefresh, { loading: tokenIsRefreshing }] =
    useLazyQuery(GET_CURRENT_USER_WITH_REFRESH, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data) {
          setModalDismissed(true);
        }
      },
      onError: () => {
        setShowError(true);
      },
    });

  /**
   * secondsUntilExpiration hasn't been set yet or it's
   * beyond the amount of time until the warning should be
   * shown, exit
   */
  if (
    isModalDismissed ||
    secondsUntilExpiration < 0 ||
    secondsUntilExpiration > EXPIRATION_WARNING_PERIOD
  ) {
    return <></>;
  }

  /**
   * Since only one modal can be shown at a time, if a modal
   * was used then it would clear out the currently
   * presented modal. This element is rendered instead to
   * preserve the user's app state
   */
  return (
    <>
      <FlashWarningInTitle />
      <div className='token-expiration-warning-z-index position-fixed position-top position-left position-right position-bottom bg-midnight-navy fade-50'></div>
      <div
        className='token-expiration-warning-z-index position-fixed position-top position-left position-right margin-top-8 margin-x-auto'
        style={{ width: 500, maxWidth: '100%' }}
      >
        <div className='position-relative d-flex mobile-d-block mobile-margin-x-5 bg-sunrise rounded-xlarge box-shadow-2 text-large padding-y-4 padding-x-5 mobile-padding-4'>
          <div className='margin-right-4'>
            {!showError && (
              <WarningCircleSvg className={WARNING_SVG_CLASSNAMES} />
            )}
            {showError && <WarningSvg className={WARNING_SVG_CLASSNAMES} />}
          </div>
          <div className='mobile-margin-top-2'>
            {!showError && (
              <>
                <p className='margin-0'>
                  Your session will expire in{' '}
                  <CountdownTimer
                    key={secondsUntilExpiration}
                    secondsUntilExpiration={secondsUntilExpiration}
                  />{' '}
                  due to inactivity.
                </p>
                <div>
                  <Button
                    label='Stay logged in'
                    category='secondary'
                    className='margin-top-4'
                    onClick={getCurrentUserWithRefresh}
                    loading={tokenIsRefreshing}
                  />
                </div>
              </>
            )}
            {showError && (
              <p className='margin-0'>
                There was an error refreshing your session. Please reload the
                page.
              </p>
            )}
          </div>
          <div className='position-absolute position-top position-right margin-top-2 margin-right-2'>
            <XSvg
              onClick={() => {
                setModalDismissed(true);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
