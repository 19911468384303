import gql from 'graphql-tag';

export const GET_NEWLY_CREATED_EMPLOYEE_WORKFLOW = gql`
  query getNewlyEnrolledWorkflowAndTasks($timelineId: ID!, $employeeId: ID!) {
    employeeWorkflows(employeeId: $employeeId, timelineIds: [$timelineId]) {
      rows {
        id
        nhtsSyncState
      }
    }
  }
`;

export const GET_NEW_HIRE_TASKS_FOR_NEWLY_ENROLLED_WORKFLOW = gql`
  query getNewHireTasksForNewlyEnrolledWorkflow(
    $employeeWorkflowId: ID!
    $employeeId: ID!
  ) {
    newHireTasks(
      actors: { newHireId: $employeeId }
      filters: { employeeWorkflows: [$employeeWorkflowId], states: [active] }
      orderBy: { field: dueDate, direction: ASC }
      limit: 1
    ) {
      rows {
        id
        task {
          id
          type
          triggerTaskId
          triggerWorkflowStage {
            id
            fieldId
          }
        }
      }
    }
  }
`;

export const EMPLOYEE_WORKFLOW_UPDATED_SUBSCRIPTION = gql`
  subscription onEmployeeWorkflowUpdated($ids: [ID!]!) {
    employeeWorkflowUpdated(ids: $ids) {
      id
      nhtsSyncState
    }
  }
`;
