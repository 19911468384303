import { node } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import CompanyLogo from 'sora-client/components/CompanyLogo';
import { HOME } from 'sora-client/constants/routes';
import log from 'sora-client/logger';

import GenericErrorMessage from './GenericErrorMessage';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: null,
  };

  static propTypes = {
    children: node.isRequired,
  };

  componentDidCatch(error, info) {
    window.DD_RUM && window.DD_RUM.addError(error, info);
    this.setState({ hasError: true, error });

    log.error(error, info);
  }

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      return (
        <div className='error-content margin-x-auto margin-y-4 padding-x-3'>
          <Link to={HOME} className='d-inline-block'>
            <CompanyLogo widthHeight={64} />
          </Link>

          <GenericErrorMessage error={error} showButtons useLargeHeading />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
