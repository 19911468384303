import gql from 'graphql-tag';

export const GET_WEBHOOK_EVENT = gql`
  query getWebhookEvent($id: ID!) {
    webhookEvent(id: $id) {
      id
      createdAt
      externalId
      status
      isPing
      replayOfWebhookEventId
      replayedByUserId
      replayedByUser {
        id
        employee {
          id
          name
          imageUrl
        }
      }
      latestReplay {
        id
        status
      }
      serviceName
      eventName
      eventType
      eventData
      eventFilters
      errors {
        type
        message
      }
      affectedEmployees {
        id
        name
        imageUrl
      }
      affectedEmployeeWorkflows {
        id
        employee {
          id
          name
          imageUrl
        }
        timeline {
          id
          name
        }
      }
      integrationV2Run {
        id
        state
        isInProgress
      }
    }
  }
`;

export const REPLAY_WEBHOOK_EVENT = gql`
  mutation replayWebhookEvent($id: ID!) {
    replayWebhookEvent(id: $id) {
      id
      serviceName
      eventName
      errors {
        type
        message
      }
      integrationV2Run {
        id
      }
    }
  }
`;
