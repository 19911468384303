import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { object } from 'prop-types';
import React from 'react';

import InnerPageNav from 'sora-client/components/common/InnerPageNav';
import * as routes from 'sora-client/constants/routes';
import { userAuthorization } from 'sora-client/helpers/authorization';

export const GET_START_DATE_FIELD = gql`
  query getStartDateField {
    companyFields(names: ["Start date"], defaultField: true) {
      id
    }
  }
`;

const EmployeesNav = ({ session }) => {
  const ua = userAuthorization(session.currentUser);
  const canManageEmployees = ua.can({
    resource: 'Employee',
    action: 'EDIT',
  });

  const { data: companyFields } = useQuery(GET_START_DATE_FIELD);

  const startDateFieldId = companyFields?.companyFields?.[0]?.id;

  // This is a temporary fix for the issue where users without access
  // to view the start date field cannot view the recently started
  // and starting soon pages because it automatically filters by start date and
  // they don't have permission to view that data for all employees. This was
  // causing the entire page to crash.
  //
  // Instead of limiting access to the entire Recently started and Starting soon pages because the user
  // doesn't have access to view the start date field for all employees,
  // we should allow them access to their direct reports if applicable.
  // TODO: https://sora.height.app/T-14157
  const canViewEmployeesByStartDate = ua.can({
    resource: 'FieldValue',
    action: 'VIEW',
    instance: { fieldId: startDateFieldId },
  });

  const navItems = [
    {
      to: routes.EMPLOYEES,
      title: 'All employees',
      requireExactMatch: true,
    },
    ...(canViewEmployeesByStartDate
      ? [
          {
            to: routes.EMPLOYEES_RECENT,
            title: 'Recently started',
          },
          {
            to: routes.EMPLOYEES_UPCOMING,
            title: 'Starting soon',
          },
        ]
      : []),
  ];

  if (canManageEmployees) {
    navItems.push({
      to: routes.EMPLOYEES_INACTIVE,
      title: 'Inactive',
    });
  }

  navItems.push({
    to: routes.EMPLOYEE_ORG_CHART,
    title: 'Org chart',
    disabled: !session.currentUser.hasPremiumAccess,
    tooltip: !session.currentUser.hasPremiumAccess
      ? 'In the premium version of Sora, you can see an org chart of your employees.'
      : '',
  });

  return <InnerPageNav title='Employees' navItems={navItems} />;
};

EmployeesNav.propTypes = {
  session: object.isRequired,
};

export default EmployeesNav;
