import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const TWILIO_INTEGRATION_ID = 41;

export const TWILIO_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: TWILIO_INTEGRATION_ID,
  name: 'twilio',
  label: 'Twilio',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/twilio.png`,
  helpDocs: 'https://help.sora.co/resources/twilio',
  website: 'https://www.twilio.com/',
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  categories: [CATEGORIES.COMMUNICATION],
  configSchema: {
    authToken: {
      type: 'string',
      title: 'Auth token',
      nullable: true,
      description: 'Your Auth Token from www.twilio.com/console',
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    accountSid: {
      type: 'string',
      title: 'Account SID',
      nullable: true,
      description: 'Your Account SID from www.twilio.com/console',
    },
    from: {
      type: 'string',
      title: 'Twilio phone number',
      nullable: true,
      description:
        "Phone number that sends the messages. This must be a Twilio phone number that you own, formatted with a '+' and country code, e.g. +16175551212",
    },
  },
  configured: (c) =>
    c.values && c.values.authToken && c.values.accountSid && c.values.from,
  validation: { action: 'twilio/getMessages', parameters: { limit: 1 } },
};
