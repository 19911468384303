import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const SMARTRECRUITERS_INTEGRATION_ID = 22;

export const SMARTRECRUITERS_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: SMARTRECRUITERS_INTEGRATION_ID,
  configured: (c) => c.values && c.values.apiKey,
  name: 'smartrecruiters',
  label: 'SmartRecruiters',
  description: 'Recruiting software and talent acquisition suite',
  website: 'https://smartrecruiters.com',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/smartrecruiters.png`,
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  categories: [CATEGORIES.ATS],
  configSchema: {
    apiKey: {
      type: 'string',
      title: 'API Key',
      nullable: true,
    },
    webHookKey: {
      type: 'string',
      title: 'Webhook key',
      autogenerate: true,
      uiSchema: {
        'ui:widget': 'ReadOnlyValueToCopy',
      },
    },
  },
};
