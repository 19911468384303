import classNames from 'classnames';
import { chunk } from 'lodash';
import { arrayOf, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import SoraPopper from 'sora-client/components/common/SoraPopper';
import { PlusSvg } from 'sora-client/components/common/Svg';

import { WorkflowTagsList } from './WorkflowTagsList';

export const WorkflowTemplateCard = ({ template }) => {
  const [firstTwoTags, extraTags] = chunk(template.tags, 2);
  const [queryParams, setQueryParams] = useQueryParams({
    tag: StringParam,
    tid: StringParam,
  });
  const [open, setOpen] = useState(false);
  return (
    <div
      style={{ width: 325, height: 175 }}
      onClick={() => setQueryParams({ tid: template.id })}
      className={classNames(
        'd-flex flex-direction-column box-shadow cursor-pointer rounded-xlarge border-a margin-x-2 margin-bottom-3 padding-top-4 padding-x-4 position-relative',
        {
          'bg-blue-7': template.id === queryParams.tid,
          'bg-hover-gray': template.id !== queryParams.tid,
        },
      )}
    >
      <strong
        className='text-weight-500 margin-bottom-2 margin-top-0'
        style={{ fontSize: 20 }}
      >
        {template.name}
      </strong>
      <div
        className='margin-bottom-2 text-medium line-clamp-3 overflow-hidden'
        style={{
          maxHeight: '60px',
          lineHeight: '20px',
        }}
      >
        {template.internalDescriptionShort}
      </div>
      <div className='margin-bottom-2 d-flex flex-wrap text-xsmall margin-bottom-4 position-absolute position-bottom align-items-center'>
        <WorkflowTagsList tags={firstTwoTags} />
        {extraTags && (
          <SoraPopper
            open={open}
            placement='bottom'
            PopperContent={() => (
              <div
                className='bg-white rounded-xlarge box-shadow-3 padding-bottom-2 padding-left-2'
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
              >
                <WorkflowTagsList tags={extraTags} />
              </div>
            )}
            Trigger={
              <div
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                className='d-flex align-items-center rounded-large text-xsmall border-a padding-x-2 padding-y-1 margin-top-2'
              >
                <PlusSvg className='svg-medium svg-stroke-default' />
                {extraTags.length}
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

WorkflowTemplateCard.propTypes = {
  template: shape({
    name: string.isRequired,
    internalDescriptionShort: string,
    tags: arrayOf(string),
  }).isRequired,
};
