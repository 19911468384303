import classNames from 'classnames';
import { noop } from 'lodash';
import { any, bool, func, object, string } from 'prop-types';
import React, { memo, useCallback, useContext, useEffect } from 'react';

import { ConfirmationContext } from 'sora-client/contexts';

import { XSvg } from './Svg';
import { useClickOutside } from './hooks';

const Modal = ({
  children,
  onDismissModal = noop,
  allowClickOutside = false,
  allowDismiss = true,
  fullScreen = false,
  maxWidthNone = false,
  prompt = null,
  closingModal = false,
  modalStyle = null,
}) => {
  const { showConfirmation } = useContext(ConfirmationContext);
  const onClickOutside = useCallback(() => {
    !allowClickOutside && dismissModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowClickOutside, onDismissModal]);
  const clickOutsideRef = useClickOutside(onClickOutside);

  useEffect(() => {
    document.body.classList.add('lock-scroll');

    return () => {
      document.body.classList.remove('lock-scroll');
    };
  });

  const dismissModal = () => {
    if (prompt) {
      showConfirmation({
        body: prompt,
        type: `warning`,
        onConfirm: () => onDismissModal(),
      });
    } else {
      onDismissModal();
    }
  };

  return (
    <div
      className={classNames(
        'modal position-fixed position-top position-right position-bottom position-left overflow-auto mobile-padding-3 text-center',
        {
          'closing-modal': closingModal,
        },
      )}
      onKeyDown={(e) => {
        // Dismiss on Esc
        e.keyCode === 27 && !allowClickOutside && dismissModal();
      }}
    >
      <div
        className={classNames(
          'modal-content position-relative text-left d-inline-block rounded-xlarge bg-white word-break-break-word',
          {
            'full-screen margin-4': fullScreen,
            'margin-8': !fullScreen,
            'max-width-none': maxWidthNone,
          },
        )}
        ref={clickOutsideRef}
        style={modalStyle}
      >
        {children}
        {allowDismiss && (
          <XSvg
            className='modal-close position-absolute position-top position-right margin-top-3 margin-right-3'
            onClick={() => dismissModal()}
          />
        )}
      </div>
    </div>
  );
};

Modal.propTypes = {
  allowDismiss: bool,
  allowClickOutside: bool,
  children: any,
  closingModal: bool,
  fullScreen: bool,
  modalStyle: object,
  onDismissModal: func,
  prompt: string,
  maxWidthNone: bool,
};

export default memo(Modal);
