import { string } from 'prop-types';
import React, { useContext } from 'react';

import { Mobile, NotMobile } from 'sora-client/components/common/MediaQueries';
import SessionContext from 'sora-client/contexts/SessionContext';

import MobileNavigation from './MobileNavigation';
import NotMobileNavigation from './NotMobileNavigation';

const Navigation = ({ currentNavItemCategory }) => {
  const session = useContext(SessionContext);
  return (
    <div className='position-sticky position-top no-select elevated-z-index border-bottom'>
      <Mobile>
        <MobileNavigation
          session={session}
          currentNavItemCategory={currentNavItemCategory}
        />
      </Mobile>
      <NotMobile>
        <NotMobileNavigation
          session={session}
          currentNavItemCategory={currentNavItemCategory}
        />
      </NotMobile>
    </div>
  );
};

Navigation.propTypes = {
  currentNavItemCategory: string,
};

const mobileNavHeight = 57;

export { mobileNavHeight };

export default Navigation;
