import { useCallback, useEffect, useState } from 'react';

const DEFAULT_STATE = {
  cursor: 0,
  lastKeyPress: null,
};

const useKeyboardNavigation = ({
  listSize,
  refNode,
  onKeyDown,
  resetStateOnListSizeChange = false,
  extraKeyEventListeners = ['Enter'],
}) => {
  const listSizeForIndex = listSize - 1;

  const [state, setState] = useState(DEFAULT_STATE);

  useEffect(() => {
    if (resetStateOnListSizeChange) {
      setState(DEFAULT_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listSize, resetStateOnListSizeChange]);

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        const cursor = event.metaKey
          ? listSizeForIndex
          : state.cursor < listSizeForIndex
          ? state.cursor + 1
          : 0;
        setState({
          lastKeyPress: event.key,
          cursor,
        });
        onKeyDown(event.key, cursor);
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        const cursor = event.metaKey
          ? 0
          : state.cursor > 0
          ? state.cursor - 1
          : listSizeForIndex;
        setState({
          lastKeyPress: event.key,
          cursor,
        });
        onKeyDown(event.key, cursor);
      } else if (extraKeyEventListeners.includes(event.key)) {
        setState({
          lastKeyPress: event.key,
          cursor: state.cursor,
        });
        onKeyDown(event.key, state.cursor);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listSize, extraKeyEventListeners, onKeyDown],
  );

  useEffect(() => {
    if (refNode) {
      refNode.addEventListener('keydown', handleKeyPress);
      return () => refNode.removeEventListener('keydown', handleKeyPress);
    }
  }, [handleKeyPress, refNode, extraKeyEventListeners]);

  useEffect(
    /*
     * Fire onKeyDown once initially so we can trigger an initial state
     * We need to do this because there are a number of places where we rely on this
     * callback to set initial state essentially. Without this firing, there are a number of places
     * where there is no item selected initially, and you need to start firing keydown events to have
     * navigation appear as expected.
     * Some examples include:
     * - SelectCustom (initial lastKeyNavigation state is empty object)
     * - NonFormSelectCustom (initial lastKeyNavigation state is empty object)
     * - FastSwitcherNavigationModal (initial lastKeyNavigation state is empty object)
     * TODO refactor those places to set initial state properly and remove this useEffect
     * TODO see https://sora.height.app/T-11664 for details
     */
    () => onKeyDown(state.lastKeyPress, state.cursor),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [state.cursor, state.lastKeyPress];
};

export default useKeyboardNavigation;
