import { get } from 'lodash';
import { bool, func, object } from 'prop-types';
import React from 'react';
import { Mutation, useMutation } from 'react-apollo';

import { handleResponseError } from 'sora-client/helpers/handleError';
import { GET_PERMISSION_GROUPS_FULL } from 'sora-client/sharedQueries/getPermissionGroupsFull';

const defaultUpdateHandlers = {
  createPermissionGroup(cache, { data }) {
    const { createPermissionGroup: permissionGroup } = data;
    if (!permissionGroup) return;
    const fromCache = cache.readQuery({
      query: GET_PERMISSION_GROUPS_FULL,
    });
    if (!fromCache) return;
    cache.writeQuery({
      query: GET_PERMISSION_GROUPS_FULL,
      data: {
        ...fromCache,
        permissionGroups: [
          ...fromCache.permissionGroups.filter(
            (pg) => pg.id !== permissionGroup.id,
          ),
          permissionGroup,
        ],
      },
    });
  },
  deletePermissionGroup(cache, { data }) {
    const { deletePermissionGroup: groupId } = data;
    if (!groupId) return;
    const fromCache = cache.readQuery({
      query: GET_PERMISSION_GROUPS_FULL,
    });
    if (!fromCache) return;
    cache.writeQuery({
      query: GET_PERMISSION_GROUPS_FULL,
      data: {
        ...fromCache,
        permissionGroups: [
          ...fromCache.permissionGroups.filter((pg) => pg.id !== groupId),
        ],
      },
    });
  },
  clonePermissionGroup(cache, { data }) {
    const { clonePermissionGroup: permissionGroup } = data;
    if (!permissionGroup) return;
    const fromCache = cache.readQuery({
      query: GET_PERMISSION_GROUPS_FULL,
    });
    if (!fromCache) return;
    cache.writeQuery({
      query: GET_PERMISSION_GROUPS_FULL,
      data: {
        ...fromCache,
        permissionGroups: [
          ...fromCache.permissionGroups.filter(
            (pg) => pg.id !== permissionGroup.id,
          ),
          permissionGroup,
        ],
      },
    });
  },
};

const MutationWrapper = ({
  mutation,
  variables,
  update,
  showError = true,
  renderFn,
}) => (
  <Mutation mutation={mutation} variables={variables} update={update}>
    {(fn, { data, loading, error }) => {
      if (showError) {
        const originalFn = fn;
        fn = (...args) => {
          return originalFn(...args).catch((error) => {
            handleResponseError(error);
            throw error;
          });
        };
      }
      return renderFn(fn, { data, loading, error });
    }}
  </Mutation>
);

MutationWrapper.propTypes = {
  mutation: object.isRequired,
  variables: object,
  renderFn: func.isRequired,
  update: func,
  showError: bool,
};

/**
 * @param {object} mutation
 * @param {object} param1
 * */
function useMutationWrapper(mutation, { showError = true, ...options } = {}) {
  const [fn, { loading }] = useMutation(mutation, {
    ...options,
    update: async (...arg) => {
      const mutationName = get(mutation.definitions[0], 'name.value');
      const updateHandler = defaultUpdateHandlers[mutationName];
      if (updateHandler) {
        await updateHandler(...arg);
      }
      if (options.update) {
        await options.update(...arg);
      }
    },
  });

  /**
   * @returns {*}
   * */
  const wrappedFn = (...arg) => {
    return fn(...arg).catch((err) => {
      if (showError) {
        handleResponseError(err);
      }
      throw err;
    });
  };

  return { fn: wrappedFn, loading };
}

export { useMutationWrapper };

export default MutationWrapper;
