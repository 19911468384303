import classNames from 'classnames';
import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { addSortInfo } from 'sora-client/helpers/search';

import SelectCustom from './SelectCustom';

// import SelectNative from './SelectNative';

const transformOptions = (options) => {
  return options.map((opt) => {
    const optObj = typeof opt === 'object' ? opt : { label: opt, value: opt };
    return addSortInfo(optObj);
  });
};

const Select = (props) => {
  const {
    children = null,
    className = null,
    CustomEntry,
    disabled,
    label = null,
    style = null,
    multiple = false,
    name,
    onChange,
    onSearch = null,
    options: optionsRaw,
    optionRenderFn = null,
    placeholder = 'Select',
    search = false,
    tiny = false,
    togglerClassName = '',
    value = '',
    valueObj = null,
    width = null,
    initiallyOpen,
    includeSelectAll,
    includeDeselectAll,
  } = props;
  const elId = `select-${name}`;

  const [options, updateOptions] = useState(() => transformOptions(optionsRaw));

  const selectRef = useRef(/** @type {HTMLElement} */ (null));

  useEffect(() => {
    const transformedOptions = transformOptions(optionsRaw);
    updateOptions(transformedOptions);
  }, [optionsRaw]);

  return (
    <>
      {label && (
        <label
          className='d-block text-small text-uppercase margin-bottom-1'
          htmlFor={elId}
          id={elId}
        >
          {label}
        </label>
      )}
      <div
        className={classNames(
          'select-wrapper d-inline-block position-relative',
          { disabled },
          className,
        )}
        ref={selectRef}
        style={{ width, minWidth: tiny ? '0' : '200px' }}
      >
        {/* Disabling for now for performance reasons, we can pick it
          * back up when we have a better strategy
          * Clickup ticket: https://app.clickup.com/t/jpy62t
          * Conversation: https://soradotco.slack.com/archives/G01H5DG2RJ9/p1610536707004100
        <SelectNative
          CustomEntry={CustomEntry}
          disabled={disabled}
          elId={elId}
          multiple={multiple}
          name={name}
          onChange={onChange}
          options={options}
          placeholder={placeholder}
          value={value}
        >
          {children}
        </SelectNative> */}
        <SelectCustom
          className={togglerClassName}
          CustomEntry={CustomEntry}
          multiple={multiple}
          name={name}
          style={style}
          onChange={onChange}
          onSearch={onSearch}
          options={options}
          optionRenderFn={optionRenderFn}
          placeholder={placeholder}
          search={search}
          value={value}
          valueObj={valueObj}
          initiallyOpen={initiallyOpen}
          includeSelectAll={includeSelectAll}
          includeDeselectAll={includeDeselectAll}
        >
          {children}
        </SelectCustom>
      </div>
    </>
  );
};

Select.propTypes = {
  children: node,
  className: string,
  CustomEntry: object,
  disabled: bool,
  label: string,
  multiple: bool,
  search: bool,
  name: string.isRequired,
  onChange: func.isRequired,
  onSearch: func,
  options: arrayOf(
    oneOfType([
      shape({
        html: object,
        name: string,
        label: string,
        className: string,
        sortLevel: number,
        value: oneOfType([array, bool, number, string, oneOf([null])]),
      }),
      string,
    ]),
  ).isRequired,
  optionRenderFn: func,
  placeholder: string,
  style: shape({
    bottom: string,
    left: string,
    right: string,
    top: string,
  }),
  togglerClassName: string,
  value: oneOfType([array, bool, number, string]),
  valueObj: object,
  width: oneOfType([number, string]),
  tiny: bool,
  initiallyOpen: bool,
  includeSelectAll: bool,
  includeDeselectAll: bool,
};

export default Select;
