import { isEmpty } from 'lodash';
import * as yup from 'yup';

import { modelConstants } from '../constants';
import { isValidEmail } from './index';

const { EMAIL } = modelConstants.field.types;
const buildYupFieldValidator = (field) => {
  let fieldValidator = yup.mixed();

  if (field.required) {
    fieldValidator = fieldValidator.test({
      name: `field${field.id}-required-validator`,
      message: `Field value is required`,
      test: (fieldValue) => !isEmpty(fieldValue),
    });
  }

  if (field.type === EMAIL) {
    // tests are chained, so if field is required AND type === EMAIL,
    // we're adding another .test() to the existing .test()
    fieldValidator = fieldValidator.test({
      name: `field${field.id}-email-validator`,
      message: `Must be a valid email address`,
      test: (fieldValue) => (fieldValue ? isValidEmail(fieldValue) : true),
    });
  }

  return fieldValidator;
};

export { buildYupFieldValidator };
