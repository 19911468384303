import moment from 'moment';
import { string } from 'prop-types';
import React, { useContext } from 'react';

import BoxCard from 'sora-client/components/common/BoxCard';
import { LinkButton } from 'sora-client/components/common/LinkButton';
import useQueryWrapper from 'sora-client/components/common/useQueryWrapper';
import * as routes from 'sora-client/constants/routes';
import SessionContext from 'sora-client/contexts/SessionContext';

import HomePageCard from '../HomePageCard';
import HomePageCardTitle from '../HomePageCardTitle';
import HomePageEmployeeList from '../HomePageEmployeeList';
import { GET_EMPLOYEES_STARTED_THIS_MONTH } from './queries';

const NewEmployeesListCard = ({ className }) => {
  const { currentUser } = useContext(SessionContext);
  const { data, loading } = useQueryWrapper(GET_EMPLOYEES_STARTED_THIS_MONTH, {
    beforeStartDate: moment().startOf('day'),
    afterStartDate: moment().subtract(30, 'days').startOf('day'),
  });

  if (loading) return null;

  const { rows = [], count = 0 } = data?.employees || {};
  // don't render unit if no new employees OR the only new employee is the current user
  if (
    !rows.length ||
    (rows.length === 1 && rows[0].id === currentUser.employeeId)
  ) {
    return null;
  }

  return (
    <HomePageCard className='new-employees-list-card'>
      <BoxCard className={className}>
        <div className='padding-x-5 padding-top-5 mobile-padding-3'>
          <HomePageCardTitle
            title={`${rows.length} ${
              count === 1 ? 'person' : 'people'
            } started recently`}
          />
          <div className='text-muted'>
            Your company is growing. Here are a few fresh faces to get
            acquainted with. Don&apos;t be a stranger. Be sure to say
            &quot;Hi!&quot;
          </div>
          <LinkButton
            className='d-inline-block margin-top-4'
            to={routes.EMPLOYEES_RECENT}
            label='See recently started'
          />
        </div>
        <HomePageEmployeeList employees={rows} listCategory='recent' />
      </BoxCard>
    </HomePageCard>
  );
};

NewEmployeesListCard.propTypes = {
  className: string,
};

export default NewEmployeesListCard;
