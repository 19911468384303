import classNames from 'classnames';
import { arrayOf, object, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import Tooltip from 'sora-client/components/common/Tooltip';

const TabsNav = ({ items, location, className }) => {
  const handleClick = (e, navItem) => {
    if (navItem.disabled) {
      e.preventDefault();
    }
  };

  return (
    <div
      className={classNames(
        'd-flex tabs-nav text-nowrap overflow-auto',
        className,
      )}
    >
      {items.map((navItem, i) => {
        return (
          <div key={i}>
            <Tooltip
              tooltip={navItem.tooltip || ''}
              placement={navItem.tooltipPlacement || 'top'}
            >
              <NavLink
                onClick={(e) => handleClick(e, navItem)}
                to={navItem.to}
                key={i}
                className={classNames(
                  'nav-item padding-bottom-2 d-inline-block',
                  {
                    disabled: navItem.disabled,
                    'margin-left-5': i,
                  },
                )}
                activeClassName='active'
                exact={navItem.requireExactMatch}
              >
                <span
                  className={classNames({
                    'text-midnight-navy-60 font-weight-500':
                      navItem.requireExactMatch
                        ? location.pathname !== navItem.to
                        : !location.pathname.includes(navItem.to),
                    'text-color-default font-weight-600':
                      navItem.requireExactMatch
                        ? location.pathname === navItem.to
                        : location.pathname.includes(navItem.to),
                  })}
                >
                  {navItem.title}
                  {navItem.badge || null}
                </span>
              </NavLink>
            </Tooltip>
          </div>
        );
      })}

      <div className='more-tab-items-right-gradient d-none mobile-d-block position-sticky position-right padding-right-7' />
    </div>
  );
};

TabsNav.propTypes = {
  items: arrayOf(
    shape({
      to: string,
      title: string,
      requireExactMatch: string,
      disabled: string,
      badge: oneOfType([string, object]),
    }),
  ),
  location: shape({ pathname: string }),
  className: string,
};

export default withRouter(TabsNav);
