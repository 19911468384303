import classNames from 'classnames';
import { camelCase, find, isEmpty, isNil, noop, xor } from 'lodash';
import { array, bool, func, number, oneOfType, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import BaseTable, { AutoResizer, Column } from 'react-base-table';

import TableCell from './TableCell';

const isTestEnv = process.env.NODE_ENV === 'test';
export const DEFAULT_HEADER_HEIGHT = 50;

const defaultWidth = 600;
const defaultHeight = 600;

export const generateKey = (column, camelcaseKey) =>
  camelcaseKey || column.camelcaseKey ? camelCase(column.title) : column.title;

const normalizeColumns = ({
  columns,
  onChange,
  onEditStart,
  onEditStop,
  camelcaseKey,
  genericCell,
  cellPlaceholder,
  editable,
}) =>
  columns.map((column) => {
    if (typeof column === 'string') {
      column = { title: column };
    }
    if (column.frozen) {
      column.frozen = Column.FrozenDirection.LEFT;
    }
    const key = column.key || generateKey(column, camelcaseKey);

    if (
      !column.cellRenderer &&
      (column.genericCell || (genericCell && isNil(column.genericCell)))
    ) {
      // eslint-disable-next-line react/display-name
      column.cellRenderer = (props) => (
        <TableCell
          {...props}
          onChange={column.onChange || onChange}
          onEditStart={column.onEditStart || onEditStart}
          onEditStop={column.onEditStop || onEditStop}
          cellPlaceholder={column.cellPlaceholder || cellPlaceholder}
          editable={column.editable || (editable && isNil(column.editable))}
        />
      );
    }

    return {
      resizable: true,
      ...column,
      key,
      dataKey: key,
      width: column.width ? column.width : 300,
    };
  });

// eslint-disable-next-line react/display-name
const Table = React.forwardRef((/** @type {any} */ props, ref) => {
  const [columns, setColumns] = useState(normalizeColumns(props));

  useEffect(() => {
    const inputColumns = normalizeColumns(props);
    const wereChanges = !isEmpty(
      xor(
        inputColumns.map((c) => c.title),
        columns.map((c) => c.title),
      ),
    );
    if (wereChanges) {
      setColumns(inputColumns);
    }
  }, [props, columns.length, columns]);

  let rowClassName = props.rowClassName;
  if (!rowClassName && props.highlightErrors) {
    rowClassName = ({ rowData }) =>
      classNames({
        'row-error': !isEmpty(rowData.cellErrors),
      });
  }

  const autoresizer = isNil(props.autoresizer) ? !isTestEnv : props.autoresizer;
  if (autoresizer) {
    return (
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            {...props}
            ref={ref}
            width={width}
            height={height}
            columns={columns}
            rowEventHandlers={{
              onClick: props.onRowClick ? props.onRowClick : noop,
            }}
            rowClassName={rowClassName}
            onColumnResize={(e) => {
              find(columns, { key: e.column.key }).width = e.width;
            }}
          />
        )}
      </AutoResizer>
    );
  } else {
    return (
      <BaseTable
        {...props}
        ref={ref}
        width={props.width || defaultWidth}
        height={props.height || defaultHeight}
        columns={columns}
        rowEventHandlers={{
          onClick: props.onRowClick ? props.onRowClick : noop,
        }}
        rowClassName={rowClassName}
        onColumnResize={(e) => {
          find(columns, { key: e.column.key }).width = e.width;
        }}
        headerHeight={props.hideHeader ? 0 : DEFAULT_HEADER_HEIGHT}
      />
    );
  }
});

// @ts-ignore
Table.propTypes = {
  columns: array.isRequired,
  editable: bool,
  genericCell: bool,
  onChange: func,
  onEditStart: func,
  onEditStop: func,
  cellPlaceholder: string,
  onRowClick: func,
  rowClassName: oneOfType([string, func]),
  highlightErrors: bool,
  autoresizer: bool,
  width: number,
  height: number,
};

export default Table;
