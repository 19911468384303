// NOTE: if you make a change to this file, be sure to update client/src/helpers/getRouteNames.js as well.
// getRouteNames.js is used for page tracking purposes in our analytics

export const SUPER_ADMIN_COMPANY_BASE = '/admin/companies';
export const SUPER_ADMIN_COMPANY_EDIT = '/admin/companies/:id/edit';
export const SUPER_ADMIN_COMPANY_USERS = '/admin/companies/:id/users';
export const SUPER_ADMIN_COMPANY_NOTIFICATIONS =
  '/admin/companies/:id/notifications';
export const SUPER_ADMIN_COMPANY_INTEGRATIONS =
  '/admin/companies/:id/integrations';
export const SUPER_ADMIN_COMPANY_INTEGRATION_TEMPLATE_NEW =
  '/admin/companies/:id/integrations/new';
export const SUPER_ADMIN_COMPANY_INTEGRATION_TEMPLATE =
  '/admin/companies/:id/integrations/:template_uuid';
export const SUPER_ADMIN_COMPANY_INTEGRATION_TEMPLATE_TAB =
  '/admin/companies/:id/integrations/:template_uuid/:tab';
export const SUPER_ADMIN_COMPANY_INTEGRATION_V2_RUN =
  '/admin/companies/:id/integrations/v2_runs/:runId';

export const HOME = '/';
export const SIGN_UP = '/signup';
export const LOG_IN = '/login';
export const NOTIFICATIONS = '/notifications';

export const SETTINGS = '/settings';
export const SETTINGS_NOTIFICATIONS = '/settings/notifications';
export const SETTINGS_PERMISSIONS = '/settings/permissions';
export const SETTINGS_COMPANY = '/settings/company';
export const SETTINGS_COMPANY_EMAILS = '/settings/company-emails';
export const SETTINGS_EMPLOYEE_DATA_DEPRECATED = '/settings/employee-data';
export const SETTINGS_INTEGRATIONS = '/settings/integrations';
export const SETTINGS_INTEGRATION = '/settings/integrations/:domainName';
export const SETTINGS_INTEGRATION_FIELD_MAPPING =
  '/settings/integrations/:domainName/field-mapping';
export const SETTINGS_AUDIT_LOGS_DEPRECATED =
  '/settings/logs/audit-logs-deprecated';
export const SETTINGS_AUDIT_LOGS = '/settings/logs/audit-logs';
export const SETTINGS_WEBHOOK_EVENTS = '/settings/logs/webhook-events';
export const SETTINGS_DASHBOARD_CARDS = '/settings/dashboard-cards';
export const SETTINGS_AVAILABLE_FIELD_MAPPING =
  '/settings/integrations/:domainName/field-mapping/reference';

export const EMPLOYEES = '/employees';
export const EMPLOYEES_INACTIVE = '/employees/inactive';
export const EMPLOYEES_RECENT = '/employees/recent';
export const EMPLOYEES_UPCOMING = '/employees/upcoming';
export const EMPLOYEE_NEW = '/employees/new';
export const EMPLOYEES_IMPORT_BASE = '/employees/import';
export const EMPLOYEES_IMPORT = '/employees/import/:uploadId';
export const EMPLOYEES_IMPORT_VALIDATE = '/employees/import/:uploadId/validate';
export const EMPLOYEES_DATA_MANAGEMENT = '/employees/data-management';
export const EMPLOYEES_DATA_SYNC = '/employees/data-management/data-sync';
export const EMPLOYEE_PROFILE = '/employee/:id';
export const EMPLOYEE_PROFILE_EDIT = '/employee/:id/edit';
export const EMPLOYEE_PROFILE_BASE = '/employee';
export const EMPLOYEE_ORG_CHART = '/employees/org_chart';

export const WORKFLOWS_ALL = '/workflows/all';
export const WORKFLOW_INTEGRATION_SETTINGS = '/workflows/manage-integrations';
export const WORKFLOW_SINGLE_INTEGRATION_SETTINGS =
  '/workflows/manage-integrations/:domainName';
export const WORKFLOW = '/workflow/:workflowId';
export const WORKFLOW_PROGRESS = '/workflow/:workflowId/progress';
export const WORKFLOW_TASK_ANALYSIS = '/workflow/:workflowId/task-analysis';
export const WORKFLOW_NEEDS_ATTENTION = '/workflow/:workflowId/needs-attention';
export const WORKFLOW_DASHBOARD = '/workflow/:workflowId/dashboard';
export const WORKFLOW_EMPLOYEE = '/workflow/:workflowId/employee/:employeeId';
export const WORKFLOW_EMPLOYEE_VIEW = '/workflow/:workflowId/employees';
export const WORKFLOW_CONFIGURE = '/workflow/:id/configure';
export const WORKFLOW_CONFIGURE_NEW_ACTION =
  '/workflow/:id/configure/new-action';
export const WORKFLOW_SELF_ENROLL = '/workflow/:workflowId/self-enroll';
export const WORKFLOW_BASE = '/workflow';
export const WORKFLOW_TASK_NEW = '/workflow/:id/new-action';
export const WORKFLOW_STAGE_NEW =
  '/workflow/:id/new-workflow-stage/:orderIndex';
export const TASK = '/action/:id';
export const TASK_EDIT = '/action/:id/edit';
export const TASK_BASE = '/action';

export const WORKFLOW_TEMPLATES_BASE = '/workflow-templates';
export const WORKFLOW_TEMPLATES_MANAGEMENT = `${WORKFLOW_TEMPLATES_BASE}/manage`;
export const WORKFLOW_TEMPLATE_CONFIGURE = `${WORKFLOW_TEMPLATES_BASE}/:id/configure`;
export const WORKFLOW_TEMPLATE_PREVIEW = `${WORKFLOW_TEMPLATES_BASE}/:id/preview`;
export const WORKFLOW_TEMPLATE_CONFIGURE_NEW_ACTION = `${WORKFLOW_TEMPLATES_BASE}/:id/configure/new-action`;
export const WORKFLOW_TEMPLATE_CONFIGURE_NEW_WORKFLOW_STAGE = `${WORKFLOW_TEMPLATES_BASE}/:id/new-workflow-stage/:orderIndex`;
export const WORKFLOW_TEMPLATE_DISCOVERY = `${WORKFLOW_TEMPLATES_BASE}/all`;

export const NEW_HIRE_TASKS = '/tasks';

export const NEW_HIRE_TASK = '/task/:id';
export const NEW_HIRE_TASK_EDIT = '/task/:id/edit';
export const NEW_HIRE_TASK_COMPLETE = '/task/:id/complete';
export const NEW_HIRE_TASK_MARK_COMPLETE = '/task/:id/mark_complete';
export const NEW_HIRE_TASK_BASE = '/task';

export const REPORTS = '/reports';
