import { capitalize, debounce, omit, words } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { ObjectParam, StringParam, useQueryParams } from 'use-query-params';

import Select from 'sora-client/components/common/Select';
import ToggleIcon from 'sora-client/components/common/Select/ToggleIcon';
import StaticDropdown from 'sora-client/components/common/StaticDropdown';
import FormInput from 'sora-client/components/common/forms/FormInput';
import FormLabel from 'sora-client/components/common/forms/FormLabel';
import { AVAILABLE_WEBHOOK_EVENTS } from 'sora-client/constants/webhookEvents';

const DOMAINS = Object.keys(AVAILABLE_WEBHOOK_EVENTS).map(capitalize).sort();

const getDisplayValue = (asofMin, asofMax) => {
  if (!asofMin && !asofMax) return 'Anytime';
  if (moment(asofMin).isSame(asofMax, 'day')) {
    return moment(asofMin).format('M/D/Y');
  }

  return (
    moment(asofMin).format('M/D/Y') + ' - ' + moment(asofMax).format('M/D/Y')
  );
};

export const WebhookFilters = () => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [queryParams, setQueryParams] = useQueryParams({
    serviceName: StringParam,
    eventName: StringParam,
    asofMin: StringParam,
    asofMax: StringParam,
    eventFilters: ObjectParam,
  });

  const { serviceName, eventName, eventFilters, asofMin, asofMax } =
    queryParams;

  const domainOptions = [
    { label: 'None', value: '' },
    ...DOMAINS.map((domain) => ({ label: domain, value: domain })),
  ];

  const eventOptions = useMemo(() => {
    if (!serviceName) return [];
    const eventsForDomain = AVAILABLE_WEBHOOK_EVENTS[
      serviceName.toLowerCase()
    ].map((event) => ({
      label: capitalize(words(event.name).join(' ')),
      value: event.name,
    }));
    return [{ label: 'None', value: '' }, ...eventsForDomain];
  }, [serviceName]);

  const selectedEvent = useMemo(() => {
    if (!(serviceName && eventName)) return [];
    return AVAILABLE_WEBHOOK_EVENTS[serviceName.toLowerCase()].find(
      (evt) => evt.name.toLowerCase() === eventName.toLowerCase(),
    );
  }, [serviceName, eventName]);

  const updateEventFilters = debounce((filterKey, value) => {
    if (!value) {
      setQueryParams({ eventFilters: omit(eventFilters, filterKey) });
    } else {
      setQueryParams({ eventFilters: { ...eventFilters, [filterKey]: value } });
    }
  }, 300);

  return (
    <div className='d-flex margin-x-5'>
      <div className='margin-right-2'>
        <FormLabel name='Event source' noMargin />
        <Select
          options={domainOptions}
          name='webhook-domain-selector'
          onChange={(value) =>
            setQueryParams({
              serviceName: value || undefined,
              eventName: undefined,
            })
          }
          togglerClassName='rounded'
          value={capitalize(serviceName)}
        />
      </div>
      <div className='margin-right-2'>
        <FormLabel name='Event' noMargin />
        <Select
          disabled={!serviceName}
          options={eventOptions}
          name='webhook-event-selector'
          onChange={(value) =>
            setQueryParams({ eventName: value || undefined })
          }
          togglerClassName='rounded'
          value={eventName}
        />
      </div>
      <div className='margin-right-2'>
        <FormLabel name='Received' noMargin />
        <StaticDropdown
          closeOnToggleClick
          closeOnItemClick
          dropdownToggle={
            <div
              className='cursor-pointer bg-white border-a rounded padding-x-3 d-flex align-items-center'
              style={{ height: '38px' }}
            >
              {getDisplayValue(asofMin, asofMax)}
              <ToggleIcon className='margin-left-2' open={isDatePickerOpen} />
            </div>
          }
          otherInnerDropdownClasses='date-range-picker'
          placement='bottom-start'
          onOpenAndClose={(val) => setIsDatePickerOpen(val)}
          dropdownItems={[
            {
              noSelect: true,
              closeOnClick: true,
              html: (
                <DateRangePicker
                  ranges={[
                    {
                      startDate: asofMin ? moment(asofMin) : new Date(),
                      endDate: asofMax ? moment(asofMax) : new Date(),
                      key: 'selection',
                    },
                  ]}
                  onChange={({ selection }) => {
                    setQueryParams({
                      asofMin: moment(selection.startDate)
                        .startOf('day')
                        .toISOString(),
                      asofMax: moment(selection.endDate)
                        .endOf('day')
                        .toISOString(),
                    });
                  }}
                />
              ),
            },
          ]}
        />
      </div>
      {selectedEvent?.filters?.map((filter) => (
        <div
          key={filter}
          className='form-control'
          data-testid='WebhookFilters-event-filters'
        >
          <FormLabel name={words(filter).join(' ')} noMargin />
          <FormInput
            overrideDefaultStyles
            className='border-a rounded padding-y-2'
            onChange={(event) => {
              updateEventFilters(filter, event.target.value);
            }}
            placeholder='Hired'
            value={eventFilters?.[filter] || ''}
          />
        </div>
      ))}
    </div>
  );
};
