import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const BAMBOO_INTEGRATION_ID = 18;

export const BAMBOO_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: BAMBOO_INTEGRATION_ID,
  configured: (c) => c.values && c.values.apiKey && c.values.subdomain,
  name: 'bamboohr',
  label: 'BambooHR',
  categories: [CATEGORIES.HRIS],
  description:
    'BambooHR provides small and medium-sized businesses with subscription-based online HR Software.',
  website: 'bamboohr.com',
  helpDocs: 'https://help.sora.co/resources/bamboohr',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/bamboohr.png`,
  availableUses: [AVAILABLE_USES_ENUMS.DATA_SYNC],
  configSchema: {
    apiKey: {
      type: 'string',
      title: 'API Key',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    subdomain: {
      type: 'string',
      title: 'Subdomain',
      nullable: true,
    },
    outboundEmployeeFieldMapping: {
      type: 'string',
      title: 'Map outbound fields from Sora employees, to BambooHR employees',
      nullable: true,
    },
  },
  validation: { action: 'bamboohr/getAllFields' },
};
