import React, { useContext } from 'react';

import BoxCard from 'sora-client/components/common/BoxCard';
import useQueryWrapper from 'sora-client/components/common/useQueryWrapper';
import SessionContext from 'sora-client/contexts/SessionContext';

import HomePageCard from '../HomePageCard';
import HomePageCardTitle from '../HomePageCardTitle';
import WorkflowCard from './components/WorkflowCard';
import { GET_WORKFLOWS } from './queries';

const WorkflowsListCard = () => {
  const { currentUser } = useContext(SessionContext);
  const { data } = useQueryWrapper(GET_WORKFLOWS);

  const { rows: workflows = [] } = data?.workflows || {};
  return (
    <HomePageCard className='workflows-list-card'>
      <BoxCard className='padding-5 mobile-padding-3'>
        <HomePageCardTitle title='Workflows in progress' />
        <div className='d-flex flex-wrap'>
          {workflows.map((workflow) => (
            <WorkflowCard
              workflow={workflow}
              currentUser={currentUser}
              key={workflow.id}
            />
          ))}
        </div>
      </BoxCard>
    </HomePageCard>
  );
};

export default WorkflowsListCard;
