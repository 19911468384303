import { object, string } from 'prop-types';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import Button from 'sora-client/components/common/Button';
import Page from 'sora-client/components/common/Page';
import { LeftArrowSvg } from 'sora-client/components/common/Svg';
import * as routes from 'sora-client/constants/routes';

const ErrorPage = ({ history, type }) => (
  <Page
    id='error-page'
    title={type === '404' ? 'Page not found' : 'Error'}
    category='Error'
  >
    <div className='margin-top-7 text-center'>
      <div className='text-large'>
        {type === '404' && `We couldn't find that page. `}Navigate below to get
        back.
      </div>
      <div className='d-inline-flex align-items-center margin-top-4'>
        <Button
          onClick={() => history.goBack()}
          labelHTML={
            <div className='d-inline-flex align-items-center'>
              <div className='svg-medium-parent-align-text'>
                <LeftArrowSvg />
              </div>
              <div className='margin-left-1'>Go back</div>
            </div>
          }
          category='primary'
        />
        <Link to={routes.HOME} className='d-inline-block margin-left-3'>
          <Button label='Go home' category='secondary' />
        </Link>
      </div>
      <div className='margin-top-5'>
        Is this a mistake? <a href='mailto:support@sora.co'>Contact support</a>.
      </div>
    </div>
  </Page>
);

ErrorPage.propTypes = {
  history: object.isRequired,
  type: string.isRequired,
};

export default withRouter(ErrorPage);
