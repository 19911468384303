import classNames from 'classnames';
import { noop } from 'lodash';
import { any, bool, func, string } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Manager, Popper, Reference } from 'react-popper';

import {
  useClickOutside,
  usePortal,
} from 'sora-client/components/common/hooks';

import DropdownToggler from './DropdownToggler';

const DropdownOuterContainer = ({
  otherClasses,
  popperClassname,
  disabled,
  dropdownToggle,
  selectedItem,
  togglerClassName,
  initiallyOpen = false,
  onOpenAndClose,
  onClose = noop,
  children,
  placeholder,
  placement = 'bottom-start',
  portalTargetRef = document.getElementById('poppers'),
  closeOnToggleClick = true,
  'data-testid': dataTestId,
  innerRef,
  ...restProps
}) => {
  const [updatedPopperPosition, setUpdatedPopperPosition] = useState(false);
  const [open, setOpen] = useState(initiallyOpen);
  const togglerRef = useRef(/** @type {HTMLElement}*/ (null));
  useEffect(() => {
    onOpenAndClose && onOpenAndClose(open);
  }, [open, onOpenAndClose]);

  const onClickOutside = useCallback(
    (e) => {
      // Determine if e.target is inside togglerRef.current
      // We need this because we want the toggler to also control
      // open/close behavior, not the `clickOutside` functionality
      if (open && !togglerRef.current.contains(e.target)) {
        setOpen(false);
        onClose();
        e.stopPropagation();
      }
    },
    [open, onClose, setOpen],
  );

  useEffect(() => {
    if (!open) setUpdatedPopperPosition(false);
  }, [open]);

  const clickOutsideRef = useClickOutside(onClickOutside);
  const portalTarget = usePortal(portalTargetRef);

  return (
    <div
      className={classNames(
        'dropdown-container position-relative',
        otherClasses,
        {
          disabled,
        },
      )}
      onKeyDown={(e) => {
        if (e.keyCode === 27) {
          // Dismiss on Esc
          e.stopPropagation();
          setOpen(false);
          onClose();
        } else if (e.keyCode === 13) {
          // Open on enter
          e.stopPropagation();
          setOpen(true);
        }
      }}
      ref={innerRef}
      {...restProps}
    >
      {!open && (
        <DropdownToggler
          dropdownClickToggleRef={togglerRef}
          open={open}
          selectedItem={selectedItem}
          className={togglerClassName}
          onClick={() => {
            setOpen(!open);
          }}
          placeholder={placeholder}
          dropdownToggle={dropdownToggle}
          data-testid={dataTestId}
        />
      )}
      {open && (
        <Manager>
          <Reference>
            {({ ref }) => (
              <div ref={ref}>
                <DropdownToggler
                  dropdownClickToggleRef={togglerRef}
                  open={open}
                  selectedItem={selectedItem}
                  className={togglerClassName}
                  onClick={() => {
                    if (closeOnToggleClick) {
                      setOpen(!open);
                      onClose();
                    }
                  }}
                  placeholder={placeholder}
                  dropdownToggle={dropdownToggle}
                />
              </div>
            )}
          </Reference>
          {createPortal(
            <Popper
              placement={placement}
              modifiers={{
                offset: { offset: '0,5' },
              }}
            >
              {({ placement, ref, style, scheduleUpdate }) => {
                if (!updatedPopperPosition) {
                  /*
                   * Popper doesn't know what the coordinates of the popup
                   * are when it first opens, so we have to tell it to
                   * update manually
                   */
                  scheduleUpdate();
                  setUpdatedPopperPosition(true);
                }
                return (
                  <div
                    ref={ref}
                    style={style}
                    data-placement={placement}
                    className={classNames('elevated-z-index', popperClassname)}
                  >
                    <div ref={clickOutsideRef}>
                      {children({ open, setOpen })}
                    </div>
                  </div>
                );
              }}
            </Popper>,
            portalTarget,
          )}
        </Manager>
      )}
    </div>
  );
};

DropdownOuterContainer.propTypes = {
  otherClasses: string,
  popperClassname: string,
  disabled: bool,
  children: any.isRequired,
  dropdownToggle: any,
  selectedItem: any,
  togglerClassName: string,
  portalTargetRef: any,
  placement: string,
  placeholder: string,
  initiallyOpen: bool,
  onOpenAndClose: func,
  onClose: func,
  closeOnToggleClick: bool,
  'data-testid': string,
};

export default DropdownOuterContainer;
