import { get } from 'lodash';
import React from 'react';
import { Query } from 'react-apollo';

import isEmpty from 'lodash/isEmpty';
import { LoadingSvg } from 'sora-client/components/common/Svg';
import { getForwardError } from 'sora-client/helpers';
import log from 'sora-client/logger';
import soraLogo from 'sora-client/static/images/sora.png';

import { GET_CURRENT_USER } from './queries';

const withSession = (Component, handleLoading = false) =>
  function WrappedWithSession(props) {
    return (
      <Query query={GET_CURRENT_USER} fetchPolicy='cache-first'>
        {({ data, error = null, refetch, loading }) => {
          log.configureScope((scope) => {
            scope.setUser({ email: get(data, 'currentUser.email') });
          });
          const isInLoadingState = props.loading || loading;

          if (isEmpty(data) && isInLoadingState && handleLoading && !error) {
            return (
              <div className='full-height d-flex flex-direction-column justify-content-center align-items-center'>
                <img
                  src={soraLogo}
                  width={100}
                  alt='Sora'
                  className='margin-bottom-5'
                />
                <LoadingSvg />
              </div>
            );
          }
          const forwardError = getForwardError(error, data);
          if (forwardError) {
            throw forwardError;
          }

          return (
            <Component
              {...props}
              loading={isInLoadingState}
              session={{ ...data, returned: true }}
              refetch={refetch}
            />
          );
        }}
      </Query>
    );
  };

export default withSession;
