import classNames from 'classnames';
import gql from 'graphql-tag';
import { isEmpty, map, orderBy, unionBy, without } from 'lodash';
import { object, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useApolloClient } from 'react-apollo-hooks';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import CompanyLogo from 'sora-client/components/CompanyLogo';
import EmployeeAvatar from 'sora-client/components/common/Avatar';
import { MenuSvg, SearchSvg, XSvg } from 'sora-client/components/common/Svg';
import { useStateDebounce } from 'sora-client/components/common/hooks';
import useQueryWrapper from 'sora-client/components/common/useQueryWrapper';
import * as routes from 'sora-client/constants/routes';
import { getEmployeeLink } from 'sora-client/helpers';

import {
  getMobileNavItems,
  getNavBarColor,
  getNavLinkColor,
  resetAdminToken,
} from '../utilities';
import EmployeeList from './EmployeeList';

const GET_EMPLOYEES = gql`
  query mobileNavigationGetEmployees(
    $offset: Int
    $limit: Int
    $searchTextFilter: String
  ) {
    employees(
      offset: $offset
      limit: $limit
      filters: { searchTextFilter: $searchTextFilter }
    ) {
      count
      rows {
        id
        name
        email
        imageUrl
      }
    }
  }
`;
const EMPLOYEE_QUERY_LIMIT = 20;

const MobileNavigation = ({ session, currentNavItemCategory }) => {
  const { ua } = session;
  const [navigationExpanded, setNavigationExpanded] = useState(false);
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [offset, setOffset] = useStateDebounce(0, 250);
  const [searchTextFilter, setSearchTextFilter] = useStateDebounce('', 250);
  const [displaySearch, setDisplaySearch] = useState('');
  const searchResults = useRef([]);
  const topNavRef = useRef(null);
  const [siteNavOffset, setSiteNavOffset] = useState(0);

  useEffect(() => {
    const topNav = topNavRef.current;

    if (topNav) {
      const boundingRect = topNav.getBoundingClientRect();
      const totalOffset = boundingRect.top + boundingRect.height;
      if (totalOffset !== siteNavOffset) {
        setSiteNavOffset(boundingRect.top + boundingRect.height);
      }
    }
  }, [siteNavOffset]);

  const { data, loading } = useQueryWrapper(GET_EMPLOYEES, {
    offset,
    limit: EMPLOYEE_QUERY_LIMIT,
    searchTextFilter,
  });

  const { count, rows = [] } = data?.employees || {};

  if (offset === 0 && !(loading && isEmpty(rows))) {
    searchResults.current = rows;
  } else if (
    !isEmpty(without(map(rows, 'id'), ...map(searchResults.current, 'id')))
  ) {
    searchResults.current = orderBy(
      unionBy(searchResults.current || [], rows, 'id'),
      'name',
    );
  }

  const mobileHeaderNavItems = getMobileNavItems({
    session,
    currentNavItemCategory,
  });

  const switchBackToken = localStorage.getItem('switchBackToken');
  const client = useApolloClient();

  if (switchBackToken) {
    mobileHeaderNavItems.personal.unshift({
      html: (
        <div
          className='d-block padding-x-4 mobile-padding-x-3 padding-y-2 bg-sunrise'
          onClick={() => {
            setNavigationExpanded(false);
            resetAdminToken({ client });
          }}
        >
          Switch back to admin account
        </div>
      ),
    });
  }

  const navBarColor = getNavBarColor({ session });
  const navLinkColor = getNavLinkColor({ session });

  // for non-active users
  if (!ua.isActive) {
    return (
      <div
        ref={topNavRef}
        id='site-nav'
        className='mobile-page-nav bg-midnight-navy padding-x-3 padding-y-2'
        style={{ backgroundColor: navBarColor }}
      >
        <div className='d-flex align-items-center'>
          <div className='flex-grow-1'>
            <CompanyLogo session={session} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        ref={topNavRef}
        id='site-nav'
        className='mobile-page-nav bg-midnight-navy padding-x-3 padding-y-2'
        style={{ backgroundColor: navBarColor }}
      >
        <div className='d-flex align-items-center'>
          <div className='flex-grow-1'>
            {!searchExpanded && (
              <Link to={routes.HOME}>
                <CompanyLogo session={session} />
              </Link>
            )}
            {searchExpanded && (
              <div className='d-flex align-items-center'>
                <Link to={routes.HOME} className='d-inline-block'>
                  <CompanyLogo session={session} />
                </Link>
                <input
                  className='flex-grow-1 padding-y-2 border-a rounded text-large bg-white margin-x-2'
                  placeholder='Search employees...'
                  value={displaySearch}
                  onChange={(e) => {
                    setDisplaySearch(e.target.value);
                    setSearchTextFilter(e.target.value);
                    setOffset(0);
                  }}
                />
              </div>
            )}
          </div>
          {ua.isActive && (
            <>
              {!searchExpanded && (
                <SearchSvg
                  className='margin-right-3'
                  style={{ stroke: navLinkColor }}
                  onClick={() => {
                    setNavigationExpanded(false);
                    setSearchExpanded(!searchExpanded);
                  }}
                />
              )}
              {searchExpanded && (
                <XSvg
                  className='margin-right-3'
                  style={{ stroke: navLinkColor }}
                  onClick={() => {
                    setSearchExpanded(false);
                  }}
                />
              )}
            </>
          )}
          {!navigationExpanded && (
            <MenuSvg
              className={classNames({ 'bg-sunrise': !!switchBackToken })}
              style={{ stroke: navLinkColor }}
              onClick={() => {
                setSearchExpanded(false);
                setNavigationExpanded(true);
              }}
            />
          )}
          {navigationExpanded && (
            <XSvg
              className={classNames({ 'bg-sunrise': !!switchBackToken })}
              style={{ stroke: navLinkColor }}
              onClick={() => {
                setNavigationExpanded(false);
              }}
            />
          )}
        </div>
      </div>
      {(navigationExpanded || searchExpanded) && (
        <div
          className='position-fixed position-left position-right position-bottom bg-white overflow-auto elevated-z-index'
          style={{
            top: siteNavOffset || 56,
          }}
        >
          {navigationExpanded && (
            <div className='padding-bottom-5'>
              <Link
                key='profile'
                className='d-flex align-items-center padding-x-4 mobile-padding-x-3 padding-y-3 text-default border-bottom'
                to={getEmployeeLink(session.currentUser.employee)}
              >
                <EmployeeAvatar
                  imageSrc={session.currentUser.employee.imageUrl}
                  widthHeight={38}
                  employeeName={session.currentUser.employee.name}
                  employeeId={session.currentUser.employee.id}
                />
                <div className='margin-left-3'>
                  <div className='font-weight-500'>
                    {session.currentUser.employee.name}
                  </div>
                  <div className='text-midnight-navy-75 margin-top-1'>
                    View profile
                  </div>
                </div>
              </Link>
              {mobileHeaderNavItems.primary}
              <hr className='separator fade-40 margin-x-3' />
              {mobileHeaderNavItems.personal.map((navItem, i) => {
                return <div key={i}>{navItem.html}</div>;
              })}
            </div>
          )}
          {searchExpanded && (
            <>
              <EmployeeList
                employees={searchResults.current}
                currentUser={session.currentUser}
                searchTextFilter={searchTextFilter}
              />
              {EMPLOYEE_QUERY_LIMIT + offset < count &&
                searchResults.current.length && (
                  <Waypoint
                    bottomOffset='-30%'
                    onEnter={() => {
                      setOffset(offset + EMPLOYEE_QUERY_LIMIT);
                    }}
                  />
                )}
            </>
          )}
        </div>
      )}
    </>
  );
};

MobileNavigation.propTypes = {
  session: object.isRequired,
  currentNavItemCategory: string,
};

export default MobileNavigation;
