import { any, string } from 'prop-types';
import React from 'react';

import Page from 'sora-client/components/common/Page';

export const SuperAdminWrapperPage = ({ id = 'admin', children }) => {
  return (
    <Page id={id} category='Admin' layout='flush'>
      {children}
    </Page>
  );
};

SuperAdminWrapperPage.propTypes = {
  id: string,
  children: any.isRequired,
};
