import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  COMMON_OAUTH_CONFIG_PROPERTIES,
  DEFAULT_INTEGRATION,
  OAUTH_REDIRECT_URI,
} from '../../common';

export const ADOBE_SIGN_INTEGRATION_ID = 44;

export const ADOBE_SIGN_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: ADOBE_SIGN_INTEGRATION_ID,
  name: 'adobesign',
  label: 'Adobe Sign',
  description:
    'Adobe Acrobat Sign is a cloud-based e-signature service that allows the user to send, sign, track, and manage signature processes using a browser or mobile device',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/adobesign.png`,
  website: 'https://www.adobe.com/sign.html',
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  categories: [CATEGORIES.OTHER],
  authFlow: {
    type: 'Oauth2',
    getAuthorizeEndpoint: (config) =>
      `https://${config.webDomain}/public/oauth/v2`,
    redirectUri: OAUTH_REDIRECT_URI,
    scope:
      'user_read:account agreement_read:account agreement_write:account agreement_send:account library_read:account workflow_read:account webhook_read:account webhook_write:account webhook_retention',
    configToClear: {
      accessToken: null,
      refreshToken: null,
      accessTokenExpirationDate: null,
    },
  },
  configured: (c) =>
    c.values &&
    c.values.webDomain &&
    c.values.apiBaseUrl &&
    c.values.accessToken &&
    c.values.refreshToken,
  configSchema: {
    webDomain: {
      type: 'string',
      title: 'Account domain',
      description: 'Example: secure.na4.adobesign.com (omit https://)',
      nullable: true,
    },
    apiBaseUrl: {
      type: 'string',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Hidden',
        'ui:options': {
          label: false,
        },
      },
    },
    clientId: {
      type: 'string',
      title: 'Client ID',
      nullable: true,
    },
    clientSecret: {
      type: 'string',
      title: 'Client secret',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    webhookKey: {
      type: 'string',
      title: 'Webhook key',
      autogenerate: true,
      uiSchema: {
        'ui:widget': 'ReadOnlyValueToCopy',
      },
    },
    ...COMMON_OAUTH_CONFIG_PROPERTIES,
    refreshToken: {
      type: 'string',
      title: 'API Refresh token',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Hidden',
        'ui:options': {
          label: false,
        },
      },
    },
  },
  validation: {
    action: 'adobesign/getLibraryDocuments',
    parameters: { limit: 1 },
  },
};
