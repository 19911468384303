import { compact, debounce, each, get, isEmpty, uniq, uniqBy } from 'lodash';
import { useEffect } from 'react';
import { useApolloClient } from 'react-apollo-hooks';
import { useUID } from 'react-uid';

import fieldGroupSubscriptionHandlers from './fieldGroupSubscriptionHandlers';
import fieldSubscriptionHandlers from './fieldSubscriptionHandlers';
import pullFieldSubscriptionHandlers from './pullFieldSubscriptionHandlers';
import taskSubscriptionHandlers from './taskSubscriptionHandlers';

// until this is implemented https://github.com/apollographql/apollo-feature-requests/issues/74
// this component should prevent duplicated subscriptions
const updateHandlers = {
  ...pullFieldSubscriptionHandlers,
  ...fieldSubscriptionHandlers,
  ...fieldGroupSubscriptionHandlers,
  ...taskSubscriptionHandlers,
};

function getName(query) {
  return query.definitions[0].name.value;
}

function getId(subscription, variables) {
  const subscriptionName = getName(subscription);
  const vars = JSON.stringify(variables);

  let id = subscriptionName;
  if (vars) {
    id += `.${vars}`;
  }
  return id;
}

function eachUniqueHandler(allUpdateHandlers, fun) {
  return each(
    allUpdateHandlers
      .map((e) => e.updateHandlersObj)
      .reduce((acc, e) => {
        return { ...acc, ...e };
      }, {}),
    fun,
  );
}

const subscriptionState = {};
/**
 *
 * @param {*} subscription
 * @param {{ variables?: object, update?: any[], skip?: boolean }} param1
 */
function useSubscriptionWrapper(
  subscription,
  { variables, update = [], skip } = {},
) {
  const client = useApolloClient();
  const componentId = useUID();
  const subscriptionId = getId(subscription, variables);

  useEffect(() => {
    if (skip) return;
    const updateHandlersObj = compact(update).reduce(
      (acc, { query, variables }) => {
        if (!query) {
          throw new Error('Query needs to be specified.');
        }
        let name = `${getName(subscription)}.${getName(query)}`;
        let updateHandler =
          updateHandlers[`${getName(subscription)}.${getName(query)}`];
        if (!updateHandler) {
          name += '.refetch';
          // use debounce wrapper in case we receive multiple subscriptions event in short period that will
          // trigger refetch
          updateHandler = debounce((arg) => {
            return arg.client.query({
              query,
              variables,
              fetchPolicy: 'network-only',
            });
          }, 2000);
        }
        acc[name] = (arg) => {
          updateHandler({ ...arg, query, variables });
        };
        return acc;
      },
      {},
    );

    if (!subscriptionState[subscriptionId]) {
      subscriptionState[subscriptionId] = {
        componentIds: [componentId],
        allUpdateHandlers: [{ componentId, updateHandlersObj }],
        subscription: client
          .subscribe({
            query: subscription,
            variables,
          })
          .subscribe(
            (nextResult) => {
              eachUniqueHandler(
                subscriptionState[subscriptionId].allUpdateHandlers,
                (handler) => {
                  handler({ client, subscriptionData: nextResult });
                },
              );
            },
            function (error) {},
          ),
      };
    } else {
      subscriptionState[subscriptionId] = {
        ...subscriptionState[subscriptionId],
        componentIds: uniq([
          ...subscriptionState[subscriptionId].componentIds,
          componentId,
        ]),
        allUpdateHandlers: uniqBy(
          [
            ...subscriptionState[subscriptionId].allUpdateHandlers,
            { componentId, updateHandlersObj },
          ],
          'componentId',
        ),
      };
    }
    return () => {
      subscriptionState[subscriptionId] = {
        ...subscriptionState[subscriptionId],
        componentIds: subscriptionState[subscriptionId].componentIds.filter(
          (id) => id !== componentId,
        ),
        allUpdateHandlers: subscriptionState[
          subscriptionId
        ].allUpdateHandlers.filter((h) => h.componentId !== componentId),
      };
      if (isEmpty(get(subscriptionState[subscriptionId], 'componentIds'))) {
        subscriptionState[subscriptionId].subscription.unsubscribe();
        subscriptionState[subscriptionId] = null;
      }
    };
    /* eslint-disable react-hooks/exhaustive-deps*/
  }, [
    JSON.stringify(variables),
    ...update.map((e) => e.refetch || JSON.stringify(e)),
  ]);
  /* eslint-enable */
}

export default useSubscriptionWrapper;
