import gql from 'graphql-tag';

export const GET_HOMEPAGE_EMPLOYEE_TASK_LIST = gql`
  fragment HomepageEmployeeTaskListCard on Query {
    tasksDueThisWeek: newHireTasks(
      actors: { assigneeIds: [$employeeId] }
      filters: {
        states: [active]
        dueDate: { beforeDate: $thisWeekDueDate }
        taskTypes: $taskTypes
      }
      orderBy: { direction: ASC, field: dueDate }
      limit: 5
    ) {
      count
      rows {
        id
        displayedTaskName
        task {
          id
          typeLabel
          type
        }
        newHire {
          id
          name
        }
        done
        dueDate
        date
      }
    }
    tasksDueUpNext: newHireTasks(
      actors: { assigneeIds: [$employeeId] }
      filters: {
        states: [active]
        dueDate: { afterDate: $nextWeekDueDate, beforeDate: $monthDueDate }
        taskTypes: $taskTypes
      }
      orderBy: { direction: ASC, field: dueDate }
      limit: 5
    ) {
      count
      rows {
        id
        displayedTaskName
        task {
          typeLabel
          type
        }
        newHire {
          id
          name
        }
        done
        dueDate
        date
      }
    }
    overdueTasksNum: newHireTasks(
      actors: { assigneeIds: [$employeeId] }
      filters: {
        states: [active]
        dueDate: { beforeDate: $overdueDueDate }
        taskTypes: $overdueTaskTypes
      }
    ) {
      count
    }
    allTasksDueNum: newHireTasks(
      actors: { assigneeIds: [$employeeId] }
      filters: {
        states: [active]
        assignedDate: { beforeDate: $overdueDueDate }
        taskTypes: $taskTypes
      }
    ) {
      count
    }
  }
`;
