import { bool } from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

import ErrorPage from 'sora-client/components/ErrorPage';

const ProtectedRoute = ({ canUserAccess, ...props }) => {
  if (canUserAccess) {
    return <Route {...props} />;
  }

  return <ErrorPage type='404' />;
};

ProtectedRoute.propTypes = {
  canUserAccess: bool,
};

export default ProtectedRoute;
