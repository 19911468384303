import { arrayOf, number, object, string } from 'prop-types';
import React from 'react';

import { EmployeeTaskListItem } from './EmployeeTaskLIstItem';

const EmployeeTaskList = ({ columnName, tasksCount = 0, tasks = [] }) => {
  return (
    <div className='list-container padding-top-2'>
      <div className='list-headers text-uppercase text-medium border-bottom'>
        <div>
          {columnName} ({tasksCount})
        </div>
        <div>Employee</div>
        <div>Due</div>
      </div>
      <>
        {tasks.map((newHireTask) => {
          return (
            <EmployeeTaskListItem
              key={newHireTask.id}
              newHireTask={newHireTask}
            />
          );
        })}
        {/* Empty task cell */}
        {!tasksCount && (
          <div className='list-item text-muted border-top'>
            You have no open tasks due up next!
          </div>
        )}
      </>
    </div>
  );
};

EmployeeTaskList.propTypes = {
  columnName: string,
  tasksCount: number,
  tasks: arrayOf(object),
};

export { EmployeeTaskList };
