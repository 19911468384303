import { get } from 'lodash';
import { object, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import SignOutLink from 'sora-client/components/SignOutLink';
import {
  DEFAULT_NAV_COLOR,
  DEFAULT_NAV_LINK_COLOR,
} from 'sora-client/components/pages/SettingsPage/SettingsPageContent/CompanySettings/constants';
import * as routes from 'sora-client/constants/routes';
import { isValidColor } from 'sora-client/helpers';

import NavItems from './NotMobileNavigation/NavItems';

const getMobileNavItems = ({ session, currentNavItemCategory }) => {
  return {
    primary: (
      <NavItems
        session={session}
        currentNavItemCategory={currentNavItemCategory}
      />
    ),
    personal: getUserAccountNavItems({ session }),
  };
};

getMobileNavItems.propTypes = {
  session: object.isRequired,
  pathname: string.isRequired,
  currentNavItemCategory: string,
};

const getUserAccountNavItems = ({ session }) => {
  const { ua } = session;
  const dropdownItems = [];
  const linkClassNames =
    'd-block padding-x-4 mobile-padding-x-3 padding-y-2 text-default bg-hover-gray';

  if (
    ua.can({
      resource: 'Company',
      action: 'EDIT',
    })
  ) {
    dropdownItems.push({
      html: (
        <Link
          key='settings'
          className={linkClassNames}
          to={routes.SUPER_ADMIN_COMPANY_BASE}
        >
          Sora admin
        </Link>
      ),
    });
  }

  dropdownItems.push({
    html: <SignOutLink key='logout' />,
    closeOnClick: true,
  });

  return dropdownItems;
};

getUserAccountNavItems.propTypes = {
  session: object.isRequired,
};

const resetAdminToken = async ({ client }) => {
  const switchBackToken = localStorage.getItem('switchBackToken');

  localStorage.setItem('token', switchBackToken);
  localStorage.removeItem('switchBackToken');

  await client.resetStoreAndWsConnection();
  window.location.reload();
};

const getNavBarColor = ({ session }) => {
  const customerNavBarColor = get(
    session,
    'currentUser.company.brandColors.navBarColor',
    '',
  );
  const navBarColor = isValidColor(customerNavBarColor)
    ? customerNavBarColor
    : DEFAULT_NAV_COLOR;

  return navBarColor;
};

const getNavLinkColor = ({ session }) => {
  const customerNavLinkColor = get(
    session,
    'currentUser.company.brandColors.navLinkColor',
    '',
  );
  const navLinkColor = isValidColor(customerNavLinkColor)
    ? customerNavLinkColor
    : DEFAULT_NAV_LINK_COLOR;

  return navLinkColor;
};

/*
 * If customer has a custom nav link color, use that
 * Otherwise fall back to the default Sora branding
 */
const getNavLinkBorderColor = ({ session }) => {
  const customerNavLinkColor = get(
    session,
    'currentUser.company.brandColors.navLinkColor',
    '',
  );
  const navLinkBorderColor = isValidColor(customerNavLinkColor)
    ? customerNavLinkColor
    : '#62b2e9'; // Spring sky blue

  return navLinkBorderColor;
};

export {
  getMobileNavItems,
  getUserAccountNavItems,
  resetAdminToken,
  getNavBarColor,
  getNavLinkColor,
  getNavLinkBorderColor,
};
