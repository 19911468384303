import classNames from 'classnames';
import { get } from 'lodash';
import moment from 'moment';
import { object } from 'prop-types';
import React from 'react';

import { TASKS_WITHOUT_DUE_DATE } from 'sora-client/constants/tasks';

const getNewHireTaskDueDateForTable = (nht) => {
  if (nht.done || nht.skipped) return '-';

  // Display N/A for cancelled tasks or calendar invites since due dates do not apply
  if (
    !nht.dueDate ||
    nht.state === 'cancelled' ||
    TASKS_WITHOUT_DUE_DATE.includes(nht.task.type)
  )
    return 'N/A';

  const tz = get(nht.assignee, 'timezone') || moment.tz.guess();
  let due = moment(nht.dueDate).tz(tz).startOf('day');

  if (moment().tz(tz).startOf('day').isSame(due)) return 'Today';
  if (moment().tz(tz).startOf('day').add(1, 'day').isSame(due))
    return 'Tomorrow';

  return due.format('ddd, M/D/YY');
};

const NewHireTaskDueDate = ({ newHireTask }) => {
  const dueDate = getNewHireTaskDueDateForTable(newHireTask);
  const tz = get(newHireTask.assignee, 'timezone') || moment.tz.guess();
  return (
    <div
      className={classNames({
        'text-danger':
          !newHireTask.done &&
          !!newHireTask.dueDate &&
          newHireTask.state !== 'cancelled' &&
          newHireTask.task.type !== 'calendarInvite' &&
          moment()
            .tz(tz)
            .startOf('day')
            .isAfter(moment(newHireTask.dueDate).tz(tz)),
      })}
    >
      {dueDate}
    </div>
  );
};

NewHireTaskDueDate.propTypes = {
  newHireTask: object.isRequired,
};

export { NewHireTaskDueDate };
