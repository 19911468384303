import classNames from 'classnames';
import { string } from 'prop-types';
import React from 'react';

const SunsetSeparator = ({ verticalSpacing = 'padding-y-4' }) => {
  return (
    <div className={classNames('d-flex align-items-center', verticalSpacing)}>
      <div className='sunset-separator d-inline-block' />
    </div>
  );
};

SunsetSeparator.propTypes = {
  verticalSpacing: string,
};

export default SunsetSeparator;
