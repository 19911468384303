import classNames from 'classnames';
import {
  array,
  bool,
  element,
  func,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';
import React from 'react';

import Checkbox from 'sora-client/components/common/Checkbox';

import CheckIcon from './CheckIcon';

const OptionCustom = ({
  option,
  className,
  'data-testId': dataTestId,
  description = null,
  disabled = false,
  html = null,
  label,
  multiple,
  onChange,
  selectName,
  selectValue,
  value,
  checked = false,
}) => {
  return (
    <div
      className={classNames(
        'select-custom-option padding-x-3 padding-y-2 d-flex align-items-center justify-content-between',
        { 'cursor-default': disabled, disabled },
        className,
      )}
      data-testid={dataTestId}
      style={{ minHeight: '40px' }}
      onClick={(e) => {
        if (disabled) {
          e.stopPropagation();
          return;
        }
        if (!multiple) {
          onChange(value, option);
        }
      }}
    >
      {multiple ? (
        <Checkbox
          checked={checked ? checked : selectValue.includes(value)}
          className='width-100'
          label={label}
          name={`mulicheck-${selectName}-${value}`}
          onChange={(e) => {
            e.target.checked
              ? onChange(selectValue.concat(value), option)
              : onChange(
                  selectValue.filter((val) => val !== value),
                  option,
                );
          }}
        />
      ) : html ? (
        html
      ) : (
        <div>
          {label}
          {description && (
            <div className='text-muted margin-top-1 text-small'>
              {description}
            </div>
          )}
        </div>
      )}
      {value === selectValue && <CheckIcon className='margin-left-1' />}
    </div>
  );
};

OptionCustom.propTypes = {
  option: object,
  className: string,
  'data-testId': string,
  description: string,
  disabled: bool,
  html: oneOfType([string, object, element]),
  label: string,
  multiple: bool,
  onChange: func.isRequired,
  selectName: string.isRequired,
  selectValue: oneOfType([array, bool, number, string, object]),
  value: oneOfType([array, bool, number, string]),
  checked: bool,
};

export default OptionCustom;
