import React from 'react';

const TalkToSalesModal = () => {
  return (
    <>
      <div className='text-center padding-y-7 margin-5 rounded-xlarge bg-twilight-indigo'>
        <p className='text-center'>
          <span className='padding-y-1 padding-x-3 rounded-xlarge bg-gray font-weight-500'>
            Sora Pro
          </span>
        </p>
        <div className='text-paper-white'>
          <h1
            className='margin-y-0 margin-x-auto'
            style={{ fontSize: '3.5em', maxWidth: 600 }}
          >
            Design a delightful onboarding journey
          </h1>
          <p className='text-xxlarge'>...and so much more</p>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <div
            className='d-inline-block margin-top-3 d-inline-block padding-y-3 padding-x-4 bg-sunset bg-sunset-85-hover transition-background rounded-xlarge link-button text-paper-white font-weight-500'
            onClick={() => {
              document.querySelector('#book-a-demo').scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            See how it works
          </div>
          <h3 className='font-p22-mackinac margin-top-7'>
            <em>Trusted by People teams</em>
          </h3>
          <div
            className='d-grid grid-auto-columns align-items-center margin-x-auto'
            style={{ maxWidth: 800 }}
          >
            <img
              src='https://uploads-ssl.webflow.com/5d35882fd1cb7f09d0395c2d/5e695031157c8caf256e6eb9_affirm.png'
              alt='Affirm logo'
              width='120'
              style={{ filter: 'brightness(0%) invert(97%)' }}
            />
            <img
              src='https://uploads-ssl.webflow.com/5d35882fd1cb7f09d0395c2d/6093271a39eff4812f9b37d7_flexport.svg'
              alt='Flexport logo'
              width='120'
              style={{ filter: 'brightness(0%) invert(97%)' }}
            />
            <img
              src='https://uploads-ssl.webflow.com/5d35882fd1cb7f09d0395c2d/609327881ba21f99ca4870b8_gainsight.png'
              alt='Gainsight logo'
              width='120'
              style={{ filter: 'brightness(0%) invert(97%)' }}
            />
            <img
              src='https://uploads-ssl.webflow.com/5d35882fd1cb7f09d0395c2d/62f14acae806def01d000dd5_Calendly-customer-grid-white.png'
              alt='Calendly logo'
              width='120'
              style={{ filter: 'brightness(0%) invert(97%)' }}
            />
            <img
              src='https://uploads-ssl.webflow.com/5d35882fd1cb7f09d0395c2d/609326eb99fed33905e4a02d_Plaid_logo.svg'
              alt='Plaid logo'
              width='120'
              style={{ filter: 'brightness(0%) invert(97%)' }}
            />
          </div>
        </div>
      </div>
      <div id='book-a-demo' />
      <div className='d-flex margin-x-5 margin-y-7'>
        <div className='padding-right-6'>
          <h2 className='margin-top-0'>See Sora for yourself</h2>
          <p className='text-xxlarge'>
            Book a customized demo to learn how to build workflows that connect
            your HR tools, automate every onboarding task under the sun, and set
            your new hires up for success.
          </p>
          <ul className='text-large'>
            <li>
              Send contracts, surveys, and personalized emails to new hires
            </li>
            <li className='margin-top-3'>
              Book calendar invites with new hires and assign onboarding buddies
              to them
            </li>
            <li className='margin-top-3'>
              Assign team members to every onboarding task on your team&apos;s
              list
            </li>
          </ul>
        </div>
        <div
          className='border-a rounded-xlarge overflow-hidden'
          style={{ minWidth: 360, height: 600 }}
        >
          <iframe
            title='Calendly'
            src='https://calendly.com/sora-4/sora-demo-1?embed_domain=www.sora.co&amp;embed_type=Inline&amp;hide_event_type_details=1&amp;utm_source=inbound'
            width='100%'
            height='100%'
            frameBorder='0'
            loading='eager'
          />
          <script
            type='text/javascript'
            src='https://assets.calendly.com/assets/external/widget.js'
          />
        </div>
      </div>
    </>
  );
};

export default TalkToSalesModal;
