import { any } from 'prop-types';
import React from 'react';

const InnerPageContentContainer = ({ children }) => (
  <div className='padding-bottom-6 margin-top-2 padding-x-5 mobile-padding-x-3 mobile-margin-y-5'>
    {children}
  </div>
);

InnerPageContentContainer.propTypes = {
  children: any,
};

export default InnerPageContentContainer;
