import { DEFAULT_INTEGRATION } from '../../common';

export const META_INTEGRATION_ID = 0;

export const META_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: META_INTEGRATION_ID,
  configurable: () => false,
  name: 'meta',
  label: 'Meta',
  description: '',
  availableUses: [],
};
