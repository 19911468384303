import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const PAYLOCITY_INTEGRATION_ID = 24;

export const PAYLOCITY_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: PAYLOCITY_INTEGRATION_ID,
  name: 'paylocity',
  label: 'Paylocity',
  description: 'Cloud-based payroll and HCM',
  website: 'https://www.paylocity.com/',
  helpDocs: 'https://help.sora.co/resources/paylocity',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/paylocity.png`,
  availableUses: [
    AVAILABLE_USES_ENUMS.WORKFLOWS,
    AVAILABLE_USES_ENUMS.DATA_SYNC,
  ],
  categories: [CATEGORIES.ATS, CATEGORIES.HRIS],
  configSchema: {
    companyId: {
      type: 'string',
      title: 'Paylocity company ID',
      nullable: true,
    },
    webhookKey: {
      type: 'string',
      title: 'Webhook key',
      autogenerate: true,
      uiSchema: {
        'ui:widget': 'ReadOnlyValueToCopy',
      },
    },
    clientId: {
      type: 'string',
      title: 'Client ID',
      nullable: true,
    },
    clientSecret: {
      type: 'string',
      title: 'Client secret',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    outboundEmployeeFieldMapping: {
      type: 'string',
      title: 'Map outbound fields from Sora employees, to Paylocity employees',
      nullable: true,
    },
  },
  configured: (c) =>
    c.values &&
    /**
     * We have a partnership with Paylocity, enabling customers to share their data with us.
     * In such cases, it's enough to configure the Company ID. Alternatively, customers
     * need to arrange the setup of a custom client ID and secret directly with Paylocity.
     */
    (c.values.companyId || (c.values.clientId && c.values.clientSecret)),
  validation: { action: 'paylocity/getEmployees', parameters: { pagesize: 1 } },
};
