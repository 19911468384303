import classNames from 'classnames';
import gql from 'graphql-tag';
import {
  array,
  bool,
  func,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React, { useContext } from 'react';

import EmployeeAvatar from 'sora-client/components/common/Avatar';
import DynamicSelect from 'sora-client/components/common/Select/DynamicSelect';
import SessionContext from 'sora-client/contexts/SessionContext';

export const GET_EMPLOYEES = gql`
  query getEmployeesForDropdownQuery(
    $offset: Int
    $limit: Int
    $searchTextFilter: String
    $terminated: Boolean
    $excludeIds: [ID]
    $orderBy: String
  ) {
    employeesLimited(
      offset: $offset
      limit: $limit
      filters: {
        searchTextFilter: $searchTextFilter
        terminated: $terminated
        excludeIds: $excludeIds
      }
      orderBy: $orderBy
    ) {
      count
      rows {
        id
        name
        imageUrl
        title
      }
    }
  }
`;
const EMPLOYEE_QUERY_LIMIT = 20;

const EmployeeOption = (props) => {
  const { className, id, imageUrl, label, onChange, title, value } = props;
  const isNull = value === '';
  return (
    <div
      onClick={onChange}
      className={classNames('d-flex align-items-center', className)}
    >
      {/* hide avatar for the null employee */}
      {!isNull ? (
        <EmployeeAvatar
          imageSrc={imageUrl}
          widthHeight={32}
          employeeName={label}
          employeeId={id}
        />
      ) : (
        <div style={{ height: '32px', width: '1px' }} />
      )}
      <div className={classNames({ 'margin-left-3': !isNull })}>
        <div className='font-weight-500'>{label}</div>
        <div className='text-small fade-70'>{title}</div>
      </div>
    </div>
  );
};

EmployeeOption.propTypes = {
  className: string,
  id: string,
  imageUrl: string,
  label: string,
  onChange: func,
  title: string,
  value: oneOfType([array, string]),
};

const EmployeeSelect = (props) => {
  const {
    allowEmpty = false,
    disabled = false,
    excludeEmployeeIds = [],
    onChange,
    placeholder = 'Search employees...',
    selectedEmployee,
    includeDirectReports = false,
  } = props;

  const session = useContext(SessionContext);
  const directReports = {
    id: 'my_direct_reports',
    label: 'My direct reports',
    entity: null,
    value: session.currentUser?.directReportsIds,
  };

  return (
    <DynamicSelect
      additionalOptions={
        includeDirectReports && directReports.value?.length
          ? [directReports]
          : null
      }
      allowEmpty={allowEmpty}
      className='width-100'
      disabled={disabled}
      entityLabelKey='name'
      entityValueKey='id'
      initialSelected={
        selectedEmployee?.id
          ? {
              label: selectedEmployee.name,
              value: selectedEmployee.id.toString(),
            }
          : null
      }
      name='employee-select'
      onChange={(item) => {
        onChange(item?.value, item?.label, item?.imageUrl);
      }}
      optionRenderFn={(props) => <EmployeeOption {...props} />}
      placeholder={placeholder}
      query={{
        gql: GET_EMPLOYEES,
        limit: EMPLOYEE_QUERY_LIMIT,
        vars: {
          terminated: false,
          excludeIds: excludeEmployeeIds,
          orderBy: 'firstName',
        },
        resultsKey: 'employeesLimited',
      }}
      search
      value={selectedEmployee?.id ? selectedEmployee.id.toString() : ''}
      valueObj={
        selectedEmployee?.id
          ? {
              label: selectedEmployee.name,
              value: selectedEmployee.id.toString(),
            }
          : null
      }
    />
  );
};

EmployeeSelect.propTypes = {
  accessFilters: shape({
    resource: string,
    action: string,
    level: string,
  }),
  allowEmpty: bool,
  disabled: bool,
  selectedEmployee: oneOfType([object, string]),
  onChange: func.isRequired,
  excludeEmployeeIds: array,
  togglerClassName: string,
  placeholder: string,
  includeDirectReports: bool,
};

export default EmployeeSelect;
