import classNames from 'classnames';
import { isBoolean, isFunction, kebabCase } from 'lodash';
import { any, bool, func, node, string } from 'prop-types';
import React, { useState } from 'react';

import { ChevronRightSvg, DownArrowSvg, UpArrowSvg } from './Svg';
import { useClickOutside } from './hooks';

const DISABLED_ARROW_COLOR = 'gray';

export const CollapsibleArrow = (props) => {
  const { disabled, collapsed, position } = props;

  const color = disabled ? DISABLED_ARROW_COLOR : props.color || '';

  return (
    <div className={`svg-arrow-${color ? color + '-' : ''}container`}>
      {position === 'left' && (
        <>
          {collapsed ? (
            <ChevronRightSvg disabled={disabled} noChangeOnHover={disabled} />
          ) : (
            <DownArrowSvg disabled={disabled} noChangeOnHover={disabled} />
          )}
        </>
      )}
      {position !== 'left' && (
        <div className='svg-medium-parent-align-text'>
          <>
            {collapsed ? (
              <DownArrowSvg disabled={disabled} noChangeOnHover={disabled} />
            ) : (
              <UpArrowSvg disabled={disabled} noChangeOnHover={disabled} />
            )}
          </>
        </div>
      )}
    </div>
  );
};

CollapsibleArrow.propTypes = {
  disabled: bool,
  collapsed: bool,
  position: string,
  color: string,
};

const Collapsible = (props) => {
  const [collapsed, setCollapsed] = useState(
    isBoolean(props.collapsed) ? props.collapsed : true,
  );
  const [isHovered, setIsHovered] = useState(true);
  const [isFocused, setIsFocused] = useState(!!props.collapsed);
  const onClickOutside = () => {
    setIsFocused(false);
  };
  const outsideRef = useClickOutside(onClickOutside);

  const titleIsString = typeof props.title === 'string';
  const testReference = `collapsible-${kebabCase(props.title)}`;

  const interactiveProps = {
    onClick: () => {
      !props.disabled && setCollapsed(!collapsed);
      !props.disabled && props.onClick();
    },
    onKeyDown: (e) => {
      if (e.key !== 'Enter') return;
      !props.disabled && setCollapsed(!collapsed);
    },
    role: 'button',
    tabIndex: 0,
    'aria-pressed': !collapsed,
    'data-testid': testReference,
    'data-cy': testReference,
  };

  return (
    <div
      className='collapsible position-relative'
      ref={outsideRef}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onClick={() => {
        setIsFocused(true);
      }}
    >
      {props.arrowPosition === 'left' && !collapsed && !props.hideRail && (
        <div
          className={classNames(
            'active-rail position-absolute position-top position-bottom border-left border-color-sunset border-thickness-l margin-y-4',
            {
              'fade-40': !isFocused && !isHovered,
              'fade-70': !isFocused && isHovered,
            },
          )}
          style={{ left: -8 }}
        />
      )}
      <div
        className={classNames(
          'd-flex',
          {
            'cursor-pointer no-select':
              !props.disabled && props.arrowPosition === 'right',
          },
          props.customInnerWrapperClassnames,
        )}
        {...(props.arrowPosition === 'right' ? interactiveProps : {})}
      >
        {props.arrowPosition === 'left' && (
          <div
            className={classNames('margin-right-2', {
              'cursor-pointer': !props.disabled,
            })}
            {...interactiveProps}
          >
            <CollapsibleArrow
              disabled={props.disabled}
              position='left'
              collapsed={collapsed}
            />
          </div>
        )}
        <div
          className={classNames(
            'flex-grow-1',
            {
              'padding-right-3': props.arrowPosition === 'right',
            },
            props.customTitleClassnames,
          )}
          style={{ minWidth: 0 }}
        >
          <div
            className={classNames('position-relative', {
              'cursor-pointer': !props.disabled,
            })}
            style={{ top: props.arrowPosition === 'left' ? 2 : 0 }}
            onClick={() => {
              !props.disabled && setCollapsed(!collapsed);
              !props.disabled && props.onClick();
            }}
          >
            {titleIsString && <strong>{props.title}</strong>}
            {!titleIsString && props.title}
            {props.description && (
              <div
                className={classNames(
                  'margin-top-1 text-muted',
                  props.customDescriptionClassnames,
                )}
              >
                {props.description}
              </div>
            )}
          </div>
          {props.arrowPosition === 'left' && !collapsed && (
            <div className='margin-top-3'>
              {isFunction(props.children)
                ? props.children({ collapsed, setCollapsed })
                : props.children}
            </div>
          )}
        </div>
        {props.arrowPosition === 'right' && (
          <CollapsibleArrow
            disabled={props.disabled}
            collapsed={collapsed}
            position='right'
          />
        )}
      </div>
      {props.arrowPosition === 'right' && !collapsed && (
        <>
          {isFunction(props.children)
            ? props.children({ collapsed, setCollapsed })
            : props.children}
        </>
      )}
    </div>
  );
};

Collapsible.propTypes = {
  collapsed: bool,
  hideRail: bool,
  disabled: bool,
  arrowPosition: string,
  title: node.isRequired,
  description: node,
  customInnerWrapperClassnames: string,
  customTitleClassnames: string,
  customDescriptionClassnames: string,
  children: any,
  onClick: func,
};

Collapsible.defaultProps = {
  arrowPosition: 'right',
  onClick: () => {},
};

export default Collapsible;
