import classNames from 'classnames';
import { bool, func, number, string } from 'prop-types';
import React from 'react';

import { pluralize } from 'sora-client/helpers';

import { NotMobile } from './MediaQueries';
import { ChevronLeftSvg, ChevronRightSvg } from './Svg';

const CondensedPagination = ({
  pageSize,
  total,
  offset,
  entityIdentifier = '',
  onChange,
  wrapperClassnames,
  chevronClassnames,
  className = null,
  hasPrevious = null,
  hasNext = null,
}) => {
  const previousPageDisabled =
    typeof hasPrevious === 'boolean' ? !hasPrevious : offset === 0;
  const nextPageDisabled =
    typeof hasNext === 'boolean' ? !hasNext : offset + pageSize >= total;

  const calculatedEndAmount =
    offset + pageSize > total ? total : offset + pageSize;

  if (total < offset) {
    // If the total number of results ends up being fewer than the current offset,
    // reset the page back to the maximum number of pages that includes the current
    // results. This may happen if we are on a later page of results and then enter
    // a search term that reduces the total number of results to fewer pages than
    // the page we are currently on, which causes the matching results to be hidden.
    // For example if we're on page 3 with 20 results per page (60 items total),
    // and enter a search term that matches only 25 of those items, the resulting
    // 25 items fit on 2 pages only. We need to reset the current page back to page
    // 2 in order to see matching results.
    const nPages = Math.floor(total / pageSize);
    onChange(nPages);
  }

  return (
    <div
      className={classNames(
        'd-flex align-items-center condensed-pagination',
        className,
      )}
    >
      {/* TODO: Remove hard-coded padding-left-3 on total display */}
      {!!total && (
        <div className='padding-left-3 padding-right-1 text-uppercase text-small'>
          {pageSize === 1 ? offset + 1 : `${offset + 1}-${calculatedEndAmount}`}
          <NotMobile>
            {' '}
            of {total} {pluralize(entityIdentifier, total)}
          </NotMobile>
        </div>
      )}
      <div
        className={classNames(wrapperClassnames, {
          'bg-hover-gray rounded': !previousPageDisabled,
          'fade-50': previousPageDisabled,
        })}
        data-testid='pagination-previous-page'
        onClick={() => {
          if (!previousPageDisabled) {
            onChange(offset / pageSize - 1);
          }
        }}
      >
        <ChevronLeftSvg
          className={classNames(chevronClassnames, {
            'no-click': previousPageDisabled,
          })}
        />
      </div>
      <div
        data-testid='pagination-next-page'
        className={classNames(wrapperClassnames, {
          'bg-hover-gray rounded': pageSize * offset < total,
          'fade-50': nextPageDisabled,
        })}
        onClick={() => {
          if (!nextPageDisabled) {
            onChange(offset / pageSize + 1);
          }
        }}
      >
        <ChevronRightSvg
          className={classNames(chevronClassnames, {
            'no-click': nextPageDisabled,
          })}
        />
      </div>
    </div>
  );
};

CondensedPagination.propTypes = {
  pageSize: number,
  total: number,
  offset: number,
  entityIdentifier: string,
  onChange: func,
  wrapperClassnames: string,
  chevronClassnames: string,
  className: string,
  hasPrevious: bool,
  hasNext: bool,
};

export default CondensedPagination;
