import { arrayOf, string } from 'prop-types';
import React from 'react';

import { Tag } from 'sora-client/components/common/Tag';
import { modelConstants } from 'sora-invariant/src/constants';

const tagOptions = modelConstants.timeline.tagOptions;

const DEFAULT_TAG_COLOR = '#c2c3e50';
export const WorkflowTagsList = ({ tags, className = null }) => {
  if (!tags?.length) return null;

  return (
    <div className={className}>
      {tags?.map((tag, index) => {
        // return early if an invalid tag is passed in
        if (!tagOptions[tag]) return;

        return (
          <Tag
            key={index}
            className='text-paper-white margin-right-2 text-xsmall margin-top-2'
            style={{
              backgroundColor: tagOptions[tag].color
                ? `#${tagOptions[tag].color.substr(1)}`
                : DEFAULT_TAG_COLOR,
            }}
          >
            {tagOptions[tag].name}
          </Tag>
        );
      })}
    </div>
  );
};

WorkflowTagsList.propTypes = {
  className: string,
  tags: arrayOf(string),
};
