import { isEmpty, map, orderBy, unionBy, without } from 'lodash';
import {
  any,
  array,
  bool,
  func,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React, { useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import { LoadingSvg } from 'sora-client/components/common/Svg';
import { useStateDebounce } from 'sora-client/components/common/hooks';
import useQueryWrapper from 'sora-client/components/common/useQueryWrapper';

import Select from './index';

const EMPTY_ITEM = { id: -1, label: 'None', entity: null, value: '' };

const DynamicSelect = (props) => {
  const {
    additionalOptions = [],
    allowEmpty,
    entityLabelKey,
    entityValueKey,
    query: { limit, gql, resultsKey, vars },
    onChange,
    value: initialValue,
    valueObj: initialValueObj,
    ...otherProps
  } = props;

  const [value, setValue] = useState(initialValue);
  const [valueObj, setValueObj] = useState(initialValueObj);
  const [offset, setOffset] = useStateDebounce(0, 250);
  const [searchTextFilter, updateSearchTextFilter] = useStateDebounce('', 250);

  const searchResults = useRef([]);

  const { data, loading } = useQueryWrapper(gql, {
    ...vars,
    offset,
    limit,
    searchTextFilter,
  });

  const { count, rows = [] } = data?.[resultsKey] || {};

  if (offset === 0 && !(loading && isEmpty(rows))) {
    // Initial search results, or reset to offset 0 after search text change --
    // overwrite searchResults.current entirely
    searchResults.current = rows;
  } else if (
    !isEmpty(without(map(rows, 'id'), ...map(searchResults.current, 'id')))
  ) {
    // There are additional entries in `rows` that are not in searchResults.current --
    // update searchResults.current
    searchResults.current = orderBy(
      unionBy(searchResults.current || [], rows, 'id'),
      'name',
    );
  }

  const options = searchResults.current.map((entity) => ({
    ...entity,
    label: entity[entityLabelKey],
    value: entity[entityValueKey],
  }));

  // Show empty
  if (allowEmpty && !searchTextFilter) {
    options.unshift(EMPTY_ITEM);
  }

  // Show additional options
  if (additionalOptions?.length && !searchTextFilter) {
    options.unshift(...additionalOptions);
  }

  return (
    <Select
      {...otherProps}
      onChange={(val, valObj) => {
        setValue(val);
        setValueObj(valObj);
        updateSearchTextFilter('');
        setOffset(0);
        if (val === EMPTY_ITEM.id) {
          onChange(null);
        } else if (additionalOptions?.find((opt) => opt.id === valObj.id)) {
          onChange(valObj);
        } else {
          onChange(options.find((opt) => opt.id === val));
        }
      }}
      onSearch={(val) => {
        setOffset(0);
        updateSearchTextFilter(val);
      }}
      options={options}
      value={value}
      valueObj={valueObj}
    >
      {!loading && limit + offset < count && searchResults.current.length && (
        <Waypoint
          bottomOffset='-50%'
          key={offset}
          onEnter={() => {
            setOffset(offset + limit);
          }}
        />
      )}
      {limit + offset < count && (
        <div className='padding-x-3'>
          <LoadingSvg />
        </div>
      )}
    </Select>
  );
};

DynamicSelect.propTypes = {
  additionalOptions: array,
  allowEmpty: bool,
  className: string,
  disabled: bool,
  entityLabelKey: string.isRequired,
  entityValueKey: string.isRequired,
  initialSelected: shape({
    label: string,
    value: oneOfType([string, number]),
  }),
  name: string,
  onChange: func.isRequired,
  optionRenderFn: func,
  placeholder: string,
  query: shape({
    key: string,
    limit: number,
    gql: object,
    vars: object,
    resultsKey: string,
  }),
  search: bool,
  value: any,
  valueObj: object,
};

export default DynamicSelect;
