import classNames from 'classnames';
import { bool, func, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { Mobile, NotMobile } from 'sora-client/components/common/MediaQueries';
import { LeftArrowSvg } from 'sora-client/components/common/Svg';
import Tooltip from 'sora-client/components/common/Tooltip';

const ButtonCore = ({ bgClassname, border }) => {
  return (
    <div
      className={classNames('rounded-circle text-center', bgClassname, {
        'border-a': border,
      })}
      style={{ width: 40, height: 40 }}
    >
      <div>
        <LeftArrowSvg className='padding-2' />
      </div>
    </div>
  );
};

ButtonCore.propTypes = {
  bgClassname: string,
  border: bool,
};

const BackButton = ({
  to,
  tooltipText = null,
  persistentHelpText = null,
  bgClassname = 'bg-gray-1',
  border = false,
  onClick = () => {},
}) => {
  return (
    <Link to={to} className='text-default margin-right-3' onClick={onClick}>
      <NotMobile>
        <div className='d-inline-flex align-items-center link stealth-link'>
          <Tooltip tooltip={tooltipText} placement='top'>
            <ButtonCore bgClassname={bgClassname} border={border} />
          </Tooltip>
          {persistentHelpText && (
            <p className='margin-y-0 font-weight-500 margin-left-3'>
              {persistentHelpText}
            </p>
          )}
        </div>
      </NotMobile>
      <Mobile>
        <div className='d-inline-flex align-items-center'>
          <ButtonCore bgClassname={bgClassname} border={border} />
          <p className='margin-y-0 font-weight-500 margin-left-3'>
            {tooltipText || persistentHelpText}
          </p>
        </div>
      </Mobile>
    </Link>
  );
};

BackButton.propTypes = {
  to: string.isRequired,
  tooltipText: string,
  persistentHelpText: string,
  bgClassname: string,
  border: bool,
  onClick: func,
};

export default BackButton;
