export const DAYS_OF_WEEK_TEXT = {
  1: { short: 'Mon', long: 'Monday' },
  2: { short: 'Tue', long: 'Tuesday' },
  3: { short: 'Wed', long: 'Wednesday' },
  4: { short: 'Thu', long: 'Thursday' },
  5: { short: 'Fri', long: 'Friday' },
  6: { short: 'Sat', long: 'Saturday' },
  7: { short: 'Sun', long: 'Sunday' },
};

export const DAYS_OF_WEEK_TYPES = {
  ANY_DAY: 'anyDay',
  ANY_WEEKDAY: 'anyWeekday',
  SPECIFIC_DAY: 'specificDay',
};
const { ANY_DAY, ANY_WEEKDAY, SPECIFIC_DAY } = DAYS_OF_WEEK_TYPES;

export const DAYS_OF_WEEK_TYPE_TEXT = {
  [ANY_DAY]: 'On any day of the week',
  [ANY_WEEKDAY]: 'On any weekday',
  [SPECIFIC_DAY]: 'Specific day(s) of the week',
};

export const TASKS_WITHOUT_DUE_DATE = ['calendarInvite', 'calendarEvent'];
