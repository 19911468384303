import classNames from 'classnames';
import { get, isEmpty } from 'lodash';
import { bool, func, number, object, oneOfType, string } from 'prop-types';
import React from 'react';

import Tooltip from './Tooltip';
import FormInput from './forms/FormInput';
import useClickOutside from './hooks/useClickOutside';

const DELAY_TIMEOUT = 700;

const ErrorSign = ({ id, errorKey, errorMessage, isWarning = false }) => (
  <div
    data-testid={`error-sign-${id}-${errorKey}`}
    className='position-absolute position-top position-right cursor-default'
  >
    <Tooltip tooltip={errorMessage} placement='top'>
      <div
        className={classNames(
          'cell-error-sign text-paper-white text-xxsmall font-weight-500 d-flex align-items-center justify-content-center',
          {
            'bg-danger': !isWarning,
            'bg-sunrise': isWarning,
          },
        )}
      >
        !
      </div>
    </Tooltip>
  </div>
);

ErrorSign.propTypes = {
  id: oneOfType([number, string]),
  errorKey: string,
  errorMessage: string,
  isWarning: bool,
};

const TableCell = ({
  cellData,
  column,
  rowData,
  rowIndex,
  onChange,
  onEditStart,
  onEditStop,
  editable = false,
  cellPlaceholder = '',
}) => {
  const cellId = `${rowData.id}-${column.key}`;
  const editCellId = (rowData.editOptions || {}).editId;
  const isEdit = cellId === editCellId;

  const onClickOutside = () => {
    isEdit && onEditStop && onEditStop();
  };

  const outSideRef = useClickOutside(onClickOutside);

  const onDataClick = () => {
    if (editable && !isEdit) {
      onEditStart({ cellId });
    }
  };

  const onInputBlur = () => {};

  const onFormInputChange = (e) => {
    const value = e.target.value;
    const newRowData = {
      ...rowData,
      [column.key]: value,
    };

    onChange &&
      onChange({
        key: column.key,
        value: value,
        id: rowData.id,
        rowIndex,
        rowData: newRowData,
      });
  };

  const errorSetup = get(rowData, `cellErrors.${column.key}`, {});
  const errorMessage =
    typeof errorSetup === 'string' ? errorSetup : errorSetup.message;
  const cellCopy =
    isEmpty(errorSetup) || errorSetup?.isWarning
      ? cellData
      : errorMessage || cellData;

  return (
    <div
      ref={outSideRef}
      className={classNames(
        'cell-fullsize d-flex align-items-center position-relative text-truncate',
        {
          'cell-border-red': errorSetup.bordered && !errorSetup.isWarning,
          'cell-border-sunrise': errorSetup.bordered && errorSetup.isWarning,
          'cell-edit': isEdit,
          'cursor-pointer bg-hover-gray': editable,
        },
      )}
      onClick={() => !isEdit && onDataClick()}
    >
      {errorSetup.mark && (
        <ErrorSign
          id={rowData.id}
          errorKey={column.key}
          errorMessage={errorMessage}
          isWarning={errorSetup.isWarning}
        />
      )}
      <div
        className={classNames('text-truncate', {
          'text-muted': !isEdit && !cellData && !errorMessage,
          'text-danger': !isEdit && !!errorMessage && !errorSetup.isWarning,
        })}
      >
        {!isEdit && (cellCopy || errorMessage || cellPlaceholder)}
        {isEdit && (
          <FormInput
            data-testid={`cell-input-${rowData.id}-${column.key}`}
            inputRef={(ref) => {
              ref && ref.focus();
            }}
            value={cellData}
            onChange={onFormInputChange}
            onBlur={onInputBlur}
            delayTimeout={DELAY_TIMEOUT}
          />
        )}
      </div>
    </div>
  );
};

TableCell.propTypes = {
  cellData: string,
  column: object,
  rowData: object,
  rowIndex: number,
  onChange: func,
  onEditStart: func,
  onEditStop: func,
  editable: bool,
  cellPlaceholder: string,
};

export default TableCell;
