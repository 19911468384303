import {
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const JUSTWORKS_INTEGRATION_ID = 47;

export const JUSTWORKS_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: JUSTWORKS_INTEGRATION_ID,
  name: 'justworks',
  label: 'JustWorks',
  description: '',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/justworks.png`,
  website: 'https://www.justworks.com/',
  availableUses: [],
  categories: [CATEGORIES.HRIS],
  configSchema: {
    username: {
      type: 'string',
      title: 'Username',
      nullable: true,
    },
    password: {
      type: 'string',
      title: 'Password',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
  },
  configured: (c) => c.values && c.values.username && c.values.password,
};
