import classNames from 'classnames';
import { omit } from 'lodash';
import { bool, func, number, string } from 'prop-types';
import React from 'react';
import { DelayInput } from 'react-delay-input';

const isTest = process.env.NODE_ENV === 'test';
const defaultTimeout = 100;

const FormTextarea = (props) => (
  <div
    className={classNames('d-flex', {
      'margin-top-2': !props.noTopMargin,
    })}
  >
    <DelayInput
      {...omit(props, ['className', 'errorMessage', 'noTopMargin'])}
      className={classNames('flex-grow-1', props.className, {
        'error-input': props.errorMessage,
      })}
      delayTimeout={isTest ? 0 : props.delayTimeout || defaultTimeout}
      element='textarea'
    />
  </div>
);

FormTextarea.propTypes = {
  autoFocus: bool,
  className: string,
  delayTimeout: number,
  disabled: bool,
  errorMessage: string,
  id: string,
  maxLength: string,
  name: string,
  onChange: func,
  placeholder: string,
  rows: string,
  value: string,
  noTopMargin: bool,
};

export default FormTextarea;
