import { bool, object, string } from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from './Button';

const HEADING_TEXT = 'Sorry, something went wrong.';

const GenericErrorMessage = ({
  error,
  showButtons = false,
  useLargeHeading = false,
  className,
}) => {
  const history = useHistory();

  const heading = useLargeHeading ? (
    <h1>{HEADING_TEXT}</h1>
  ) : (
    <h4 className='font-p22-mackinac'>{HEADING_TEXT}</h4>
  );

  return (
    <div className={className}>
      {heading}
      <p>
        Please reload and retry the previous operation. If the problem persists,{' '}
        <a href='mailto:support@sora.co'>report feedback</a>.
      </p>
      {['development', 'staging'].includes(process.env.NODE_ENV) &&
        error.message.slice(0, 100)}
      {showButtons && (
        <div className='margin-top-4 d-flex'>
          <Button
            onClick={() => {
              history.go(0); // Reloads the url
            }}
            category='primary'
            label='Reload'
          />
          <Button
            label='Go home'
            className='margin-left-2'
            onClick={() => {
              history.push('/');
            }}
          />
        </div>
      )}
    </div>
  );
};

GenericErrorMessage.propTypes = {
  error: object.isRequired,
  showButtons: bool,
  useLargeHeading: bool,
  className: string,
};

export default GenericErrorMessage;
