import classNames from 'classnames';
import { number, string } from 'prop-types';
import React, { memo } from 'react';

import Img from 'sora-client/components/common/Img';
import { DEFAULT_AVATAR_COLORS } from 'sora-client/constants/components';
import genericUserAvatar from 'sora-client/static/images/generic-user.png';

const EmployeeAvatar = ({
  imageSrc,
  widthHeight = 32,
  customWrapperClassnames,
  customImgClassnames,
  employeeName = '',
  employeeId = 0,
}) => {
  return (
    <div
      className={classNames(
        'employee-avatar position-relative d-inline-block rounded-circle',
        customWrapperClassnames,
      )}
      style={{ width: widthHeight, height: widthHeight }}
    >
      {/* Default Avatar (initials) */}
      {!imageSrc && (
        <div
          className={classNames('default-employee-avatar', customImgClassnames)}
          key={`${employeeId}::${widthHeight}`}
          style={{
            width: widthHeight,
            height: widthHeight,
            fontSize: widthHeight / 3,
            background:
              DEFAULT_AVATAR_COLORS[employeeId % DEFAULT_AVATAR_COLORS.length],
          }}
        >
          {/* First and Last initials (usually) */}
          {employeeName
            ?.split(' ')
            .map((n) => n[0]?.toUpperCase())
            .slice(0, 2)
            .join('')}
        </div>
      )}
      {/* Employee Photo */}
      {imageSrc && (
        <Img
          src={imageSrc}
          backupImgSrc={genericUserAvatar}
          className={classNames('rounded-circle', customImgClassnames)}
          alt='Employee avatar'
          width={widthHeight}
          height={widthHeight}
          key={`${imageSrc}::${widthHeight}`}
        />
      )}
      <div className='avatar-shadow position-absolute-center rounded-circle' />
    </div>
  );
};

EmployeeAvatar.propTypes = {
  imageSrc: string,
  widthHeight: number,
  customWrapperClassnames: string,
  customImgClassnames: string,
  employeeName: string.isRequired,
  employeeId: string.isRequired,
};

export default memo(EmployeeAvatar);
