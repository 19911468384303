import gql from 'graphql-tag';

export const GET_FIELD_MAPPING_CONFIGS = gql`
  query getAvailableFieldMappingConfigs(
    $serviceName: String!
    $email: String
    $employeeId: ID
  ) {
    availableFieldMappingConfigs(
      serviceName: $serviceName
      email: $email
      employeeId: $employeeId
    ) {
      uniquePath
      dataPath
      label
      description
    }
  }
`;
