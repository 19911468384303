import classNames from 'classnames';
import { kebabCase, noop, omit } from 'lodash';
import { bool, func, node, number, object, oneOf, string } from 'prop-types';
import React, { useContext } from 'react';

import { BUTTON_SIZES, BUTTON_TYPES } from 'sora-client/constants/components';
import { AnalyticsContext } from 'sora-client/contexts';
import PreviewContext from 'sora-client/contexts/PreviewContext';

import { DownArrowSvg, LoadingSvg } from './Svg';

const Button = (props) => {
  const {
    category = 'secondary',
    isDropdown = false,
    label = '',
    labelHTML = null,
    type = 'button',
    size = 'default',
    full = false,
    disabled = false,
    loading = false,
    className,
    onClick = noop,
    analyticsEventName: analyticsEventNameExplicit = null,
    analyticsEventData = {},
    enableInPreview = false,
  } = props;

  const { track } = useContext(AnalyticsContext);
  const { previewingAsEmployee } = useContext(PreviewContext);
  const primaryType = category === 'primary';
  const secondaryType = category === 'secondary';
  const warningType = category === 'warning';
  const destructiveType = category === 'destructive';
  const infoType = category === 'info';
  const actionType = category === 'action';
  const actionSecondaryType = category === 'actionSecondary';
  const linkType = category === 'link';

  const isNotDisabledAndNotLoading = !disabled && !loading;
  const isDisabledOrLoading =
    disabled || loading || (previewingAsEmployee && !enableInPreview);

  const analyticsEventName =
    analyticsEventNameExplicit || `Clicked ${label}` || null;

  return (
    <button
      {...omit(props, [
        'label',
        'labelHTML',
        'loading',
        'size',
        'full',
        'className',
        'onClick',
        'isDropdown',
        'analyticsEventData',
        'analyticsEventName',
      ])}
      data-cy={kebabCase(label)}
      data-size={size}
      className={classNames(
        'no-select align-items-center justify-content-center text font-family-inherit transition-background',
        {
          'padding-x-0': linkType,
          'padding-x-3': !linkType && ['small', 'default'].includes(size),
          'padding-x-5': !linkType && size === 'large',
          'padding-y-1 text-small rounded-large': size === 'small',
          'padding-1 text-small rounded-large': size === 'xsmall',
          'text-medium rounded-xlarge': size === 'default',
          'padding-y-3 text-large rounded-xlarge': size === 'large',
          'd-inline-flex': !full,
          'd-flex width-100': full,
          'cursor-pointer': isNotDisabledAndNotLoading,
          'hover-box-shadow-lower transition-box-shadow transition-duration-05':
            isNotDisabledAndNotLoading && !linkType,
          'cursor-default': disabled || loading,
          'bg-high-neon-indigo text-paper-white border-none':
            primaryType && isNotDisabledAndNotLoading,
          'bg-high-neon-indigo-50 text-paper-white border-none':
            primaryType && isDisabledOrLoading,
          'bg-white border-a': secondaryType && isNotDisabledAndNotLoading,
          'bg-white border-a text-muted fade-50':
            secondaryType && isDisabledOrLoading,
          'bg-white border-a border-color-warning-red text-danger':
            warningType && isNotDisabledAndNotLoading,
          'bg-gray-2 fade-50 border-a text-danger':
            warningType && isDisabledOrLoading,
          'bg-destructive border-none text-paper-white':
            destructiveType && isNotDisabledAndNotLoading,
          'bg-destructive-disabled border-none text-paper-white':
            destructiveType && isDisabledOrLoading,
          'bg-sunset text-paper-white border-none':
            infoType && isNotDisabledAndNotLoading,
          'bg-sunset-50 text-paper-white border-none':
            infoType && isDisabledOrLoading,
          'bg-green text-paper-white border-none':
            actionType && isNotDisabledAndNotLoading,
          'bg-green-50 text-paper-white border-none':
            actionType && isDisabledOrLoading,
          'bg-white text-green border-a border-color-green':
            actionSecondaryType && isNotDisabledAndNotLoading,
          'bg-white text-green-50 border-a border-color-green-50':
            actionSecondaryType && isDisabledOrLoading,
          'pointer-events-none': isDisabledOrLoading && !enableInPreview,
          'bg-transparent link border-none': linkType,
        },
        className,
      )}
      type={type}
      onClick={(e) => {
        onClick(e);
        if (analyticsEventName) track(analyticsEventName, analyticsEventData);
      }}
    >
      <div
        className={classNames(
          'd-inline-flex align-items-center justify-content-center',
          { 'fade-10': loading, 'text-blue': secondaryType },
        )}
      >
        <div
          className={classNames(
            'd-inline-flex align-items-center justify-content-center',
            { 'fade-10': loading },
          )}
        >
          {!!labelHTML && labelHTML}
          {!labelHTML && (
            <div className={classNames({ 'text-blue': secondaryType })}>
              {label}
            </div>
          )}
          {isDropdown && (
            <div className='margin-left-1 svg-parent-align-text'>
              <DownArrowSvg
                className={classNames('svg-medium svg-fill-none', {
                  'stroke-blue': secondaryType,
                })}
              />
            </div>
          )}
        </div>
        {loading && <LoadingSvg className='position-absolute no-click' />}
      </div>
      {loading && <LoadingSvg className='position-absolute no-click' />}
    </button>
  );
};

Button.propTypes = {
  category: oneOf(BUTTON_TYPES),
  label: string,
  labelHTML: node,
  size: oneOf(BUTTON_SIZES),
  type: oneOf(['submit', 'reset', 'button']),
  disabled: bool,
  full: bool,
  isDropdown: bool,
  loading: bool,
  className: string,
  onClick: func,
  tabIndex: number,
  analyticsEventName: string,
  analyticsEventData: object,
  enableInPreview: bool,
};

export default Button;
