import gql from 'graphql-tag';

export const GET_WORKFLOW_STAGE_FIELDS_AND_REPLACEMENT_FIELDS = gql`
  query getWorkflowStages($timelineId: ID!, $forTemplateCompany: Boolean) {
    workflowStages(timelineId: $timelineId) {
      id
      name
      description
      fieldId
      calendarDate
      triggerField {
        id
        surveyId
      }
    }
    companyFields(
      forTemplateCompany: $forTemplateCompany
      types: [date, timestamp, time_with_timezone, event]
      onlyEmployeeFields: true
    ) {
      id
      name
    }
  }
`;

export const COPY_WORKFLOW_TEMPLATE = gql`
  mutation copyWorkflowWithinCompany($id: ID!, $fieldReplacements: JSON!) {
    copyTemplate(id: $id, fieldReplacements: $fieldReplacements) {
      id
    }
  }
`;

export const CREATE_NEW_TEMPLATE = gql`
  mutation createTemplateFromWorkflow($id: ID!, $fieldReplacements: JSON!) {
    createTemplateFromWorkflow(id: $id, fieldReplacements: $fieldReplacements)
  }
`;
