import { saveAs } from 'file-saver';
import { find, get, intersection, isEmpty, isEqual, without } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef } from 'react';
import { matchPath } from 'react-router-dom';

import * as routes from 'sora-client/constants/routes';
import { TASKS_WITHOUT_DUE_DATE } from 'sora-client/constants/tasks';
import { DAYS_OF_WEEK_TYPES } from 'sora-client/constants/tasks';
import { getRouteNames } from 'sora-client/helpers/getRouteNames';
import { error } from 'sora-invariant/src/constants';
import { formatDate, pluralize } from 'sora-invariant/src/helpers';

import getErrorCode from './getErrorCode';

const { ANY_DAY, ANY_WEEKDAY, SPECIFIC_DAY } = DAYS_OF_WEEK_TYPES;

const { INTERNAL_SERVER_ERROR } = error.codes;
const { SEQUELIZE_VALIDATION_ERROR } = error.types;

//TODO: get rid of direct using of apollo client
// @ts-ignore
const getApolloClient = () => window.apolloClient;

const genericBackendError =
  'Sorry, something went wrong. Please contact support!';

const shouldShowMessage = (e) => !/PersistentQueryNotFound/.test(e.message);

const getErrorMessage = (error, showRawMessage = false) => {
  if (!shouldShowMessage(error)) {
    return '';
  }

  const code = get(error, 'extensions.code', INTERNAL_SERVER_ERROR);
  const errorType = get(error, 'extensions.exception.name');
  const message =
    code !== INTERNAL_SERVER_ERROR ||
    showRawMessage ||
    errorType === SEQUELIZE_VALIDATION_ERROR
      ? error.message
      : genericBackendError;

  return message;
};

/**
 * Given a pathname, this fn returns an object representing the redirect URL from the log in page.
 * The returned object, when constructed back into a URL, will look something like `/login?redirectTo=${redirectTo}`.
 *
 * @param {string} redirectTo
 * @returns {{
 *   pathname: routes.LOG_IN,
 *   search: string,
 * }}
 */
const getRedirectUrl = (redirectTo) => {
  const parsedUrl = new URL(redirectTo, window.location.origin);
  const parsedPathname = parsedUrl.pathname || '';

  let search =
    parsedPathname[0] === '/'
      ? `?redirectTo=${encodeURIComponent(
          `${parsedPathname}${parsedUrl.search}`,
        )}`
      : '';

  if (parsedPathname === routes.LOG_IN) {
    // If user is already on the log in page, there is no need to append an additional `redirectTo` param
    // because there is nowhere to redirect them to.
    search = parsedUrl.search;
  }

  return {
    pathname: routes.LOG_IN,
    search,
  };
};

const formatError = (error, showRawMessage = false) => {
  const graphQLErrors = get(error, 'graphQLErrors');
  if (!graphQLErrors || !graphQLErrors.length) return getErrorMessage(error);
  const errorMessages = error.graphQLErrors.map((e) =>
    getErrorMessage(e, showRawMessage),
  );
  return errorMessages[0];
};

const calendarDateDiff = (dateA, dateB) =>
  moment(dateA)
    .utc()
    .startOf('day')
    .diff(moment(dateB).utc().startOf('day'), 'days');

const getCurrentPageRoute = (pathname) => {
  return Object.values(routes).find(
    (route) => !!matchPath(pathname, { path: route, exact: true }),
  );
};

const getCurrentPageName = (pathname) => {
  const currentRoute = getCurrentPageRoute(pathname);
  const routes = getRouteNames(null, false);
  const page = find(routes, (r) => r.route === currentRoute);

  if (page) return page.name;

  return '';
};

const intersectionExists = (array1, array2) => {
  return (
    !!array1 &&
    array1.length &&
    !!array2 &&
    array2.length &&
    !isEmpty(intersection(array1, array2))
  );
};

const offsetTimeText = (int) => {
  switch (true) {
    case int < 0:
      return `before`;
    case int > 0:
      return `after`;
    default:
      return `on`;
  }
};

const dueDateCopy = (taskType) => {
  const offset = taskType.dueOffset;
  const unit = taskType.dueOffsetUnit;

  const amountText = !offset
    ? ''
    : `${Math.abs(offset)} ${pluralize(unit, taskType.dueOffsetUnit)}`;

  if (taskType.dueDateField) {
    return (
      <>
        Due {amountText} {offsetTimeText(offset)}{' '}
        <strong>{taskType.dueDateField.name.toLowerCase()}</strong>
      </>
    );
  } else if (taskType.dueOffset > 0) {
    return (
      <>
        Due {amountText} after <strong>assigned</strong>
      </>
    );
  } else if (taskType.dueOffset === 0) {
    return (
      <>
        Due same day <strong>assigned</strong>
      </>
    );
  } else {
    return `Invalid due date`;
  }
};

/**
 *
 * @param {number} days
 * @returns {[number,string]}
 */
const getInputFromDays = (days = 0, plural = `(s)`) => {
  if (days === 0) {
    return [0, `day${plural}`];
  } else if (days % 30 === 0) {
    return [days / 30, `month${plural}`];
  } else if (days % 7 === 0) {
    return [days / 7, `week${plural}`];
  } else {
    return [days, `day${plural}`];
  }
};

const isNewHireTaskOverdue = (newHireTask) =>
  !newHireTask.done &&
  newHireTask.state !== 'cancelled' &&
  moment().isAfter(newHireTask.dueDate, 'day');

const getNewHireTaskDueDateForMobileTaskList = (newHireTask) => {
  if (
    !newHireTask.dueDate ||
    newHireTask.state === 'cancelled' ||
    TASKS_WITHOUT_DUE_DATE.includes(newHireTask.task.type)
  )
    return 'N/A';
  if (newHireTask.done || newHireTask.skipped) return '-';
  const tz = get(newHireTask.assignee, 'timezone') || moment.tz.guess();

  const time = moment().tz(tz);
  if (time.isSame(moment(newHireTask.dueDate).tz(tz), 'day')) {
    return `Today`;
  }

  return formatDate({ value: newHireTask.dueDate, tz });
};

const slugRegex = /[/\\ ]+/g;

const getEmployeeSlug = (employee) => {
  if (employee.name) {
    return `${employee.id}-${employee.name.replace(slugRegex, '-')}`;
  }

  return employee.id;
};

const getEmployeeLink = (employee) => {
  return `${routes.EMPLOYEES}?eid=${employee.id}`;
};

const getRecentEmployeeLink = (employee) => {
  return `${routes.EMPLOYEES_RECENT}?eid=${employee.id}`;
};

const getUpcomingEmployeeLink = (employee) => {
  return `${routes.EMPLOYEES_UPCOMING}?eid=${employee.id}`;
};

const getNewHireTaskSlug = (newHireTask) => {
  let slug = '';
  slug += newHireTask.id;

  if (newHireTask.task && newHireTask.task.name) {
    slug += '-' + newHireTask.task.name.replace(slugRegex, '-');
    if (newHireTask.newHire && newHireTask.newHire.name) {
      slug += '-for-' + newHireTask.newHire.name.replace(slugRegex, '-');
    }
  }

  return slug;
};

const getTaskSlug = (task) => {
  if (task.name) {
    return `${task.id}-${task.name.replace(slugRegex, '-')}`;
  }

  return task.id;
};

const isUrlSlug = (id) => id.includes('-');

// At the moment, taskTypeObject will only ever be an email, calendar invite, or calendar event.
const emailAddressCopy = (field, taskTypeObject) => {
  const copyPieces = [];
  if (
    (field === `to` && !!taskTypeObject.toNewHire) ||
    (field === 'attendee' && !!taskTypeObject.attendeeNewHire)
  ) {
    copyPieces.push(`Employee email address`);
  }
  if (taskTypeObject[`${field}Address`]) {
    copyPieces.push(taskTypeObject[`${field}Address`]);
  }
  if (taskTypeObject[`${field}Addresses`]) {
    const addresses = taskTypeObject[`${field}Addresses`];
    if (Array.isArray(addresses)) {
      copyPieces.push(...addresses);
    } else {
      copyPieces.push(addresses);
    }
  }
  if (taskTypeObject[`${field}AddressRelationshipField`]) {
    const relationshipFieldName =
      taskTypeObject[`${field}AddressRelationshipField`].name;
    copyPieces.push(`${relationshipFieldName} email address`);
  }
  if (taskTypeObject[`${field}AddressFields`]) {
    taskTypeObject[`${field}AddressFields`].forEach((addressField) => {
      copyPieces.push(`${addressField.name} email address`);
    });
  }
  return copyPieces.join(', ');
};

const augmentApolloClient = (client, wsLink, persistor = null) => {
  client.logIn = async () => {
    await client.clearStore();
    await wsLink.subscriptionClient.close(true, true);
  };
  client.logOut = async () => {
    persistor && (await persistor.purge());
    await client.cache.reset();
    wsLink.subscriptionClient.close(true, false);
  };
  client.resetStoreAndWsConnection = () => {
    return Promise.all([
      client.resetStore(),
      wsLink.subscriptionClient.close(),
    ]);
  };
  return client;
};

const inputIsNumber = (input) => {
  return (input && !isNaN(input)) || Number(input) === 0;
};

const addOrRemove = (arr = [], value) => {
  const exists = arr.includes(value);

  return exists ? without(arr, value) : [...arr, value];
};

const getForwardError = (error, data) => {
  if (!error) return null;
  // do not throw on network error if there is data in cache we can show
  if (
    get(error, 'message', '').match('Network error: Failed to fetch') &&
    !isEmpty(data)
  ) {
    return null;
  }
  return get(error, 'message', '').match(
    'Updating data. Give us a try in one minute!',
  )
    ? null
    : error;
};
const isLoading = (data, loading, error) =>
  (loading && isEmpty(data)) ||
  (get(error, 'message', '').match(
    'Updating data. Give us a try in one minute!',
  ) &&
    isEmpty(data));

const triggerWindowResizeEvent = () => {
  if (Event.prototype.initEvent) {
    const evt = window.document.createEvent('UIEvents');
    // @ts-ignore
    evt.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(evt);
  } else {
    window.dispatchEvent(new Event('resize'));
  }
};

const copyTextToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const extractTextFromHtml = (html) => {
  const div = document.createElement('div');

  div.innerHTML = html;

  return div.innerText || '';
};

function getWorkflowViewUrl({ workflow, overrideConfigureUrl = false }) {
  if (!workflow.active && !overrideConfigureUrl) {
    return `${routes.WORKFLOW_BASE}/${workflow.id}/configure`;
  }

  return `${routes.WORKFLOW_BASE}/${workflow.id}`;
}

const saveFile = (content, name) => {
  saveAs(new Blob([content], { type: 'text/plain;charset=utf-8' }), name);
};

const useEffectWithoutUnmount = (fn, depArr) => {
  useEffect(() => {
    let mounted = true;

    const timeout = setTimeout(() => {
      if (mounted) fn();
    });

    return () => {
      mounted = false;
      clearTimeout(timeout);
    };
  }, depArr); // eslint-disable-line
};

const useEffectAfterMount = (fn, inputs) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
};

const scrollContainerToFocusedElement = ({ container, focusedElement }) => {
  if (!focusedElement || !container) return;

  const focusedElementDimensions = focusedElement.getBoundingClientRect();

  if (container instanceof Window) {
    const siteNav = document.querySelector('#site-nav');
    let siteNavHeight = 68;
    if (siteNav) {
      siteNavHeight = siteNav.getBoundingClientRect().height;
    }

    const focusedElementTop = focusedElementDimensions.top;

    if (focusedElementTop - siteNavHeight < 0) {
      container.scrollTo(
        0,
        container.scrollY +
          focusedElementTop -
          container.innerHeight / 2 -
          siteNavHeight,
      );
    } else if (
      focusedElementTop + focusedElementDimensions.height >
      container.innerHeight
    ) {
      container.scrollTo(
        0,
        container.scrollY +
          focusedElementTop -
          focusedElementDimensions.height -
          siteNavHeight,
      );
    }
  } else {
    const focusedElementPosition = focusedElement.offsetTop;
    const containerDimensions = container.getBoundingClientRect();
    const containerTop = container.offsetTop + container.scrollTop;
    const containerHeight = containerDimensions.height;
    const containerBottom = containerTop + containerHeight;

    if (focusedElementPosition < containerTop) {
      container.scrollTop =
        focusedElement.offsetTop -
        focusedElementDimensions.height -
        containerHeight / 2;
    } else if (
      focusedElementPosition + focusedElementDimensions.height >=
      containerBottom
    ) {
      container.scrollTop =
        focusedElementPosition - focusedElementDimensions.height;
    }
  }
};

const isValidColor = (strColor) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== '';
};

/**
 * Converts a number to a string formatted with the ordinal suffix
 * (i.e. 1st, 2nd, 3rd)
 *
 * @param {number|string} num
 */
export const numWithOrdinalSuffix = (num) => {
  const int = typeof num === 'string' ? parseInt(num) : num;
  const lastDigit = int % 10;
  const lastTwoDigits = int % 100;
  let ordinal = '';

  switch (lastDigit) {
    case 1: {
      ordinal = lastTwoDigits === 11 ? 'th' : 'st';
      break;
    }
    case 2: {
      ordinal = lastTwoDigits === 12 ? 'th' : 'nd';
      break;
    }
    case 3: {
      ordinal = lastTwoDigits === 13 ? 'th' : 'rd';
      break;
    }
    default:
      ordinal = 'th';
      break;
  }

  return `${num.toString()}${ordinal}`;
};

const prettyPrintJson = (json) => {
  return JSON.stringify(json, undefined, 2);
};

const getDaysOfWeekType = (daysOfWeek) => {
  const daysOfWeekSorted = daysOfWeek.sort();

  if (isEqual(daysOfWeekSorted, [1, 2, 3, 4, 5, 6, 7])) {
    return ANY_DAY;
  } else if (isEqual(daysOfWeekSorted, [1, 2, 3, 4, 5])) {
    return ANY_WEEKDAY;
  } else if (!isEmpty(daysOfWeekSorted)) {
    return SPECIFIC_DAY;
  } else {
    return ANY_DAY;
  }
};

export {
  triggerDateGroupingCopy,
  getTriggerDateCopy,
} from './getTriggerDateCopy';

export {
  offsetTimeText,
  getApolloClient,
  getErrorMessage,
  formatError,
  calendarDateDiff,
  pluralize,
  dueDateCopy,
  emailAddressCopy,
  getCurrentPageName,
  getInputFromDays,
  isNewHireTaskOverdue,
  getNewHireTaskDueDateForMobileTaskList,
  getEmployeeSlug,
  getEmployeeLink,
  getRecentEmployeeLink,
  getUpcomingEmployeeLink,
  getNewHireTaskSlug,
  getTaskSlug,
  isUrlSlug,
  intersectionExists,
  augmentApolloClient,
  inputIsNumber,
  addOrRemove,
  getForwardError,
  isLoading,
  triggerWindowResizeEvent,
  copyTextToClipboard,
  extractTextFromHtml,
  getRedirectUrl,
  getWorkflowViewUrl,
  saveFile,
  useEffectWithoutUnmount,
  useEffectAfterMount,
  scrollContainerToFocusedElement,
  isValidColor,
  getErrorCode,
  prettyPrintJson,
  getDaysOfWeekType,
};
