import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
  OAUTH_REDIRECT_URI,
} from '../../common';

export const DOCUSIGN_INTEGRATION_ID = 36;

export const DOCUSIGN_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: DOCUSIGN_INTEGRATION_ID,
  name: 'docusign',
  label: 'DocuSign',
  helpDocs: 'https://help.sora.co/resources/docusign',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/docusign.png`,
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  categories: [CATEGORIES.OTHER],
  authFlow: {
    type: 'Oauth2',
    redirectUri: OAUTH_REDIRECT_URI,
    scope: 'signature dtr.documents.read dtr.company.read impersonation',
    authorizeAddons: { response_type: 'code' },
    getAuthorizeEndpoint: (config) => {
      return config.isProductionApp
        ? 'https://account.docusign.com'
        : 'https://account-d.docusign.com';
    },
  },
  configSchema: {
    userId: {
      type: 'string',
      title: 'User ID (API username)',
      nullable: true,
    },
    apiAccountId: {
      type: 'string',
      title: 'API Account ID',
      nullable: true,
    },
    accountBaseUri: {
      type: 'string',
      title: 'Account Base URI',
      nullable: true,
    },
    clientId: {
      type: 'string',
      title: 'Integration Key',
      nullable: true,
    },
    rsaPrivateKey: {
      type: 'string',
      title: 'RSA private key',
      nullable: true,
      uiSchema: {
        'ui:widget': 'FormTextarea',
      },
    },
    isProductionApp: {
      type: 'boolean',
      title: 'Promoted to production',
      nullable: true,
    },
    webhookKey: {
      type: 'string',
      title: 'Webhook key',
      description:
        'Used to compose the unique webhook URL for this integration',
      autogenerate: true,
      uiSchema: {
        'ui:widget': 'ReadOnlyValueToCopy',
      },
    },
    webhookUsername: {
      type: 'string',
      title: 'Webhook username',
      description:
        'Username for Basic Authentication used to verify the authenticity of incoming messages',
      nullable: true,
    },
    webhookPassword: {
      type: 'string',
      title: 'Webhook password',
      description:
        'Password for Basic Authentication used to verify the authenticity of incoming messages.',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
  },
  configured: (c) => {
    if (!c.values) return false;
    return [
      'userId',
      'apiAccountId',
      'accountBaseUri',
      'clientId',
      'rsaPrivateKey',
    ].every((schemaKey) => {
      return !!c.values[schemaKey];
    });
  },
  validation: { action: 'docusign/listTemplates' },
};
