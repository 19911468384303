import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { WebSocketLink } from 'apollo-link-ws';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';
import { get, isNil, map } from 'lodash';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import * as ReactDOM from 'react-dom';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import App from './components/App';
import { signOut } from './components/SignOutLink';
import history from './constants/history';
import introspectionQueryResultData from './fragmentTypes.json';
import { augmentApolloClient, getRedirectUrl } from './helpers';
import log from './logger';

const PUBLIC_GRAPHQL_OPERATIONS_SAFELIST = [
  'signUpWithNewSandboxCompanyOperation',
  'logInSignUpOperation',
  'lookupIntegrationConfigForLogin',
];

const wsUri = window.location.host.includes('app2')
  ? process.env.REACT_APP_WS_LINK.replace('api', 'api2')
  : process.env.REACT_APP_WS_LINK;
const wsClient = new SubscriptionClient(wsUri, {
  lazy: true,
  reconnect: true,
  timeout: 50000,
  connectionParams: () => ({
    authToken: localStorage.getItem('token') || window.soraToken,
  }),
});

export const wsLink = new WebSocketLink(wsClient);

const uri = window.location.host.includes('app2')
  ? process.env.REACT_APP_HTTP_LINK.replace('api', 'api2')
  : process.env.REACT_APP_HTTP_LINK;
const httpLink = createUploadLink({ uri });

const terminatingLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token') || window.soraToken;

  // Don't make graphQL request to server if user is not signed in, unless it's
  // one of the safelisted operations
  if (
    !token &&
    !PUBLIC_GRAPHQL_OPERATIONS_SAFELIST.includes(operation.operationName)
  ) {
    const loginWithRedirect = getRedirectUrl(
      (window.location.pathname || '') + (window.location.search || ''),
    );
    history.push(loginWithRedirect);
    return;
  }
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...(token && { 'x-token': token }),
    },
  }));

  return forward(operation).map((response) => {
    const context = operation.getContext();
    const headers = get(context, 'response.headers');

    if (headers) {
      const token = headers.get('x-refresh-token');
      if (!isNil(token)) {
        localStorage.setItem('token', token);
      }

      const tokenExpiration = headers.get('x-token-expiration');
      if (!isNil(tokenExpiration)) {
        localStorage.setItem('tokenExpiration', tokenExpiration);
      }
    }

    return response;
  });
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

/** @type {import('apollo-client').DefaultOptions} */
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
};

const shouldLogToSentry = ({ graphQLErrors, networkError, operation }) => {
  // We get a lot of noise from the checkCurrentUserTokenNoRefresh query when
  // the server restarts. Suppress those errors from sentry, since they aren't
  // actionable anyways.
  if (
    !graphQLErrors.length &&
    operation?.operationName === 'checkCurrentUserTokenNoRefresh' &&
    networkError?.name === 'TypeError' &&
    networkError?.message === 'Failed to fetch'
  ) {
    return false;
  }
  return true;
};

async function apolloInit() {
  const errorLink = onError(
    ({ graphQLErrors = [], networkError, operation }) => {
      if (
        map(graphQLErrors, 'extensions.code').includes('UNAUTHENTICATED') ||
        get(networkError, 'statusCode') === 401 ||
        get(networkError, 'message') ===
          'Your session has expired. Please log in.'
      ) {
        signOut({
          client,
          redirectTo:
            (window.location.pathname || '') + (window.location.search || ''),
        });

        window.addMessage({
          body: get(
            graphQLErrors,
            '[0].message',
            'Your session has expired, please log in.',
          ),
          type: 'warning',
          timeout: 15000,
        });
        return;
      }

      if (
        networkError &&
        operation.operationName !== 'IgnoreErrorsQuery' &&
        shouldLogToSentry({ graphQLErrors, networkError, operation })
      ) {
        log.error(networkError, { operation: { ...operation, query: null } });
      }
    },
  );

  if (process.env.REACT_APP_REAL_ENV !== 'production') {
    wsClient.onDisconnected(() => {
      window.addMessage({
        body: 'Websocket connection lost.',
        type: 'failure',
      });
    });
    wsClient.onReconnected(() => {
      window.addMessage({
        body: 'Websocket connection established.',
        type: 'success',
      });
    });
    wsClient.onError((e) => {
      window.addMessage({
        body: get(e, 'message'),
        type: 'failure',
      });
    });
  }

  const cache = new InMemoryCache({
    fragmentMatcher,
  });
  const link = createPersistedQueryLink().concat(
    ApolloLink.from([authLink, errorLink, terminatingLink]),
  );
  const client = new ApolloClient({
    link,
    cache,
    defaultOptions,
  });

  // we need to write defaults after clearing store(logging out) because apollo is only
  // writing defaults on initializations

  return augmentApolloClient(client, wsLink /** , persistor */);
}

// To add to production and staging environments, see the following Slab doc:
// https://sora.slab.com/posts/how-to-update-test-content-security-policy-1unsv83j
function addCSP() {
  const head = document.getElementsByTagName('head')[0];
  const meta = document.createElement('meta');
  meta.httpEquiv = 'Content-Security-Policy';
  meta.content = `
  default-src 'self';
  script-src 'self' https://www.datadoghq-browser-agent.com/ https://cdn.sprig.com https://cdn.ckeditor.com https://storage.googleapis.com https://cdn.segment.com https://cdn.mxpnl.com https://widget.intercom.io https://edge.fullstory.com https://js.intercomcdn.com https://apis.google.com https://cdn.jsdelivr.net https://login.microsoftonline.com https://api.userleap.com https://api.sprig.com https://rs.fullstory.com https://accounts.google.com/gsi/client https://*.okta.com;
  connect-src 'self' http://localhost:8081 ws://localhost:8081 https://cdn.segment.com https://*.ingest.sentry.io https://*.browser-intake-datadoghq.com wss://nexus-websocket-a.intercom.io https://edge.fullstory.com https://cdn.segment.com https://api.segment.io https://api.mixpanel.com https://api-js.mixpanel.com https://sentry.io https://rs.fullstory.com https://api-iam.intercom.io https://apis.google.com https://login.microsoftonline.com https://api.userleap.com https://api.sprig.com https://rs.fullstory.com https://accounts.google.com/gsi/status https://accounts.google.com/gsi/log https://apis.google.com https://*.okta.com;
  img-src 'self' data: https://*;
  style-src 'self' 'unsafe-inline' https://fonts.googleapis.com https://*.typekit.net https://cdn.ckeditor.com https://accounts.google.com/gsi/style;
  base-uri 'none';
  form-action 'none';
  frame-src http://localhost:3000 https://accounts.google.com/ https://calendly.com/;
  font-src 'self' https://fonts.gstatic.com https://fonts.intercomcdn.com https://*.typekit.net data:;
  media-src 'self' https://js.intercomcdn.com;
  worker-src 'self' blob:;`;

  head.prepend(meta);
}
(process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') &&
  addCSP();

apolloInit().then((client) => {
  // temporary solution for getting apollo client from any place without wrapping components into ApolloConsumer.
  //
  window.apolloClient = client;
  ReactDOM.render(
    <ApolloProvider client={window.apolloClient}>
      <ApolloHooksProvider client={window.apolloClient}>
        <App />
      </ApolloHooksProvider>
    </ApolloProvider>,
    document.getElementById('root'),
  );
});
