import gql from 'graphql-tag';

export const GET_EMPLOYEES_STARTED_THIS_MONTH = gql`
  query getEmployeesStartedThisMonth(
    $beforeStartDate: IsoDate
    $afterStartDate: IsoDate
  ) {
    employees(
      filters: {
        startDate: { afterDate: $afterStartDate, beforeDate: $beforeStartDate }
        terminated: false
      }
      orderBy: "startDate"
      order: "DESC"
      limit: 4
    ) {
      count
      rows {
        id
        name
        timezone
        title
        imageUrl
        startDate
      }
    }
  }
`;
