import { OktaAuth } from '@okta/okta-auth-js';
import { get } from 'lodash';
import { bool, func, string } from 'prop-types';
import React from 'react';
import { useQuery } from 'react-apollo-hooks';

import oktaLogo from 'sora-client/static/images/okta-logo.png';
import { OKTA_INTEGRATION_ID } from 'sora-invariant/src/integrations/constants';

import { LOOKUP_INTEGRATION_CONFIG } from './queries';

const OktaLoginButton = ({
  disabled,
  domain,
  authType,
  onSuccess,
  onFailure,
}) => {
  const { loading, data } = useQuery(LOOKUP_INTEGRATION_CONFIG, {
    variables: {
      integrationId: OKTA_INTEGRATION_ID,
      configKey: 'domain',
      configValue: domain,
    },
  });

  const login = () => {
    const clientId = get(
      data,
      'lookupIntegrationConfigBySearchableValue.values.soraClientId',
    );
    if (!clientId) {
      onFailure(new Error('Sora client ID not found'));
      return;
    }
    const oktaAuth = new OktaAuth({
      issuer: `https://${domain}`,
      clientId,
      redirectUri: window.location.origin + '/login',
    });
    oktaAuth.token
      .getWithPopup()
      .then((res) => {
        const tokens = res.tokens;
        onSuccess(tokens.accessToken.accessToken);
      })
      .catch(onFailure);
  };

  return (
    <div id='okta-auth-button'>
      <button
        className='d-flex cursor-pointer rounded border-none margin-top-3 padding-1 margin-x-auto bg-okta-blue'
        style={{ minWidth: '210px' }}
        disabled={disabled || loading}
        onClick={login}
      >
        <div className='okta-logo rounded bg-white padding-2 d-flex'>
          <img
            src={oktaLogo}
            className='position-relative'
            alt='Okta'
            width='20px'
          />
        </div>
        <div className='flex-grow-1 align-self-center padding-x-2 text-small'>
          <strong className='text-paper-white'>{`${
            authType === 'signUp' ? 'Sign up' : 'Log in'
          } with Okta`}</strong>
        </div>
      </button>
    </div>
  );
};

OktaLoginButton.propTypes = {
  authType: string,
  disabled: bool,
  domain: string.isRequired,
  onSuccess: func.isRequired,
  onFailure: func.isRequired,
};

export default OktaLoginButton;
