import React from 'react';

export default React.createContext({
  /**
   * @type {*}
   */
  currentUser: null,
  /** @type {import('../helpers/authorization').UA} */
  ua: null,
  refetch: () => {},
});
