import moment from 'moment';
import { func, object } from 'prop-types';
import React from 'react';

import Select from 'sora-client/components/common/Select';

export const GreenhouseAdditionalSelectors = ({
  employeeIdentifiers,
  onUpdate,
  rawData,
}) => {
  const { applications } = rawData;

  // Only show the application picker dropdown if multiple applications exist
  // for the candidate
  if (!applications || applications.length === 1) return null;

  return (
    <div className='d-flex align-items-center margin-top-2 margin-left-3'>
      <span className='text-uppercase text-small font-weight-500 text-midnight-navy-75 text-nowrap margin-right-2'>
        Select application:{' '}
      </span>
      <Select
        name='greenhouse-application-id-selector'
        options={applications.map((application) => ({
          // Label takes the form `M/D/YYYY: Job name (ID / status)
          label: `${moment(application.appliedAt).format(
            'M/D/YYYY',
          )}: ${application.jobs.map((job) => job.name).join(', ')} (${
            application.id
          } / ${application.status})`,
          value: application.id,
        }))}
        onChange={(applicationId) =>
          onUpdate({
            applicationId,
            jobId: applications.find((app) => app.id === applicationId)
              ?.jobs?.[0]?.id,
          })
        }
        width={372}
        value={employeeIdentifiers.applicationId}
      />
    </div>
  );
};

GreenhouseAdditionalSelectors.propTypes = {
  employeeIdentifiers: object.isRequired,
  onUpdate: func.isRequired,
  rawData: object.isRequired,
};
