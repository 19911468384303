import classNames from 'classnames';
import { noop } from 'lodash';
import { func, string } from 'prop-types';
import React, { useContext } from 'react';
import { Link as LinkOriginal } from 'react-router-dom';

import PreviewContext from 'sora-client/contexts/PreviewContext';

export const Link = (props) => {
  const { previewingAsEmployee } = useContext(PreviewContext);

  return (
    <LinkOriginal
      {...props}
      onClick={
        previewingAsEmployee ? (e) => e.preventDefault() : props.onClick || noop
      }
      className={classNames(
        {
          'pointer-events-none': previewingAsEmployee,
        },
        props.className,
      )}
    />
  );
};

Link.propTypes = {
  onClick: func,
  className: string,
};
