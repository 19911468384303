import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const FRESHTEAM_INTEGRATION_ID = 23;

export const FRESHTEAM_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: FRESHTEAM_INTEGRATION_ID,
  configured: (c) => c.values && c.values.accountName && c.values.accessToken,
  name: 'freshteam',
  label: 'Freshteam',
  description: 'Recruiting software and talent acquisition suite',
  website: 'https://www.freshworks.com/hrms/',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/freshteam.png`,
  availableUses: [
    AVAILABLE_USES_ENUMS.WORKFLOWS,
    AVAILABLE_USES_ENUMS.DATA_SYNC,
  ],
  categories: [CATEGORIES.ATS, CATEGORIES.HRIS],
  configSchema: {
    accountName: {
      type: 'string',
      title: 'Account name',
      nullable: true,
    },
    accessToken: {
      type: 'string',
      title: 'Access token',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    outboundEmployeeFieldMapping: {
      type: 'string',
      title: 'Map outbound fields from Sora employees, to Freshteam employees',
      nullable: true,
    },
  },
};
