import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  COMMON_OAUTH_CONFIG_PROPERTIES,
  DEFAULT_INTEGRATION,
  OAUTH_REDIRECT_URI,
} from '../../common';

export const GUSTO_INTEGRATION_ID = 30;

export const GUSTO_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: GUSTO_INTEGRATION_ID,
  name: 'gusto',
  label: 'Gusto',
  categories: [CATEGORIES.HRIS],
  description:
    'Payroll, benefits, time tracking tools, and more. Everything your small business needs to grow your team and thrive.',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/gusto.svg`,
  website: 'https://gusto.com',
  helpDocs: 'https://help.sora.co/resources/gusto',
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  authFlow: {
    type: 'Oauth2',
    authorizeEndpoint: `${process.env.GUSTO_API_ROOT}/oauth/authorize`,
    redirectUri: OAUTH_REDIRECT_URI,
    clientId: process.env.GUSTO_CLIENT_ID,
    configToClear: {
      accessToken: null,
      refreshToken: null,
      accessTokenExpirationDate: null,
    },
  },
  configured: (c) => c.values && c.values.accessToken,
  configSchema: {
    ...COMMON_OAUTH_CONFIG_PROPERTIES,
    refreshToken: {
      type: 'string',
      title: 'API Refresh token',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Hidden',
        'ui:options': {
          label: false,
        },
      },
    },
  },
  validation: { action: 'gusto/getCurrentUser' },
};
