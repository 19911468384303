import classNames from 'classnames';
import { array, object, string } from 'prop-types';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import Collapsible from 'sora-client/components/common/Collapsible';
import { Mobile, NotMobile } from 'sora-client/components/common/MediaQueries';
import TabsNav from 'sora-client/components/common/TabsNav';

function getCurrentNavItemTitle({ location, navItems }) {
  for (let i = 0, limit = navItems.length; i < limit; i++) {
    const navItem = navItems[i];
    if (navItem.partialMatch && location.pathname.indexOf(navItem.to) === 0) {
      return navItem.title;
    }

    if (!navItem.partialMatch && location.pathname === navItem.to) {
      return navItem.title;
    }
  }
}

const InnerPageNavItemTitle = ({ location, navItem }) => {
  if (navItem.partialMatch && location.pathname.indexOf(navItem.to) === 0) {
    return <strong>{navItem.title}</strong>;
  }

  if (!navItem.partialMatch && location.pathname === navItem.to) {
    return <strong>{navItem.title}</strong>;
  }

  return navItem.title;
};

InnerPageNavItemTitle.propTypes = {
  location: object.isRequired,
  navItem: object.isRequired,
};

const InnerPageNavItems = ({ navItems, location, className = null }) =>
  navItems.map((navItem, i) => (
    <div key={i} className={classNames('margin-top-4', className)}>
      <Link to={navItem.to} className='d-inline-block stealth-link'>
        <InnerPageNavItemTitle location={location} navItem={navItem} />
      </Link>
    </div>
  ));

// `not-mobile-page-nav-actions` is used to attach page-level actions with usePortal
const InnerPageNavCore = ({ title, navItems = [], location }) => (
  <>
    <NotMobile>
      <div className='d-flex align-items-center'>
        <h2 className='margin-y-0 flex-grow-1 font-p22-mackinac'>{title}</h2>
        <div id='not-mobile-page-nav-actions' /> {/* See note above */}
      </div>
      {!!navItems.length && (
        <TabsNav
          items={navItems}
          location={location}
          className='margin-top-4'
        />
      )}
    </NotMobile>
    <Mobile>
      <InnerPageNavItems navItems={navItems} location={location} />
    </Mobile>
  </>
);

InnerPageNavCore.propTypes = {
  title: string.isRequired,
  navItems: array.isRequired,
  location: object.isRequired,
};

const InnerPageNav = (props) => (
  <>
    <NotMobile>
      <div className='padding-5'>
        <InnerPageNavCore {...props} />
      </div>
    </NotMobile>
    <Mobile>
      <div className='padding-3 border-bottom'>
        <Collapsible
          title={getCurrentNavItemTitle(props) || props.title}
          customInnerWrapperClassnames='align-items-center'
          customTitleClassnames='font-p22-mackinac text-large'
        >
          <InnerPageNavCore {...props} />
        </Collapsible>
      </div>
    </Mobile>
  </>
);

InnerPageNav.propTypes = {
  title: string.isRequired,
};

export default withRouter(InnerPageNav);
