import classNames from 'classnames';
import { node, oneOf, string } from 'prop-types';
import React from 'react';

const NoticeBox = ({
  type = 'info',
  children,
  padding = 'padding-4',
  rounded = 'rounded-xlarge',
  className = '',
  ...restProps
}) => (
  <div
    className={classNames(
      {
        'bg-sunset-50 text-danger': type === 'danger',
        'bg-winter-mint text-green-2': type === 'success',
        'bg-blue-7 text-high-neon-indigo': type === 'info',
        'bg-sunrise-50 text-warning': type === 'warning',
      },
      rounded,
      padding,
      className,
    )}
    {...restProps}
  >
    {children}
  </div>
);

NoticeBox.propTypes = {
  type: oneOf(['warning', 'success', 'info', 'danger']),
  children: node.isRequired,
  className: string,
  rounded: string,
  padding: string,
};

export default NoticeBox;
