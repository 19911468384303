import { useQuery } from '@apollo/react-hooks';
import { orderBy } from 'lodash';
import React from 'react';
import {
  NumberParam,
  ObjectParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';

import { SettingsLogTabNavigator } from 'sora-client/components/SettingsLogTabNavigator';
import { WebhookEventFlyout } from 'sora-client/components/WebhookEventFlyout';
import { WebhookEventList } from 'sora-client/components/WebhookEventList';
import CondensedPagination from 'sora-client/components/common/CondensedPagination';
import Loading from 'sora-client/components/common/Loading';
import NoticeBox from 'sora-client/components/common/NoticeBox';
import { getIntegrationByName } from 'sora-invariant/src/integrations/constants';

import { WebhookFilters } from './WebhookFilters';
import { GET_WEBHOOK_EVENTS } from './queries';

const ROW_LIMIT = 20;

export const WebhookEventsPage = () => {
  const [queryParams, setQueryParams] = useQueryParams({
    offset: NumberParam,
    serviceName: StringParam,
    eventFilters: ObjectParam,
    eventName: StringParam,
    asofMin: StringParam,
    asofMax: StringParam,
    webhookEventId: StringParam,
  });

  const {
    offset = 0,
    serviceName,
    eventFilters,
    eventName,
    asofMin,
    asofMax,
    webhookEventId,
  } = queryParams;

  const queryVariables = {
    offset,
    limit: ROW_LIMIT,
    serviceName: serviceName?.toLowerCase() || null,
    eventName,
    asofMin,
    asofMax,
    eventFilters,
  };
  const { data, loading, error, refetch } = useQuery(GET_WEBHOOK_EVENTS, {
    variables: queryVariables,
    pollInterval: 10000,
  });

  const integration = serviceName ? getIntegrationByName(serviceName) : null;

  // Pull replays out and flatten them in the list of runs. Replays will be
  // listed below their originating event
  const webhookEvents = [];
  if (data?.webhookEvents?.rows) {
    data.webhookEvents.rows.forEach((row) => {
      webhookEvents.push(row);
      if (row.replays?.length) {
        webhookEvents.push(...orderBy(row.replays, 'createdAt'));
      }
    });
  }

  // Check for webhookEvents.length to prevent the page from fully reloading
  // when polling the API
  const isInitialLoad = loading && !webhookEvents.length;

  return (
    <div>
      <div className='margin-x-5 margin-bottom-3'>
        <SettingsLogTabNavigator />
      </div>
      <WebhookFilters />
      {/* Check for webhookEvents.length to prevent the page from reloading  */}
      {isInitialLoad && <Loading />}
      {error && <NoticeBox type='danger'>Error: {error}</NoticeBox>}
      {!isInitialLoad && !error && (
        <>
          <div className='padding-y-1 padding-right-4'>
            <CondensedPagination
              chevronClassnames='svg-parent-align-text'
              className='justify-content-end'
              pageSize={ROW_LIMIT}
              total={data.webhookEvents.count}
              offset={offset}
              entityIdentifier='event'
              onChange={(page) => setQueryParams({ offset: page * ROW_LIMIT })}
            />
          </div>
          <WebhookEventList
            helpDocsLink={integration?.helpDocs || null}
            webhookEvents={webhookEvents}
          />
        </>
      )}
      {webhookEventId && (
        <WebhookEventFlyout
          onReplayComplete={refetch}
          webhookEventId={webhookEventId}
        />
      )}
    </div>
  );
};
