/* eslint-disable react/display-name */
import { useMutation, useQuery } from '@apollo/react-hooks';
import { isEmpty, isEqual } from 'lodash';
import { func, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { ApiErrorMessage } from 'sora-client/components/common/ApiErrorMessage';
import Button from 'sora-client/components/common/Button';
import CondensedPagination from 'sora-client/components/common/CondensedPagination';
import EditableTable from 'sora-client/components/common/EditableTable';
import InnerPageContentContainer from 'sora-client/components/common/InnerPageContentContainer';
import Loading from 'sora-client/components/common/Loading';
import NoticeBox from 'sora-client/components/common/NoticeBox';
import { LeftArrowSvg, UserSvg } from 'sora-client/components/common/Svg';
import Tooltip from 'sora-client/components/common/Tooltip';
import useDimensions from 'sora-client/components/common/hooks/useDimensions';
import { MessagesContext } from 'sora-client/contexts';

import { ReviewErrorsButton } from './ReviewErrorsButton';
import { createErrorsMap, createWarningsMap, generateColumns } from './helpers';
import {
  GET_IMPORTED_EMPLOYEES,
  SAVE_ROWS_TO_GO_BACK,
  SUBMIT_IMPORT,
  VALIDATE_IMPORT,
} from './queries';

const isTestEnv = process.env.NODE_ENV === 'test';
const PAGE_SIZE = isTestEnv ? 2 : 20;

const ImportEmployeesCheckStep = ({ uploadId, onNext, onCancel, onBack }) => {
  const [submitImport, { loading: loadingSubmitImport }] =
    useMutation(SUBMIT_IMPORT);

  const [
    validateImport,
    { loading: loadingValidateImport, error: validateError },
  ] = useMutation(VALIDATE_IMPORT);

  const [saveAllRowChangesForBack] = useMutation(SAVE_ROWS_TO_GO_BACK);

  const [currentPage, setCurrentPage] = useState(0);
  const [deselectedRowsMap, setDeselectedRowsMap] = useState({});
  const [changedRowsMap, setChangedRowsMap] = useState({});
  const [tableData, setTableData] = useState([]);
  const [errorsMap, setErrorsMap] = useState({});
  const [warningsMap, setWarningsMap] = useState({});
  const [errorsFromImport, setErrorsFromImport] = useState([]);
  const { addMessage } = useContext(MessagesContext);

  const deselectedRowsMapRef = React.useRef(deselectedRowsMap);

  const offset = currentPage * PAGE_SIZE;

  const {
    data: queryData,
    loading,
    refetch,
  } = useQuery(GET_IMPORTED_EMPLOYEES, {
    variables: {
      uploadId,
      offset,
      limit: PAGE_SIZE,
    },
  });

  const {
    count = 0,
    allFields = [],
    rows = [],
  } = queryData?.importedEmployees || {};

  const hasDeselectedRowsMapChanged = !isEqual(
    deselectedRowsMapRef.current,
    deselectedRowsMap,
  );

  useEffect(() => {
    if (rows?.length) {
      setTableData(
        rows.map((row) => ({
          ...row.fields.reduce((map, fv) => {
            map[fv.key] = fv.value;
            return map;
          }, {}),
          selected: !deselectedRowsMap[row.id],
          id: row.id,
          existingEmployee: row.existingEmployee,
        })),
      );

      if (hasDeselectedRowsMapChanged) {
        deselectedRowsMapRef.current = deselectedRowsMap;
      }

      setErrorsMap(createErrorsMap({ rows, changedRowsMap }));
      setWarningsMap(createWarningsMap({ rows, changedRowsMap }));
    }
  }, [rows, hasDeselectedRowsMapChanged]);

  const onSubmitButtonClick = async () => {
    const changedRows = [].concat(
      ...Object.keys(changedRowsMap).map((rId) =>
        Object.keys(changedRowsMap[rId]).map((propName) => ({
          rowId: Number(rId),
          key: propName,
          value: changedRowsMap[rId][propName],
        })),
      ),
    );

    const result = await submitImport({
      variables: {
        uploadId,
        deselectedRows: Object.keys(deselectedRowsMap)
          .filter((r) => deselectedRowsMap[r])
          .map((r) => Number(r)),
        changedRows,
      },
    });

    const isSuccessful = result.data.submitEmployeeCsvUpload.successful;
    if (isSuccessful) {
      return onNext();
    } else {
      setErrorsFromImport(result.data.submitEmployeeCsvUpload.errors);
      setChangedRowsMap({});
      setCurrentPage(0);
      refetch();
    }
  };

  const onValidateButtonClick = async () => {
    /* 
    shape of changedRowsMap is 
    {
      [rowId] : {
        [fieldName] : [changedValue]
      }
    }
    shape of changedRows variable is 
    [
       {
        rowId: number,
        key: fieldName,
        value: changedValue
      }
    ]
    */
    const { data } = await validateImport({
      variables: {
        uploadId,
        deselectedRows: Object.keys(deselectedRowsMap)
          .filter((r) => deselectedRowsMap[r])
          .map((r) => Number(r)),
        changedRows: [].concat(
          ...Object.keys(changedRowsMap).map((rId) =>
            Object.keys(changedRowsMap[rId]).map((propName) => ({
              rowId: Number(rId),
              key: propName,
              value: changedRowsMap[rId][propName],
            })),
          ),
        ),
      },
    });

    const newRows = data.validateEmployeeCsvUpload.rows || [];
    setTableData(
      newRows.map((row) => ({
        ...row.fields.reduce((map, fv) => {
          map[fv.key] = fv.value;
          return map;
        }, {}),
        selected: !deselectedRowsMap[row.id],
        id: row.id,
        existingEmployee: row.existingEmployee,
      })),
    );

    const newErrors = createErrorsMap({
      rows: newRows,
      changedRowsMap: {},
    });
    const newWarnings = createWarningsMap({
      rows: newRows,
      changedRowsMap: {},
    });

    setErrorsMap(newErrors);
    setWarningsMap(newWarnings);

    if (isEmpty(newErrors) && isEmpty(newWarnings)) {
      addMessage({
        body: 'Validation successful',
        type: 'success',
      });
    }
  };

  const onBackButtonClick = async () => {
    // save all the changes that have been made to the rows before
    // going back to field mapping
    await saveAllRowChangesForBack({
      variables: {
        uploadId,
        changedRows: [].concat(
          ...Object.keys(changedRowsMap).map((rId) =>
            Object.keys(changedRowsMap[rId]).map((propName) => ({
              rowId: Number(rId),
              key: propName,
              value: changedRowsMap[rId][propName],
            })),
          ),
        ),
      },
    });

    onBack();
  };

  const onCancelButtonClick = () => {
    onCancel();
  };

  const onSelectChange = (selectMap) => {
    setDeselectedRowsMap((prevValue) => ({
      ...prevValue,
      ...Object.keys(selectMap).reduce((newMap, key) => {
        newMap[key] = !selectMap[key];
        return newMap;
      }, {}),
    }));
  };

  const onEmployeeDataChanged = (e) => {
    setChangedRowsMap((prevValue) => {
      const changedMap = prevValue[e.id] || {};
      changedMap[e.key] = e.value;
      return {
        ...prevValue,
        [e.id]: changedMap,
      };
    });
  };

  const columns = generateColumns(allFields);

  const [tableContainerRef, { width: tableWidth }] = useDimensions({
    events: ['resize'],
  });

  if (loading || loadingValidateImport) {
    return <Loading />;
  }

  const hasErrors = Object.keys(errorsMap).some(
    (error) => !!errorsMap[error].length,
  );
  const allRowsDeselected =
    Object.keys(deselectedRowsMap).filter((e) => deselectedRowsMap[e])
      .length === count;
  const isImportDisabled = allRowsDeselected || hasErrors;

  return (
    <div>
      <InnerPageContentContainer>
        <div>
          {/* make a custom back button since it isn't a real link */}
          <div
            className='d-flex align-items-center'
            onClick={onBackButtonClick}
          >
            <div
              className={'rounded-circle text-center bg-gray-1'}
              style={{ width: 40, height: 40 }}
            >
              <LeftArrowSvg className='padding-2' />
            </div>
            <p className='margin-y-0 font-weight-500 margin-left-3 text-hover-blue'>
              Back to field mapping
            </p>
          </div>
          <div className='d-flex align-items-center margin-top-5'>
            <div className='flex-grow-1'>
              <h2 className='margin-y-0 flex-grow-1 font-p22-mackinac'>
                Import employees
              </h2>
              <div className='margin-top-3'>
                Rows with this symbol <UserSvg className='svg-medium' />{' '}
                indicate that there is an existing employee with that personal
                email and they will be updated on import.
              </div>
            </div>
            {validateError && (
              <ApiErrorMessage
                className='d-inline-block margin-left-2'
                error={validateError}
              />
            )}
            <div>
              <Button
                label='Cancel'
                category='secondary'
                className='d-inline-block margin-left-2'
                onClick={onCancelButtonClick}
              />
              <Button
                label='Validate'
                category='primary'
                className='d-inline-block margin-left-2'
                onClick={onValidateButtonClick}
              />
              <Tooltip
                tooltip={hasErrors ? 'Correct errors before importing.' : ''}
              >
                <Button
                  label='Import'
                  category='action'
                  disabled={isImportDisabled}
                  loading={loadingSubmitImport}
                  className='d-inline-block margin-left-2'
                  onClick={onSubmitButtonClick}
                />
              </Tooltip>
            </div>
          </div>
          {!!errorsFromImport?.length && (
            <NoticeBox type='danger' className='margin-top-2'>
              <div className='margin-bottom-1'>
                Import failed: No rows were imported. To review a list of
                errors, click{' '}
                <ReviewErrorsButton errorsFromImport={errorsFromImport} />. To
                continue, fix the errors, click Validate and then Import again.
              </div>
              NOTE: Some errors may be transient; if the errors do not appear to
              be data related, you may click Import to retry.
            </NoticeBox>
          )}
        </div>
      </InnerPageContentContainer>
      <div className='d-flex align-items-center justify-content-end padding-right-4'>
        <CondensedPagination
          pageSize={PAGE_SIZE}
          total={count}
          offset={offset}
          entityIdentifier='imported employee'
          onChange={(page) => setCurrentPage(page)}
          wrapperClassnames='padding-2'
          chevronClassnames='svg-parent-align-text'
        />
      </div>
      <div className='border-top' ref={tableContainerRef}>
        <EditableTable
          selectable
          columns={columns}
          fixed={true}
          autoresizer={false}
          rowHeight={52}
          width={tableWidth}
          height={52 * (tableData.length + 1)}
          data={tableData}
          className='default-cell-padding'
          changedMap={changedRowsMap}
          errorsMap={errorsMap}
          warningsMap={warningsMap}
          onSelectChange={onSelectChange}
          onChange={onEmployeeDataChanged}
          cellPlaceholder='No value to import'
        />
      </div>
    </div>
  );
};

ImportEmployeesCheckStep.propTypes = {
  uploadId: string.isRequired,
  onNext: func.isRequired,
  onCancel: func.isRequired,
  onBack: func.isRequired,
};

export default ImportEmployeesCheckStep;
