import { any, object } from 'prop-types';
import React from 'react';

import SettingsNav from 'sora-client/components/SettingsNav';
import Page from 'sora-client/components/common/Page';

const SettingsWrapperPage = ({ session, children }) => {
  return (
    <Page id='settings-page' category='Settings' layout='flush'>
      <SettingsNav session={session} />
      {children}
    </Page>
  );
};

SettingsWrapperPage.propTypes = {
  session: object.isRequired,
  children: any.isRequired,
};

export default SettingsWrapperPage;
