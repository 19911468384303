import classNames from 'classnames';
import { object } from 'prop-types';
import React from 'react';

import EmployeeAvatar from 'sora-client/components/common/Avatar';
import BoxCard from 'sora-client/components/common/BoxCard';
import { LinkButton } from 'sora-client/components/common/LinkButton';
import Tooltip from 'sora-client/components/common/Tooltip';
import { WORKFLOW_BASE } from 'sora-client/constants/routes';
import { userAuthorization } from 'sora-client/helpers/authorization';

const AVATAR_WIDTH_HEIGHT = 28;
const PHOTOS_SHOWN = 6;

const WorkflowCard = ({ workflow, currentUser }) => {
  const ua = userAuthorization(currentUser);
  return (
    <BoxCard
      key={workflow.id}
      className='padding-4 margin-y-3 margin-right-3 mobile-margin-right-0 mobile-margin-y-2'
    >
      <div className='d-flex align-items-center'>
        <Tooltip
          tooltip={workflow.active ? 'Active' : 'Inactive'}
          placement='top'
        >
          <div
            className={classNames('padding-1 rounded-circle', {
              'bg-sunset': !workflow.active,
              'bg-green-2': workflow.active,
            })}
          />
        </Tooltip>
        <h5 className='margin-y-0 margin-left-2'>{workflow.name}</h5>
      </div>
      {workflow.inProgressEmployees.count > 0 ? (
        <div
          className='d-flex margin-y-3'
          data-testid={`link-workflow-${workflow.id}`}
          style={{ height: `${AVATAR_WIDTH_HEIGHT}px` }}
        >
          {workflow.inProgressEmployees.rows.slice(0, PHOTOS_SHOWN).map((e) => (
            <div key={e.id} className='margin-right-1'>
              <EmployeeAvatar
                imageSrc={e.imageUrl}
                widthHeight={AVATAR_WIDTH_HEIGHT}
                employeeName={e.name}
                employeeId={e.id}
              />
            </div>
          ))}
          {workflow.inProgressEmployees.count - PHOTOS_SHOWN > 0 ? (
            <div
              className='d-flex justify-content-center align-items-center text-xsmall rounded-circle bg-twilight-indigo-25'
              style={{
                width: `${AVATAR_WIDTH_HEIGHT}px`,
                height: `${AVATAR_WIDTH_HEIGHT}px`,
              }}
            >
              {`${Math.min(
                workflow.inProgressEmployees.count - PHOTOS_SHOWN,
                99,
              )}+`}
            </div>
          ) : null}
        </div>
      ) : (
        <p
          style={{ minHeight: `${AVATAR_WIDTH_HEIGHT}px` }}
          className='margin-y-3 text-muted'
        >
          No employees in progress{' '}
          {workflow.active ? (
            ''
          ) : (
            <>
              while the
              <br />
              workflow is paused
            </>
          )}
        </p>
      )}
      <div className='d-flex mobile-d-block justify-content-between margin-top-3'>
        {workflow.active ? (
          <LinkButton
            label={
              workflow.inProgressEmployees.count > 0
                ? 'View employees'
                : 'Add employees'
            }
            size='small'
            category='primary'
            to={`${WORKFLOW_BASE}/${workflow.id}`}
            className='d-inline-block'
          />
        ) : null}

        {ua.can({
          resource: 'Timeline',
          action: 'EDIT',
          instance: workflow,
        }) && (
          <LinkButton
            to={`${WORKFLOW_BASE}/${workflow.id}/configure`}
            className={classNames('d-inline-block mobile-margin-top-3', {
              'margin-left-3 mobile-margin-left-0': workflow.active,
            })}
            label='Manage workflow'
            size='small'
          />
        )}
      </div>
    </BoxCard>
  );
};

WorkflowCard.propTypes = {
  currentUser: object.isRequired,
  workflow: object.isRequired,
};

export default WorkflowCard;
