import { useCallback, useEffect, useState } from 'react';

const useClickOutside = (onClickOutside) => {
  const [domNode, setDomNode] = useState(null);

  useEffect(() => {
    const onClick = (e) => {
      if (domNode && !domNode.contains(e.target)) {
        onClickOutside(e);
      }
    };

    document.addEventListener('click', onClick, true);
    return () => {
      document.removeEventListener('click', onClick, true);
    };
  }, [domNode, onClickOutside]);

  const refCallback = useCallback(setDomNode, [onClickOutside]);

  return refCallback;
};

export default useClickOutside;
