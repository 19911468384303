import classNames from 'classnames';
import { bool, object, oneOfType, string } from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Tooltip from 'sora-client/components/common/Tooltip';

import NavItemActiveHoverIndicator from './NavItemActiveHoverIndicator';

const NavItem = ({
  navItemRoute,
  navItemCategory,
  currentNavItemCategory,
  title,
  icon,
  showIcon = true,
  titleStyle,
  selectedBorderStyle,
  activeTitleClassname = 'text-default',
  inactiveTitleClassname = 'text-muted',
  isDisabled = false,
  disabledTooltipMessage = '',
  target = '',
}) => {
  const handleClick = function (e) {
    if (isDisabled) {
      e.preventDefault();
    }
  };

  const [isHovering, setIsHovering] = useState(false);
  const isSelected = currentNavItemCategory === navItemCategory;

  return (
    <Tooltip
      tooltip={isDisabled ? disabledTooltipMessage : ''}
      placement='bottom'
    >
      <Link
        onClick={handleClick}
        to={navItemRoute}
        target={target}
        className={classNames(
          'site-nav-link d-flex align-items-center margin-x-3 mobile-margin-x-0 padding-x-2 mobile-padding-x-3 position-relative',
          {
            disabled: isDisabled,
            selected: isSelected,
            'justify-content-center': !showIcon,
          },
        )}
        data-testid={`route-${title}`}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {showIcon && (
          <div
            className={classNames(
              'nav-icon position-relative svg-medium-parent-align-text',
              { 'margin-right-3': !!title },
            )}
          >
            {icon}
          </div>
        )}
        <div
          style={titleStyle || {}}
          className={classNames({
            [activeTitleClassname]: currentNavItemCategory === navItemCategory,
            [inactiveTitleClassname]:
              currentNavItemCategory !== navItemCategory,
          })}
        >
          <strong>{title}</strong>
        </div>
        <NavItemActiveHoverIndicator
          selectedBorderStyle={selectedBorderStyle}
          className={{
            'fade-100': isSelected,
            'fade-50': isHovering && !isSelected,
            'fade-0': !isHovering && !isSelected,
          }}
        />
      </Link>
    </Tooltip>
  );
};

NavItem.propTypes = {
  navItemRoute: oneOfType([string, object]).isRequired,
  navItemCategory: string.isRequired,
  currentNavItemCategory: string,
  icon: object.isRequired,
  title: string,
  showIcon: bool,
  titleStyle: object,
  selectedBorderStyle: object,
  activeTitleClassname: string,
  inactiveTitleClassname: string,
  isDisabled: bool,
  disabledTooltipMessage: string,
  target: string,
};

export default NavItem;
