import gql from 'graphql-tag';

export const GET_IMPORTED_EMPLOYEES = gql`
  query getImportedEmployees($uploadId: String!, $offset: Int, $limit: Int) {
    importedEmployees(
      uploadId: $uploadId
      offset: $offset
      limit: $limit
      errorsFirst: true
    ) {
      count
      allFields {
        name
      }
      rows {
        id
        fields {
          key
          value
        }
        errors {
          key
          error
        }
        warnings {
          key
          warning
        }
        existingEmployee
      }
    }
  }
`;

export const SUBMIT_IMPORT = gql`
  mutation SubmitEmployeeCsvUploadMutation(
    $uploadId: String!
    $deselectedRows: [Int!]
    $changedRows: [RowChangeInput!]
  ) {
    submitEmployeeCsvUpload(
      uploadId: $uploadId
      deselectedRows: $deselectedRows
      changedRows: $changedRows
    ) {
      uploadId
      successful
      errors {
        email
        error
      }
    }
  }
`;

export const VALIDATE_IMPORT = gql`
  mutation ValidateEmployeeCsvUpload(
    $uploadId: String!
    $deselectedRows: [Int!]
    $changedRows: [RowChangeInput!]
  ) {
    validateEmployeeCsvUpload(
      uploadId: $uploadId
      deselectedRows: $deselectedRows
      changedRows: $changedRows
    ) {
      count
      allFields {
        name
      }
      rows {
        id
        fields {
          key
          value
        }
        errors {
          key
          error
        }
        warnings {
          key
          warning
        }
        existingEmployee
      }
    }
  }
`;

export const SAVE_ROWS_TO_GO_BACK = gql`
  mutation saveAllChangedRowsForBack(
    $uploadId: String!
    $changedRows: [RowChangeInput!]
  ) {
    saveAllCsvRowChanges(uploadId: $uploadId, changedRows: $changedRows) {
      uploadId
    }
  }
`;
