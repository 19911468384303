import { noop } from 'lodash';
import { func, string } from 'prop-types';
import React from 'react';
import ReactImg from 'react-image';

import BackButton from 'sora-client/components/common/BackButton';
import { FALLBACK_INTEGRATION_LOGO_URL } from 'sora-invariant/src/integrations/common';

export const IntegrationConfigPageHeader = ({
  backButtonDestination,
  logoUrl,
  name,
  onClickBackButton = noop,
}) => {
  return (
    <>
      <div className='margin-top-1 margin-right-4 margin-bottom-3'>
        <BackButton
          to={backButtonDestination}
          onClick={onClickBackButton}
          persistentHelpText='Back to integrations list'
        />
      </div>
      <div className='d-flex align-items-center'>
        <div>
          <ReactImg
            src={[logoUrl, FALLBACK_INTEGRATION_LOGO_URL]}
            width={50}
            className='rounded'
          />
        </div>
        <div className='flex-grow-1 margin-x-3 mobile-margin-x-2'>
          <h2 className='margin-y-0'>{name}</h2>
        </div>
      </div>
    </>
  );
};

IntegrationConfigPageHeader.propTypes = {
  backButtonDestination: string.isRequired,
  logoUrl: string.isRequired,
  name: string.isRequired,
  onClickBackButton: func,
};
