import { object } from 'prop-types';
import React from 'react';

import { pick } from 'lodash/fp';
import InnerPageNav from 'sora-client/components/common/InnerPageNav';
import * as routes from 'sora-client/constants/routes';
import { userAuthorization } from 'sora-client/helpers/authorization';

const allNavItems = ({ currentUser, ua }) => [
  {
    to: routes.SETTINGS_NOTIFICATIONS,
    title: 'Notifications',
    scope: {
      resource: 'Company',
      action: 'EDIT',
      instance: { id: currentUser.companyId },
    },
  },
  {
    to: `${routes.SETTINGS_PERMISSIONS}`,
    title: 'Permissions',
    scope: {
      resource: 'PermissionGroup',
      action: 'EDIT',
    },
  },
  {
    to: '/settings/logs',
    title: 'Logs',
    getCanView: (currentUser) => {
      const ua = userAuthorization(currentUser);
      if (
        ua.can({
          resource: 'Company',
          action: '*',
        })
      )
        return true;

      if (
        ua.can({
          resource: 'Integration',
          action: 'VIEW',
          instance: { companyId: currentUser.companyId },
        })
      ) {
        return true;
      }

      return false;
    },
  },
  {
    to: routes.SETTINGS_COMPANY,
    title: 'Company info',
    requireExactMatch: true,
    scope: {
      resource: 'Company',
      action: 'EDIT',
      instance: { id: currentUser.companyId },
    },
  },
  {
    to: routes.SETTINGS_COMPANY_EMAILS,
    title: 'Company emails',
    scope: {
      resource: 'Company',
      action: 'EDIT',
      instance: { id: currentUser.companyId },
    },
    disabled: !currentUser.hasPremiumAccess,
    tooltip: !currentUser.hasPremiumAccess
      ? 'In the premium version of Sora, you can verify email domains and set up email tracking.'
      : '',
  },
  {
    to: routes.SETTINGS_INTEGRATIONS,
    title: 'Integrations',
    scope: {
      resource: 'Integration',
      action: 'EDIT',
      instance: { companyId: currentUser.companyId },
    },
    isAlwaysVisible: true,
  },
  // This will be accessible to admins + superadmins only
  {
    to: routes.SETTINGS_DASHBOARD_CARDS,
    title: 'Home Dashboard',
    isAlwaysVisible: true,
    disabled: !currentUser.hasPremiumAccess,
    tooltip: !currentUser.hasPremiumAccess
      ? 'In the premium version of Sora, you can create custom dashboard cards for the home page with important resources.'
      : '',
  },
];

const getNavItems = ({ currentUser }) => {
  const ua = userAuthorization(currentUser);

  return allNavItems({ currentUser, ua })
    .filter(({ scope, isSoraDevOnly, isAlwaysVisible, getCanView }) => {
      let canUserViewScope;
      if (getCanView) {
        canUserViewScope = getCanView(currentUser);
      } else {
        canUserViewScope = !scope || ua.can(scope);
      }

      if (isAlwaysVisible) return true;

      if (isSoraDevOnly) {
        return canUserViewScope && ua.isSoraDev;
      }

      return canUserViewScope;
    })
    .map(
      pick([
        'to',
        'title',
        'disabled',
        'tooltip',
        'hideContentTitle',
        'requireExactMatch',
      ]),
    );
};

const SettingsNav = ({ session }) => {
  const navItems = getNavItems({ currentUser: session.currentUser });

  return <InnerPageNav title='Settings' navItems={navItems} />;
};

SettingsNav.propTypes = {
  session: object.isRequired,
};

export { getNavItems };

export default SettingsNav;
