import { get } from 'lodash';

import * as r from '../constants/routes';

/**
 * @param {object} [currentUser]
 * @param {boolean} currentUser.hasPremiumAccess
 * @param {string} currentUser.employeeId
 */
const getRouteNames = (currentUser, checkPermissions = true) => {
  const companyId = get(currentUser, 'company.id');
  const isWorkflowTemplatesCompany =
    // @ts-ignore
    currentUser?.company?.isWorkflowTemplatesCompany;

  return [
    {
      name: 'Home',
      route: r.HOME,
      index: true,
      indexScope: {
        resource: 'Employee',
        action: 'VIEW',
        instance: { id: currentUser?.employeeId },
      },
    },
    ...(!isWorkflowTemplatesCompany
      ? [
          /** Routes visible to all companies except for Sora Workflow Templates company */
          {
            name: 'Workflows',
            route: r.WORKFLOWS_ALL,
            index: true,
            indexScope: { resource: 'Timeline', action: 'VIEW', any: true },
          },
          {
            name: 'Tasks',
            route: r.NEW_HIRE_TASKS,
            index: true,
            indexScope: {
              resource: 'NewHireTask',
              action: 'VIEW',
              instance: { assigneeId: currentUser?.employeeId },
            },
          },
          {
            name: 'Employees',
            route: r.EMPLOYEES,
            index: true,
            indexScope: {
              resource: 'Employee',
              action: 'VIEW',
              instance: {
                companyId,
              },
            },
          },
          {
            name: 'Auth — Signup',
            route: r.SIGN_UP,
          },
          {
            name: 'Auth — Login',
            route: r.LOG_IN,
          },
          {
            name: 'Notifications',
            route: r.NOTIFICATIONS,
          },
          {
            name: 'Settings — Notifications',
            route: r.SETTINGS_NOTIFICATIONS,
            index: true,
            indexScope: {
              resource: 'Company',
              action: 'EDIT',
              instance: { id: companyId },
            },
          },
          {
            name: 'Settings — Permissions',
            route: `${r.SETTINGS_PERMISSIONS}/everyone`,
            index: true,
            indexScope: {
              resource: 'PermissionGroup',
              action: 'EDIT',
              instance: { companyId },
            },
          },
          {
            name: 'Settings — Company audit logs',
            route: r.SETTINGS_AUDIT_LOGS,
            index: true,
            indexScope: {
              resource: 'Company',
              action: '*',
              instance: { id: companyId },
            },
          },
          {
            name: 'Settings — Company info',
            route: r.SETTINGS_COMPANY,
            index: true,
            indexScope: {
              resource: 'Company',
              action: 'EDIT',
              instance: { id: companyId },
            },
          },
          {
            name: 'Settings — Company e-mails',
            route: r.SETTINGS_COMPANY_EMAILS,
            index: true,
            indexScope: {
              resource: 'Company',
              action: 'EDIT',
              instance: { id: companyId },
            },
          },
          {
            name: 'Super Admin — Companies',
            route: r.SUPER_ADMIN_COMPANY_BASE,
            indexScope: { resource: 'Company', action: '*' },
            index: true,
          },
          {
            name: 'Super Admin — Edit company',
            route: r.SUPER_ADMIN_COMPANY_EDIT,
            interpolatedRoute: r.SUPER_ADMIN_COMPANY_EDIT.replace(
              ':id',
              companyId,
            ),
            indexScope: { resource: 'Company', action: '*' },
            index: true,
          },
          {
            name: 'Super Admin — Company notifications',
            route: r.SUPER_ADMIN_COMPANY_NOTIFICATIONS,
            interpolatedRoute: r.SUPER_ADMIN_COMPANY_NOTIFICATIONS.replace(
              ':id',
              companyId,
            ),
            indexScope: { resource: 'Company', action: '*' },
            index: true,
          },
          {
            name: 'Super Admin — New integration template',
            route: r.SUPER_ADMIN_COMPANY_INTEGRATION_TEMPLATE_NEW,
            interpolatedRoute:
              r.SUPER_ADMIN_COMPANY_INTEGRATION_TEMPLATE_NEW.replace(
                ':id',
                companyId,
              ),
            indexScope: {
              resource: '*',
              action: '*',
            },
            index: true,
          },
          {
            name: 'Super Admin — Company integrations',
            route: r.SUPER_ADMIN_COMPANY_INTEGRATIONS,
            interpolatedRoute: r.SUPER_ADMIN_COMPANY_INTEGRATIONS.replace(
              ':id',
              companyId,
            ),
            indexScope: {
              resource: '*',
              action: '*',
            },
            index: true,
          },
          {
            name: 'Super Admin — New integration template',
            route: r.SUPER_ADMIN_COMPANY_INTEGRATION_TEMPLATE_NEW,
          },
          {
            name: 'Super Admin — Integration template detail',
            route: r.SUPER_ADMIN_COMPANY_INTEGRATION_TEMPLATE,
          },
          {
            name: 'Employees — Recently started',
            route: r.EMPLOYEES_RECENT,
          },
          {
            name: 'Employees — Starting soon',
            route: r.EMPLOYEES_UPCOMING,
          },
          {
            name: 'Employee — Profile',
            route: r.EMPLOYEE_PROFILE,
          },
          {
            name: 'Employee — Edit profile',
            route: r.EMPLOYEE_PROFILE_EDIT,
          },
          {
            name: 'Employees — Import from CSV',
            route: r.EMPLOYEES_IMPORT,
          },
          {
            name: 'Employee — New',
            route: r.EMPLOYEE_NEW,
          },
          {
            name: 'Workflow',
            route: r.WORKFLOW,
          },
          {
            name: 'Workflow Spreadsheet',
            route: r.WORKFLOW_PROGRESS,
          },
          {
            name: 'Workflow - Task analysis',
            route: r.WORKFLOW_TASK_ANALYSIS,
          },
          {
            name: 'Workflow — Configure',
            route: r.WORKFLOW_CONFIGURE,
          },
          {
            name: 'Action — New',
            route: r.WORKFLOW_TASK_NEW,
          },
          {
            name: 'Workflow Stage — New',
            route: r.WORKFLOW_STAGE_NEW,
          },
          {
            name: 'Action',
            route: r.TASK,
          },
          {
            name: 'Action — Edit',
            route: r.TASK_EDIT,
          },
          {
            name: 'Task',
            route: r.NEW_HIRE_TASK,
          },
          {
            name: 'Task — Edit',
            route: r.NEW_HIRE_TASK_EDIT,
          },
          {
            name: 'Task — Mark complete',
            route: r.NEW_HIRE_TASK_MARK_COMPLETE,
          },
          ...(!checkPermissions || currentUser?.hasPremiumAccess
            ? /**
               * Only users in premium (non-sandbox) companies can view the following routes
               */
              [
                {
                  name: 'Reports',
                  route: r.REPORTS,
                  index: true,
                  indexScope: {
                    resource: 'Task',
                    action: 'VIEW',
                    any: true,
                  },
                },
                {
                  name: 'Workflows — Manage integrations',
                  route: r.WORKFLOW_INTEGRATION_SETTINGS,
                  index: true,
                  indexScope: {
                    resource: 'Integration',
                    action: 'EDIT',
                    instance: { companyId },
                  },
                },
                {
                  name: 'Employees — Data management',
                  route: r.EMPLOYEES_DATA_MANAGEMENT,
                  index: true,
                  indexScope: {
                    resource: 'FieldGroup',
                    action: 'VIEW',
                    instance: { companyId },
                  },
                },
                {
                  name: 'Employees — Org chart',
                  route: r.EMPLOYEE_ORG_CHART,
                  index: true,
                  indexScope: {
                    resource: 'Employee',
                    action: 'VIEW',
                    instance: {
                      companyId,
                    },
                  },
                },
                {
                  name: 'Settings — Integrations',
                  route: r.SETTINGS_INTEGRATIONS,
                  index: true,
                  indexScope: {
                    resource: 'Integration',
                    action: 'EDIT',
                    instance: { companyId },
                  },
                },
              ]
            : []),
        ]
      : [
          /** Routes visible to Sora Workflow Templates company */
          {
            name: 'Data management',
            route: r.EMPLOYEES_DATA_MANAGEMENT,
            index: true,
            indexScope: { resource: 'Company', action: '*' },
          },
          {
            name: 'Super admin - Workflow templates',
            route: r.WORKFLOW_TEMPLATES_MANAGEMENT,
            index: true,
            indexScope: { resource: 'Company', action: '*' },
          },
          {
            name: 'Super admin — Companies',
            route: r.SUPER_ADMIN_COMPANY_BASE,
            indexScope: { resource: 'Company', action: '*' },
            index: true,
          },
        ]),
  ];
};

export { getRouteNames };
