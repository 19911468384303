import classNames from 'classnames';
import { object } from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';

import withSession from 'sora-client/components/Session/withSession';
import { KeyDownSearchBar } from 'sora-client/components/common/Search/KeyDownSearchBar';
import { useStateDebounce } from 'sora-client/components/common/hooks';
import history from 'sora-client/constants/history';
import {
  scrollContainerToFocusedElement,
  useEffectAfterMount,
} from 'sora-client/helpers';
import { userAuthorization } from 'sora-client/helpers/authorization';
import { getRouteNames } from 'sora-client/helpers/getRouteNames';
import { filterAndSortItems } from 'sora-client/helpers/search';

const FastSwitcherNavigationModal = ({ session }) => {
  const [searchTextFilter, setSearchTextFilter] = useStateDebounce('', 20);
  const [lastKeyNavigation, setLastKeyNavigation] = useState(
    /** @type {*} */ ({}),
  );
  const containerRef = useRef();

  const INDEXED_ROUTE_NAMES = getRouteNames(session.currentUser).filter(
    (rn) => {
      return (
        rn.index === true &&
        // @ts-ignore
        userAuthorization(session.currentUser).can(rn.indexScope)
      );
    },
  );

  const filteredItems = filterAndSortItems(
    INDEXED_ROUTE_NAMES,
    searchTextFilter,
  );

  useEffectAfterMount(() => {
    const currentDropdownItem = document.querySelector(
      `.route-item-${lastKeyNavigation.cursor}`,
    );

    scrollContainerToFocusedElement({
      container: containerRef.current,
      focusedElement: currentDropdownItem,
    });
  }, [lastKeyNavigation]);

  const placeholderText = useMemo(() => {
    const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    return `Quick switcher (${isMac ? '⌘' : 'Ctrl'} + K)`;
  }, []);

  return (
    <div className='fast-switcher-modal'>
      <div className='padding-y-3 padding-x-4 border-bottom'>
        <KeyDownSearchBar
          onSearchInputChange={(value) => {
            if (value !== searchTextFilter) {
              setSearchTextFilter(value);
            }
          }}
          placeHolderText={placeholderText}
          customInputClassnames='flat text-xxlarge'
          autoFocus={true}
          listSize={filteredItems.length}
          onKeyNavigation={({ activeCursor, lastKeyPress }) => {
            setLastKeyNavigation({
              cursor: activeCursor,
              lastKeyPress,
            });

            if (lastKeyPress === 'Enter' && filteredItems[activeCursor]) {
              history.push(
                filteredItems[activeCursor].interpolatedRoute ||
                  filteredItems[activeCursor].route,
              );
            }
          }}
        />
      </div>
      <div
        className='overflow-auto'
        style={{ maxHeight: 400 }}
        ref={containerRef}
      >
        {!filteredItems.length && !!searchTextFilter.length && (
          <div className='padding-y-3 padding-x-4 text-muted'>
            No results for &quot;{searchTextFilter}&quot;
          </div>
        )}
        {filteredItems.map((item, index) => {
          return (
            <div
              key={item.route}
              className={classNames(
                `route-item-${index}`,
                'padding-y-3 padding-x-4 bg-hover-gray cursor-pointer',
                { 'bg-gray-1': lastKeyNavigation.cursor === index },
              )}
              onClick={() => {
                history.push(item.interpolatedRoute || item.route);
              }}
            >
              {item.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

FastSwitcherNavigationModal.propTypes = {
  session: object.isRequired,
};

export default withSession(FastSwitcherNavigationModal);
