import React from 'react';

export default React.createContext({
  /**
   * @param {string} event
   * @param {any} [props]
   */
  track: (event, props) => {},
  /**
   * @param {...*} args
   */
  page: (...args) => {},
  /**
   * @param {...*} args
   */
  identify: (...args) => {},
  /**
   * @param {number} companyId
   * @param {any} obj
   */
  group: (companyId, obj) => {},
  /**
   * @param {string} name
   */
  getCount: (name) => {},
  reset: () => {},
  /**
   * @param {string} name
   */
  increment: (name) => {},
});
