import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  COMMON_OAUTH_CONFIG_PROPERTIES,
  DEFAULT_INTEGRATION,
  OAUTH_REDIRECT_URI,
} from '../../common';

export const GOOGLE_CALENDAR_INTEGRATION_ID = 49;

export const GOOGLE_CALENDAR_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: GOOGLE_CALENDAR_INTEGRATION_ID,
  name: 'googlecalendar',
  label: 'Google Calendar',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/googlecalendar.png`,
  website: 'https://calendar.google.com',
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  categories: [CATEGORIES.OTHER],
  authFlow: {
    type: 'Oauth2',
    redirectUri: OAUTH_REDIRECT_URI,
    scope:
      'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/admin.directory.group.readonly https://www.googleapis.com/auth/calendar.readonly',
    clientId: process.env.GOOGLE_CLIENT_ID,
    clientSecret: process.env.GOOGLE_CLIENT_SECRET,
    authorizeAddons: {
      access_type: 'offline',
      prompt: 'consent',
    },
    authorizeEndpoint: 'https://accounts.google.com/o/oauth2/auth',
    configToClear: {
      accessToken: null,
      accessTokenExpirationDate: null,
      refreshToken: null,
    },
  },
  configSchema: {
    ...COMMON_OAUTH_CONFIG_PROPERTIES,
    refreshToken: {
      type: 'string',
      title: 'API Refresh token',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Hidden',
        'ui:options': {
          label: false,
        },
      },
    },
  },
  configured: (c) => {
    if (!c.values) return false;
    return ['accessToken', 'refreshToken'].every((schemaKey) => {
      return !!c.values[schemaKey];
    });
  },
};
