import * as msal from '@azure/msal-browser';
import React from 'react';

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
  },
};

var loginRequest = {
  scopes: ['user.read'],
};

const MicrosoftLogin = ({ onSuccess, onFailure, children }) => {
  const onClick = () => {
    return new msal.PublicClientApplication(msalConfig)
      .loginPopup(loginRequest)
      .then((response) => {
        onSuccess({ token: response.accessToken });
      })
      .catch(onFailure);
  };
  return <div onClick={onClick}>{children}</div>;
};

export default MicrosoftLogin;
