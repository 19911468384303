module.exports = {
  ownSurveyFieldValue: ({ currentUser, instance }) => {
    return !!(
      instance &&
      instance.field &&
      instance.field.surveyId &&
      instance.employeeId === currentUser.employeeId
    );
  },
};
