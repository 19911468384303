import { any, array, string } from 'prop-types';
import React from 'react';

import Tooltip from './Tooltip';

const InputValidation = ({
  validators,
  defaultMessage = '',
  placement,
  children,
}) => {
  let isValid = true;
  let validationMessage = defaultMessage;
  for (const validator of validators) {
    const validatorResult = validator();
    if (validatorResult && typeof validatorResult !== 'string') {
      continue;
    }

    isValid = false;
    if (typeof validatorResult === 'string') {
      validationMessage = validatorResult;
    }
    break;
  }

  if (process.env.NODE_ENV === 'development') {
    // Because of the React.cloneElement happening below, this component only works
    // properly if the top-level component in its `children` is the Button or CTA
    // that can be disabled. This is an imperfect solution, but at least in dev we
    // can log this warning to try to help ensure the component is being used properly.
    React.Children.forEach(children, (child) => {
      // eslint-disable-next-line react/forbid-foreign-prop-types
      if (child && !Object.keys(child.type.propTypes).includes('disabled')) {
        console.warn(
          `InputValidation: Top-level child does not have "disabled" property. Please ensure you're rendering the Button or CTA as the top-level child`,
        );
      }
    });
  }

  const validatedChildren = isValid
    ? children
    : React.Children.map(children, (child) =>
        child
          ? React.cloneElement(child, {
              disabled: true,
            })
          : null,
      );

  return (
    <Tooltip tooltip={isValid ? '' : validationMessage} placement={placement}>
      {validatedChildren}
    </Tooltip>
  );
};

InputValidation.propTypes = {
  validators: array,
  defaultMessage: string,
  placement: string,
  children: any,
};

export default InputValidation;
