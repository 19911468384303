import classNames from 'classnames';
import { bool, func, string } from 'prop-types';
import React from 'react';

export const WorkflowTemplateTagSelectorItem = ({
  name,
  color,
  onSelect,
  selected,
}) => {
  return (
    <div
      className={classNames(
        'rounded-xlarge margin-y-1 padding-2 bg-white text-large cursor-pointer d-flex align-items-center box-shadow position-relative',
        {
          'bg-blue-7': selected,
          'bg-hover-gray': !selected,
        },
      )}
      style={{ width: 230 }}
      onClick={onSelect}
    >
      <div
        className='d-inline padding-x-2 padding-y-1 margin-right-2 position-absolute position-left rounded-left'
        style={{ backgroundColor: `#${color?.substr(1)}`, height: 30 }}
      />
      <div className='margin-left-3'>{name}</div>
    </div>
  );
};

WorkflowTemplateTagSelectorItem.propTypes = {
  name: string.isRequired,
  onSelect: func.isRequired,
  selected: bool.isRequired,
  color: string,
};
