import gql from 'graphql-tag';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import React from 'react';

import { DashboardCardDisplay } from 'sora-client/components/common/DashboardCardDisplay';

import HomePageCard from './HomePageCard';

export const DashboardCard = ({ dashboardCard, onDismiss }) => {
  return (
    <HomePageCard>
      <DashboardCardDisplay
        dashboardCard={dashboardCard}
        onDismiss={onDismiss}
      />
    </HomePageCard>
  );
};
DashboardCard.propTypes = {
  dashboardCard: shape({
    title: string,
    body: string,
    dismissable: bool,
    id: string,
    filters: arrayOf(
      shape({
        fieldId: string,
        values: string,
        op: string,
      }),
    ),
  }),
  onDismiss: func,
};

DashboardCard.fragments = {
  query: gql`
    fragment DashboardCard_Query on Query {
      allCards: dashboardCardsToDisplay(previewEmployeeId: $employeeId) {
        count
        rows {
          id
          title
          body
          dismissable
          filters {
            fieldId
            values
            op
          }
        }
      }
    }
  `,
};
