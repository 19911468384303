import { string } from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

const getHref = (x) => `/favicon-${x}-${x}.png`;

const PageTitleAndCompanyLogo = ({ title }) => {
  return (
    <Helmet>
      {!!title && <title>{title} — Sora</title>}
      {!title && <title>Sora</title>}
      {['16', '32', '96', '192'].map((x) => (
        <link
          key={x}
          rel='icon'
          type='image/png'
          sizes={`${x}x${x}`}
          href={getHref(x)}
        />
      ))}
      <link rel='shortcut icon' href='/favicon.png' />
    </Helmet>
  );
};

PageTitleAndCompanyLogo.propTypes = {
  title: string,
};

export default PageTitleAndCompanyLogo;
