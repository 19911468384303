import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import { modelConstants } from 'sora-invariant/src/constants';

import { WorkflowTemplateTagSelectorItem } from './WorkflowTemplateTagSelectorItem';

const tagOptions = modelConstants.timeline.tagOptions;

export const WorkflowTemplateSideBar = () => {
  const [queryParams, setQueryParams] = useQueryParams({ tag: StringParam });
  return (
    <div
      className='d-flex margin-left-5 margin-right-4 border-a flex-direction-column padding-2 bg-gray rounded-xlarge'
      style={{ height: 375 }}
    >
      <WorkflowTemplateTagSelectorItem
        name='All'
        onSelect={() => setQueryParams({ tag: undefined })}
        selected={!queryParams.tag}
      />
      {Object.values(tagOptions).map(({ name, value, color }) => (
        <WorkflowTemplateTagSelectorItem
          key={name}
          name={name}
          color={color}
          onSelect={() => setQueryParams({ tag: value })}
          selected={value === queryParams.tag}
        />
      ))}
    </div>
  );
};
