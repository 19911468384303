export const SIDEBAR_DEFAULT_OPEN_WIDTH = '450px';
export const SIDEBAR_DEFAULT_CLOSED_WIDTH = '60px';

export const WORKFLOW_OVERVIEW_CONTAINER_WIDTH_DESKTOP_OFFSET = '375px';
export const WORKFLOW_OVERVIEW_CONTAINER_WIDTH_SMALL_DESKTOP_OFFSET = '100px';

export const DASHBOARD_TAB = 'dashboard';
export const EMPLOYEE_TAB = 'employees';
export const SPREADSHEET_TAB = 'progress';
export const TASK_ANALYSIS_TAB = 'task-analysis';
export const NEEDS_ATTENTION_TAB = 'needs-attention';

// Needs attention tabs
export const ISSUES_TAB = 'issues';
export const OVERDUE_TAB = 'overdue';
export const DUE_SOON_TAB = 'due-soon';
