import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  COMMON_OAUTH_CONFIG_PROPERTIES,
  DEFAULT_INTEGRATION,
  OAUTH_REDIRECT_URI,
} from '../../common';

export const DROPBOX_SIGN_INTEGRATION_ID = 51;

// Global OAuth application credentials and settings
export const DROPBOX_SIGN_APP_CLIENT_ID =
  process.env.DROPBOX_SIGN_APP_CLIENT_ID;
export const DROPBOX_SIGN_APP_CLIENT_SECRET =
  process.env.DROPBOX_SIGN_APP_CLIENT_SECRET;
export const DROPBOX_SIGN_AUTH_BASE_URL = 'https://app.hellosign.com/oauth';
export const DROPBOX_SIGN_API_BASE_URL = 'https://api.hellosign.com/v3';

export const DROPBOX_SIGN_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: DROPBOX_SIGN_INTEGRATION_ID,
  name: 'dropboxsign',
  label: 'Dropbox Sign',
  description:
    'Dropbox Sign streamlines your document workflows by helping you prepare, send, sign, and track agreements with legally-binding eSignatures.',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/dropboxsign.png`,
  website: 'https://www.hellosign.com/',
  helpDocs: 'https://help.sora.co/resources/dropbox-sign',
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  categories: [CATEGORIES.OTHER],
  authFlow: {
    type: 'Oauth2',
    authorizeEndpoint: `${DROPBOX_SIGN_AUTH_BASE_URL}/authorize`,
    redirectUri: OAUTH_REDIRECT_URI,
    clientId: DROPBOX_SIGN_APP_CLIENT_ID,
    clientSecret: DROPBOX_SIGN_APP_CLIENT_SECRET,
    authorizeAddons: { response_type: 'code' },
    configToClear: {
      accessToken: null,
      refreshToken: null,
      accessTokenExpirationDate: null,
    },
    popupWindowOverrides: {
      width: 1200,
      height: 650,
    },
  },
  configSchema: {
    ...COMMON_OAUTH_CONFIG_PROPERTIES,
    refreshToken: {
      type: 'string',
      title: 'API Refresh token',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Hidden',
        'ui:options': {
          label: false,
        },
      },
    },
  },
  configured: (c) => c.values && c.values.accessToken && c.values.refreshToken,
  validation: {
    action: 'dropboxsign/getTemplates',
    parameters: { page: 1, pageSize: 1 },
  },
};
