import { arrayOf, number, object, shape } from 'prop-types';
import React, { useContext } from 'react';

import { SessionContext } from 'sora-client/contexts';
import { userAuthorization } from 'sora-client/helpers/authorization';

import EmployeeTaskListCard from './EmployeeTaskListCard';
import EmployeesStartingSoonCard from './EmployeesStartingSoonCard';
import HelpfulLinks from './HelpfulLinks';
import NewEmployeesListCard from './NewEmployeesListCard';
import WorkflowsListCard from './WorkflowsListCard';

export const DefaultDashboardCards = ({
  tasksDueThisWeek,
  tasksDueUpNext,
  overdueTasksCount,
  allTasksDueCount,
  selfEnrollableWorkflows,
}) => {
  const { currentUser } = useContext(SessionContext);

  const ua = userAuthorization(currentUser);
  const canViewWorkflows = ua.can({
    resource: 'Timeline',
    action: 'VIEW',
    any: true,
  });

  return (
    <>
      <EmployeeTaskListCard
        tasksDueThisWeek={tasksDueThisWeek}
        tasksDueUpNext={tasksDueUpNext}
        overdueTasksCount={overdueTasksCount}
        allTasksDueCount={allTasksDueCount}
      />
      {
        // Only render this section for premium companies
        currentUser.company.enablePremiumFeatures ? (
          <>
            {!!selfEnrollableWorkflows?.length && (
              <HelpfulLinks selfEnrollableWorkflows={selfEnrollableWorkflows} />
            )}
            <NewEmployeesListCard />
          </>
        ) : canViewWorkflows ? (
          <>
            <EmployeesStartingSoonCard />
            <WorkflowsListCard />
          </>
        ) : null
      }
    </>
  );
};

DefaultDashboardCards.propTypes = {
  tasksDueThisWeek: shape({
    count: number,
    rows: arrayOf(object),
  }),
  tasksDueUpNext: shape({
    count: number,
    rows: arrayOf(object),
  }),
  overdueTasksCount: number,
  allTasksDueCount: number,
  selfEnrollableWorkflows: arrayOf(object).isRequired,
};
