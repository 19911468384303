import classNames from 'classnames';
import { omit } from 'lodash';
import {
  arrayOf,
  bool,
  func,
  instanceOf,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React from 'react';

import FullWidthInput from 'sora-client/components/common/FullWidthInput';

const FormInput = (props) => (
  <FullWidthInput
    {...omit(props, ['className', 'errorMessage', 'overrideDefaultStyles'])}
    className={classNames(
      {
        'flat-with-bottom-border margin-top-2': !props.overrideDefaultStyles,
      },
      props.className,
      { 'error-input': props.errorMessage },
    )}
  />
);

FormInput.propTypes = {
  className: string,
  delayTimeout: number,
  outerClassName: string,
  id: string,
  maxLength: number,
  name: string,
  type: string,
  errorMessage: string,
  placeholder: string,
  value: string,
  style: object,
  onChange: func,
  onKeyDown: func,
  onKeyPress: func,
  onFocus: func,
  onBlur: func,
  overrideDefaultStyles: bool,
  autoFocus: bool,
  autoComplete: string,
  disabled: bool,
  tabIndex: number,
  forbiddenChars: arrayOf(string),
  inputRef: oneOfType([func, shape({ current: instanceOf(Element) })]),
};

FormInput.defaultProps = {
  overrideDefaultStyles: false,
};

export default FormInput;
