import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

// Insert the next ID
export const GOOGLE_CLOUD_STORAGE_INTEGRATION_ID = 45;

export const GOOGLE_CLOUD_STORAGE_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: GOOGLE_CLOUD_STORAGE_INTEGRATION_ID,
  name: 'googlecloudstorage',
  label: 'Google Cloud Storage',
  description: '',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/googlecloudstorage.png`,
  website: '',
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  categories: [CATEGORIES.OTHER],
  configSchema: {},
  configured: () => true,
};
