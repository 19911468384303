import gql from 'graphql-tag';

export const GET_WEBHOOK_EVENTS = gql`
  query getWebhookEvents(
    $serviceName: String
    $eventName: String
    $offset: Int
    $limit: Int
    $asofMin: String
    $asofMax: String
    $eventFilters: JSON
  ) {
    webhookEvents(
      serviceName: $serviceName
      eventName: $eventName
      offset: $offset
      limit: $limit
      asofMin: $asofMin
      asofMax: $asofMax
      eventFilters: $eventFilters
    ) {
      count
      rows {
        id
        createdAt
        externalId
        status
        serviceName
        eventName
        eventFilters
        replays {
          id
          createdAt
          status
          serviceName
          eventName
          eventFilters
          eventData
          replayOfWebhookEventId
        }
      }
    }
  }
`;
