import classNames from 'classnames';
import { array, func } from 'prop-types';
import React from 'react';

import Button from './Button';
import { XSvg } from './Svg';

/**
 * @typedef {Object} MessageButton
 * @property {function} onClick
 * @property {string} text
 *
 * @typedef {Object} Message
 * @property {string|number} id
 * @property {'success'|'failure'|'warning'|'info'} [type]
 * @property {string} body
 * @property {MessageButton} [button]
 * @property {boolean} [preventDismiss]
 *
 * @param {object} p0
 * @param {Message[]} p0.messages
 * @param {function} p0.removeMessage
 */
const Messages = ({ messages, removeMessage }) => (
  <div
    data-cy='messages-container'
    className='messages-container position-fixed text-right'
  >
    {messages.map((message, i) => (
      <div className='margin-y-3' key={i}>
        <div className='d-inline-block'>
          <div
            className={classNames(
              'message d-flex align-items-center bg-white rounded box-shadow padding-4',
              message.type,
            )}
          >
            <div className='flex-grow-1 padding-right-3 text-left'>
              {message.body}
              {message.button && (
                <div className='margin-top-3'>
                  <Button
                    onClick={message.button.onClick}
                    category='primary'
                    label={message.button.text}
                  />
                </div>
              )}
            </div>
            {!message.preventDismiss && (
              <div
                className='padding-x-3 padding-y-2 bg-hover-gray cursor-pointer rounded svg-parent-align-text'
                onClick={() => removeMessage({ id: message.id })}
              >
                <XSvg className='svg-medium' />
              </div>
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
);

Messages.propTypes = {
  messages: array.isRequired,
  removeMessage: func.isRequired,
};

Messages.defaultProps = {
  messages: [],
};

export default Messages;
