import classNames from 'classnames';
import { arrayOf, node, number, object, oneOf, string } from 'prop-types';
import React from 'react';

import Table from 'sora-client/components/common/Table';
import useDimensions from 'sora-client/components/common/hooks/useDimensions';

const ROW_HEIGHT = 66;

export const DataTable = ({
  mobileView = null,
  rows,
  selectedId = null,
  ...otherProps
}) => {
  const [runContainerRef, { width: runContainerWidth }] = useDimensions({
    events: ['resize'],
  });

  const isMobileContainer = runContainerWidth < 600;

  return (
    <>
      <div ref={runContainerRef}>
        {!isMobileContainer && (
          <Table
            className='default-cell-padding border-top'
            autoresizer={false}
            rowHeight={ROW_HEIGHT}
            height={Math.max(ROW_HEIGHT * (rows.length + 1), 200)}
            width={runContainerWidth}
            data={rows}
            rowClassName={({ rowData, rowIndex }) => {
              return classNames({
                'even-row': !!(rowIndex % 2),
                selected: rowData.id === selectedId,
              });
            }}
            {...otherProps}
          />
        )}
      </div>
      {isMobileContainer && mobileView}
    </>
  );
};

DataTable.propTypes = {
  mobileView: node,
  rows: arrayOf(object).isRequired,
  selectedId: oneOf([string, number]),
};
