import { capitalize, words } from 'lodash';
import moment from 'moment';
import { arrayOf, object, shape, string } from 'prop-types';
import React from 'react';
import { Column } from 'react-base-table';
import { StringParam, useQueryParams } from 'use-query-params';

import { DataTable } from 'sora-client/components/DataTable';
import { Badge } from 'sora-client/components/common/Badge';
import { IntegrationLogo } from 'sora-client/components/common/IntegrationLogo';
import { CornerDownRightSvg } from 'sora-client/components/common/Svg';
import { WEBHOOK_EVENT_STATUS_TO_COLOR } from 'sora-client/constants/components';

const COLUMNS = [
  {
    key: 'id',
    title: 'ID',
    width: 120,
    frozen: Column.FrozenDirection.LEFT,
    cellRenderer: ({ cellData, rowData }) => {
      const isReplay = !!rowData.replayOfWebhookEventId;
      if (isReplay) {
        return (
          <div className='d-inline-flex align-items-center'>
            <CornerDownRightSvg className='pointer-events-none svg-medium margin-right-2' />
            {cellData}
          </div>
        );
      }
      return cellData;
    },
  },
  {
    key: 'status',
    title: 'Status',
    width: 120,
    // eslint-disable-next-line react/display-name
    cellRenderer: ({ cellData }) => (
      <Badge color={WEBHOOK_EVENT_STATUS_TO_COLOR[cellData]}>
        {capitalize(cellData)}
      </Badge>
    ),
  },
  {
    key: 'domainAndEvent',
    title: 'Event',
    // eslint-disable-next-line react/display-name
    cellRenderer: ({ rowData }) => (
      <>
        <IntegrationLogo
          name={rowData.serviceName}
          width={32}
          className='margin-right-4'
        />{' '}
        {capitalize(words(rowData.eventName).join(' '))}
      </>
    ),
  },
  {
    key: 'createdAt',
    title: 'Received at',
    cellRenderer: ({ cellData }) =>
      moment(cellData).format('MMM DD, YYYY hh:mm:ss A'),
  },
  {
    key: 'externalId',
    title: 'Service ID',
  },
  {
    key: 'eventFilters',
    title: 'Filters',
    // eslint-disable-next-line react/display-name
    cellRenderer: ({ cellData }) => {
      return <pre className='overflow-scroll'>{JSON.stringify(cellData)}</pre>;
    },
  },
];

export const WebhookEventList = ({ helpDocsLink = null, webhookEvents }) => {
  const [, setQueryParams] = useQueryParams({
    eventName: StringParam,
    webhookEventId: StringParam,
  });

  return (
    <DataTable
      columns={COLUMNS}
      rows={webhookEvents}
      onRowClick={({ rowData }) =>
        setQueryParams({ webhookEventId: rowData.id })
      }
      fixed
      emptyRenderer={
        <div className='padding-x-5 padding-y-3'>
          <p className='text-muted'>
            No events found.
            {helpDocsLink && (
              <>
                {' '}
                Visit the{' '}
                <a
                  href={helpDocsLink}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  help center
                </a>{' '}
                for a step-by-step guide on setting up incoming webhook events.
              </>
            )}
          </p>
        </div>
      }
    />
  );
};

WebhookEventList.propTypes = {
  helpDocsLink: string,
  webhookEvents: arrayOf(
    shape({
      id: string.isRequired,
      externalId: string,
      status: string,
      createdAt: string.isRequired,
      eventData: object,
    }),
  ),
};
