import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const ASHBY_INTEGRATION_ID = 43;

export const ASHBY_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: ASHBY_INTEGRATION_ID,
  name: 'ashby',
  label: 'Ashby',
  categories: [CATEGORIES.ATS],
  description:
    "Unlock hiring excellence with Ashby's all-in-one recruiting platform.",
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/ashby.svg`,
  website: 'https://ashbyhq.com',
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  configured: (c) => c.values && c.values.apiKey,
  configSchema: {
    apiKey: {
      type: 'string',
      title: 'API Key',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    webhookKey: {
      type: 'string',
      title: 'Secret key',
      autogenerate: true,
      uiSchema: {
        'ui:widget': 'ReadOnlyValueToCopy',
      },
    },
  },
  validation: { action: 'ashby/getDepartments' },
};
