import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const CHARTHOP_INTEGRATION_ID = 48;

export const CHARTHOP_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: CHARTHOP_INTEGRATION_ID,
  name: 'charthop',
  label: 'ChartHop',
  description:
    'ChartHop pulls all your people data into one place, creating a single, real-time picture that everyone can access to make better decisions.',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/charthop.png`,
  website: 'https://www.charthop.com',
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  categories: [CATEGORIES.HRIS],
  configSchema: {
    organizationId: {
      type: 'string',
      title: 'Organization ID',
      nullable: true,
    },
    apiKey: {
      type: 'string',
      title: 'API Token',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    webhookKey: {
      type: 'string',
      title: 'Secret key',
      autogenerate: true,
      uiSchema: {
        'ui:widget': 'ReadOnlyValueToCopy',
      },
    },
  },
  configured: (c) => c.values && c.values.organizationId && c.values.apiKey,
  validation: { action: 'charthop/getCurrentUser' },
};
