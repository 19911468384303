import { array, oneOf } from 'prop-types';
import React from 'react';

import EmployeeAvatar from 'sora-client/components/common/Avatar';
import { Link } from 'sora-client/components/common/Link';
import {
  getRecentEmployeeLink,
  getUpcomingEmployeeLink,
} from 'sora-client/helpers';
import { formatDatesForDisplay } from 'sora-invariant/src/helpers';

const employeLinkFns = {
  upcoming: getUpcomingEmployeeLink,
  recent: getRecentEmployeeLink,
};

const HomePageEmployeeList = ({ employees, listCategory }) => {
  return (
    <div className='list-container overflow-hidden'>
      <div className='list-headers text-uppercase text-small'>
        <span>Employee</span>
        <span>Start Date</span>
      </div>
      <div className='overflow-auto' style={{ maxHeight: 250 }}>
        {employees.map((employee) => {
          return (
            <Link
              className='list-item border-top text-default bg-hover-gray'
              key={employee.id}
              to={employeLinkFns[listCategory](employee)}
            >
              <span className='d-flex align-items-center text-truncate'>
                <EmployeeAvatar
                  imageSrc={employee.imageUrl}
                  widthHeight={48}
                  employeeName={employee.name}
                  employeeId={employee.id}
                />
                <span className='margin-left-2 text-truncate'>
                  <strong>{employee.name}</strong>
                  <div className='text-muted text-truncate'>
                    {employee.title}
                  </div>
                </span>
              </span>
              <div>
                {formatDatesForDisplay(employee.startDate, { type: 'date' })}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

HomePageEmployeeList.propTypes = {
  employees: array.isRequired,
  listCategory: oneOf(['recent', 'upcoming']).isRequired,
};

export default HomePageEmployeeList;
