import classNames from 'classnames';
import { node, string } from 'prop-types';
import React from 'react';

import NoticeBox from 'sora-client/components/common/NoticeBox';

import Icon from './Icon';

const DangerNoticeBox = ({
  children,
  className = '',
  padding = 'padding-4',
  ...restProps
}) => (
  <NoticeBox
    type='danger'
    className={classNames('d-flex align-items-center', className)}
    padding={padding}
    {...restProps}
  >
    {children}
  </NoticeBox>
);

DangerNoticeBox.propTypes = {
  children: node.isRequired,
  className: string,
  padding: string,
};

DangerNoticeBox.Icon = Icon;

export default DangerNoticeBox;
