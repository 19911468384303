import { string } from 'prop-types';
import React, { useContext } from 'react';

import TabsNav from 'sora-client/components/common/TabsNav';
import { SessionContext } from 'sora-client/contexts';
import { userAuthorization } from 'sora-client/helpers/authorization';

export const IntegrationConfigTabNavigator = ({ serviceName }) => {
  const { currentUser } = useContext(SessionContext);
  const ua = userAuthorization(currentUser);

  return (
    <div className='margin-y-3 d-flex tabs-nav'>
      <TabsNav
        items={[
          {
            to: `/settings/integrations/${serviceName}`,
            requireExactMatch: true,
            title: 'Integration setup',
          },
          {
            to: `/settings/integrations/${serviceName}/field-mapping`,
            title: 'Inbound field mapping',
            requireExactMatch: true,
          },
          ...(ua.isSuperAdmin
            ? [
                {
                  to: `/settings/integrations/${serviceName}/field-mapping/reference`,
                  title: 'Field mapping reference',
                },
              ]
            : []),
        ]}
      />
    </div>
  );
};

IntegrationConfigTabNavigator.propTypes = {
  serviceName: string.isRequired,
};
