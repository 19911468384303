import { get } from 'lodash';
import { number, object } from 'prop-types';
import React from 'react';

import soraSvg from 'sora-client/static/images/sora.png';

import Img from './common/Img';
import Tooltip from './common/Tooltip';

const CompanyLogo = ({ session, widthHeight = 38 }) => {
  const companyName = get(session, 'currentUser.company.name');
  return (
    <Tooltip
      tooltip={companyName ? `Go to ${companyName} home` : 'Go home'}
      placement='bottom'
    >
      <Img
        src={get(session, 'currentUser.company.logoUrl')}
        backupImgSrc={soraSvg}
        width={widthHeight}
        height={widthHeight}
        alt='Company logo'
      />
    </Tooltip>
  );
};

CompanyLogo.propTypes = {
  session: object,
  widthHeight: number,
};

export default CompanyLogo;
