import gql from 'graphql-tag';

export const LOOKUP_INTEGRATION_CONFIG = gql`
  query lookupIntegrationConfigForLogin(
    $integrationId: ID!
    $configKey: String!
    $configValue: String!
  ) {
    lookupIntegrationConfigBySearchableValue(
      integrationId: $integrationId
      configKey: $configKey
      configValue: $configValue
    ) {
      values
    }
  }
`;
