import { bool, number, string } from 'prop-types';
import React from 'react';
import ReactImg from 'react-image';

import { FALLBACK_INTEGRATION_LOGO_URL } from 'sora-invariant/src/integrations/common';
import { getIntegrationByName } from 'sora-invariant/src/integrations/constants';

import Tooltip from './Tooltip';

export const IntegrationLogo = ({
  name = null,
  width = 48,
  height = null,
  className = null,
  includeTooltip = false,
}) => {
  const integration = name ? getIntegrationByName(name) : null;
  const label = integration?.label || name;

  return (
    <Tooltip tooltip={includeTooltip ? label : null} placement='bottom'>
      <ReactImg
        className={className}
        src={[integration?.logoUrl, FALLBACK_INTEGRATION_LOGO_URL]}
        alt={`${label} logo`}
        width={width}
        height={height}
      />
    </Tooltip>
  );
};

IntegrationLogo.propTypes = {
  name: string,
  width: number,
  className: string,
  height: number,
  includeTooltip: bool,
};
