import classNames from 'classnames';
import { bool, func, object, string } from 'prop-types';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Manager, Popper, Reference } from 'react-popper';

import { usePrevious } from 'sora-client/components/common/hooks';

import ResizableBox from './ResizableBox';

const PopperContentWrapper = ({
  open,
  innerRef,
  style,
  placement,
  resizable,
  resizableOptions,
  scheduleUpdate,
  PopperContent,
  popperContentClassName,
}) => {
  const prevOpen = usePrevious(open);

  useEffect(() => {
    if (open && !prevOpen) {
      // Updates the positioning of the popper when it is opened
      scheduleUpdate();
    }
  }, [open, prevOpen, scheduleUpdate]);

  return (
    <div
      ref={innerRef}
      style={style}
      data-placement={placement}
      className={classNames('elevated-z-index', popperContentClassName)}
    >
      {open && (
        <>
          {resizable && (
            <ResizableBox {...resizableOptions}>
              {PopperContent({ scheduleUpdate })}
            </ResizableBox>
          )}
          {!resizable && PopperContent({ scheduleUpdate })}
        </>
      )}
    </div>
  );
};

const SoraPopper = ({
  offset = '0,0',
  open,
  placement = 'bottom-start',
  resizable,
  resizableOptions,
  PopperContent,
  Trigger,
  wrapperClassname = null,
  popperContentClassName = null,
}) => {
  const portalTargetRef = document.getElementById('poppers');

  return (
    <div className={wrapperClassname}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref} className='cursor-default'>
              {Trigger}
            </div>
          )}
        </Reference>
        {createPortal(
          <Popper
            placement={placement}
            modifiers={{
              offset: { offset },
            }}
          >
            {({ placement, ref, style, scheduleUpdate }) => {
              return (
                <PopperContentWrapper
                  innerRef={ref}
                  style={style}
                  placement={placement}
                  resizable={resizable}
                  resizableOptions={resizableOptions}
                  scheduleUpdate={scheduleUpdate}
                  open={open}
                  PopperContent={PopperContent}
                  popperContentClassName={popperContentClassName}
                />
              );
            }}
          </Popper>,
          portalTargetRef,
        )}
      </Manager>
    </div>
  );
};

SoraPopper.propTypes = {
  offset: string,
  open: bool.isRequired,
  placement: string,
  resizable: bool,
  resizableOptions: object,
  PopperContent: func.isRequired,
  Trigger: object.isRequired,
  wrapperClassName: string,
  popperContentClassName: string,
};

export default SoraPopper;
