const fieldOrderMap = {
  'First name': 1,
  'Middle name': 2,
  'Last name': 3,
  'Preferred first name': 4,
  'Personal email': 5,
};

const sortFieldForImport = (fields) =>
  fields.sort((a, b) => {
    if (a.required && !b.required) {
      return -1;
    } else if (!a.required && b.required) {
      return 1;
    }

    const getOrder = (f) => fieldOrderMap[f.name] || Number.MAX_VALUE;

    if (getOrder(a) < getOrder(b)) {
      return -1;
    } else if (getOrder(a) > getOrder(b)) {
      return 1;
    }

    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  });

export { sortFieldForImport };
