import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const WEBEX_INTEGRATION_ID = 52;

export const WEBEX_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: WEBEX_INTEGRATION_ID,
  name: 'webex',
  label: 'Webex',
  description:
    'Webex is a comprehensive platform for streamlined communication and collaboration.',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/webex.png`,
  website: 'https://www.webex.com/',
  // TODO: Add when published
  helpDocs: '',
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  categories: [CATEGORIES.COMMUNICATION],
  configSchema: {
    botToken: {
      type: 'string',
      title: 'Bot access token',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
  },
  configured: (c) => c.values && c.values.botToken,
  validation: { action: 'webex/getRooms', parameters: { max: 1 } },
};
