import classNames from 'classnames';
import { isEqual, noop } from 'lodash';
import { bool, func, object, shape, string } from 'prop-types';
import qs from 'qs';
import React, { forwardRef, useEffect, useRef, useState } from 'react';

import history from 'sora-client/constants/history';
import { useEffectAfterMount } from 'sora-client/helpers';

import { SearchSvg } from '../Svg';

/* eslint-disable-next-line react/display-name */
const SearchBar = forwardRef(
  (
    {
      className,
      placeHolderText,
      onSearchInputChange = noop,
      customInputClassnames,
      urlQueryParam,
      autoFocusAfterTimeout = false,
      disabled = false,
      style = null,
      value = '',
      inputProps = {
        autoFocus: false,
      },
    },
    ref,
  ) => {
    const [searchValue, setSearchValue] = useState(value);
    const [focusInMotion, setFocusInMotion] = useState(false);

    const inputRef = useRef(null);

    const queryParams = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    // Whenever the URL changes, we must reconcile searchValue,
    // but only if searchValue can be dictated by a URL query param
    // (as indicated by the presence of the urlQueryParam prop).
    //
    // This is necessary for when users navigate back/forward in the browser, and the
    // adjacent history entry is the same component, so it simply re-renders without
    // initializing state.
    useEffect(() => {
      if (!urlQueryParam) return;

      const paramSearchValue = queryParams[urlQueryParam] || '';
      if (!isEqual(searchValue, paramSearchValue)) {
        onSearchInputChange(paramSearchValue);
        setSearchValue(paramSearchValue);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location]);

    const commonInputProps = {
      ...inputProps,
      className: classNames(
        'flex-grow-1 margin-left-1',
        customInputClassnames,
        {
          disabled,
        },
      ),
      type: 'text',
      value: searchValue,
      placeholder: placeHolderText ? placeHolderText : 'Search...',
      disabled,
      onChange: (e) => {
        setSearchValue(e.target.value);
        onSearchInputChange(e.target.value);
      },
    };

    useEffectAfterMount(() => {
      if (autoFocusAfterTimeout && !focusInMotion) {
        setFocusInMotion(true);
        setTimeout(() => {
          inputRef.current && inputRef.current.focus();
        }, 250); // Arbitrary. Accounts for delays in popover repositioning.
      }
    });

    return (
      <div
        className={classNames('search-bar d-flex align-items-center', {
          [className]: !!className,
        })}
        style={style}
        ref={ref}
      >
        <div className='svg-parent-align-text'>
          <SearchSvg className='no-click' />
        </div>
        <input {...commonInputProps} ref={inputRef} />
      </div>
    );
  },
);

SearchBar.propTypes = {
  className: string,
  placeHolderText: string,
  onSearchInputChange: func,
  customInputClassnames: string,
  defaultSearchValue: string,
  value: string,
  urlQueryParam: string,
  autoFocusAfterTimeout: bool,
  disabled: bool,
  style: object,
  inputProps: shape({
    onFocus: func,
    onBlur: func,
    autoFocus: bool,
  }),
};

export default SearchBar;
