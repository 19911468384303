import { any, oneOf, string } from 'prop-types';
import React from 'react';

import Page from 'sora-client/components/common/Page';

export const WorkflowsWrapperPage = ({ id, children, layout = 'flush' }) => {
  return (
    <Page id={id} category='Workflows' layout={layout}>
      {children}
    </Page>
  );
};

WorkflowsWrapperPage.propTypes = {
  id: string.isRequired,
  children: any.isRequired,
  layout: oneOf(['no-navigation', 'flush', 'default']),
};
