import { capitalize, groupBy, words } from 'lodash';
import { arrayOf, shape, string } from 'prop-types';
import React from 'react';

import NoticeBox from 'sora-client/components/common/NoticeBox';

import { DataField } from './DataField';

export const WebhookEventErrors = ({ errors }) => {
  const errorsGrouped = groupBy(errors, 'type');
  return (
    <DataField
      label='Errors'
      valueRendered={
        <NoticeBox type='danger' className='margin-top-2'>
          {Object.entries(errorsGrouped).map(([typeName, typeErrors]) => (
            <div key={typeName}>
              <strong className='margin-bottom-1 d-block'>
                {capitalize(words(typeName).join(' '))}
              </strong>
              {typeErrors.map((error, idx) => (
                <li key={idx} className='margin-bottom-1'>
                  {error.message}
                </li>
              ))}
            </div>
          ))}
        </NoticeBox>
      }
    />
  );
};

WebhookEventErrors.propTypes = {
  errors: arrayOf(
    shape({ type: string.isRequired, message: string.isRequired }),
  ).isRequired,
};
