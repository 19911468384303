import gql from 'graphql-tag';

import { PERMISSION_GROUP_ALL } from 'sora-client/fragments/permissionGroupAllProperties';

/**
 * Used in MutationWrapper and on the SettingsPage
 */
export const GET_PERMISSION_GROUPS_FULL = gql`
  query getPermissionGroups {
    permissionGroups {
      ...PermissionGroupAllProperties
    }
  }
  ${PERMISSION_GROUP_ALL}
`;
