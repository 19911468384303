import { arrayOf, func, shape, string } from 'prop-types';
import React from 'react';

import Select from 'sora-client/components/common/Select';

export const WorkflowStageAssociationRow = ({
  availableFieldOptions,
  fieldIdsInUse,
  onSelect,
  value,
  workflowStage,
}) => {
  return (
    <div className='d-flex align-items-center margin-bottom-1'>
      <div style={{ width: '200px' }} className='margin-right-3'>
        {workflowStage.name}
      </div>
      <div
        style={{ width: '300px', maxWidth: '800px' }}
        className='d-flex flex-grow-1 margin-right-3'
      >
        <Select
          className='margin-right-1 flex-grow-0'
          search
          name={`workflowworkflowStageAssociation-${workflowStage.id}`}
          data-testid={`workflowworkflowStageAssociation-${workflowStage.id}`}
          onChange={(value) => {
            onSelect({
              targetFieldId: value,
            });
          }}
          options={availableFieldOptions.map((fieldOption) => ({
            ...fieldOption,
            disabled: fieldIdsInUse.includes(fieldOption.value),
          }))}
          optionRenderFn={(option) => {
            return <div>{option.label}</div>;
          }}
          placeholder={workflowStage.fieldId ? 'Select' : workflowStage.name}
          value={value}
          disabled={!workflowStage.fieldId}
          width={210}
        />
      </div>
    </div>
  );
};

WorkflowStageAssociationRow.propTypes = {
  availableFieldOptions: arrayOf(
    shape({
      label: string.isRequired,
      value: string,
    }),
  ).isRequired,
  fieldIdsInUse: arrayOf(string),
  workflowStage: shape({ fieldId: string, name: string }).isRequired,
  onSelect: func.isRequired,
  value: string,
};
