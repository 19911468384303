import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const WORKDAY_INTEGRATION_ID = 17;

export const WORKDAY_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: WORKDAY_INTEGRATION_ID,
  name: 'workday',
  label: 'Workday',
  description: `Workday provides SaaS-based enterprise solutions for a company's human resources and financial management activities.`,
  website: 'workday.com',
  helpDocs: 'https://help.sora.co/resources/workday',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/workday.png`,
  availableUses: [
    AVAILABLE_USES_ENUMS.WORKFLOWS,
    AVAILABLE_USES_ENUMS.DATA_SYNC,
  ],
  categories: [CATEGORIES.HRIS],
  configSchema: {
    username: {
      type: 'string',
      title: 'Username',
      nullable: true,
    },
    password: {
      type: 'string',
      title: 'Password',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    tenantIdentifier: {
      type: 'string',
      title: 'Tenant ID',
      nullable: true,
    },
    wsdlUrl: {
      type: 'string',
      title: 'Web services URL',
      nullable: true,
    },
    outboundEmployeeFieldMapping: {
      type: 'string',
      title: 'Map outbound fields from Sora employees, to Workday employees',
      nullable: true,
    },
    extraData: {
      type: 'object',
      title: 'Extra configuration data',
      nullable: true,
    },
  },
  configured: (c) =>
    c.values &&
    ['username', 'password', 'tenantIdentifier', 'wsdlUrl'].every(
      (key) => !!c.values[key],
    ),
};
