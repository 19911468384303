import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

const useStateDebounce = (initialValue, tm) => {
  const [value, setValue] = useState(initialValue);
  const setOffsetDebounced = useCallback(debounce(setValue, tm), [setValue]);

  useEffect(() => {
    return function cleanupSetOffsetDebounced() {
      setOffsetDebounced.cancel();
    };
  }, [setOffsetDebounced]);

  return [value, setOffsetDebounced, setValue];
};

export default useStateDebounce;
