import gql from 'graphql-tag';

export const PERMISSION_GROUP_ALL = gql`
  fragment PermissionGroupAllProperties on PermissionGroup {
    id
    name
    description
    default
    enableSignIn
    enableFullUIAccess
    permissionMatrix
    users {
      count
    }
  }
`;
