import { isArray, isEmpty } from 'lodash';
import { any, object, string } from 'prop-types';
import React from 'react';

import EmployeeListNullState from 'sora-client/components/EmployeeListNullState';
import EmployeeListItem from 'sora-client/components/MobileEmployeeBlurbWithLink';

const EmployeeList = ({ employees, currentUser, searchTextFilter }) => {
  if (isArray(employees) && isEmpty(employees) && !searchTextFilter) {
    return <EmployeeListNullState currentUser={currentUser} />;
  }

  if (!isArray(employees)) return <div />;

  return isEmpty(employees) ? (
    <div className='text-center padding-y-4 margin-top-4'>No results.</div>
  ) : (
    <>
      {employees.map((employee) => (
        <EmployeeListItem key={employee.id} employee={employee} />
      ))}
    </>
  );
};

EmployeeList.propTypes = {
  currentUser: object.isRequired,
  employees: any,
  searchTextFilter: string,
};

export default EmployeeList;
