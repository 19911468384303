import * as DOMPurify from 'dompurify';
import { marked } from 'marked';
import { string } from 'prop-types';
import React from 'react';

const renderer = {
  // Overwrite h*'s to just display as bold text so they don't overpower
  // the template name or CTAs
  heading(text) {
    return `<strong>${text}</strong>`;
  },
};

export const MarkdownTemplateDescription = ({ description }) => {
  marked.use({ renderer });
  return (
    <div
      className='margin-top-2'
      style={{ lineHeight: '25px' }}
      dangerouslySetInnerHTML={{
        // This shouldn't be strictly necessary, since we are sanitizing HTML
        // when saving to & retrieving from the DB (see the `set` and `get`
        // methods on Timeline.internalDescriptionLong), but we'll also sanitize
        // the string here for extra safety
        __html: DOMPurify.sanitize(marked.parse(description)),
      }}
    />
  );
};

MarkdownTemplateDescription.propTypes = {
  description: string.isRequired,
};
