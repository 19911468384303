import React from 'react';

export default React.createContext({
  previewingAsEmployee: null,
  /**
   * @param {object} employee
   * @param {number} employee.id
   * @param {string} employee.name
   */
  setPreviewingEmployee: (employee) => {},
});
