import gql from 'graphql-tag';

const LOG_IN_COMPANY = gql`
  mutation ($companyId: ID!) {
    logInCompany(companyId: $companyId) {
      token
    }
  }
`;

const IMPERSONATE_USER = gql`
  mutation ($userId: ID!) {
    impersonateUser(userId: $userId) {
      token
    }
  }
`;

const SAVE_SETTINGS = gql`
  mutation ($settings: UserSettingsInput!) {
    saveSettings(settings: $settings) {
      id
      settings {
        employeeListAdditionalColumns
      }
    }
  }
`;

export { LOG_IN_COMPANY, IMPERSONATE_USER, SAVE_SETTINGS };
