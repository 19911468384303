import { noop } from 'lodash';
import { bool, func, number, object, string } from 'prop-types';
import React, { useRef } from 'react';

import SearchBar from 'sora-client/components/common/Search/SearchBar';
import useKeyboardNavigation from 'sora-client/components/common/hooks/useKeyboardNavigation';

export const KeyDownSearchBar = ({
  className,
  placeHolderText,
  onSearchInputChange = noop,
  onKeyNavigation = noop,
  listSize = 0,
  customInputClassnames,
  defaultSearchValue = '',
  urlQueryParam,
  onFocus = noop,
  onBlur = noop,
  autoFocus = false,
  autoFocusAfterTimeout = false,
  disabled = false,
  style = null,
}) => {
  const ref = useRef(null);

  useKeyboardNavigation({
    listSize,
    refNode: ref.current,
    onKeyDown: (lastKeyPress, activeCursor) =>
      onKeyNavigation({ activeCursor, lastKeyPress }),
  });

  return (
    <SearchBar
      className={className}
      placeHolderText={placeHolderText}
      onSearchInputChange={onSearchInputChange}
      customInputClassnames={customInputClassnames}
      value={defaultSearchValue}
      urlQueryParam={urlQueryParam}
      inputProps={{
        onFocus,
        onBlur,
        autoFocus,
      }}
      autoFocusAfterTimeout={autoFocusAfterTimeout}
      disabled={disabled}
      style={style}
      ref={ref}
    />
  );
};

KeyDownSearchBar.propTypes = {
  className: string,
  placeHolderText: string,
  onSearchInputChange: func,
  onKeyNavigation: func,
  listSize: number,
  customInputClassnames: string,
  defaultSearchValue: string,
  urlQueryParam: string,
  onFocus: func,
  onBlur: func,
  autoFocus: bool,
  autoFocusAfterTimeout: bool,
  disabled: bool,
  style: object,
};
