import { number } from 'prop-types';
import React, { useState } from 'react';

import { useInterval } from 'sora-client/components/common/hooks';

const formatTimer = ({ secondsUntilExpiration }) => {
  const minutes = Math.floor(secondsUntilExpiration / 60);
  const seconds = Math.round(secondsUntilExpiration % 60);

  /**
   * If `seconds` is a single digit, this prefixes with '0'
   * to render as eg '09' or '08'. If `seconds` is two
   * digits, no modification is made
   */
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

export const CountdownTimer = ({ secondsUntilExpiration }) => {
  const [countdownSeconds, setCountdownSeconds] = useState(
    secondsUntilExpiration,
  );
  useInterval(() => {
    setCountdownSeconds((lastSeconds) => {
      return Math.max(lastSeconds - 1, 0);
    });
  }, 1000);

  return (
    <strong>{formatTimer({ secondsUntilExpiration: countdownSeconds })}</strong>
  );
};

CountdownTimer.propTypes = {
  secondsUntilExpiration: number.isRequired,
};
