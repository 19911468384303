const fieldSubscriptionHandlers = {
  /**
   * @param client
   * @param {{data: { fieldCreated: {}}}}subscriptionData
   * @param query
   * @param variables
   */
  'onFieldCreated.getFieldsQuery': ({
    client,
    subscriptionData,
    query,
    variables,
  }) => {
    const field = subscriptionData.data.fieldCreated;

    const fromCache = client.readQuery({
      query,
      variables,
    });
    client.writeQuery({
      query,
      variables,
      data: {
        ...fromCache,
        companyFields: [
          ...fromCache.companyFields.filter((f) => f.id !== field.id),
          field,
        ],
      },
    });
  },
  'onFieldDeleted.getFieldsQuery': ({
    client,
    subscriptionData,
    query,
    variables,
  }) => {
    const field = subscriptionData.data.fieldDeleted;

    const fromCache = client.readQuery({
      query,
      variables,
    });
    client.writeQuery({
      query,
      variables,
      data: {
        ...fromCache,
        companyFields: fromCache.companyFields.filter((f) => f.id !== field.id),
      },
    });
  },
  'onFieldCreated.getFieldGroupsQuery': ({
    client,
    subscriptionData,
    query,
    variables,
  }) => {
    const field = subscriptionData.data.fieldCreated;
    const fieldGroupId = field.fieldGroupId;

    const fromCache = client.readQuery({
      query,
      variables,
    });
    const fieldGroup = fromCache.fieldGroups.find(
      (fg) => fg.id === fieldGroupId,
    );

    client.writeQuery({
      query,
      variables,
      data: {
        ...fromCache,
        fieldGroups: fromCache.fieldGroups.map((fg) =>
          fg.id === fieldGroup?.id
            ? { ...fieldGroup, fields: [...fieldGroup.fields, field] }
            : fg,
        ),
      },
    });
  },
  'onFieldUpdated.getFieldGroupsQuery': ({
    client,
    subscriptionData,
    query,
    variables,
  }) => {
    const field = subscriptionData.data.fieldUpdated;
    const fieldGroupId = field.fieldGroupId;

    const fromCache = client.readQuery({
      query,
      variables,
    });

    client.writeQuery({
      query,
      variables,
      data: {
        ...fromCache,
        fieldGroups: fromCache.fieldGroups.map((fg) => {
          const fieldIds = fg.fields.map(({ id }) => id);

          if (fieldGroupId === fg.id) {
            // Field was not moved to a different field group,
            // so leave things as is
            if (fieldIds.includes(field.id)) {
              return fg;
            }

            // Field was moved to this field group,
            // so add it to this field group's fields
            return {
              ...fg,
              fields: [...fg.fields, field],
            };
          }

          if (fieldIds.includes(field.id)) {
            // Field was moved to a different field group,
            // so remove it from this field group's fields
            return {
              ...fg,
              fields: fg.fields.filter((f) => f.id !== field.id),
            };
          }

          return fg;
        }),
      },
    });
  },
  'onFieldDeleted.getFieldGroupsQuery': ({
    client,
    subscriptionData,
    query,
    variables,
  }) => {
    const fieldId = subscriptionData.data.fieldDeleted;

    const fromCache = client.readQuery({
      query,
      variables,
    });
    const fieldGroup = fromCache.fieldGroups.find((fg) =>
      fg.fields.find((f) => f.id === fieldId),
    );
    // in case field belong to survey don't update fieldGroups
    if (!fieldGroup) return;

    client.writeQuery({
      query,
      variables,
      data: {
        ...fromCache,
        fieldGroups: fromCache.fieldGroups.map((fg) =>
          fg.id === fieldGroup.id
            ? {
                ...fieldGroup,
                fields: [...fieldGroup.fields.filter((f) => f.id !== fieldId)],
              }
            : fg,
        ),
      },
    });
  },
};

export default fieldSubscriptionHandlers;
