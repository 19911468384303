import classNames from 'classnames';
import { any, bool, func, string } from 'prop-types';
import React from 'react';

const DropdownInnerContainer = ({
  open,
  setOpen,
  otherInnerDropdownClasses,
  clickOutsideOnClickDropdown,
  children,
}) => {
  return (
    <>
      {open && (
        <div
          className={classNames(
            'dropdown bg-white d-flex flex-direction-column text-left rounded-xlarge',
            otherInnerDropdownClasses,
          )}
          onClick={(e) => {
            e.stopPropagation();
            if (clickOutsideOnClickDropdown) setOpen(false);
          }}
        >
          {children}
        </div>
      )}
    </>
  );
};

DropdownInnerContainer.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
  otherInnerDropdownClasses: string,
  clickOutsideOnClickDropdown: bool,
  children: any,
};

export default DropdownInnerContainer;
