import { bool, object, string } from 'prop-types';
import React from 'react';

import DangerNoticeBoxWithIcon from 'sora-client/components/common/DangerNoticeBoxWithIcon';
import { formatError } from 'sora-client/helpers';

export const ApiErrorMessage = ({
  className = null,
  error,
  showRawMessage = false,
}) => {
  return (
    <DangerNoticeBoxWithIcon className={className}>
      <span>{formatError(error, showRawMessage)}</span>
    </DangerNoticeBoxWithIcon>
  );
};

ApiErrorMessage.propTypes = {
  className: string,
  error: object.isRequired,
  showRawMessage: bool,
};
