import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { useInterval } from 'sora-client/components/common/hooks';

export const FlashWarningInTitle = () => {
  const [showExclamation, setShowExclamation] = useState(true);

  useInterval(() => {
    setShowExclamation((prevValue) => !prevValue);
  }, 1500);

  return (
    <Helmet>
      <title>{showExclamation ? '(!) ' : ''}Sora</title>
    </Helmet>
  );
};
