import gql from 'graphql-tag';
import { isEmpty, isNull, keyBy, omit, omitBy } from 'lodash';
import { array, func, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import Button from 'sora-client/components/common/Button';
import InnerPageContentContainer from 'sora-client/components/common/InnerPageContentContainer';
import Loading from 'sora-client/components/common/Loading';
import StaticDropdown from 'sora-client/components/common/StaticDropdown';
import { WarningCircleSvg } from 'sora-client/components/common/Svg';
import Table from 'sora-client/components/common/Table';
import Tooltip from 'sora-client/components/common/Tooltip';
import useDimensions from 'sora-client/components/common/hooks/useDimensions';
import useMutationWrapper from 'sora-client/components/common/useMutationWrapper';
import useQueryWrapper from 'sora-client/components/common/useQueryWrapper';
import { modelConstants } from 'sora-invariant/src/constants';

const fieldTypes = modelConstants.field.types;
const GET_IMPORTED_FIELDS = gql`
  query getFieldsForImportQuery($uploadId: String!) {
    importedFields(uploadId: $uploadId) {
      importedFieldsArray {
        name
      }
      uploadMapping
    }
  }
`;

const SEND_MAPPING = gql`
  mutation mapImportFieldsMutation(
    $uploadId: String!
    $fieldMapList: [FieldMapInput!]!
  ) {
    mapCsvUploadFields(uploadId: $uploadId, fieldMapList: $fieldMapList) {
      uploadId
    }
  }
`;

const NO_MAPPING = '<No mapping>';

const FIELDS_ALWAYS_REQUIRED_FOR_IMPORT = ['Personal email'];

/**
 * Adds additional logic to the field required property on the company fields. Due to a unique case where
 * a few companies have the Personal email field set to not required for their configurations, we need to manually
 * override that field to make it required in all imports. Otherwise, if one of those companies tried to import a list they
 * would get stuck on the import employees screen without being aware that `Personal email` is alwayus required in the CSV import.
 *
 * @param {Object} field
 * @returns {boolean}
 */
const isFieldRequiredForImport = (field) => {
  return (
    field.required || FIELDS_ALWAYS_REQUIRED_FOR_IMPORT.includes(field.name)
  );
};

function createDefaultFieldsMap(companyFields, importedFields) {
  const importedFieldsMap = keyBy(importedFields, (iField) =>
    iField.name.toLowerCase(),
  );
  const defaultFieldsMap = {};

  companyFields.forEach((cf) => {
    const lowercaseFieldName = cf.name.toLowerCase();
    defaultFieldsMap[cf.name] = importedFieldsMap[lowercaseFieldName]
      ? importedFieldsMap[lowercaseFieldName].name
      : null;
  });

  return omitBy(defaultFieldsMap, isNull);
}

const ImportFieldsMappingStep = ({
  uploadId,
  companyFields,
  onNext,
  onCancel,
}) => {
  const { fn: mapImportFields, loading: loadingNextStep } =
    useMutationWrapper(SEND_MAPPING);

  const { data, loading } = useQueryWrapper(GET_IMPORTED_FIELDS, {
    uploadId,
  });

  const importedFieldsArray = data?.importedFields?.importedFieldsArray || [];
  const existingUploadMapping = data?.importedFields?.uploadMapping;

  const importFieldsItems = [
    NO_MAPPING,
    ...importedFieldsArray.map((f) => f.name),
  ];

  const [errorsMap, setErrorsMap] = useState({});
  const [fieldsMap, setFieldsMap] = useState({});

  useEffect(
    () => {
      // when coming back from the importedEmployeeStep,
      // set the existing fieldMapping rather than the default one
      if (!isEmpty(existingUploadMapping)) {
        setFieldsMap(existingUploadMapping);
      } else {
        setFieldsMap(
          createDefaultFieldsMap(companyFields, importedFieldsArray),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, existingUploadMapping],
  );

  const getErrorsMap = () => {
    return companyFields
      .filter((cf) => isFieldRequiredForImport(cf))
      .reduce((map, cf) => {
        if (!fieldsMap[cf.name]) {
          map[cf.name] = true;
        }
        return map;
      }, {});
  };

  const nextButtonDisabled = useMemo(() => {
    return !!Object.keys(getErrorsMap()).length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsMap]);

  const onNextButtonClick = async () => {
    const errorsMap = getErrorsMap();

    if (Object.keys(errorsMap).length) {
      return setErrorsMap(errorsMap);
    }

    const fieldMapList = Object.keys(fieldsMap).map((companyField) => ({
      companyField,
      importedField: fieldsMap[companyField],
    }));

    await mapImportFields({
      variables: {
        uploadId,
        fieldMapList,
      },
    });

    onNext();
  };

  const onCancelButtonClick = () => {
    onCancel();
  };

  const onFieldMapUpdate = (companyField, importField) => {
    if (importField === NO_MAPPING) {
      setFieldsMap((prev) => omit(prev, companyField));
    } else {
      setFieldsMap((prev) => ({
        ...prev,
        [companyField]: importField,
      }));
    }
  };

  const tableFields = useMemo(
    () => [
      {
        title: 'Sora field',
        cellRenderer: ({ cellData, rowIndex }) => {
          const field = companyFields[rowIndex];

          return (
            <div className='d-flex align-items-center'>
              <div>{cellData}</div>
              {field.type === fieldTypes.MULTIPLE_CHOICE &&
                !field.allowAutomaticTagValues && (
                  <Tooltip tooltip='This multiple choice field does not accept new values on import. Import will fail for employees with values that do not match.'>
                    <WarningCircleSvg className='stroke-sunset svg-medium margin-left-2 margin-top-1' />
                  </Tooltip>
                )}
            </div>
          );
        },
      },
      {
        title: 'Field type',
        width: 280,
      },
      {
        title: 'CSV header',
        // eslint-disable-next-line react/display-name, react/prop-types
        cellRenderer: ({ cellData, rowIndex }) => (
          <StaticDropdown
            search
            dropdownItems={importFieldsItems}
            selectedItem={cellData}
            onClickItem={(f) =>
              onFieldMapUpdate(companyFields[rowIndex].name, f)
            }
            togglerClassName=''
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [importFieldsItems],
  );

  const tableData = useMemo(
    () =>
      companyFields.map((companyField, index) => ({
        id: index,
        soraField: `${companyField.name}${
          isFieldRequiredForImport(companyField) ? '*' : ''
        }`,
        fieldType: companyField.type,
        csvHeader: fieldsMap[companyField.name],
        cellErrors: errorsMap[companyField.name]
          ? {
              soraField: {
                mark: true,
                bordered: true,
              },
            }
          : {},
      })),
    [companyFields, errorsMap, fieldsMap],
  );

  const [tableContainerRef, { width: tableWidth }] = useDimensions({
    events: ['resize'],
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <InnerPageContentContainer>
        <div className='d-flex align-items-center margin-top-5'>
          <div className='flex-grow-1'>
            <h2 className='margin-y-0 flex-grow-1 font-p22-mackinac'>
              Map CSV to Sora fields
            </h2>
          </div>
          <div>
            <Button
              label='Cancel'
              category='secondary'
              onClick={onCancelButtonClick}
            />
            <Tooltip
              tooltip={
                nextButtonDisabled
                  ? 'Map all required fields before continuing'
                  : ''
              }
            >
              <Button
                label='Next step'
                category='primary'
                disabled={nextButtonDisabled}
                className='margin-left-3'
                onClick={onNextButtonClick}
                loading={loadingNextStep}
              />
            </Tooltip>
          </div>
        </div>
      </InnerPageContentContainer>
      <div className='border-top' ref={tableContainerRef}>
        <Table
          highlightErrors
          camelcaseKey
          genericCell
          className='default-cell-padding'
          key='import-fields'
          fixed={true}
          autoresizer={false}
          rowHeight={52}
          width={tableWidth}
          height={52 * (tableData.length + 1)}
          columns={tableFields}
          data={tableData}
        />
      </div>
    </div>
  );
};

ImportFieldsMappingStep.propTypes = {
  uploadId: string.isRequired,
  companyFields: array.isRequired,
  onNext: func.isRequired,
  onCancel: func.isRequired,
};

export default ImportFieldsMappingStep;
