import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const TRINET_INTEGRATION_ID = 40;

export const TRINET_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: TRINET_INTEGRATION_ID,
  name: 'trinet',
  label: 'TriNet',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/trinet.png`,
  website: 'https://www.trinet.com/',
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  categories: [CATEGORIES.HRIS],
  configSchema: {
    companyId: {
      type: 'string',
      title: 'Company ID',
      nullable: true,
    },
    client_id: {
      type: 'string',
      title: 'Client ID (key)',
      nullable: true,
    },
    client_secret: {
      type: 'string',
      title: 'Client secret',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
  },
  configured: (c) =>
    c.values &&
    c.values.companyId &&
    c.values.client_id &&
    c.values.client_secret,
  validation: { action: 'trinet/getAllEmployeeData', parameters: { limit: 1 } },
};
