import { extend, get, isEmpty } from 'lodash';
import { bool, func, object } from 'prop-types';
import React, {
  Suspense,
  lazy,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ErrorBoundary from 'sora-client/components/common/ErrorBoundary';
import Page from 'sora-client/components/common/Page';
import history from 'sora-client/constants/history';
import * as routes from 'sora-client/constants/routes';
import AnalyticsContext from 'sora-client/contexts/AnalyticsContext';
import SessionContext from 'sora-client/contexts/SessionContext';
import {
  getCurrentPageName,
  triggerWindowResizeEvent,
} from 'sora-client/helpers';
import { userAuthorization } from 'sora-client/helpers/authorization';

import '../static/css/app.css';
import ErrorPage from './ErrorPage';
import HomePage from './HomePage';
import ProtectedRoute from './ProtectedRoute';
import { CheckSession } from './Session/CheckSession/CheckSession';
import withSession from './Session/withSession';
import ConfirmationModal from './common/ConfirmationModal';
import Loading from './common/Loading';
import Modal from './common/Modal';
import PageTitleAndCompanyLogo from './common/PageTitleAndCompanyLogo';
import { steps } from './pages/EmployeeCsvImportPage';
import EmployeesWrapperPage from './pages/EmployeesWrapperPage';
import { FieldMappingReferencePage } from './pages/FieldMappingReferencePage';
import NewHireTaskWrapperPage from './pages/NewHireTaskWrapperPage';
import SettingsWrapperPage from './pages/SettingsWrapperPage';
import { SuperAdminWrapperPage } from './pages/SuperAdminWrapperPage';
import { WebhookEventsPage } from './pages/WebhookEventsPage';
import {
  DASHBOARD_TAB,
  DUE_SOON_TAB,
  EMPLOYEE_TAB,
  ISSUES_TAB,
  NEEDS_ATTENTION_TAB,
  OVERDUE_TAB,
  SPREADSHEET_TAB,
  TASK_ANALYSIS_TAB,
} from './pages/WorkflowOverviewContainer/constants';
import { WorkflowTemplateDiscoveryPage } from './pages/WorkflowTemplateDiscoveryPage';
import { WorkflowsWrapperPage } from './pages/WorkflowsWrapperPage';

const CompanyEmailsSettings = lazy(() =>
  import('sora-client/components/CompanyEmailsSettings'),
);
const IntegrationConfigPage = lazy(() =>
  import('sora-client/components/IntegrationConfigPage/IntegrationConfigPage'),
);
const CompanyAuditLogs = lazy(() =>
  import('./pages/SettingsPage/SettingsPageContent/CompanyAuditLogs'),
);
const CompanyAuditLogsV2 = lazy(() =>
  import(
    './pages/SettingsPage/SettingsPageContent/CompanyAuditLogsV2/CompanyAuditLogsV2'
  ),
);
const CompanySettings = lazy(() =>
  import('./pages/SettingsPage/SettingsPageContent/CompanySettings'),
);
const DashboardCards = lazy(() =>
  import('./pages/SettingsPage/SettingsPageContent/DashboardCards'),
);
const NotificationsSettingsWrapper = lazy(() =>
  import('./pages/SettingsPage/SettingsPageContent/NotificationsSettings'),
);
const PermissionsSettings = lazy(() =>
  import('./pages/SettingsPage/SettingsPageContent/PermissionsSettings'),
);
const EmployeeProfilePage = lazy(() => import('./pages/EmployeeProfilePage'));
const EmployeeOrgChartPage = lazy(() => import('./pages/EmployeeOrgChartPage'));
const EmployeeAddPage = lazy(() => import('./pages/EmployeeAddPage'));
const EmployeeDataManagementFieldsPage = lazy(() =>
  import('./pages/EmployeeDataManagementFieldsPage'),
);
const EmployeeDataManagementDataSyncPage = lazy(() =>
  import('./pages/EmployeeDataManagementDataSyncPage'),
);
const EmployeeCsvImportPage = lazy(() =>
  import('./pages/EmployeeCsvImportPage'),
);
const EmployeeListPage = lazy(() => import('./pages/EmployeeListPage'));
const FieldMappingConfigPage = lazy(() =>
  import('./pages/FieldMappingConfigPage'),
);
const NewHireTaskListPaginatedQuery = lazy(() =>
  import('./pages/NewHireTaskListPaginatedQuery'),
);
const NewHireTaskPage = lazy(() => import('./pages/NewHireTaskPage'));
const NewHireTaskMarkCompletePage = lazy(() =>
  import('./pages/NewHireTaskMarkCompletePage'),
);
const SuperAdminPage = lazy(() => import('./pages/SuperAdminPage'));
const SuperAdminCompanyPage = lazy(() =>
  import('./pages/SuperAdminCompanyPage'),
);
const SuperAdminCompanyIntegrationTemplatePage = lazy(() =>
  import('./pages/SuperAdminCompanyIntegrationTemplatePage'),
);
const SuperAdminCompanyIntegrationsPage = lazy(() =>
  import('./pages/SuperAdminCompanyIntegrationsPage'),
);
const SuperAdminIntegrationEditorPage = lazy(() =>
  import('./pages/SuperAdminIntegrationEditorPage'),
);
const SuperAdminIntegrationV2RunViewerPage = lazy(() =>
  import('./pages/SuperAdminIntegrationV2RunViewerPage'),
);
const WorkflowConfigPage = lazy(() => import('./pages/WorkflowConfigPage'));
const WorkflowConfigPreviewPage = lazy(() =>
  import('./pages/WorkflowConfigPage/WorkflowConfigPreviewPage'),
);
const WorkflowOverviewContainer = lazy(() =>
  import('./pages/WorkflowOverviewContainer'),
);
const WorkflowTemplatesListPage = lazy(() =>
  import('./pages/WorkflowTemplatesListPage'),
);
const WorkflowIntegrationSettingsPage = lazy(() =>
  import('./pages/WorkflowIntegrationSettingsPage'),
);
const WorkflowSelfEnrollPage = lazy(() =>
  import('./pages/WorkflowSelfEnrollPage'),
);
const ReportsPage = lazy(() => import('./pages/ReportsPage'));

const initializeAnalytics = ({ analyticsContext, currentUser, session }) => {
  const { getCount, track, identify, group, increment } = analyticsContext;
  if (!isEmpty(currentUser)) {
    // TODO this count resets when we call analytics.reset()
    // figure out a way to keep it consistent
    const count = getCount('Session');
    increment(`Session`);
    track(`Session Started`, { 'Session Count': count });
    const userId = session.currentUser.id;
    const companyId = session.currentUser.companyId;
    identify(userId, {
      Email: session.currentUser.email,
      Name: session.currentUser.employee.name,
      'Permission groups': session.currentUser.permissionGroups
        .map((e) => e.name)
        .join(', '),
      'User ID': userId,
      // TODO fix count (see comment above)
      // 'Session Count': count,
    });
    group(companyId, {
      'Company Name': session.currentUser.company.name,
      'Company ID': companyId,
      'Company Logo Used': !!session.currentUser.company.logoUrl,
      'Has Premium Access': !!session.currentUser.company.enablePremiumFeatures,
    });
  }
};

const AppSession = ({
  session,
  refetch,
  confirmation,
  onConfirmationDismiss,
  modalOptions,
  onModalDismiss,
  loading,
}) => {
  const analyticsContext = useContext(AnalyticsContext);
  const currentUser = session.currentUser;
  const employee = currentUser.employee;
  const ua = userAuthorization(currentUser);
  const hasFullUIAccess = currentUser.hasFullUIAccess;
  const hasPremiumAccess = currentUser.hasPremiumAccess;
  const isSuperAdmin = ua.isSuperAdmin;
  const isAdminPlus = ua.isAdminPlus;
  const isWorkflowTemplatesCompany =
    currentUser.company.isWorkflowTemplatesCompany;

  // for non-active don't store tokens in local storage
  // TODO: we should probably find better place for this piece of code
  if (!ua.isActive && localStorage.getItem('token')) {
    window.soraToken = localStorage.getItem('token');
    localStorage.setItem('token', '');
  }

  //set version on RUM to the environment variable so it will match the source maps in DD
  useEffect(() => {
    window.DD_RUM &&
      window.DD_RUM.addRumGlobalContext(
        'version',
        process.env.REACT_APP_SCHEMA_VERSION,
      );
  }, []);

  useEffect(
    () => {
      //set user email and id for Sprig (formerly UserLeap) to make product research not anonymous
      if (currentUser && window.Sprig) {
        window.Sprig('setUserId', currentUser.id);
        window.Sprig('setEmail', currentUser.email);
      }
      initializeAnalytics({ analyticsContext, currentUser, session });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser.id, currentUser.email, employee.name],
  );

  const [siteWideMessage, setSiteWideMessage] = useState(null);

  // @ts-ignore
  window.setSiteWideMessage = (message) => {
    setSiteWideMessage(message);
    triggerWindowResizeEvent();
  };

  const currentPageName = useMemo(() => {
    return getCurrentPageName(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <>
      <CheckSession />
      <SessionContext.Provider value={{ currentUser, ua, refetch }}>
        {!!siteWideMessage && (
          <div className='padding-x-5 mobile-padding-x-3 padding-y-2 bg-sunrise'>
            {siteWideMessage}
          </div>
        )}
        <PageTitleAndCompanyLogo title={currentPageName} />
        <ErrorBoundary>
          <Switch>
            {loading && !get(session, `currentUser`) && (
              // loading, or no session yet, or session exists but not yet returned
              <Route>
                <div className='page-container margin-y-8'>
                  <Loading />
                </div>
              </Route>
            )}
            {session && session.currentUser && (
              <>
                {!isEmpty(modalOptions) && (
                  <Modal
                    {...modalOptions}
                    onDismissModal={() => {
                      if (modalOptions.onDismissModal) {
                        modalOptions.onDismissModal();
                      }
                      onModalDismiss();
                    }}
                  />
                )}
                {!isEmpty(confirmation) && (
                  <ConfirmationModal
                    confirmation={extend(
                      { dismissOnConfirm: true },
                      confirmation,
                    )}
                    dismissModal={onConfirmationDismiss}
                  />
                )}
                <Switch>
                  <ProtectedRoute
                    exact
                    path={routes.SUPER_ADMIN_COMPANY_EDIT}
                    canUserAccess={
                      (hasPremiumAccess && hasFullUIAccess) || isSuperAdmin
                    }
                    render={({ match, location }) => (
                      <SuperAdminWrapperPage>
                        <Suspense fallback={<Loading />}>
                          <SuperAdminCompanyPage
                            companyId={match.params.id}
                            tab='edit'
                          />
                        </Suspense>
                      </SuperAdminWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SUPER_ADMIN_COMPANY_USERS}
                    canUserAccess={
                      (hasPremiumAccess && hasFullUIAccess) || isSuperAdmin
                    }
                    render={({ match, location }) => (
                      <SuperAdminWrapperPage>
                        <Suspense fallback={<Loading />}>
                          <SuperAdminCompanyPage
                            companyId={match.params.id}
                            tab='users'
                          />
                        </Suspense>
                      </SuperAdminWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SUPER_ADMIN_COMPANY_NOTIFICATIONS}
                    canUserAccess={
                      (hasPremiumAccess && hasFullUIAccess) || isSuperAdmin
                    }
                    render={({ match, location }) => (
                      <SuperAdminWrapperPage>
                        <Suspense fallback={<Loading />}>
                          <SuperAdminCompanyPage
                            companyId={match.params.id}
                            tab='notifications'
                          />
                        </Suspense>
                      </SuperAdminWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SUPER_ADMIN_COMPANY_INTEGRATIONS}
                    canUserAccess={
                      (hasPremiumAccess && hasFullUIAccess) || isSuperAdmin
                    }
                    render={({ match, location }) => (
                      <SuperAdminWrapperPage>
                        <Suspense fallback={<Loading />}>
                          <SuperAdminCompanyIntegrationsPage
                            companyId={match.params.id}
                          />
                        </Suspense>
                      </SuperAdminWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SUPER_ADMIN_COMPANY_INTEGRATION_TEMPLATE_NEW}
                    canUserAccess={
                      (hasPremiumAccess && hasFullUIAccess) || isSuperAdmin
                    }
                    render={({ match, location }) => (
                      <SuperAdminWrapperPage>
                        <Suspense fallback={<Loading />}>
                          <SuperAdminIntegrationEditorPage
                            companyId={match.params.id}
                          />
                        </Suspense>
                      </SuperAdminWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SUPER_ADMIN_COMPANY_INTEGRATION_V2_RUN}
                    canUserAccess={
                      (hasPremiumAccess && hasFullUIAccess) || isSuperAdmin
                    }
                    render={({ match, location }) => (
                      <SuperAdminWrapperPage>
                        <Suspense fallback={<Loading />}>
                          <SuperAdminIntegrationV2RunViewerPage
                            runId={Number(match.params.runId)}
                          />
                        </Suspense>
                      </SuperAdminWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SUPER_ADMIN_COMPANY_INTEGRATION_TEMPLATE}
                    canUserAccess={
                      (hasPremiumAccess && hasFullUIAccess) || isSuperAdmin
                    }
                    render={({ match, location }) => (
                      <SuperAdminWrapperPage id='admin-integrations-template-page'>
                        <Suspense fallback={<Loading />}>
                          <SuperAdminCompanyIntegrationTemplatePage
                            companyId={match.params.id}
                            integrationTemplateUuid={match.params.template_uuid}
                          />
                        </Suspense>
                      </SuperAdminWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SUPER_ADMIN_COMPANY_INTEGRATION_TEMPLATE_TAB}
                    canUserAccess={
                      (hasPremiumAccess && hasFullUIAccess) || isSuperAdmin
                    }
                    render={({ match, location }) => (
                      <SuperAdminWrapperPage id='admin-integrations-template-page'>
                        <Suspense fallback={<Loading />}>
                          <SuperAdminCompanyIntegrationTemplatePage
                            companyId={match.params.id}
                            integrationTemplateUuid={match.params.template_uuid}
                            tab={match.params.tab}
                          />
                        </Suspense>
                      </SuperAdminWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SUPER_ADMIN_COMPANY_BASE}
                    canUserAccess={
                      (hasPremiumAccess && hasFullUIAccess) || isSuperAdmin
                    }
                    render={({ location }) => (
                      <SuperAdminWrapperPage id='admin-page'>
                        <Suspense fallback={<Loading />}>
                          <SuperAdminPage />
                        </Suspense>
                      </SuperAdminWrapperPage>
                    )}
                  />
                  <Route
                    exact
                    path={routes.HOME}
                    render={({ location }) => (
                      <Page
                        id='home-page'
                        title='Home'
                        subtitle='Everything you need to know for today.'
                        category='Home'
                      >
                        <HomePage />
                      </Page>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SETTINGS_PERMISSIONS}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ location }) => (
                      <SettingsWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <PermissionsSettings />
                        </Suspense>
                      </SettingsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={`${routes.SETTINGS_PERMISSIONS}/:groupId`}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ location }) => (
                      <SettingsWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <PermissionsSettings />
                        </Suspense>
                      </SettingsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SETTINGS_COMPANY_EMAILS}
                    canUserAccess={
                      !isWorkflowTemplatesCompany &&
                      hasFullUIAccess &&
                      hasPremiumAccess
                    }
                    render={({ location }) => (
                      <SettingsWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <CompanyEmailsSettings
                            companyId={session.currentUser.companyId}
                          />
                        </Suspense>
                      </SettingsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SETTINGS_COMPANY}
                    canUserAccess={hasFullUIAccess}
                    render={({ location }) => (
                      <SettingsWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <CompanySettings
                            companyId={session.currentUser.companyId}
                            currentUser={session.currentUser}
                          />
                        </Suspense>
                      </SettingsWrapperPage>
                    )}
                  />
                  <Route
                    path='/settings/logs'
                    exact
                    render={() => (
                      <Redirect to={routes.SETTINGS_WEBHOOK_EVENTS} />
                    )}
                  />
                  {/** Redirect for old audit logs route */}
                  <Route
                    path='/settings/audit-logs'
                    render={() => <Redirect to={routes.SETTINGS_AUDIT_LOGS} />}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SETTINGS_AUDIT_LOGS_DEPRECATED}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ location }) => (
                      <SettingsWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <CompanyAuditLogs />
                        </Suspense>
                      </SettingsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SETTINGS_AUDIT_LOGS}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ location }) => (
                      <SettingsWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <CompanyAuditLogsV2 />
                        </Suspense>
                      </SettingsWrapperPage>
                    )}
                  />

                  <ProtectedRoute
                    exact
                    path={routes.SETTINGS_NOTIFICATIONS}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ location }) => (
                      <SettingsWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <NotificationsSettingsWrapper
                            currentUser={session.currentUser}
                          />
                        </Suspense>
                      </SettingsWrapperPage>
                    )}
                  />
                  <Route
                    path={routes.SETTINGS_EMPLOYEE_DATA_DEPRECATED}
                    render={() => (
                      <Redirect to={routes.EMPLOYEES_DATA_MANAGEMENT} />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SETTINGS_INTEGRATIONS}
                    canUserAccess={
                      !isWorkflowTemplatesCompany &&
                      (hasFullUIAccess || isSuperAdmin)
                    }
                    render={({ location }) => (
                      <SettingsWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <IntegrationConfigPage />
                        </Suspense>
                      </SettingsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SETTINGS_INTEGRATION}
                    canUserAccess={
                      !isWorkflowTemplatesCompany &&
                      (hasFullUIAccess || isSuperAdmin)
                    }
                    render={({ match }) => (
                      <SettingsWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <IntegrationConfigPage
                            selectedIntegrationName={match.params.domainName}
                          />
                        </Suspense>
                      </SettingsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SETTINGS_INTEGRATION_FIELD_MAPPING}
                    canUserAccess={
                      !isWorkflowTemplatesCompany &&
                      (hasFullUIAccess || isSuperAdmin)
                    }
                    render={({ match }) => (
                      <SettingsWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <FieldMappingConfigPage
                            serviceName={match.params.domainName}
                          />
                        </Suspense>
                      </SettingsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SETTINGS_AVAILABLE_FIELD_MAPPING}
                    canUserAccess={!isWorkflowTemplatesCompany && isSuperAdmin}
                    render={({ match }) => (
                      <SettingsWrapperPage session={session}>
                        <FieldMappingReferencePage
                          serviceName={match.params.domainName}
                        />
                      </SettingsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SETTINGS_DASHBOARD_CARDS}
                    canUserAccess={
                      !isWorkflowTemplatesCompany &&
                      isAdminPlus &&
                      hasPremiumAccess
                    }
                    render={({ location }) => (
                      <SettingsWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <DashboardCards
                            companyId={session.currentUser.companyId}
                          />
                        </Suspense>
                      </SettingsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.SETTINGS_WEBHOOK_EVENTS}
                    canUserAccess={
                      !isWorkflowTemplatesCompany &&
                      (hasFullUIAccess || isSuperAdmin)
                    }
                    render={({ location }) => (
                      <SettingsWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <WebhookEventsPage />
                        </Suspense>
                      </SettingsWrapperPage>
                    )}
                  />
                  <Route
                    path='/settings'
                    render={() => <Redirect to={routes.SETTINGS_COMPANY} />}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.EMPLOYEE_PROFILE}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ match, location }) => (
                      <EmployeesWrapperPage session={session}>
                        <div className='mobile-padding-top-5'>
                          <Suspense fallback={<Loading />}>
                            <EmployeeProfilePage
                              id={match.params.id}
                              session={session}
                            />
                          </Suspense>
                        </div>
                      </EmployeesWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.EMPLOYEE_PROFILE_EDIT}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ match, location }) => (
                      <EmployeesWrapperPage session={session}>
                        <div className='mobile-padding-top-5'>
                          <Suspense fallback={<Loading />}>
                            <EmployeeProfilePage
                              id={match.params.id}
                              session={session}
                              editSectionProp='Basic Info'
                            />
                          </Suspense>
                        </div>
                      </EmployeesWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.EMPLOYEE_ORG_CHART}
                    canUserAccess={
                      !isWorkflowTemplatesCompany &&
                      hasPremiumAccess &&
                      hasFullUIAccess
                    }
                    render={({ location }) => (
                      <EmployeesWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <EmployeeOrgChartPage />
                        </Suspense>
                      </EmployeesWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.EMPLOYEE_NEW}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ location }) => (
                      <EmployeesWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <EmployeeAddPage
                            currentUser={session.currentUser}
                            history={history}
                          />
                        </Suspense>
                      </EmployeesWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.EMPLOYEES_IMPORT}
                    canUserAccess={
                      !isWorkflowTemplatesCompany &&
                      hasPremiumAccess &&
                      hasFullUIAccess
                    }
                    render={({ match, location }) => (
                      <EmployeesWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <EmployeeCsvImportPage
                            uploadId={match.params.uploadId}
                            currentUser={session.currentUser}
                          />
                        </Suspense>
                      </EmployeesWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.EMPLOYEES_IMPORT_VALIDATE}
                    canUserAccess={
                      !isWorkflowTemplatesCompany &&
                      hasPremiumAccess &&
                      hasFullUIAccess
                    }
                    render={({ match, location }) => (
                      <EmployeesWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <EmployeeCsvImportPage
                            uploadId={match.params.uploadId}
                            currentUser={session.currentUser}
                            startingStep={steps.checkEmployees}
                          />
                        </Suspense>
                      </EmployeesWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.EMPLOYEES_DATA_MANAGEMENT}
                    canUserAccess={hasPremiumAccess && hasFullUIAccess}
                    render={({ location }) => (
                      <EmployeesWrapperPage session={session} hideNav>
                        <Suspense fallback={<Loading />}>
                          <EmployeeDataManagementFieldsPage session={session} />
                        </Suspense>
                      </EmployeesWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.EMPLOYEES_DATA_SYNC}
                    canUserAccess={
                      !isWorkflowTemplatesCompany &&
                      hasPremiumAccess &&
                      hasFullUIAccess
                    }
                    render={({ location }) => (
                      <EmployeesWrapperPage session={session} hideNav>
                        <Suspense fallback={<Loading />}>
                          <EmployeeDataManagementDataSyncPage
                            session={session}
                          />
                        </Suspense>
                      </EmployeesWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path='/employees*'
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ location }) => (
                      <EmployeesWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <EmployeeListPage session={session} />
                        </Suspense>
                      </EmployeesWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOWS_ALL}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ location }) => (
                      <WorkflowsWrapperPage id='workflow-page'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowOverviewContainer
                            location={location}
                            session={session}
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_INTEGRATION_SETTINGS}
                    canUserAccess={
                      !isWorkflowTemplatesCompany &&
                      hasPremiumAccess &&
                      hasFullUIAccess
                    }
                    render={({ location }) => (
                      <WorkflowsWrapperPage id='workflow-page'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowIntegrationSettingsPage session={session} />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_SINGLE_INTEGRATION_SETTINGS}
                    canUserAccess={
                      !isWorkflowTemplatesCompany &&
                      hasPremiumAccess &&
                      hasFullUIAccess
                    }
                    render={({ match }) => (
                      <WorkflowsWrapperPage id='workflow-page'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowIntegrationSettingsPage
                            session={session}
                            selectedIntegrationName={match.params.domainName}
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ match, location }) => (
                      <WorkflowsWrapperPage id='workflow-employees'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowOverviewContainer
                            workflowId={match.params.workflowId}
                            selectedTab={EMPLOYEE_TAB}
                            location={location}
                            session={session}
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_EMPLOYEE}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ match, location }) => (
                      <WorkflowsWrapperPage id='workflow-employees'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowOverviewContainer
                            workflowId={match.params.workflowId}
                            selectedTab={EMPLOYEE_TAB}
                            location={location}
                            session={session}
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_PROGRESS}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ match, location }) => (
                      <WorkflowsWrapperPage id='workflow-progress'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowOverviewContainer
                            workflowId={match.params.workflowId}
                            selectedTab={SPREADSHEET_TAB}
                            location={location}
                            session={session}
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_TASK_ANALYSIS}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ match, location }) => (
                      <WorkflowsWrapperPage id='workflow-task-analysis'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowOverviewContainer
                            workflowId={match.params.workflowId}
                            selectedTab={TASK_ANALYSIS_TAB}
                            location={location}
                            session={session}
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_NEEDS_ATTENTION}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ match, location }) => (
                      <WorkflowsWrapperPage id='workflow-needs-attention'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowOverviewContainer
                            workflowId={match.params.workflowId}
                            selectedTab={NEEDS_ATTENTION_TAB}
                            subSelectedTab={ISSUES_TAB}
                            location={location}
                            session={session}
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={`${routes.WORKFLOW_NEEDS_ATTENTION}/${OVERDUE_TAB}`}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ match, location }) => (
                      <WorkflowsWrapperPage id='workflow-needs-attention'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowOverviewContainer
                            workflowId={match.params.workflowId}
                            selectedTab={NEEDS_ATTENTION_TAB}
                            subSelectedTab={OVERDUE_TAB}
                            location={location}
                            session={session}
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={`${routes.WORKFLOW_NEEDS_ATTENTION}/${DUE_SOON_TAB}`}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ match, location }) => (
                      <WorkflowsWrapperPage id='workflow-needs-attention'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowOverviewContainer
                            workflowId={match.params.workflowId}
                            selectedTab={NEEDS_ATTENTION_TAB}
                            subSelectedTab={DUE_SOON_TAB}
                            location={location}
                            session={session}
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_DASHBOARD}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ match, location }) => (
                      <WorkflowsWrapperPage id='workflow-dashboard-tab'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowOverviewContainer
                            workflowId={match.params.workflowId}
                            selectedTab={DASHBOARD_TAB}
                            location={location}
                            session={session}
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_CONFIGURE}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ match }) => (
                      <WorkflowsWrapperPage id='workflow-configuration'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowConfigPage
                            id={match.params.id}
                            session={session}
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_CONFIGURE_NEW_ACTION}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ match }) => (
                      <WorkflowsWrapperPage id='workflow-configuration'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowConfigPage
                            id={match.params.id}
                            session={session}
                            addNewAction
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_SELF_ENROLL}
                    canUserAccess={!isWorkflowTemplatesCompany}
                    render={({ match }) => (
                      <WorkflowsWrapperPage
                        id='workflow-employees'
                        layout='default'
                      >
                        <Suspense fallback={<Loading />}>
                          <WorkflowSelfEnrollPage
                            workflowId={match.params.workflowId}
                            session={session}
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_TASK_NEW}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ match, location }) => (
                      <WorkflowsWrapperPage id='workflow-configuration'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowConfigPage
                            id={match.params.id}
                            session={session}
                            newTask
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_STAGE_NEW}
                    canUserAccess={
                      !isWorkflowTemplatesCompany && hasFullUIAccess
                    }
                    render={({ match, location }) => (
                      <WorkflowsWrapperPage id='workflow-configuration'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowConfigPage
                            id={match.params.id}
                            newWorkflowStageOrderIndex={parseInt(
                              match.params.orderIndex,
                            )}
                            session={session}
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  {/* Beginning of pages visible only to Sora Workflow Templates company */}
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_TEMPLATES_MANAGEMENT}
                    canUserAccess={isWorkflowTemplatesCompany && isSuperAdmin}
                    render={({ match, location }) => (
                      <WorkflowsWrapperPage id='workflow-templates-management'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowTemplatesListPage />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_TEMPLATE_CONFIGURE}
                    canUserAccess={isWorkflowTemplatesCompany && isSuperAdmin}
                    render={({ match, location }) => (
                      <WorkflowsWrapperPage id='workflow-template-configuration'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowConfigPage
                            id={match.params.id}
                            session={session}
                            isTemplate
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_TEMPLATE_CONFIGURE_NEW_ACTION}
                    canUserAccess={isWorkflowTemplatesCompany && isSuperAdmin}
                    render={({ match, location }) => (
                      <WorkflowsWrapperPage id='workflow-template-configuration'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowConfigPage
                            id={match.params.id}
                            session={session}
                            addNewAction
                            isTemplate
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_TEMPLATE_CONFIGURE_NEW_WORKFLOW_STAGE}
                    canUserAccess={isWorkflowTemplatesCompany && isSuperAdmin}
                    render={({ match, location }) => (
                      <WorkflowsWrapperPage id='workflow-template-configuration'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowConfigPage
                            id={match.params.id}
                            session={session}
                            newWorkflowStageOrderIndex={parseInt(
                              match.params.orderIndex,
                            )}
                            isTemplate
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  {/* End of pages visible only to Sora Workflow Templates company */}
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_TEMPLATE_PREVIEW}
                    // Previews of templates are available in any company across
                    // the Sora app
                    canUserAccess={hasFullUIAccess}
                    render={({ match }) => (
                      <WorkflowsWrapperPage id='workflow-template-preview'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowConfigPreviewPage
                            id={match.params.id}
                            session={session}
                          />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.WORKFLOW_TEMPLATE_DISCOVERY}
                    // List of templates can be viewed by all companies
                    canUserAccess={hasFullUIAccess}
                    render={({ match }) => (
                      <WorkflowsWrapperPage id='workflow-template-discovery'>
                        <Suspense fallback={<Loading />}>
                          <WorkflowTemplateDiscoveryPage />
                        </Suspense>
                      </WorkflowsWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.NEW_HIRE_TASKS}
                    canUserAccess={!isWorkflowTemplatesCompany}
                    render={({ location }) => (
                      <NewHireTaskWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <NewHireTaskListPaginatedQuery
                            currentUser={session.currentUser}
                          />
                        </Suspense>
                      </NewHireTaskWrapperPage>
                    )}
                  />
                  {/* temporary redirects while the consolidated tasks page is still new */}
                  <Route
                    path='/tasks/my-tasks'
                    exact
                    render={() => <Redirect to={routes.NEW_HIRE_TASKS} />}
                  />
                  <Route
                    path='/tasks/open-tasks'
                    exact
                    render={() => <Redirect to={routes.NEW_HIRE_TASKS} />}
                  />
                  <Route
                    path='/tasks/closed-tasks'
                    exact
                    render={() => <Redirect to={routes.NEW_HIRE_TASKS} />}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.NEW_HIRE_TASK}
                    canUserAccess={!isWorkflowTemplatesCompany}
                    render={({ match, location }) => (
                      <NewHireTaskWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <NewHireTaskPage
                            id={match.params.id}
                            session={session}
                          />
                        </Suspense>
                      </NewHireTaskWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.NEW_HIRE_TASK_MARK_COMPLETE}
                    canUserAccess={!isWorkflowTemplatesCompany}
                    render={({ match, location }) => (
                      <NewHireTaskWrapperPage session={session}>
                        <Suspense fallback={<Loading />}>
                          <NewHireTaskMarkCompletePage id={match.params.id} />
                        </Suspense>
                      </NewHireTaskWrapperPage>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={routes.REPORTS}
                    canUserAccess={
                      !isWorkflowTemplatesCompany &&
                      hasPremiumAccess &&
                      hasFullUIAccess
                    }
                    render={({ location }) => (
                      <Page id='reports-page' category='Reports' layout='flush'>
                        <Suspense fallback={<Loading />}>
                          <ReportsPage currentUser={session.currentUser} />
                        </Suspense>
                      </Page>
                    )}
                  />

                  {/* catch-all 404 page */}
                  <Route render={() => <ErrorPage type={'404'} />} />
                </Switch>
              </>
            )}
          </Switch>
        </ErrorBoundary>
      </SessionContext.Provider>
    </>
  );
};

AppSession.propTypes = {
  session: object,
  refetch: func,
  loading: bool,
  confirmation: object,
  onConfirmationDismiss: func,
  modalOptions: object,
  onModalDismiss: func,
};

export default withSession(AppSession, true);
