import classNames from 'classnames';
import { any, bool, element, func, string } from 'prop-types';
import React from 'react';

import { DownArrowSvg } from 'sora-client/components/common/Svg';

const DropdownToggler = ({
  dropdownToggle,
  dropdownClickToggleRef,
  onClick,
  open,
  placeholder,
  selectedItem,
  className = 'padding-bottom-2 border-bottom',
  'data-testid': dataTestId,
}) => (
  <div
    className={classNames('dropdown-click-toggle no-select cursor-pointer', {
      active: open,
      'd-flex align-items-center': !dropdownToggle,
    })}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }}
    ref={dropdownClickToggleRef}
    data-testid={dataTestId}
  >
    {dropdownToggle ? (
      dropdownToggle
    ) : (
      <div
        className={classNames(
          'dropdown-select-button flex-grow-1 d-flex align-items-center',
          className,
        )}
      >
        <div
          className={classNames('flex-grow-1 text-truncate', {
            'text-muted': !selectedItem,
          })}
        >
          {selectedItem ? selectedItem : placeholder}
        </div>
        <div className='svg-parent-align-text margin-left-1'>
          <DownArrowSvg className='white-bg-svg' />
        </div>
      </div>
    )}
  </div>
);

DropdownToggler.propTypes = {
  dropdownToggle: element,
  dropdownClickToggleRef: any,
  onClick: func.isRequired,
  open: bool.isRequired,
  placeholder: string,
  selectedItem: any,
  className: string,
  'data-testid': string,
};

DropdownToggler.defaultProps = {
  placeholder: 'Select',
};

export default DropdownToggler;
