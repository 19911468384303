import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import BackButton from 'sora-client/components/common/BackButton';
import Flyout from 'sora-client/components/common/Flyout';
import Loading from 'sora-client/components/common/Loading';
import { WORKFLOWS_ALL } from 'sora-client/constants/routes';

import { WorkflowTemplateCard } from './WorkflowTemplateCard';
import { WorkflowTemplateDetails } from './WorkflowTemplateDetails';
import { WorkflowTemplateSideBar } from './WorkflowTemplateSideBar';

const GET_WORKFLOW_TEMPLATES = gql`
  query getPublishedWorkflowTemplates($tag: String) {
    publishedWorkflowTemplates(tag: $tag) {
      id
      name
      tags
      internalDescriptionShort
    }
  }
`;

export const WorkflowTemplateDiscoveryPage = () => {
  const [queryParams, setQueryParams] = useQueryParams({
    tag: StringParam,
    tid: StringParam,
  });
  const { data, loading } = useQuery(GET_WORKFLOW_TEMPLATES, {
    variables: {
      tag: queryParams.tag,
    },
  });
  if (loading) return <Loading />;

  const publishedWorkflowTemplates = data?.publishedWorkflowTemplates || [];

  return (
    <div className='width-100'>
      <h2 className='font-p22-mackinac margin-top-5 padding-x-5 mobile-padding-x-3 d-flex align-items-center'>
        <BackButton to={WORKFLOWS_ALL} tooltipText='Back to all workflows' />
        Workflow templates
      </h2>
      <div className='d-flex'>
        {queryParams.tid && (
          <Flyout
            onClickClose={() => {
              setQueryParams({
                tid: undefined,
              });
            }}
          >
            {() => (
              <WorkflowTemplateDetails selectedTemplateId={queryParams.tid} />
            )}
          </Flyout>
        )}
        <WorkflowTemplateSideBar />
        <div className='d-flex flex-wrap align-items-start overflow-auto'>
          {!publishedWorkflowTemplates.length && (
            <div>No published workflows found.</div>
          )}
          {publishedWorkflowTemplates?.map((template) => (
            <WorkflowTemplateCard key={template.id} template={template} />
          ))}
        </div>
      </div>
    </div>
  );
};
