import classNames from 'classnames';
import { omit } from 'lodash';
import {
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';
import React from 'react';
import { DelayInput } from 'react-delay-input';

const isTest = process.env.NODE_ENV === 'test';
const defaultTimeout = 100;

const FullWidthInput = (props) => (
  <div
    className={classNames('d-flex', props.outerClassName)}
    style={props.outerStyle}
  >
    {props.forbiddenChars?.length ? (
      <input
        {...omit(props, ['forbiddenChars'])}
        ref={props.inputRef}
        onChange={(e) => {
          const reg = new RegExp(`(${props.forbiddenChars.join('|')})`, 'g');
          e.target.value = (e.target.value || '').replace(reg, '');
          props.onChange(e);
        }}
        className={classNames('flex-grow-1', props.className)}
      />
    ) : (
      <DelayInput
        {...omit(props, 'outerClassName')}
        inputRef={props.inputRef}
        delayTimeout={isTest ? 0 : props.delayTimeout || defaultTimeout}
        className={classNames('flex-grow-1', props.className)}
      />
    )}
  </div>
);

FullWidthInput.propTypes = {
  autoFocus: bool,
  className: string,
  outerClassName: string,
  outerStyle: object,
  delayTimeout: number,
  disabled: bool,
  forbiddenChars: arrayOf(string),
  id: string,
  inputRef: oneOfType([object, func]),
  maxLength: oneOfType([string, number]),
  name: string,
  onChange: func,
  onFocus: func,
  onBlur: func,
  placeholder: string,
  type: string,
  value: string,
};

export default FullWidthInput;
