import { omit } from 'lodash';
import { number, object, string } from 'prop-types';
import React, { useRef, useState } from 'react';

/**
 * Replaces image AWS S3 url with imgix url and adds width & height as param
 */
const generateImageUrl = ({ originalUrl, width, height }) => {
  const env = process.env.REACT_APP_REAL_ENV;
  // ignore non s3 sources
  if (
    !['sora-dev-temp', `honu-${env}-images`].some((source) =>
      originalUrl.includes(source),
    ) ||
    originalUrl.includes('imgix')
  ) {
    return originalUrl;
  }

  const url = `https://sora-${
    process.env.IMGIX_DOMAIN_SUFFIX || env
  }.imgix.net${
    originalUrl.split('amazonaws.com')[1]
  }?fit=crop&q=80&auto=format${width ? `&w=${width}` : ''}${
    height ? `&h=${height}` : ''
  }`;

  return url;
};

const Img = (props) => {
  const { src, backupImgSrc, width, height, alt } = props;

  const [imageBackupSet, setImageBackupSet] = useState(!src);
  const imgRef = useRef(/** @type {HTMLElement}*/ null);

  const generatedSrc = generateImageUrl({
    originalUrl: src || backupImgSrc,
    width: width * 2,
    height: height * 2,
  });

  return (
    <img
      {...omit(props, 'backupImgSrc', 'keepOriginalUrl')}
      src={generatedSrc}
      ref={imgRef}
      alt={alt || ''}
      onError={() => {
        if (!imageBackupSet && backupImgSrc) {
          imgRef.current.setAttribute('src', backupImgSrc);
          setImageBackupSet(true);
        }
      }}
    />
  );
};

Img.propTypes = {
  src: string,
  backupImgSrc: string,
  width: number,
  height: number,
  alt: string,
  className: string,
  style: object,
};

export default Img;
