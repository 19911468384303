import gql from 'graphql-tag';

export const GET_WORKFLOWS = gql`
  query getWorkflowsForHomePage($searchTextFilter: String) {
    workflows(searchTextFilter: $searchTextFilter) {
      count
      rows {
        id
        name
        active
        inProgressEmployees {
          count
          rows {
            id
            imageUrl
          }
        }
      }
    }
  }
`;
