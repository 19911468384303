/* eslint-disable react/display-name */
import { arrayOf, shape, string } from 'prop-types';
import React, { useContext } from 'react';

import Table from 'sora-client/components/common/Table';
import { ModalContext } from 'sora-client/contexts';

export const ReviewErrorsButton = ({ errorsFromImport }) => {
  const { showModal } = useContext(ModalContext);
  return (
    <span
      className='text-blue'
      onClick={() => {
        showModal({
          children: (
            <div
              className='bg-white rounded padding-10'
              style={{ minHeight: 300, width: 1000, maxHeight: 3000 }}
            >
              <Table
                autoresizer
                data={errorsFromImport}
                columns={[
                  {
                    key: 'email',
                    title: 'Personal email',
                    width: 300,
                  },
                  {
                    key: 'error',
                    title: 'Error',
                    width: 500,
                  },
                ]}
              />
            </div>
          ),
        });
      }}
    >
      here
    </span>
  );
};

ReviewErrorsButton.propTypes = {
  errorsFromImport: arrayOf(shape({ email: string, error: string })),
};
