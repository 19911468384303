import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const HTTP_INTEGRATION_ID = 35;

export const HTTP_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  categories: [CATEGORIES.OTHER],
  id: HTTP_INTEGRATION_ID,
  name: 'http',
  label: 'HTTP',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/http.png`,
  configSchema: {
    webhookKey: {
      type: 'string',
      title: 'Webhook key',
      autogenerate: true,
      uiSchema: {
        'ui:widget': 'ReadOnlyValueToCopy',
      },
    },
  },
  configured: () => true,
};
