import { node, string } from 'prop-types';
import React from 'react';

export const DataField = ({ label, value = null, valueRendered = null }) => {
  return (
    <div className='margin-bottom-4'>
      <h3 className='font-weight-500 text-uppercase text-midnight-navy-75 text-small margin-bottom-1 margin-top-0 text-uppercase'>
        {label}
      </h3>
      {valueRendered || value || <span className='text-muted'>None</span>}
    </div>
  );
};

DataField.propTypes = {
  label: string.isRequired,
  value: string,
  valueRendered: node,
};
