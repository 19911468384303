import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { arrayOf, shape, string } from 'prop-types';
import React, { useContext } from 'react';

import WorkflowSelfEnrollCard from 'sora-client/components/WorkflowSelfEnrollCard';
import { SessionContext } from 'sora-client/contexts';

const GET_EMPLOYEE_WORKFLOWS = gql`
  query getEmployeeWorkflows($employeeId: ID!, $timelineIds: [ID!]) {
    employeeWorkflows(employeeId: $employeeId, timelineIds: $timelineIds) {
      rows {
        id
        createdAt
        completeDate
        status
        timeline {
          id
        }
      }
    }
  }
`;

const SelfServeWorkflows = (props) => {
  const { workflows } = props;
  const session = useContext(SessionContext);

  const { data } = useQuery(GET_EMPLOYEE_WORKFLOWS, {
    variables: {
      employeeId: session.currentUser.employeeId,
      timelineIds: workflows.map((w) => w.id),
    },
  });
  const employeeWorkflows = data?.employeeWorkflows?.rows || [];

  return (
    <div>
      <h4>Actions</h4>
      {workflows.map((workflow) => {
        const existingEmployeeWorkflows = employeeWorkflows.filter((ew) => {
          return ew.timeline.id === workflow.id;
        });

        return (
          <WorkflowSelfEnrollCard
            existingEmployeeWorkflows={existingEmployeeWorkflows}
            key={workflow.id}
            header={workflow.selfEnrollmentHeader || workflow.name}
            description={workflow.selfEnrollmentDescription}
            workflowName={workflow.name}
            allowMultipleEnrollmentsPerEmployee={
              workflow.allowMultipleEnrollmentsPerEmployee
            }
            workflowId={workflow.id}
          />
        );
      })}
    </div>
  );
};

SelfServeWorkflows.propTypes = {
  workflows: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      selfEnrollmentDescription: string,
      selfEnrollmentHeader: string,
    }),
  ),
};

export default SelfServeWorkflows;
