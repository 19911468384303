import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { string } from 'prop-types';
import React, { useContext } from 'react';

import TalkToSalesModal from 'sora-client/components/TalkToSalesModal';
import { WorkflowStageAssociationModal } from 'sora-client/components/WorkflowStageAssociationModal/WorkflowStageAssociationModal';
import Button from 'sora-client/components/common/Button';
import { LinkButton } from 'sora-client/components/common/LinkButton';
import Loading from 'sora-client/components/common/Loading';
import { ModalContext, SessionContext } from 'sora-client/contexts';
import soraLogo from 'sora-client/static/images/sora.png';

import { MarkdownTemplateDescription } from './MarkdownTemplateDescription';
import { WorkflowTagsList } from './WorkflowTagsList';

const GET_TEMPLATE_DETAILS = gql`
  query getTemplateDetails($id: ID!) {
    workflow(id: $id) {
      id
      name
      tags
      internalDescriptionLong
    }
  }
`;

export const WorkflowTemplateDetails = ({ selectedTemplateId }) => {
  const { showModal } = useContext(ModalContext);
  const { currentUser } = useContext(SessionContext);

  const { data, loading } = useQuery(GET_TEMPLATE_DETAILS, {
    variables: { id: selectedTemplateId },
  });

  if (loading) return <Loading />;

  const selectedTemplate = data?.workflow;

  return (
    <div className='padding-top-5 d-flex flex-direction-column padding-x-5'>
      <strong style={{ fontSize: 25 }}>{selectedTemplate?.name}</strong>
      {/* temporarily put Sora logo while only Sora users can make templated */}
      <div className='margin-top-2 text-large d-flex align-items-center'>
        By:
        <img
          src={soraLogo}
          width={20}
          height={20}
          alt='Sora'
          className='margin-x-2'
        />
        Sora
      </div>
      {selectedTemplate?.internalDescriptionLong && (
        <div className='margin-top-2 text-xlarge'>
          <MarkdownTemplateDescription
            description={selectedTemplate.internalDescriptionLong}
          />
        </div>
      )}
      {selectedTemplate?.tags && (
        <div className='d-flex flex-direction-column margin-top-2 margin-bottom-3 text-xlarge'>
          <WorkflowTagsList tags={selectedTemplate.tags} />
        </div>
      )}
      <LinkButton
        label='See preview'
        size='large'
        full
        category='secondary'
        className='margin-bottom-3'
        to={`/workflow-templates/${selectedTemplate?.id}/preview`}
      />
      <Button
        label='Use template'
        size='large'
        category='primary'
        className='margin-top-1'
        full
        onClick={() => {
          showModal({
            children: currentUser.hasPremiumAccess ? (
              <WorkflowStageAssociationModal
                workflowId={selectedTemplate?.id}
              />
            ) : (
              <TalkToSalesModal />
            ),
          });
        }}
      />
    </div>
  );
};

WorkflowTemplateDetails.propTypes = {
  selectedTemplateId: string.isRequired,
};
