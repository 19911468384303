import classNames from 'classnames';
import { kebabCase, omit } from 'lodash';
import {
  bool,
  node,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React, { useContext } from 'react';

import { Link } from 'sora-client/components/common/Link';
import { BUTTON_SIZES, BUTTON_TYPES } from 'sora-client/constants/components';
import { AnalyticsContext } from 'sora-client/contexts';
import PreviewContext from 'sora-client/contexts/PreviewContext';

export const LinkButton = (props) => {
  const {
    href = null,
    to = null,
    category = 'secondary',
    label = '',
    labelHTML = null,
    type = 'button',
    size = 'default',
    full = false,
    disabled = false,
    className,
    analyticsEventName: analyticsEventNameExplicit = null,
    analyticsEventData = {},
  } = props;

  const { track } = useContext(AnalyticsContext);
  const { previewingAsEmployee } = useContext(PreviewContext);
  const primaryType = category === 'primary';
  const secondaryType = category === 'secondary';
  const warningType = category === 'warning';
  const destructiveType = category === 'destructive';
  const infoType = category === 'info';
  const actionType = category === 'action';
  const actionSecondaryType = category === 'actionSecondary';
  const linkType = category === 'link';

  const analyticsEventName =
    analyticsEventNameExplicit || `Clicked ${label}` || null;

  // even though functionality will be disabled via the new
  // Sora Link component, this variable is still needed
  // for all disable mode styling of the LinkButton
  const isDisabled = disabled || previewingAsEmployee;

  return (
    <Link
      {...omit(props, [
        'label',
        'labelHTML',
        'size',
        'full',
        'className',
        'analyticsEventName',
        'analyticsEventData',
      ])}
      to={to || href}
      data-cy={kebabCase(label)}
      data-size={size}
      className={classNames(
        'link-button no-select align-items-center justify-content-center text font-family-inherit transition-background',
        {
          'padding-x-0': linkType,
          'padding-x-3': !linkType && ['small', 'default'].includes(size),
          'padding-x-5': !linkType && size === 'large',
          'padding-y-1 text-small rounded-large': size === 'small',
          'text-medium rounded-xlarge': size === 'default',
          'padding-y-3 text-large rounded-xlarge': size === 'large',
          'd-inline-flex': !full,
          'd-flex width-100': full,
          'cursor-pointer': !isDisabled,
          'hover-box-shadow-lower transition-box-shadow transition-duration-05':
            !isDisabled && !linkType,
          'cursor-default': isDisabled,
          'bg-high-neon-indigo text-paper-white border-none':
            primaryType && !isDisabled,
          'bg-high-neon-indigo-50 text-paper-white border-none':
            primaryType && isDisabled,
          'bg-white border-a': secondaryType && !isDisabled,
          'bg-white border-a text-muted fade-50': secondaryType && isDisabled,
          'bg-white border-a border-color-warning-red text-danger':
            warningType && !isDisabled,
          'bg-gray-2 fade-50 border-a text-danger': warningType && isDisabled,
          'bg-destructive border-none text-paper-white':
            destructiveType && !isDisabled,
          'bg-destructive-isDisabled border-none text-paper-white':
            destructiveType && isDisabled,
          'bg-sunset text-paper-white border-none': infoType && !isDisabled,
          'bg-sunset-50 text-paper-white border-none': infoType && isDisabled,
          'bg-green text-paper-white border-none': actionType && !isDisabled,
          'bg-green-50 text-paper-white border-none': actionType && isDisabled,
          'bg-white text-green border-a border-color-green':
            actionSecondaryType && !isDisabled,
          'bg-white text-green-50 border-a border-color-green-50':
            actionSecondaryType && isDisabled,
          'pointer-events-none': isDisabled || previewingAsEmployee,
          'bg-transparent link border-none': linkType,
        },
        className,
      )}
      type={type}
      onClick={(event) => {
        analyticsEventName && track(analyticsEventName, analyticsEventData);
      }}
    >
      <div
        className={classNames(
          'd-inline-flex align-items-center justify-content-center',
          { 'text-blue': secondaryType },
        )}
      >
        <div
          className={classNames(
            'd-inline-flex align-items-center justify-content-center',
          )}
        >
          {!!labelHTML && labelHTML}
          {!labelHTML && (
            <div className={classNames({ 'text-blue': secondaryType })}>
              {label}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

LinkButton.propTypes = {
  category: oneOf(BUTTON_TYPES),
  href: string,
  to: oneOfType([string, shape({ pathname: string.isRequired })]),
  label: string,
  labelHTML: node,
  size: oneOf(BUTTON_SIZES),
  type: oneOf(['submit', 'reset', 'button']),
  disabled: bool,
  full: bool,
  className: string,
  tabIndex: number,
  target: string,
  analyticsEventName: string,
  analyticsEventData: object,
};
