const pullFieldSubscriptionHandlers = {
  'onPullFieldCreated.getCompanyFieldsQuery': ({
    client,
    subscriptionData,
    query,
    variables,
  }) => {
    const { pullFieldCreated } = subscriptionData.data;
    const fieldId = pullFieldCreated.field.id;

    const fromCache = client.readQuery({
      query,
      variables,
    });

    client.writeQuery({
      query,
      variables,
      data: {
        ...fromCache,
        companyFields: fromCache.companyFields.map((field) => {
          const { id, pullSources } = field;
          return id === fieldId
            ? {
                ...field,
                pullSources: {
                  ...pullSources,
                  rows: [...pullSources.rows, pullFieldCreated],
                  count: pullSources.count + 1,
                },
              }
            : field;
        }),
      },
    });
  },
  'onPullFieldUpdated.getCompanyFieldsQuery': ({
    client,
    subscriptionData,
    query,
    variables,
  }) => {
    const { pullFieldUpdated } = subscriptionData.data;
    const fieldId = pullFieldUpdated.field.id;

    const fromCache = client.readQuery({
      query,
      variables,
    });

    client.writeQuery({
      query,
      variables,
      data: {
        ...fromCache,
        companyFields: fromCache.companyFields.map((field) => {
          const { id, pullSources } = field;
          return id === fieldId
            ? {
                ...field,
                pullSources: {
                  ...pullSources,
                  rows: pullSources.rows.map((pullSource) =>
                    pullSource.id === pullFieldUpdated.id
                      ? pullFieldUpdated
                      : pullSource,
                  ),
                },
              }
            : field;
        }),
      },
    });
  },
  'onPullFieldDeleted.getCompanyFieldsQuery': ({
    client,
    subscriptionData,
    query,
    variables,
  }) => {
    const { pullFieldDeleted: deletedPullFieldId } = subscriptionData.data;

    const fromCache = client.readQuery({
      query,
      variables,
    });

    client.writeQuery({
      query,
      variables,
      data: {
        ...fromCache,
        companyFields: fromCache.companyFields.map((field) => {
          const { pullSources } = field;

          return pullSources.rows[0]?.id === deletedPullFieldId
            ? {
                ...field,
                pullSources: {
                  ...pullSources,
                  rows: pullSources.rows.filter(
                    ({ id }) => id !== deletedPullFieldId,
                  ),
                  count: pullSources.count - 1,
                },
              }
            : field;
        }),
      },
    });
  },
};

export default pullFieldSubscriptionHandlers;
