import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { string } from 'prop-types';
import React, { useContext } from 'react';

import BoxCard from 'sora-client/components/common/BoxCard';
import { LinkButton } from 'sora-client/components/common/LinkButton';
import { EMPLOYEES_UPCOMING } from 'sora-client/constants/routes';
import SessionContext from 'sora-client/contexts/SessionContext';

import HomePageCard from '../HomePageCard';
import HomePageCardTitle from '../HomePageCardTitle';
import HomePageEmployeeList from '../HomePageEmployeeList';
import { GET_EMPLOYEES_STARTING_SOON } from './queries';

const EmployeesStartingSoonCard = ({ className }) => {
  const { currentUser } = useContext(SessionContext);
  const { data, loading } = useQuery(GET_EMPLOYEES_STARTING_SOON, {
    variables: {
      afterStartDate: moment()
        .startOf('day')
        .subtract(1, 'days')
        .startOf('day')
        .toDate(),
      beforeStartDate: moment().add(14, 'days').startOf('day').toDate(),
    },
  });

  if (loading) return null;

  const { rows = [], count = 0 } = data?.employees || {};
  // don't render unit if no new employees OR the only new employee is the current user
  if (
    !rows.length ||
    (rows.length === 1 && rows[0].id === currentUser.employeeId)
  ) {
    return null;
  }

  return (
    <HomePageCard className='new-employees-list-card'>
      <BoxCard className={className}>
        <div className='padding-x-5 padding-top-5 mobile-padding-3'>
          <HomePageCardTitle
            title={`${rows.length} ${
              count === 1 ? 'person' : 'people'
            } starting soon`}
          />
          <p className='text-muted margin-y-0'>
            Here are all the people starting in the next two weeks!
          </p>
          <LinkButton
            className='d-inline-block margin-top-4'
            to={EMPLOYEES_UPCOMING}
            label='See starting soon'
          />
        </div>
        <HomePageEmployeeList employees={rows} listCategory='upcoming' />
      </BoxCard>
    </HomePageCard>
  );
};

EmployeesStartingSoonCard.propTypes = {
  className: string,
};

export default EmployeesStartingSoonCard;
