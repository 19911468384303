const _ = require('lodash');

const splitEmails = (emailsString) => {
  if (!emailsString) return [];
  return _.split(emailsString || [], ',').map((e) => e.trim());
};

const isValidEmail = (email) => {
  const trimmedEmail = email ? String(email.trim()).toLowerCase() : '';

  // From https://stackoverflow.com/a/9204568
  // Performs a simple syntax check that essentially just checks
  // that the string is formatted like: anystring@anystring.anystring
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(trimmedEmail);
};

const isValidEmails = (value) => {
  return splitEmails(value).every(isValidEmail);
};

const fieldValidatorsMap = {
  number(value) {
    if (Number.isNaN(Number(value))) {
      return `${value} is not a valid number.`;
    }
    return true;
  },
  email(value) {
    if (!value) {
      return true;
    }

    const isEmail = isValidEmail(value);

    return isEmail || `${value} is not a valid email address.`;
  },
  relationship(value) {
    if (!value) {
      return true;
    }

    // Allow for employee ID
    if (!Number.isNaN(Number(value))) {
      return true;
    }

    // Allow for employee email address
    const isEmail = isValidEmail(value);

    return isEmail || `"${value}" is not a valid email address or employee ID.`;
  },
};

const validateRequired = (value, field) => {
  if (field.required && !value) {
    return `The value for ${field.name} should not be empty.`;
  }
  return true;
};

const validateEnumFieldValue = (value, field) => {
  if (
    value &&
    field.isEnumerated &&
    field.enumValues &&
    !field.allowAutomaticTagValues
  ) {
    const splitValues = value.split(',').map((val) => _.trim(val));

    const missingValues = [];
    splitValues.forEach((enumValue) => {
      if (!field.enumValues.includes(enumValue)) missingValues.push(enumValue);
    });

    if (!missingValues.length) return true;

    return `The value ${missingValues.join(', ')} for ${
      field.name
    } is not one of the existing multiple choice values.`;
  }
};

const validateRangeValue = (value, field) => {
  if (!field.isRanged) return true;

  const checkRange = (value) => {
    switch (field.type) {
      case 'number':
        value = Number(value);
        return (
          value <= Number(field.maxValue) && value >= Number(field.minValue)
        );
      case 'short_answer':
      case 'paragraph':
        return (
          value.length <= Number(field.maxValue) &&
          value.length >= Number(field.minValue)
        );
      case 'date':
        return value <= field.maxValue && value >= field.minValue;
      default:
        return true;
    }
  };
  const isInRange = checkRange(value);
  return (
    isInRange || `The value for ${field.name} is not in the required range.`
  );
};

const getFieldValueError = (value, field) => {
  const validateType = fieldValidatorsMap[field.type] || (() => null);

  const allValidators = [
    validateRequired,
    validateType,
    validateEnumFieldValue,
    validateRangeValue,
  ];

  for (let validate of allValidators) {
    const validationResult = validate(value, field);
    if (typeof validationResult === 'string') {
      return validationResult;
    }
  }

  return null;
};

module.exports = {
  isValidEmail,
  isValidEmails,
  splitEmails,
  getFieldValueError,
};
