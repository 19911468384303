import { useQuery } from '@apollo/react-hooks';
import { orderBy } from 'lodash';
import { string } from 'prop-types';
import React from 'react';

import { IntegrationConfigPageHeader } from 'sora-client/components/IntegrationConfigPageHeader';
import { IntegrationConfigTabNavigator } from 'sora-client/components/IntegrationConfigTabNavigator';
import DangerNoticeBoxWithIcon from 'sora-client/components/common/DangerNoticeBoxWithIcon';
import InnerPageContentContainer from 'sora-client/components/common/InnerPageContentContainer';
import Loading from 'sora-client/components/common/Loading';
import { TestEmployeePicker } from 'sora-client/components/pages/FieldMappingConfigPage/TestEmployeePicker/TestEmployeePicker';
import { SETTINGS_INTEGRATIONS as SETTINGS_INTEGRATIONS_ROUTE } from 'sora-client/constants/routes';
import { modelConstants } from 'sora-invariant/src/constants';
import { getIntegrationByName } from 'sora-invariant/src/integrations/constants';

import { GET_FIELD_MAPPING_CONFIGS } from './queries';

const { lever } = modelConstants.webhookEvents.domains;

export const FieldMappingReferencePage = ({ serviceName }) => {
  const { data, loading, error, refetch } = useQuery(
    GET_FIELD_MAPPING_CONFIGS,
    {
      variables: { serviceName },
    },
  );

  const integration = getIntegrationByName(serviceName);
  return (
    <InnerPageContentContainer>
      <div className='d-flex align-items-end'>
        <div>
          <IntegrationConfigPageHeader
            backButtonDestination={SETTINGS_INTEGRATIONS_ROUTE}
            name={`${integration.label} field reference`}
            logoUrl={integration.logoUrl}
          />
        </div>
        <div
          className={
            'padding-x-3 padding-y-2 rounded bg-sunrise-50 text-warning margin-bottom-2'
          }
        >
          Super-admin only
        </div>
      </div>
      <IntegrationConfigTabNavigator serviceName={serviceName} />

      {loading && <Loading />}
      {error && (
        <DangerNoticeBoxWithIcon>
          There was an error loading these fields. Please refresh or contact
          Sora support for assistance
        </DangerNoticeBoxWithIcon>
      )}

      {serviceName === lever && (
        <TestEmployeePicker
          instructions='Load custom fields from employee:'
          onSubmit={async ({ email, employeeId }) => {
            await refetch({ serviceName, email, employeeId });
          }}
          testLoading={loading}
        />
      )}

      {orderBy(data?.availableFieldMappingConfigs || [], 'label').map(
        (fieldMappingConfig) => (
          <div key={fieldMappingConfig.uniquePath} className='margin-bottom-5'>
            <h5 className='margin-bottom-1'>{fieldMappingConfig.label}</h5>
            {fieldMappingConfig.description && (
              <p className='text-muted margin-top-0 margin-bottom-1'>
                {fieldMappingConfig.description}
              </p>
            )}
            <div className='d-flex margin-top-2'>
              <div className='margin-right-4' style={{ minWidth: '450px' }}>
                <label className='text-uppercase text-small margin-right-2 d-block'>
                  Unique path
                </label>
                <code>{fieldMappingConfig.uniquePath}</code>
              </div>
              <div>
                <label className='text-uppercase text-small margin-right-2 d-block'>
                  JSONata usage
                </label>
                <code>{`$getServiceField('${fieldMappingConfig.uniquePath}')  // required`}</code>
                <br />
                <code>{`$queryServiceField('${fieldMappingConfig.uniquePath}')  // optional`}</code>
              </div>
            </div>
          </div>
        ),
      )}
    </InnerPageContentContainer>
  );
};

FieldMappingReferencePage.propTypes = {
  serviceName: string.isRequired,
};
