import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const GREENHOUSE_INTEGRATION_ID = 11;

export const GREENHOUSE_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: GREENHOUSE_INTEGRATION_ID,
  name: 'greenhouse',
  label: 'Greenhouse',
  categories: [CATEGORIES.ATS],
  description:
    'Greenhouse is a talent acquisition suite software provider that helps companies become great at hiring.',
  website: 'greenhouse.io',
  helpDocs: 'https://help.sora.co/resources/greenhouse',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/greenhouse.png`,
  availableUses: [
    AVAILABLE_USES_ENUMS.DATA_SYNC,
    AVAILABLE_USES_ENUMS.WORKFLOWS,
  ],
  configSchema: {
    harvestApiKey: {
      type: 'string',
      title: 'Harvest API key',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    webhookKey: {
      type: 'string',
      title: 'Secret key',
      autogenerate: true,
      uiSchema: {
        'ui:widget': 'ReadOnlyValueToCopy',
      },
    },
    accountBaseUrl: {
      type: 'string',
      title: 'Account base URL',
      nullable: true,
    },
  },
  configured: (c) => c.values && c.values.harvestApiKey,
  validation: {
    action: 'greenhouse/listJobStages',
  },
};
