import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const SUCCESSFACTORS_INTEGRATION_ID = 31;

export const SUCCESSFACTORS_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: SUCCESSFACTORS_INTEGRATION_ID,
  name: 'successfactors',
  label: 'SuccessFactors',
  description: 'World-leading provider of cloud human experience management',
  website: 'sap.com',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/successfactors.svg`,
  availableUses: [
    AVAILABLE_USES_ENUMS.WORKFLOWS,
    AVAILABLE_USES_ENUMS.DATA_SYNC,
  ],
  categories: [CATEGORIES.ATS, CATEGORIES.HRIS],
  configSchema: {
    username: {
      type: 'string',
      title: 'API user username',
      nullable: true,
    },
    password: {
      type: 'string',
      title: 'API user password',
      nullable: true,
    },
    companyId: {
      type: 'string',
      title: 'Company ID',
      nullable: true,
    },
    companyName: {
      type: 'string',
      title: 'Company name',
      nullable: true,
    },
    apiRoot: {
      type: 'string',
      title: 'API root url (no trailing slash)',
      nullable: true,
    },
    outboundEmployeeFieldMapping: {
      type: 'string',
      title:
        'Map outbound fields from Sora employees, to SuccessFactors employees',
      nullable: true,
    },
    webhookKey: {
      type: 'string',
      title: 'Webhook key',
      autogenerate: true,
      uiSchema: {
        'ui:widget': 'ReadOnlyValueToCopy',
      },
    },
    localEmployeeNumberCtrEmployees: {
      type: 'number',
      title: 'Local employee number counter (employees)',
      nullable: true,
    },
    localEmployeeNumberCtrContractors: {
      type: 'number',
      title: 'Local employee number counter (contractors)',
      nullable: true,
    },
    businessEmailTypeId: {
      type: 'number',
      title: 'Business email type ID',
      nullable: true,
    },
    personalEmailTypeId: {
      type: 'number',
      title: 'Personal email type ID',
      nullable: true,
    },
    otherEmailTypeId: {
      type: 'number',
      title: 'Other email type ID',
      nullable: true,
    },
    homePhoneTypeId: {
      type: 'number',
      title: 'Home phone type ID',
      nullable: true,
    },
    usPhoneCountryCodeId: {
      type: 'number',
      title: 'US phone country code ID',
      nullable: true,
    },
  },
  validation: { action: 'successfactors/listJobRequisitions' },
  configured: (c) =>
    c.values &&
    ['apiRoot', 'companyId', 'password', 'username'].every(
      (key) => !!c.values[key],
    ),
};
