import { object } from 'prop-types';
import React from 'react';

import EmployeeAvatar from 'sora-client/components/common/Avatar';

const NotMobileUserProfile = ({ session }) => {
  const switchBackToken = localStorage.getItem('switchBackToken');

  return (
    <div className='d-flex align-items-center justify-content-center'>
      {!!switchBackToken && (
        <div className='d-flex flex-grow-1 align-items-center text-truncate bg-sunrise margin-right-3 text-xsmall font-weight-500 rounded padding-1 text-uppercase'>
          Impersonating
        </div>
      )}
      <EmployeeAvatar
        imageSrc={session.currentUser.employee.imageUrl}
        widthHeight={42}
        customImgClassnames='bg-white'
        employeeName={session.currentUser.employee.name}
        employeeId={session.currentUser.employee.id}
      />
    </div>
  );
};

NotMobileUserProfile.propTypes = {
  session: object.isRequired,
};

export default NotMobileUserProfile;
