import sanitizeHtmlBase from 'sanitize-html';

export const sanitizeHtml = (html, options = {}) => {
  const { parser: parserOptions, ...restOptions } = options;
  /**
   * We are, for the most part, using the default options provided by the sanitize-html library.
   * See https://github.com/apostrophecms/sanitize-html#default-options for more details.
   */
  return sanitizeHtmlBase(html, {
    allowedAttributes: {
      ...sanitizeHtmlBase.defaults.allowedAttributes,
      '*': ['class', 'style', 'data-*'],
    },
    allowedTags: [...sanitizeHtmlBase.defaults.allowedTags, 'img'],
    parser: {
      lowerCaseAttributeNames: false,
      ...parserOptions,
    },
    ...restOptions,
  });
};
