import React from 'react';

import HomePageCardTitle from 'sora-client/components/HomePage/HomePageCardTitle';
import BoxCard from 'sora-client/components/common/BoxCard';
import { LinkButton } from 'sora-client/components/common/LinkButton';
import { WORKFLOW_TEMPLATE_DISCOVERY } from 'sora-client/constants/routes';

import HomePageCard from './HomePageCard';

export const WorkflowTemplatesDiscoveryCard = () => {
  return (
    <HomePageCard className='workflow-template-dashboard-card'>
      <BoxCard style={{ minHeight: 150 }}>
        <div className='padding-5 mobile-padding-3'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='flex-grow-1'>
              <HomePageCardTitle title='Workflow templates' />
              <div className='text-muted'>
                Check out our library of available templates to get new
                workflows up and running faster than ever.
              </div>
            </div>
            <LinkButton
              to={WORKFLOW_TEMPLATE_DISCOVERY}
              label='View template library'
              category='action'
              className='margin-right-2 margin-left-4 flex-shrink-0'
            />
          </div>
        </div>
      </BoxCard>
    </HomePageCard>
  );
};
