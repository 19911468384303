import classNames from 'classnames';
import { bool, func, shape, string } from 'prop-types';
import React from 'react';

import BoxCard from 'sora-client/components/common/BoxCard';

import Button from '../Button';
import { DangerousDashboardCardTemplate } from './DangerousDashboardCardTemplate';

export const DashboardCardDisplay = ({
  dashboardCard,
  className,
  isCardPreview = false,
  onDismiss,
}) => {
  const { title, body, id, dismissable } = dashboardCard;

  // isCardPreview is for when the user is previewing the individual card
  // in the middle of editing. In which case, the "Dismiss" button
  // should not show up at all
  const showButton = dismissable && !isCardPreview && onDismiss;
  return (
    <BoxCard
      className={classNames('padding-5', className)}
      style={{ boxSizing: 'border-box' }}
    >
      <DangerousDashboardCardTemplate
        title={title}
        body={body}
        isPreview={isCardPreview}
      />
      {showButton && (
        <Button
          label='Dismiss'
          category='secondary'
          className='margin-top-3'
          onClick={() => onDismiss(id)}
        />
      )}
    </BoxCard>
  );
};

DashboardCardDisplay.propTypes = {
  dashboardCard: shape({
    title: string.isRequired,
    body: string.isRequired,
    dismissable: bool.isRequired,
    id: string.isRequired,
  }),
  isCardPreview: bool,
  className: string,
  onDismiss: func,
};

DashboardCardDisplay.defaultProps = {
  className: '',
};
