import { arrayOf, bool, func } from 'prop-types';
import React from 'react';

import Button from 'sora-client/components/common/Button';
import InputValidation from 'sora-client/components/common/InputValidation';

export const ReplayButton = ({ loading, onRunReplay, validators }) => {
  return (
    <div className='margin-top-5 position-sticky position-bottom bg-white border-top padding-y-3'>
      <InputValidation validators={validators}>
        <Button
          full
          disabled={loading}
          category='action'
          onClick={onRunReplay}
          loading={loading}
          label='Replay event'
        />
      </InputValidation>
    </div>
  );
};

ReplayButton.propTypes = {
  loading: bool.isRequired,
  onRunReplay: func.isRequired,
  validators: arrayOf(func).isRequired,
};
