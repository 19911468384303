import { modelConstants } from 'sora-invariant/src/constants';

const workflowTriggerTypes = modelConstants.timeline.triggerTypes;
const { ashby, greenhouse, lever, adp, adprun } =
  modelConstants.webhookEvents.domains;
const {
  ashby: ashbyEvents,
  greenhouse: greenhouseEvents,
  lever: leverEvents,
  adp: adpEvents,
  adprun: adpRunEvents,
} = modelConstants.webhookEvents.events;

// We don't build this object automatically from modelConstants so that we can
// implement the backend support for new trigger events before exposing them in
// the UI, which adding them to this object will do
export const AVAILABLE_WEBHOOK_EVENTS = {
  [ashby]: [
    {
      name: ashbyEvents.candidateStageChange.eventName,
      isWorkflowTrigger: true,
      type: workflowTriggerTypes.native,
      filters: ashbyEvents.candidateStageChange.filterKeys,
    },
  ],
  // Lever not yet available because we have to do some sorcery to properly
  // pull custom fields (slated for ~Feb 2023)
  [lever]: [
    {
      name: leverEvents.candidateHired.eventName,
      isWorkflowTrigger: true,
      type: workflowTriggerTypes.native,
    },
    {
      name: leverEvents.candidateStageChange.eventName,
      isWorkflowTrigger: true,
      type: workflowTriggerTypes.native,
      filters: leverEvents.candidateStageChange.filterKeys,
    },
  ],
  [greenhouse]: [
    {
      name: greenhouseEvents.candidateHired.eventName,
      isWorkflowTrigger: true,
      type: workflowTriggerTypes.native,
    },
    {
      name: greenhouseEvents.offerCreated.eventName,
      isWorkflowTrigger: true,
      type: workflowTriggerTypes.native,
    },
    {
      name: greenhouseEvents.candidateStageChange.eventName,
      isWorkflowTrigger: true,
      type: workflowTriggerTypes.native,
    },
  ],
  [adp]: [
    {
      name: adpEvents.subscriptionCreated.eventName,
      isWorkflowTrigger: false,
    },
    {
      name: adpEvents.subscriptionCanceled.eventName,
      isWorkflowTrigger: false,
    },
  ],
  [adprun]: [
    {
      name: adpRunEvents.subscriptionCreated.eventName,
      isWorkflowTrigger: false,
    },
    {
      name: adpEvents.subscriptionCanceled.eventName,
      isWorkflowTrigger: false,
    },
  ],
};
