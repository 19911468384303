import { useQuery } from 'react-apollo-hooks';

import { getForwardError, isLoading } from 'sora-client/helpers';
import { handleResponseError } from 'sora-client/helpers/handleError';

/**
 *
 * @param {*} query
 * @param {*} [variables]
 * @param {*} [param2]
 */
function useQueryWrapper(
  query,
  variables,
  { handleError = true, showError = true, ...options } = {},
) {
  const { data, error, loading, refetch, fetchMore } = useQuery(query, {
    variables,
    ...options,
  });

  const forwardError = getForwardError(error, data);
  if (handleError && forwardError && showError) {
    handleResponseError(error);
  }

  return {
    data,
    loading: isLoading(data, loading, error),
    loadingRaw: loading,
    refetch,
    fetchMore,
    error: forwardError,
  };
}

export default useQueryWrapper;
