import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  COMMON_OAUTH_CONFIG_PROPERTIES,
  DEFAULT_INTEGRATION,
  OAUTH_REDIRECT_URI,
} from '../../common';

export const LEVER_INTEGRATION_ID = 12;

export const LEVER_AUTH_MODES = {
  OAUTH: 'oauth',
  API_KEY: 'apiKey',
};

export const LEVER_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: LEVER_INTEGRATION_ID,
  name: 'lever',
  label: 'Lever',
  categories: [CATEGORIES.ATS],
  description:
    'The modern, collaborative recruiting platform that powers a more human approach to hiring.',
  website: 'https://www.lever.co',
  helpDocs: 'https://help.sora.co/resources/lever',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/lever.png`,
  availableUses: [
    AVAILABLE_USES_ENUMS.DATA_SYNC,
    AVAILABLE_USES_ENUMS.WORKFLOWS,
  ],
  authFlow: {
    type: 'Oauth2',
    authorizeEndpoint: `${process.env.LEVER_AUTH_BASE_URL}/authorize`,
    redirectUri: OAUTH_REDIRECT_URI,
    clientId: process.env.LEVER_PARTNER_CLIENT_ID,
    scope:
      'offline_access opportunities:read:admin offers:read:admin stages:read:admin webhooks:write:admin forms:read:admin referrals:read:admin users:read:admin',
    // Additional required params, see: https://hire.lever.co/developer/documentation#authentication
    authorizeAddons: {
      // The unique identifier of the target API to access
      audience: `${process.env.LEVER_API_BASE_URL}/`,
      // Specify consent to require user consent
      prompt: 'consent',
    },
    configToClear: {
      accessToken: null,
      refreshToken: null,
      accessTokenExpirationDate: null,
    },
  },
  configSchema: {
    apiKey: {
      type: 'string',
      title: 'API key',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    webhookKey: {
      type: 'string',
      title: 'Webhook key',
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    authMode: {
      type: 'string',
      title: 'Preferred authentication mode (oauth or apiKey)',
      default: LEVER_AUTH_MODES.API_KEY, // To support existing configurations
    },
    ...COMMON_OAUTH_CONFIG_PROPERTIES,
    refreshToken: {
      type: 'string',
      title: 'API Refresh token',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Hidden',
        'ui:options': {
          label: false,
        },
      },
    },
  },
  configured: (c) => {
    if (!c.values) return false;

    if (c.values.authMode === LEVER_AUTH_MODES.OAUTH) {
      return !!c.values.refreshToken && !!c.values.accessToken;
    }

    if (
      /* Default to API_KEY approach if no authentication mode is set.
      Before the introduction of OAuth support, using an API Key was the only way
      to authenticate to Lever, so there was no "authMode" configuration parameter.
      To ensure backward compatibility with previously configured integrations,
      it is assumed that integrations without "authMode" parameter use API_KEY mode. */
      !c.values.authMode ||
      c.values.authMode === LEVER_AUTH_MODES.API_KEY
    ) {
      return !!c.values.apiKey;
    }

    return false;
  },
  validation: { action: 'lever/listStages' },
};
