import classNames from 'classnames';
import { string } from 'prop-types';
import React from 'react';

const HomePageCardTitle = (props) => {
  const { className, title } = props;
  return (
    <h2
      className={classNames(
        'margin-top-0 margin-bottom-2 font-p22-mackinac font-weight-400',
        className,
      )}
    >
      {title}
    </h2>
  );
};

HomePageCardTitle.propTypes = {
  className: string,
  title: string.isRequired,
};

export default HomePageCardTitle;
