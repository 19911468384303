import { offsetTimeText, pluralize } from '.';
import arrayToSentence from 'array-to-sentence';
import { isEmpty, isEqual } from 'lodash';

import { DAYS_OF_WEEK_TEXT } from 'sora-client/constants/tasks';

/**
 * Returns a simplified version of what's returned from the getTriggerDateCopy helper
 * (does not include the specific days of the week or recurrence count).
 * Used for grouping tasks together by their trigger workflow stage.
 *
 * Examples: "On start date" or "3 days after added to workflow"
 *
 * @param {object} task
 * @returns {string}
 */
const triggerDateGroupingCopy = (task) => {
  const { triggerOffset, triggerWorkflowStage, triggerOffsetUnit } = task;

  if (!triggerWorkflowStage) return 'No trigger';

  const amountText = triggerOffset
    ? `${Math.abs(triggerOffset)} ${pluralize(
        triggerOffsetUnit,
        triggerOffset,
      )} `
    : '';
  const offsetText = offsetTimeText(triggerOffset);

  return `${amountText}${offsetText} <strong>${triggerWorkflowStage.name.toLowerCase()}</strong>`;
};

const getTriggerDateCopy = ({ task, recurrenceCount = null }) => {
  let recurringPrefix = '';

  if (task.isRecurring && recurrenceCount) {
    const numRecurrenceUnits = recurrenceCount * task.recurrenceInterval;

    recurringPrefix = `${numRecurrenceUnits} ${pluralize(
      task.recurrenceUnit,
      numRecurrenceUnits,
    )} after `;
  }

  if (task.triggerTask) {
    const prefix = recurringPrefix ? `${recurringPrefix} ` : `After `;
    return `${prefix} <strong>${task.triggerTask.name.toLowerCase()}</strong> is completed`;
  }

  if (task.triggerWorkflowStage) {
    const offset = task.triggerOffset;
    const amountText = !offset
      ? ''
      : `${Math.abs(offset)} ${pluralize(task.triggerOffsetUnit, offset)} `;
    const triggerTypeText = task.triggerWorkflowStage.name;
    // Note that if triggerValidDays is present and triggerOffset is 0, then the
    // trigger date copy will be in a completely different format, e.g. instead of
    // saying "1 month after start date" or "2 weeks before start date on Wed",
    // it will say "First Wed after start date".
    let specificDayText = '';
    if (!isEmpty(task.triggerValidDays)) {
      const prefix = recurringPrefix ? `${recurringPrefix} f` : 'F';

      if (task.triggerValidDays.length === 7) {
        if (offset === 0) {
          return `${prefix}irst day of the week after <strong>${triggerTypeText.toLowerCase()}</strong>`;
        } else {
          specificDayText = ' on any day of the week';
        }
      } else if (isEqual(task.triggerValidDays, [1, 2, 3, 4, 5])) {
        if (offset === 0) {
          return `${prefix}irst weekday after <strong>${triggerTypeText.toLowerCase()}</strong>`;
        } else {
          specificDayText = ' on any weekday';
        }
      } else {
        const days = task.triggerValidDays.map(
          (dayNumber) => DAYS_OF_WEEK_TEXT[dayNumber]['short'],
        );
        const daysSentence = arrayToSentence(days, {
          lastSeparator: ' or ',
        });
        if (offset === 0) {
          return `${prefix}irst ${daysSentence} after <strong>${triggerTypeText.toLowerCase()}</strong>`;
        } else {
          specificDayText = ` on ${daysSentence}`;
        }
      }
    }

    const offsetText = offsetTimeText(offset);
    const offsetAndRecurringText =
      recurringPrefix && offsetText === 'on'
        ? recurringPrefix
        : `${offsetText} ${recurringPrefix}`;

    return `${amountText}${offsetAndRecurringText}<strong>${triggerTypeText.toLowerCase()}</strong>${specificDayText}`;
  }

  // This should only be returned for draft tasks that don't have a trigger set yet
  return `No trigger`;
};

export { triggerDateGroupingCopy, getTriggerDateCopy };
