import React from 'react';
import Responsive from 'react-responsive';

const sizesFn = (width) => {
  if (width > 1300) return 'lg';
  if (width > 1100) return 'md';
  if (width > 900) return 'sm';

  return 'xs';
};

const LargeScreen = (props) => <Responsive {...props} minWidth={1300} />;
const NormalScreen = (props) => <Responsive {...props} minWidth={1100} />;
const NotNormalScreen = (props) => <Responsive {...props} maxWidth={1099} />;
const SmallScreen = (props) => (
  <Responsive {...props} maxWidth={1099} minWidth={900} />
);
const ExtraSmallScreen = (props) => <Responsive {...props} maxWidth={899} />;
const NotExtraSmallScreen = (props) => <Responsive {...props} minWidth={900} />;
const NotMobile = (props) => <Responsive {...props} minWidth={700} />;
const Mobile = (props) => <Responsive {...props} maxWidth={699} />;

export {
  LargeScreen,
  NormalScreen,
  NotNormalScreen,
  SmallScreen,
  ExtraSmallScreen,
  NotExtraSmallScreen,
  NotMobile,
  Mobile,
  sizesFn,
};
