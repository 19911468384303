import { noop } from 'lodash';
import moment from 'moment';
import { arrayOf, bool, shape, string } from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'sora-client/components/common/Button';
import DangerNoticeBoxWithIcon from 'sora-client/components/common/DangerNoticeBoxWithIcon';
import NoticeBox from 'sora-client/components/common/NoticeBox';
import { CheckSvg } from 'sora-client/components/common/Svg';
import Tooltip from 'sora-client/components/common/Tooltip';
import { useSelfEnroll } from 'sora-client/components/common/hooks/useSelfEnroll';
import { NEW_HIRE_TASKS } from 'sora-client/constants/routes';
import { formatError } from 'sora-client/helpers';
import { modelConstants } from 'sora-invariant/src/constants';

const { TRIGGERED, COMPLETED } = modelConstants.employeeWorkflow.statuses;

const WorkflowSelfEnrollCard = ({
  existingEmployeeWorkflows = [],
  description,
  header,
  readOnly = false,
  workflowId,
  workflowName,
  allowMultipleEnrollmentsPerEmployee,
}) => {
  const history = useHistory();
  const { enrollInWorkflow, enrollError, enrolledWorkflow, isLoading } =
    useSelfEnroll({
      workflowId,
    });

  let anyEw;
  let activeEw;
  let completedEw;

  for (const ew of existingEmployeeWorkflows) {
    anyEw = ew;

    if (ew.status === TRIGGERED) {
      activeEw = ew;
    } else if (ew.status === COMPLETED) {
      completedEw = ew;
    }
  }

  let showCompletedText;
  let showViewTasksButton;
  let showSelfEnrollButton;

  if (allowMultipleEnrollmentsPerEmployee) {
    // We don't want to show completed text if multiple enrollments are allowed because we should instead prompt the user
    // to re-enroll.
    showCompletedText = false;
    // Only show the "view tasks" button if there is an active enrollment (otherwise we show the self-enroll button)
    showViewTasksButton = !!activeEw;
    // Only show the self enroll button if there isn't already an active enrollment
    showSelfEnrollButton = !activeEw;
  } else {
    showCompletedText = !!completedEw;
    // Show the "view tasks" button if there is an active enrollment.
    showViewTasksButton = !!activeEw;
    // Only show the self enroll button if there are NO existing enrollments (including completed ones)
    showSelfEnrollButton = !anyEw;
  }

  return (
    <>
      <NoticeBox className='d-flex align-items-center margin-top-3'>
        <div className='flex-1 margin-right-3'>
          <h5 className='margin-y-0'>{header}</h5>
          {description && (
            <p className='margin-top-2 margin-bottom-0 text-small'>
              {description}
            </p>
          )}
        </div>
        {
          // This will only ever be shown if multiple enrollments are not allowed for the workflow
          showCompletedText && (
            <span className='text-xsmall text-default bg-white rounded padding-x-2 padding-y-1'>
              Completed on {moment(completedEw.completeDate).format('M/D/YY')}
            </span>
          )
        }
        {showViewTasksButton && (
          <Tooltip
            tooltip={`Started on ${moment(activeEw?.createdAt).format(
              'M/D/YY',
            )}`}
            placement='bottom'
          >
            <Button
              label='View tasks'
              category='secondary'
              onClick={() => {
                history.push(
                  `${NEW_HIRE_TASKS}?workflow[id]=${workflowId}&workflow[name]=${workflowName}&show_future_tasks=true`,
                );
              }}
            />
          </Tooltip>
        )}
        {
          // This will never be shown if the employee currently has an active enrollment in this workflow
          showSelfEnrollButton && (
            <Button
              className='margin-left-2'
              disabled={!!(isLoading || enrolledWorkflow)}
              label={!enrolledWorkflow ? 'Get started' : ''}
              labelHTML={
                enrolledWorkflow ? (
                  <CheckSvg className='svg-stroke-paper-white svg-fill-none' />
                ) : null
              }
              loading={isLoading}
              category='primary'
              onClick={readOnly ? noop : enrollInWorkflow}
            />
          )
        }
      </NoticeBox>
      {enrollError && (
        <DangerNoticeBoxWithIcon className='margin-top-3'>
          <span>{formatError(enrollError)}</span>
        </DangerNoticeBoxWithIcon>
      )}
    </>
  );
};

WorkflowSelfEnrollCard.propTypes = {
  existingEmployeeWorkflows: arrayOf(
    shape({
      id: string.isRequired,
      createdAt: string,
      completedAt: string,
      status: string,
    }),
  ),
  description: string,
  header: string,
  workflowId: string.isRequired,
  workflowName: string,
  readOnly: bool,
  allowMultipleEnrollmentsPerEmployee: bool,
};

export default WorkflowSelfEnrollCard;
