import classNames from 'classnames';
import { any, func, object, string } from 'prop-types';
import React from 'react';

const BoxCard = (props) => (
  <div
    {...props}
    className={classNames(
      'border-a border-color-midnight-navy-10 rounded-xlarge box-shadow',
      props.className,
    )}
  >
    {props.children}
  </div>
);

BoxCard.propTypes = {
  onClick: func,
  className: string,
  children: any,
  style: object,
};

export default BoxCard;
