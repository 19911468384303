import { bool, object, string } from 'prop-types';
import React from 'react';

import { Mobile } from 'sora-client/components/common/MediaQueries';
import {
  BarChartSvg,
  CheckedSquareSvg,
  ExternalLinkSvg,
  HomeSvg,
  SettingsSvg,
  TimelineSvg,
  UsersSvg,
} from 'sora-client/components/common/Svg';
import * as routes from 'sora-client/constants/routes';
import { userAuthorization } from 'sora-client/helpers/authorization';

import NavItem from './NavItem';

const NavItems = ({
  session,
  showIcons = true,
  currentNavItemCategory,
  titleStyle,
  selectedBorderStyle,
  activeTitleClassname,
  inactiveTitleClassname,
}) => {
  const ac = userAuthorization(session.currentUser);

  const canEditNotificationsSettings = ac.can({
    resource: 'Company',
    action: 'EDIT',
    instance: { id: session.currentUser.companyId },
  });

  const canEditIntegrationsSettings = ac.can({
    resource: 'Integration',
    action: 'EDIT',
    instance: { companyId: session.currentUser.companyId },
  });

  const canViewReports = ac.can({
    resource: 'Task',
    action: 'VIEW',
    any: true,
  });

  const hasPremiumAccess = session.currentUser.hasPremiumAccess;
  const isWorkflowTemplatesCompany =
    session.currentUser.company.isWorkflowTemplatesCompany;

  return (
    <>
      {!isWorkflowTemplatesCompany && !session.currentUser.hasFullUIAccess && (
        <NavItem
          navItemRoute={routes.NEW_HIRE_TASKS}
          title='Tasks'
          icon={<CheckedSquareSvg />}
          navItemCategory='Tasks'
          currentNavItemCategory={currentNavItemCategory}
          showIcon={showIcons}
          titleStyle={titleStyle}
          selectedBorderStyle={selectedBorderStyle}
          activeTitleClassname={activeTitleClassname}
          inactiveTitleClassname={inactiveTitleClassname}
        />
      )}
      {session.currentUser.hasFullUIAccess && (
        <>
          <NavItem
            navItemRoute={routes.HOME}
            title='Home'
            icon={<HomeSvg />}
            navItemCategory='Home'
            currentNavItemCategory={currentNavItemCategory}
            showIcon={showIcons}
            titleStyle={titleStyle}
            selectedBorderStyle={selectedBorderStyle}
            activeTitleClassname={activeTitleClassname}
            inactiveTitleClassname={inactiveTitleClassname}
          />
          {
            /** The following nav items are hidden from the Sora Workflow Templates company */
            !isWorkflowTemplatesCompany && (
              <>
                {ac.can({
                  resource: 'Timeline',
                  action: 'VIEW',
                  any: true,
                }) && (
                  <NavItem
                    navItemRoute={routes.WORKFLOWS_ALL}
                    title='Workflows'
                    icon={<TimelineSvg />}
                    navItemCategory='Workflows'
                    currentNavItemCategory={currentNavItemCategory}
                    showIcon={showIcons}
                    titleStyle={titleStyle}
                    selectedBorderStyle={selectedBorderStyle}
                    activeTitleClassname={activeTitleClassname}
                    inactiveTitleClassname={inactiveTitleClassname}
                  />
                )}
                <NavItem
                  navItemRoute={routes.NEW_HIRE_TASKS}
                  title='Tasks'
                  icon={<CheckedSquareSvg />}
                  navItemCategory='Tasks'
                  currentNavItemCategory={currentNavItemCategory}
                  showIcon={showIcons}
                  titleStyle={titleStyle}
                  selectedBorderStyle={selectedBorderStyle}
                  activeTitleClassname={activeTitleClassname}
                  inactiveTitleClassname={inactiveTitleClassname}
                />
                {ac.can({
                  resource: 'Employee',
                  action: 'VIEW',
                  anyNotSelf: true,
                }) && (
                  <NavItem
                    navItemRoute={routes.EMPLOYEES}
                    title='Employees'
                    icon={<UsersSvg />}
                    navItemCategory='Employees'
                    currentNavItemCategory={currentNavItemCategory}
                    showIcon={showIcons}
                    titleStyle={titleStyle}
                    selectedBorderStyle={selectedBorderStyle}
                    activeTitleClassname={activeTitleClassname}
                    inactiveTitleClassname={inactiveTitleClassname}
                  />
                )}
                {canViewReports && (
                  <NavItem
                    navItemRoute={routes.REPORTS}
                    title='Reports'
                    icon={<BarChartSvg />}
                    navItemCategory='Reports'
                    currentNavItemCategory={currentNavItemCategory}
                    showIcon={showIcons}
                    titleStyle={titleStyle}
                    selectedBorderStyle={selectedBorderStyle}
                    activeTitleClassname={activeTitleClassname}
                    inactiveTitleClassname={inactiveTitleClassname}
                    isDisabled={!hasPremiumAccess}
                    disabledTooltipMessage='In the premium version of Sora, you are able to export survey results and view email open rates.'
                  />
                )}
                <Mobile>
                  <NavItem
                    navItemRoute={{
                      pathname: 'https://help.sora.co/',
                    }}
                    target='_blank'
                    title='Help'
                    icon={<ExternalLinkSvg />}
                    navItemCategory='Help'
                    currentNavItemCategory={currentNavItemCategory}
                    showIcon={showIcons}
                    titleStyle={titleStyle}
                    selectedBorderStyle={selectedBorderStyle}
                    activeTitleClassname={activeTitleClassname}
                    inactiveTitleClassname={inactiveTitleClassname}
                  />
                  {(canEditNotificationsSettings ||
                    canEditIntegrationsSettings) && (
                    <NavItem
                      navItemRoute={
                        canEditNotificationsSettings
                          ? routes.SETTINGS_NOTIFICATIONS
                          : routes.SETTINGS_INTEGRATIONS
                      }
                      title='Settings'
                      icon={<SettingsSvg />}
                      navItemCategory='Settings'
                      currentNavItemCategory={currentNavItemCategory}
                      showIcon={showIcons}
                      titleStyle={titleStyle}
                      selectedBorderStyle={selectedBorderStyle}
                      activeTitleClassname={activeTitleClassname}
                      inactiveTitleClassname={inactiveTitleClassname}
                    />
                  )}
                </Mobile>
              </>
            )
          }
          {
            /** The following routes are only visible to the Sora Workflow Templates company */
            isWorkflowTemplatesCompany && (
              <>
                <NavItem
                  navItemRoute={routes.EMPLOYEES_DATA_MANAGEMENT}
                  title='Data management'
                  icon={<UsersSvg />}
                  navItemCategory='Employees'
                  currentNavItemCategory={currentNavItemCategory}
                  showIcon={showIcons}
                  titleStyle={titleStyle}
                  selectedBorderStyle={selectedBorderStyle}
                  activeTitleClassname={activeTitleClassname}
                  inactiveTitleClassname={inactiveTitleClassname}
                />
                <NavItem
                  navItemRoute={routes.WORKFLOW_TEMPLATES_MANAGEMENT}
                  title='Workflow templates'
                  icon={<UsersSvg />}
                  navItemCategory='Workflow templates'
                  currentNavItemCategory={currentNavItemCategory}
                  showIcon={showIcons}
                  titleStyle={titleStyle}
                  selectedBorderStyle={selectedBorderStyle}
                  activeTitleClassname={activeTitleClassname}
                  inactiveTitleClassname={inactiveTitleClassname}
                />
              </>
            )
          }
        </>
      )}
    </>
  );
};

NavItems.propTypes = {
  session: object.isRequired,
  showIcons: bool,
  currentNavItemCategory: string,
  titleStyle: object,
  selectedBorderStyle: object,
  activeTitleClassname: string,
  inactiveTitleClassname: string,
};

export default NavItems;
