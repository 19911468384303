import classNames from 'classnames';
import { node, oneOf, string } from 'prop-types';
import React from 'react';

import {
  BG_COLORS_TO_CLASSNAME,
  COLORS,
} from 'sora-client/constants/components';

export const BADGE_SIZES = { SMALL: 'small', MEDIUM: 'medium', LARGE: 'large' };

export const Badge = ({
  children,
  className = null,
  color = COLORS.GRAY,
  size = BADGE_SIZES.MEDIUM,
}) => (
  <div
    className={classNames(
      `d-inline text-small rounded ${BG_COLORS_TO_CLASSNAME[color]}`,
      className,
      {
        'padding-y-1': size !== BADGE_SIZES.SMALL,
        'text-paper-white': [
          COLORS.GREEN,
          COLORS.ORANGE,
          COLORS.YELLOW,
          COLORS.RED,
        ].includes(color),
        'padding-2': size === BADGE_SIZES.LARGE,
        'padding-x-2': [BADGE_SIZES.SMALL, BADGE_SIZES.MEDIUM].includes(size),
      },
    )}
  >
    {children}
  </div>
);

Badge.propTypes = {
  children: node.isRequired,
  className: string,
  color: oneOf(Object.values(COLORS)),
  size: oneOf(Object.values(BADGE_SIZES)),
};
