import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
  OAUTH_REDIRECT_URI,
} from '../../common';

export const CHECKR_INTEGRATION_ID = 13;

export const CHECKR_STATUSES = {
  INVITATION_SENT: 'Invitation Sent',
  PENDING: 'Pending',
  INVITATION_EXPIRED: 'Invitation Expired',
  INVITATION_CANCELED: 'Invitation Canceled',
  CLEAR: 'Clear',
  COMPLETE_WITH_CANCELED: 'Complete with Canceled ⚠️',
  CANCELED: 'Canceled',
  NEEDS_REVIEW: 'Needs Review',
  PRE_ADVERSE_ACTION: 'Pre Adverse Action',
  NOT_ELIGIBLE: 'Not Eligible',
  SUSPENDED: 'Suspended',
  DISPUTED: 'Disputed',
};

export const CHECKR_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: CHECKR_INTEGRATION_ID,
  name: 'checkr',
  label: 'Checkr',
  description:
    'Checkr is a service that provides automatically generated background checks.',
  website: 'https://checkr.com',
  helpDocs: 'https://help.sora.co/resources/checkr',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/checkr.png`,
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  categories: [CATEGORIES.OTHER],
  authFlow: {
    type: 'Oauth2',
    authorizeEndpoint: `${process.env.CHECKR_CONNECT_BASE_URL}/authorize/${process.env.CHECKR_PARTNER_CLIENT_ID}`,
    redirectUri: OAUTH_REDIRECT_URI,
    configToClear: {
      accessToken: null,
      checkrAccountId: null,
      credentialed: false,
    },
  },
  configured: (c) =>
    c.values && c.values.accessToken && c.values.checkrAccountId,
  configSchema: {
    accessToken: {
      type: 'string',
      title: 'API Access token',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Hidden',
        'ui:options': {
          label: false,
        },
      },
      searchable: true,
    },
    checkrAccountId: {
      type: 'string',
      title: 'Customer account ID',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Hidden',
        'ui:options': {
          label: false,
        },
      },
      searchable: true,
    },
    credentialed: {
      type: 'boolean',
      title: "Account is credentialed by Checkr's Customer Success team",
      default: false,
      nullable: true,
      uiSchema: {
        'ui:widget': 'Hidden',
        'ui:disabled': true,
        'ui:options': {
          label: false,
        },
      },
    },
  },
  validation: { action: 'checkr/getPackages' },
};
