import React, { memo } from 'react';
import { bool, object } from 'prop-types';

import { LoadingSvg } from 'sora-client/components/common/Svg';

const Loading = ({ hideMargin, style }) => (
  <div className={`text-center margin-y-${hideMargin ? 0 : 6} loading`}>
    <LoadingSvg data-testid='spinner' style={style} />
  </div>
);

Loading.propTypes = {
  hideMargin: bool,
  style: object,
};

export default memo(Loading);
