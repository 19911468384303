import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const ULTIPRO_INTEGRATION_ID = 21;

export const ULTIPRO_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: ULTIPRO_INTEGRATION_ID,
  name: 'ultipro',
  label: 'UltiPro',
  categories: [CATEGORIES.HRIS],
  description:
    'UltiPro unites all aspects of HR, payroll, and talent management in one comprehensive cloud solution.',
  website: 'ultimatesoftware.com',
  helpDocs: 'https://help.sora.co/resources/ultipro',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/ultipro.png`,
  availableUses: [
    AVAILABLE_USES_ENUMS.DATA_SYNC,
    AVAILABLE_USES_ENUMS.WORKFLOWS,
  ],
  configSchema: {
    username: {
      type: 'string',
      title: 'UltiPro Connect username',
      nullable: true,
    },
    password: {
      type: 'string',
      title: 'UltiPro Connect password',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    userAPIKey: {
      type: 'string',
      title: 'UltiPro Connect user API key',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    customerAPIKey: {
      type: 'string',
      title: 'UltiPro Connect customer API key',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    tenantIdentifier: {
      type: 'string',
      title: 'UltiPro Connect tenant ID',
      nullable: true,
    },
    onboardingClientId: {
      type: 'string',
      title: 'Onboarding client ID',
      nullable: true,
    },
    onboardingSecret: {
      type: 'string',
      title: 'Onboarding secret',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    onboardingCredentialsAreForSandbox: {
      type: 'boolean',
      title: 'Onboarding credentials are for sandbox',
      nullable: true,
    },
    servicesDomainURL: {
      type: 'string',
      title: 'UltiPro Connect services domain URL',
      nullable: true,
    },
    outboundEmployeeFieldMapping: {
      type: 'string',
      title: 'Map outbound fields from Sora employees, to UltiPro employees',
      nullable: true,
    },
    companyId: {
      type: 'string',
      title: 'Company ID',
      nullable: true,
    },
  },
  validation: {
    action: 'ultipro/getPersonnelPeopleDetails',
    parameters: { per_Page: 1 },
  },
  configured: (c) =>
    c.values &&
    [
      'username',
      'password',
      'userAPIKey',
      'customerAPIKey',
      'servicesDomainURL',
      'companyId',
    ].every((key) => !!c.values[key]),
};
