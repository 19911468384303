import gql from 'graphql-tag';
import React, { useContext } from 'react';
import { useApolloClient } from 'react-apollo-hooks';

import { useMutationWrapper } from 'sora-client/components/common/MutationWrapper';
import AnalyticsContext from 'sora-client/contexts/AnalyticsContext';
import { getRedirectUrl } from 'sora-client/helpers';
import log from 'sora-client/logger';

const LOG_OUT = gql`
  mutation logOut {
    logOut
  }
`;

const SignOutLink = () => {
  const { track, reset } = useContext(AnalyticsContext);
  const client = useApolloClient();

  const { fn: logOutServer } = useMutationWrapper(LOG_OUT);

  return (
    <div
      className='d-block cursor-pointer padding-x-4 mobile-padding-x-3 padding-y-2 bg-hover-gray text-danger'
      onClick={async () => {
        track(`Logged Out`);
        await logOutServer();
        await signOut({ client, reset });
      }}
    >
      Log out
    </div>
  );
};

const signOut = async ({ client, reset = null, redirectTo = '' }) => {
  localStorage.setItem('token', '');

  await client.logOut();

  log.addBreadcrumb({
    category: 'auth',
    message: `User logged out`,
    level: 'info',
  });

  reset && reset();
  if (window.Sprig) {
    window.Sprig('logoutUser');
  }
  const loginWithRedirect = getRedirectUrl(redirectTo);

  // Set window.location.href directly to force a full page refresh when logging
  // out. This will ensure that no users ever have too old of an app version, since
  // refreshing the page will fetch the current app version.
  window.location.href = `${loginWithRedirect.pathname}${loginWithRedirect.search}`;
};

export { signOut };

export default SignOutLink;
