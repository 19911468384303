const taskSubscriptionHandlers = {
  'onTaskCreated.getAllWorkflowTasks': ({
    client,
    subscriptionData,
    query,
    variables,
  }) => {
    const createdTask = subscriptionData.data.taskCreated;

    if (createdTask.timelineId !== variables.timelineId) {
      // Task waas created for a different timeline than the one this
      // subscription is querying for. No-op
      return;
    }

    const fromCache = client.readQuery({
      query,
      variables,
    });
    const { tasks } = fromCache;

    client.writeQuery({
      query,
      variables,
      data: {
        ...fromCache,
        tasks: {
          ...tasks,
          rows: [...tasks?.rows, createdTask],
        },
      },
    });
  },
};

export default taskSubscriptionHandlers;
