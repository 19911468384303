import { bool, string } from 'prop-types';
import React from 'react';

import { sanitizeHtml } from 'sora-invariant/src/helpers/html';

export const DangerousDashboardCardTemplate = ({
  title,
  body,
  isPreview = false,
}) => {
  // We need to add this ID if this dashboard card is in preview mode to copy over the styles from the
  // template preview where the variables are highlighted.
  const commonProps = isPreview ? { id: 'template-preview' } : {};

  return (
    <>
      <div
        role='heading'
        aria-level='1'
        className='font-p22-mackinac margin-bottom-2 h3'
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(title),
        }}
        {...commonProps}
      />
      <div
        className='text-muted'
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(body, {
            parseStyleAttributes: false,
          }),
        }}
        {...commonProps}
      />
    </>
  );
};

DangerousDashboardCardTemplate.propTypes = {
  title: string.isRequired,
  body: string.isRequired,
  isPreview: bool,
};
