export const DEFAULT_INTEGRATION = {
  // Some fields need to be completed at UX level if 'true'
  configurable: () => true, // take companyId as arg
  label: '',
  configSchema: {},
  configAcquirer: 'userForm',
  acquirerParameters: {},
  logoUrl: '',
};

export const AVAILABLE_USES_ENUMS = {
  WORKFLOWS: 'workflows',
  DATA_SYNC: 'dataSync',
};

export const BASE_INTEGRATION_LOGO_URL =
  'https://honu-app.s3-us-west-1.amazonaws.com/static/media/service-logos';

export const FALLBACK_INTEGRATION_LOGO_URL = `${BASE_INTEGRATION_LOGO_URL}/generic-business.png`;

export const CATEGORIES = {
  HRIS: 'hris',
  ATS: 'ats',
  COMMUNICATION: 'communication',
  OTHER: 'other',
};

export const COMMON_OAUTH_CONFIG_PROPERTIES = {
  accessToken: {
    type: 'string',
    title: 'API Access token',
    nullable: true,
    uiSchema: {
      'ui:widget': 'Hidden',
      'ui:options': {
        label: false,
      },
    },
  },
  accessTokenExpirationDate: {
    type: 'string',
    title: 'Access token expiration date',
    nullable: true,
    uiSchema: {
      'ui:widget': 'Hidden',
      'ui:options': {
        label: false,
      },
    },
  },
};

export let OAUTH_REDIRECT_URI =
  process.env.NODE_ENV === 'staging'
    ? 'https://dispatcher.staging.sora.co/auth/challenge/response'
    : 'https://dispatcher.sora.co/auth/challenge/response';

if (
  ['development', 'test'].includes(process.env.NODE_ENV) &&
  process.env.DISPATCHER_TUNNEL
) {
  // should be 'https://xxxxxx.ngrok.io/auth/challenge/response', see dispatcher/README.md
  OAUTH_REDIRECT_URI = `${process.env.DISPATCHER_TUNNEL}/auth/challenge/response`;
}
