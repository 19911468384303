const _ = require('lodash');
const moment = require('moment-timezone');
const constants = require('../constants');

const ft = constants.modelConstants.field.types;
const df = constants.modelConstants.field.dateFormats;
const ddf = constants.modelConstants.field.displayDateFormats;

const ENPS_ENUM_LENGTH = 11;

const pickRandomColor = () => {
  const colors = ['c7f8c8d', 'cc0392b', 'cd35400', 'c16a085', 'c16a085'];
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

const pluralize = (text, num) => {
  return Math.abs(num) !== 1 ? `${text}s` : text;
};

const kFormatNumber = (num) => {
  if (Math.abs(num) < 1000) return num.toString();

  return `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}K`;
};

const roundPercent = (numerator, denominator) => {
  return Math.round((numerator / denominator) * 100);
};

const formatDate = ({ value, tz = null, format = 'MM/DD/YYYY' }) => {
  if (!value) return '';
  // to avoid double date formating - applying imezone twice
  if (
    typeof value === 'string' &&
    moment(value, format).format(format) === value
  ) {
    return value;
  }
  if (!tz) {
    tz = moment.tz.guess();
  }
  return moment(new Date(value)).tz(tz).format(format);
};

function formatDatesForDisplay(date, field) {
  if (!date) return '';
  const type = _.camelCase(field.type);

  return moment.parseZone(date, df[type], true).format(ddf[type]);
}

// `timestamp` can be a string, date, or moment object.
const formatTime = ({ timestamp }) => {
  return moment(timestamp).format('hh:mma');
};

const constructConvertedEmployeeFieldValue = ({
  field,
  employee,
  fieldName,
  omitType,
}) => {
  let constructedObj = {
    field: { ...(omitType ? _.omit(field, ['__typename']) : field) },
    fieldId: field.id,
  };

  const employeeFieldReference = employee[fieldName];

  if (!employeeFieldReference) return null;

  if (field.type === 'relationship') {
    _.extend(constructedObj, {
      values: [employeeFieldReference.id],
      relationshipEmployee: {
        id: employeeFieldReference.id,
        name: employeeFieldReference.fullName,
      },
    });
  } else {
    constructedObj.values = _.isArray(employeeFieldReference)
      ? employeeFieldReference
      : [employeeFieldReference];
  }

  return constructedObj;
};

const convertIntoEmployeeModel = ({ employee, fields, omitType = false }) => {
  const clonedEmployee = _.cloneDeep(employee || {});
  clonedEmployee.fieldValues = [];

  _.keys(clonedEmployee).forEach((k) => {
    const field = _.find(fields, { name: k });
    if (field) {
      const constructedObj = constructConvertedEmployeeFieldValue({
        field,
        employee: clonedEmployee,
        fieldName: k,
        omitType,
      });

      if (constructedObj) {
        clonedEmployee.fieldValues.push(constructedObj);
      }
    }
  });

  return clonedEmployee;
};

const constructEmployeePayload = ({ fieldValues }) => {
  return _.map(fieldValues, (fv) => {
    return _.pick(fv, ['fieldId', 'values', 'newHireTaskId']);
  });
};

/** @param {string} type */
const isDateType = (type) =>
  [
    ft.DATE,
    ft.TIMESTAMP,
    ft.TIMESTAMP_WITH_TIMEZONE,
    ft.TIME,
    ft.TIME_WITH_TIMEZONE,
    ft.EVENT,
  ].includes(type);

const wait = (ms) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve();
    }, ms),
  );
};

const isValidDate = (dateStr, formats = []) => {
  const isStrict = true;
  const momentDateObj = moment(dateStr, formats, isStrict);

  return momentDateObj.isValid();
};

/**
 * Given an array of enum values such as ['1','2','3','4','5'] this function
 * determines if the enum values match the NPS range of 0-10.
 *
 * @param {Array} enumValues
 * @returns {boolean}
 */
const isNPSType = (enumValues) => {
  const expectedOrder = Array.from({ length: ENPS_ENUM_LENGTH }, (_, i) =>
    String(i),
  );

  if (enumValues.length !== expectedOrder.length) {
    return false;
  }

  return expectedOrder.every((value, index) => value === enumValues[index]);
};

module.exports = {
  pickRandomColor,
  formatDate,
  formatTime,
  pluralize,
  convertIntoEmployeeModel,
  constructEmployeePayload,
  isDateType,
  formatDatesForDisplay,
  kFormatNumber,
  roundPercent,
  wait,
  isValidDate,
  isNPSType,
};
