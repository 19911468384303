import { object, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import EmployeeAvatar from 'sora-client/components/common/Avatar';
import * as routes from 'sora-client/constants/routes';
import { getEmployeeSlug } from 'sora-client/helpers';

const EmployeeListItem = ({ employee, customClassNames }) => (
  <Link
    to={`${routes.EMPLOYEE_PROFILE_BASE}/${getEmployeeSlug(employee)}`}
    className='d-flex align-items-center text-default text-truncate padding-y-3 padding-x-4 mobile-padding-x-3 border-bottom'
  >
    <EmployeeAvatar
      imageSrc={employee.imageUrl}
      widthHeight={50}
      employeeName={employee.name}
      employeeId={employee.id}
    />
    <div className='flex-grow-1 margin-left-4 mobile-margin-left-3 text-truncate'>
      <strong>{employee.name}</strong>
      <div className='margin-top-1 text-small text-truncate'>
        {employee.email}
      </div>
    </div>
  </Link>
);

EmployeeListItem.propTypes = {
  employee: object.isRequired,
  customClassNames: string,
};

export default EmployeeListItem;
