import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const ADP_RUN_INTEGRATION_ID = 46;

export const ADP_RUN_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: ADP_RUN_INTEGRATION_ID,
  name: 'adprun',
  label: 'ADP Run',
  description:
    'ADP RUN is a cloud-based solution designed to meet the payroll needs of a small business.',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/adprun.png`,
  website: 'https://runpayroll.adp.com/',
  helpDocs: 'https://help.sora.co/resources/adp-run',
  availableUses: [
    AVAILABLE_USES_ENUMS.WORKFLOWS,
    AVAILABLE_USES_ENUMS.DATA_SYNC,
  ],
  categories: [CATEGORIES.HRIS],
  configSchema: {
    organizationId: {
      type: 'string',
      title: 'Organization ID',
      searchable: true,
      nullable: true,
    },
    clientId: {
      type: 'string',
      title: 'Client ID',
      nullable: true,
    },
    clientSecret: {
      type: 'string',
      title: 'Client secret',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    outboundNewHireFieldMapping: {
      type: 'string',
      title: 'Map outbound fields from Sora employees, to ADP Run employees',
      nullable: true,
    },
  },
  configured: (c) => c.values && c.values.clientId && c.values.clientSecret,
  validation: { action: 'adprun/getWorkers', parameters: { limit: 1 } },
};
