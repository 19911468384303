import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const SLACK_INTEGRATION_ID = 15;

export const SLACK_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: SLACK_INTEGRATION_ID,
  name: 'slack',
  label: 'Slack',
  description:
    'Slack is an enterprise software platform that allows teams and businesses of all sizes to communicate effectively.',
  website: 'slack.com',
  helpDocs: 'https://help.sora.co/resources/slack',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/slack.png`,
  availableUses: [
    AVAILABLE_USES_ENUMS.DATA_SYNC,
    AVAILABLE_USES_ENUMS.WORKFLOWS,
  ],
  categories: [CATEGORIES.COMMUNICATION],
  authFlow: {
    type: 'Oauth2',
    authorizeEndpoint: 'https://slack.com/oauth/v2/authorize',
    redirectUri:
      process.env.SLACK_REDIRECT_URI ||
      process.env.REACT_APP_SLACK_REDIRECT_URI,
    clientId: process.env.SLACK_CLIENT_ID || '442720014547.3101470946530',
    clientSecret: process.env.SLACK_CLIENT_SECRET,
    scope:
      'channels:join,channels:manage,channels:read,chat:write,chat:write.customize,groups:read,groups:write,im:write,mpim:read,mpim:write,users.profile:read,users:read,users:read.email',
    authorizeAddons: { response_type: 'code' },
    configToClear: { soraSlackBotAuthKey: null },
  },
  configured: (c) => {
    return c.values && (c.values.soraSlackBotAuthKey || c.values.authKey);
  },
  configSchema: {
    soraSlackBotAuthKey: {
      type: 'string',
      title: 'Sora Slack bot auth key',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Hidden',
        'ui:options': {
          label: false,
        },
      },
    },
    authKey: {
      type: 'string',
      title: 'Auth key (Deprecated)',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    outboundEmployeeFieldMapping: {
      type: 'string',
      title: 'Field mapping for Sora employees to Slack users',
      nullable: true,
    },
  },
  validation: { action: 'slack/api_test' },
};
