import classNames from 'classnames';
import { string } from 'prop-types';
import React from 'react';

import { WarningSvg } from 'sora-client/components/common/Svg';

export const Icon = ({ className }) => {
  return (
    <WarningSvg className={classNames('stroke-danger no-hover', className)} />
  );
};

Icon.propTypes = {
  className: string,
};

Icon.displayName = 'DangerNoticeBox.Icon';

export default Icon;
