import { isEmpty } from 'lodash';

import log from 'sora-client/logger';
import { error } from 'sora-invariant/src/constants/index';

import { getErrorMessage } from './index';

const getActionButton = (action) => {
  switch (action.name) {
    case error.actions.SWITCH_TO_COMPANY: {
      return {
        text: 'Switch to company',
        onClick: () => {
          const url = new URL('login', window.location.origin);

          url.searchParams.append(
            'redirectTo',
            window.location.pathname + window.location.search,
          );
          url.searchParams.append('switchToCompanyId', action.companyId);

          window.open(url.toString(), '_self');
        },
      };
    }
    default: {
      return null;
    }
  }
};

const showMessage = (error) => {
  const message = {
    body: getErrorMessage(error),
    type: 'failure',
    timeout: 7000,
  };

  // If error action is provided then show proper button
  if (error?.extensions?.action) {
    message.button = getActionButton(error.extensions.action);
    message.timeout = 10000;
  }

  window.addMessage(message);
};

export const handleResponseError = (error) => {
  log.error(error);

  if (!isEmpty(error.graphQLErrors)) {
    error.graphQLErrors.forEach((e) => showMessage(e));
  } else if (error.message) {
    showMessage(error);
  }
};

export default {
  handleResponseError,
};
