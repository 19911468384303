const moment = require('moment-timezone');

const defaultGrants = './defaultCompanyGrants';

const modelConstants = {
  analyticsFilterSetting: {
    filterOptions: {
      '30_DAY': '30_DAY',
      '90_DAY': '90_DAY',
      THIS_YEAR: 'THIS_YEAR',
      LAST_YEAR: 'LAST_YEAR',
      ALL_TIME: 'ALL_TIME',
      CUSTOM: 'CUSTOM',
    },
  },
  calendarEvent: {
    types: {
      GOOGLE_CALENDAR: 'google_calendar',
      MICROSOFT_OUTLOOK: 'microsoft_outlook',
    },
    recurrenceUnits: {
      DAY: 'day',
      WEEK: 'week',
      MONTH: 'month',
      YEAR: 'year',
    },
  },
  company: {
    timezones: moment.tz.names(),
  },
  csvFileUpload: {
    statuses: {
      STARTED: 'STARTED',
      PROCESSING: 'PROCESSING',
      COMPLETED: 'COMPLETED',
      FAILED: 'FAILED',
    },
  },
  customMetric: {
    chartTypes: {
      BAR: 'BAR',
      LINE: 'LINE',
    },
    measurementTypes: {
      NPS: 'nps',
      AVG: 'avg',
    },
  },
  dashboardCards: {
    states: {
      DRAFT: 'DRAFT',
      ACTIVE: 'ACTIVE',
    },
  },
  permissionGroup: {
    defaultGrants,
    defaultGroups: {
      superadministrators: 'superadministrators',
      administrators: 'administrators',
      coordinators: 'coordinators',
      everyone: 'everyone',
    },
  },
  user: {
    providers: {
      GOOGLE: 'GOOGLE',
      MICROSOFT: 'MICROSOFT',
      EMAIL: 'EMAIL',
    },
    defaultProfileImageUrl:
      'https://sora-production.imgix.net/generic-user.png?fit=crop&q=65&auto=format&w=84&h=84',
  },
  task: {
    states: {
      ACTIVE: 'active',
      ARCHIVED: 'archived',
      DRAFT: 'draft',
    },
    recurrenceUnits: {
      DAY: 'day',
      WEEK: 'week',
      MONTH: 'month',
      YEAR: 'year',
    },
    triggerOffsetUnits: {
      DAY: 'day',
      WEEK: 'week',
      MONTH: 'month',
      YEAR: 'year',
    },
    types: {
      EMAIL: 'email',
      ASSIGNMENT: 'assignment',
      SURVEY: 'survey',
      CALENDAR_INVITE: 'calendarInvite',
      CALENDAR_EVENT: 'calendarEvent',
      INTEGRATION: 'integration',
      NATIVE_INTEGRATION: 'nativeIntegration',
    },
    /**
     * Shared constant that encapsulates the enum values for both `triggerDateSelectionMethod` on the Task model
     * and `eventDateSelectionMethod` on the CalendarInvite model. For more context on how these enums are used,
     * see the `calculateValidTriggerOrEventDate` class method on the NewHireTask model.
     */
    triggerOrEventDateSelectionMethods: {
      CLOSEST_VALID_DAY: 'closest_valid_day',
      NEXT_VALID_DAY: 'next_valid_day',
      PREVIOUS_VALID_DAY: 'previous_valid_day',
    },
    filterOps: {
      IS_SET: 'isSet',
      IS_NOT_SET: 'isNotSet',
      OR_EQ: 'orEq',
      OR_NOT_EQ: 'orNotEq',
    },
  },
  taskType: {
    dueOffsetUnits: {
      DAY: 'day',
      WEEK: 'week',
      MONTH: 'month',
      YEAR: 'year',
    },
  },
  workflowStage: {
    states: {
      ACTIVE: 'active',
      ARCHIVED: 'archived',
    },
  },
  timeline: {
    addEmployeesToWorkflowErrors: {
      EMPLOYEE_DOES_NOT_EXIST: 'Employee does not exist',
      MULTIPLE_ENROLLMENTS_FORBIDDEN:
        'Employee cannot be added to workflow more than once',
      WORKFLOW_FILTERS_DO_NOT_APPLY:
        'Workflow filters do not apply to employee',
    },
    triggerTypes: {
      custom: 'custom',
      native: 'native',
    },
    tagOptions: {
      onboarding: { name: 'Onboarding', color: 'c8467d7', value: 'onboarding' },
      off_boarding: {
        name: 'Offboarding',
        color: 'c7f8c8d',
        value: 'off_boarding',
      },
      training_and_development: {
        name: 'Training & Development',
        color: 'cf67280',
        value: 'training_and_development',
      },
      company_events: {
        name: 'Company events',
        color: 'c85bb65',
        value: 'company_events',
      },
      change_management: {
        name: 'Change management',
        color: 'cf39c12',
        value: 'change_management',
      },
      experience_and_delight: {
        name: 'Experience & Delight',
        color: 'cb5651d',
        value: 'experience_and_delight',
      },
      leaves_of_absence: {
        name: 'Leaves of absence',
        color: 'c728fce',
        value: 'leaves_of_absence',
      },
    },
  },
  newHireTask: {
    states: {
      /**
       * CREATED - inactive state for newHireTask, it was not yet trigger, e.g. trigger task is not completed
       */
      CREATED: 'created',
      ACTIVE: 'active',
      /**
       * CANCELLED - manually set in cases when we don't want it to apply to employee, will remember completed
       * newHireNotification(in case any one exists), also differed from simply deleting
       * because it will never be recreated
       */
      CANCELLED: 'cancelled',
      /**
       * COMPLETED - done field is set to non-null value(some date)
       */
      COMPLETED: 'completed',
      /**
       * PAUSED will be set when timeline is not active(used for performance optimization),
       * should not be set individually since activation/deactivation of timeline will
       * override it
       */
      PAUSED: 'paused',
      /**
       * SKIPPED will be set for surveys IFF:
       *   - All survey questions use field forwarding to employee fields
       *   - The survey has skipIfForwardedFieldsFulfilled set to true
       *   - All forwarded-to employee fields are already set
       */
      SKIPPED: 'skipped',
    },
    flags: {
      MISSING_VALUE: 'MISSING_VALUE',
      TRIGGER_TASK_NOT_COMPLETED: 'TRIGGER_TASK_NOT_COMPLETED',
      DUE_DATE_BEFORE_TRIGGER_DATE: 'DUE_DATE_BEFORE_TRIGGER_DATE',
      MISSING_EMPLOYEE_FIELD: 'MISSING_EMPLOYEE_FIELD',
      A_REQUIRED_RELATIONSHIP_NOT_SET: 'A_REQUIRED_RELATIONSHIP_NOT_SET',
      PENDING_SURVEY_COMPLETION: 'PENDING_SURVEY_COMPLETION',
      INTEGRATION_RUN_ERROR: 'INTEGRATION_RUN_ERROR',
      INTEGRATION_TRIGGER_ERROR: 'INTEGRATION_TRIGGER_ERROR',
      INTEGRATION_START_DATE_ERROR: 'INTEGRATION_START_DATE_ERROR',
      TASK_RUN_ERROR: 'TASK_RUN_ERROR',
      TRIGGER_DATE_IN_PAST: 'TRIGGER_DATE_IN_PAST',
      MISSING_RELATIONSHIP_VALUE: 'MISSING_RELATIONSHIP_VALUE',
    },
    executionStates: {
      ACTIVE: 'active',
      COMPLETED: 'completed',
      FAILED: 'failed',
    },
  },
  notification: {
    types: {
      GENERAL: 'GENERAL',
      ON_CANCEL: 'ON_CANCEL',
      ON_TRIGGER: 'ON_TRIGGER',
      ON_REASSIGN: 'ON_REASSIGN',
      DUE_DATE_CHANGE: 'DUE_DATE_CHANGE',
      DUE_DATE_REMINDER: 'DUE_DATE_REMINDER',
      ADMIN_ON_COMPLETE: 'ADMIN_ON_COMPLETE',
      ADMIN_DUE_DATE_REMINDER: 'ADMIN_DUE_DATE_REMINDER',
    },
  },
  employeeWorkflow: {
    statuses: {
      /**
       * TRIGGERED means employee workflow is active
       */
      TRIGGERED: 'TRIGGERED',
      /**
       * PAUSED will be set when timeline is not active (used for performance optimization),
       * should not be set individually since activation/deactivation of timeline will
       * override it
       */
      PAUSED: 'PAUSED',
      /**
       * COMPLETED - set when all newHireTasks that belong to it are either CANCELLED or COMPLETED
       */
      COMPLETED: 'COMPLETED',
    },
    inactiveStatuses: ['COMPLETED'], // PAUSED is intentionally omitted here
    nhtsSyncStates: {
      PENDING: 'PENDING',
      RUNNING: 'RUNNING',
      SYNCED: 'SYNCED',
    },
    idle: 'Idle',
  },
  newHireNotification: {
    statuses: {
      /**
       * CREATED - nHn will be in this status if newHireTask is in CREATED or CANCELED
       */
      CREATED: 'created',
      /**
       * ACTIVE - this is only state from which newHireNotification can be actually sent(in which email job is created)
       */
      ACTIVE: 'active',
      /**
       * CANCELLED - manually set in case when we don't want to send it to employee
       */
      CANCELLED: 'cancelled',
      /**
       * FLAGGED - something is wrong with nHn, e.g. there is no recipient
       */
      FLAGGED: 'flagged',
      /**
       * COMPLETED - sent field is set to non-null value(some date)
       */
      COMPLETED: 'completed',
      /**
       * PAUSED - will be set when timeline is not active(used for performance optimization),
       * should not be set individually since activation/deactivation of timeline will
       * override it
       */
      PAUSED: 'paused',
      /**
       * SKIPPED will be set for surveys IFF:
       *   - All survey questions use field forwarding to employee fields
       *   - The survey has skipIfForwardedFieldsFulfilled set to true
       *   - All forwarded-to employee fields are already set
       */
      SKIPPED: 'skipped',
    },
  },
  field: {
    defaultNames: {
      FIRST_NAME: 'First name',
      PREFERRED_FIRST_NAME: 'Preferred first name',
      EMAIL: 'Email',
      PERSONAL_EMAIL: 'Personal email',
      LAST_NAME: 'Last name',
    },
    /**
     * @namespace fieldTypes
     */
    types: {
      EMPLOYEE_FIELD: 'existing_employee_field',
      SHORT_ANSWER: 'short_answer',
      PARAGRAPH: 'paragraph',
      NUMBER: 'number',
      RELATIONSHIP: 'relationship',
      SECURE: 'secure',
      DATE: 'date',
      TIMESTAMP: 'timestamp',
      TIMESTAMP_WITH_TIMEZONE: 'timestamp_with_timezone',
      TIME: 'time',
      TIME_WITH_TIMEZONE: 'time_with_timezone',
      IMAGE: 'image',
      FILE: 'file',
      LINK: 'link',
      EMAIL: 'email',
      PHONE: 'phone',
      BOOLEAN: 'boolean',
      ADDRESS: 'address',
      MULTIPLE_CHOICE: 'multiple_choice',
      TIMEZONE: 'timezone',
      EVENT: 'event',
      MEASUREMENT: 'measurement',
    },
    fieldTypes: {
      SURVEY: 'survey',
      FIELD: 'field',
    },
    // support also these formats for input date values for simplified imports
    additionalFormats: ['MM/DD/YYYY', 'M/D/YYYY', 'MM/DD/YY', 'M/D/YY'],
    dateFormats: {
      date: 'YYYY-MM-DD',
      timestamp: 'YYYY-MM-DDTHH:mm:ss',
      timestampWithTimezone: 'YYYY-MM-DDTHH:mm:ssZ',
      time: 'HH:mm',
      timeWithTimezone: 'HH:mmZ',
      event: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
    },
    displayDateFormats: {
      date: 'MM/DD/YYYY',
      timestamp: 'MM/DD/YYYY HH:mm',
      timestampWithTimezone: 'MM/DD/YYYY HH:mm Z',
      time: 'HH:mm',
      timeWithTimezone: 'HH:mm Z',
      // lower z formating will show UTC instead of +00:00
      event: 'MM/DD/YYYY HH:mm z',
    },
  },
  integrationRunTrigger: {
    states: {
      CREATED: 'CREATED',
      QUEUED: 'QUEUED',
      LOADING: 'LOADING',
      ACTIVE: 'ACTIVE',
      PAUSED: 'PAUSED',
      ABORTED: 'ABORTED',
      ENDED: 'ENDED',
    },
  },
  integrationRunWork: {
    outputTypes: {
      STRING: 'string',
      NUMBER: 'number',
      JSON: 'json',
      ARRAY: 'array',
    },
  },
  integrationV2Run: {
    seedPath: '$1',
    states: {
      PENDING: 'PENDING',
      ACTIVE: 'ACTIVE',
      COMPLETED: 'COMPLETED',
      FAILED: 'FAILED',
      CANCELLED: 'CANCELLED',
    },
  },
  taskRun: {
    states: {
      COMPLETED: 'COMPLETED',
      FAILED: 'FAILED',
    },
  },
  webhookEvents: {
    domains: {
      ashby: 'ashby',
      greenhouse: 'greenhouse',
      lever: 'lever',
      adp: 'adp',
      adprun: 'adprun',
    },
    events: {
      ashby: {
        candidateStageChange: {
          eventName: 'candidateStageChange',
          filterKeys: ['stageName'],
        },
      },
      greenhouse: {
        candidateHired: { eventName: 'candidateHired' },
        candidateStageChange: {
          eventName: 'candidateStageChange',
          filterKeys: ['stageId'],
        },
        offerCreated: { eventName: 'offerCreated' },
      },
      lever: {
        candidateStageChange: {
          eventName: 'candidateStageChange',
          filterKeys: ['stageId'],
        },
        candidateHired: { eventName: 'candidateHired' },
      },
      adp: {
        subscriptionCreated: {
          eventName: 'subscriptionCreated',
        },
        subscriptionCanceled: {
          eventName: 'subscriptionCanceled',
        },
      },
      adprun: {
        subscriptionCreated: {
          eventName: 'subscriptionCreated',
        },
        subscriptionCanceled: {
          eventName: 'subscriptionCanceled',
        },
      },
    },
    errorTypes: {
      FIELD_MAPPING: 'fieldMapping',
      OTHER: 'other',
    },
    statuses: {
      SUCCESS: 'success',
      FAILURE: 'failure',
      PENDING: 'pending',
    },
    types: {
      WORKFLOW_TRIGGER: 'workflowTrigger',
      NOTIFICATION: 'notification',
    },
  },
  webhookEventFieldMappings: {
    configuredDomains: {
      ashby: 'ashby',
      greenhouse: 'greenhouse',
      lever: 'lever',
    },
    mappingTypes: {
      DATA_PATH: 'dataPath',
      JSONATA_EXPRESSION: 'jsonataExpression',
    },
  },
  spreadsheetViews: {
    viewTypes: {
      workflow: 'workflow',
    },
    defaultViewNamesByType: {
      workflow: 'All tasks',
    },
  },
  spreadsheetViewColumns: {
    objectTypes: {
      task: 'task',
      field: 'field',
      employeeWorkflow: 'employeeWorkflow',
    },
  },
  email: {
    defaultReplyToAddress: 'no-reply@sora.co',
    defaultEmailDisplayName: 'Sora notifications',
  },
};

module.exports = {
  modelConstants,
  nhtStates: modelConstants.newHireTask.states,
  nhtFlags: modelConstants.newHireTask.flags,
  timezones: modelConstants.company.timezones,
  digestFrequencies: {
    NEVER: 'NEVER',
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
  },
  error: {
    codes: {
      NOT_FOUND: 'NOT_FOUND',
      FORBIDDEN: 'FORBIDDEN',
      UNAUTHENTICATED: 'UNAUTHENTICATED',
      NEEDS_REVIEW: 'NEEDS_REVIEW',
      INVALID: 'INVALID',
      INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
      BAD_USER_INPUT: 'BAD_USER_INPUT',
      PERSISTED_QUERY_NOT_FOUND: 'PERSISTED_QUERY_NOT_FOUND',
    },
    types: {
      SEQUELIZE_VALIDATION_ERROR: 'SequelizeValidationError',
      SEQUELIZE_UNIQUE_CONSTRAINT_ERROR: 'SequelizeUniqueConstraintError',
    },
    messages: {
      WRONG_COMPANY: 'You need to switch first to company number',
      RESIZE_OBSERVER_LOOP_UNDELIVERED_NOTIFS:
        'ResizeObserver loop completed with undelivered notifications.',
    },
    actions: {
      SWITCH_TO_COMPANY: 'SWITCH_TO_COMPANY',
    },
  },
  integrations: {
    domains: {
      meta: {
        collectArbitraryDataSchemaParameter: 'arbitraryDataCollectionSchema',
      },
    },
  },
  filters: {
    ops: {
      is: 'is',
      isNot: 'isNot',
      isSet: 'isSet',
      isNotSet: 'isNotSet',
      inDateRange: 'inDateRange',
      contains: 'contains',
    },
  },
  csvConstants: {
    EMPTY_VALUE: '<EMPTY VALUE>',
  },
  emailConstants: {
    MAXIMUM_EMAIL_BODY_SIZE: 10 ** 7,
  },
  birthdayCelebrationWorkflowName: 'Birthday celebration',
};
