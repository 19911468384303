import { noop } from 'lodash';
import { bool, func, string } from 'prop-types';
import React from 'react';

import microsoftLogo from 'sora-client/static/images/microsoft-logo.png';

const MicrosoftLoginButton = ({ disabled, onClick = noop, authType }) => {
  return (
    <button
      className='d-flex cursor-pointer border-none margin-top-3 margin-x-auto rounded'
      style={{
        minWidth: '210px',
        backgroundColor: '#2f2f2f',
        padding: '6px 12px',
      }}
      disabled={disabled}
      onClick={onClick}
    >
      <div>
        <img
          src={microsoftLogo}
          className='position-relative'
          alt='Microsoft'
          width='28'
        />
      </div>
      <div
        className='flex-grow-1 align-self-center text-small'
        style={{ marginLeft: '12px' }}
      >
        <strong className='text-paper-white'>{`${
          authType === 'signUp' ? 'Sign up' : 'Log in'
        } with Microsoft`}</strong>
      </div>
    </button>
  );
};

MicrosoftLoginButton.propTypes = {
  disabled: bool,
  onClick: func,
  authType: string,
};

export default MicrosoftLoginButton;
