import classNames from 'classnames';
import { arrayOf, number, object, shape, string } from 'prop-types';
import React from 'react';

import BoxCard from 'sora-client/components/common/BoxCard';
import { LinkButton } from 'sora-client/components/common/LinkButton';
import { NEW_HIRE_TASKS } from 'sora-client/constants/routes';
import { pluralize } from 'sora-client/helpers';
import waterPlantImage from 'sora-client/static/images/water-plant.png';

import HomePageCard from '../HomePageCard';
import HomePageCardTitle from '../HomePageCardTitle';
import { EmployeeTaskList } from './EmployeeTaskList';
import { GET_HOMEPAGE_EMPLOYEE_TASK_LIST } from './queries';

const EmployeeTaskListCard = ({
  className,
  tasksDueThisWeek = {},
  tasksDueUpNext = {},
  overdueTasksCount = 0,
  allTasksDueCount = 0,
}) => {
  // @ts-ignore
  const { count: tasksDueThisWeekCount = 0, rows: tasksDueThisWeekRows = [] } =
    tasksDueThisWeek;
  // @ts-ignore
  const { count: tasksDueUpNextCount = 0, rows: tasksDueUpNextRows = [] } =
    tasksDueUpNext;

  return (
    <HomePageCard className='employee-task-list-card'>
      <BoxCard className={className}>
        {/* display empty state */}
        {!tasksDueThisWeekCount && (
          <div className='padding-5 mobile-padding-3'>
            <div className='d-flex justify-content-between'>
              <h3 className='margin-y-0 font-p22-mackinac font-weight-400'>
                You&apos;re all caught up!
              </h3>
              <LinkButton
                className='margin-left-auto'
                to={NEW_HIRE_TASKS}
                label='Go to all Tasks'
                analyticsEventName='Open tasks button'
                analyticsEventData={{
                  'All Tasks Count': allTasksDueCount,
                  'Overdue Task Count': overdueTasksCount,
                }}
              />
            </div>
            <div className='text-muted'>
              Thanks for staying on top of your tasks! You&apos;ll get notified
              of new tasks via email.
            </div>
            <div
              className={classNames('text-center mobile-margin-top-5', {
                'margin-top-7': !tasksDueUpNextCount,
              })}
            >
              <img src={waterPlantImage} alt='All done' width={280} />
            </div>
          </div>
        )}
        {!!tasksDueThisWeekCount && (
          <>
            <div className='padding-top-5 padding-x-5 mobile-padding-3 d-flex justify-content-between'>
              <HomePageCardTitle
                title={`${tasksDueThisWeekCount} open ${pluralize(
                  'task',
                  tasksDueThisWeekCount,
                )} assigned to you`}
              />
              <LinkButton
                className=''
                to={NEW_HIRE_TASKS}
                label='Go to all Tasks'
                analyticsEventName='Open tasks button'
                analyticsEventData={{
                  'All Tasks Count': allTasksDueCount,
                  'Overdue Task Count': overdueTasksCount,
                }}
              />
            </div>
            <div className='padding-x-5 mobile-padding-3'>
              {overdueTasksCount > 0 && (
                <div className='text-muted'>
                  Uh oh! Looks like you have{' '}
                  <span className='font-weight-500 text-danger fade-70'>
                    {overdueTasksCount} overdue
                  </span>{' '}
                  {pluralize('task', overdueTasksCount)}.
                </div>
              )}
            </div>
            {/* Due in Next 7 Days task list */}
            <EmployeeTaskList
              columnName='Due in Next 7 Days'
              tasksCount={tasksDueThisWeekCount}
              tasks={tasksDueThisWeekRows}
            />
          </>
        )}
        {(!!tasksDueUpNextCount ||
          (!!tasksDueThisWeekCount && !tasksDueUpNextCount)) && (
          <>
            {/* Up Next (7 - 30 days) task list */}
            <EmployeeTaskList
              columnName='Up Next'
              tasksCount={tasksDueUpNextCount}
              tasks={tasksDueUpNextRows}
            />
          </>
        )}
      </BoxCard>
    </HomePageCard>
  );
};

EmployeeTaskListCard.propTypes = {
  className: string,
  tasksDueThisWeek: shape({
    count: number,
    rows: arrayOf(object),
  }),
  tasksDueUpNext: shape({
    count: number,
    rows: arrayOf(object),
  }),
  overdueTasksCount: number,
  allTasksDueCount: number,
};

EmployeeTaskListCard.fragments = {
  query: GET_HOMEPAGE_EMPLOYEE_TASK_LIST,
};

export default EmployeeTaskListCard;
