import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  COMMON_OAUTH_CONFIG_PROPERTIES,
  DEFAULT_INTEGRATION,
  OAUTH_REDIRECT_URI,
} from '../../common';

export const MICROSOFT_OUTLOOK_INTEGRATION_ID = 50;

// This allows support for multi-tenant organization accounts,
// that is accounts associated with many organizations and not limited to only one
const TENANT = 'organizations';

export const MICROSOFT_OUTLOOK_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: MICROSOFT_OUTLOOK_INTEGRATION_ID,
  name: 'microsoftoutlook',
  label: 'Outlook Calendar',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/microsoftoutlook.png`,
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  categories: [CATEGORIES.OTHER],
  authFlow: {
    type: 'Oauth2',
    redirectUri: OAUTH_REDIRECT_URI,
    scope: 'offline_access Calendars.ReadWrite Calendars.ReadWrite.Shared',
    clientId: process.env.MICROSOFT_CLIENT_ID,
    clientSecret: process.env.MICROSOFT_CLIENT_SECRET,
    authorizeAddons: {
      response_mode: 'query',
    },
    authorizeEndpoint: `https://login.microsoftonline.com/${TENANT}/oauth2/v2.0/authorize`,
    configToClear: {
      accessToken: null,
      accessTokenExpirationDate: null,
      refreshToken: null,
    },
  },
  configSchema: {
    ...COMMON_OAUTH_CONFIG_PROPERTIES,
    refreshToken: {
      type: 'string',
      title: 'API Refresh token',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Hidden',
        'ui:options': {
          label: false,
        },
      },
    },
  },
  configured: (c) => {
    if (!c.values) return false;
    return ['accessToken', 'refreshToken'].every((schemaKey) => {
      return !!c.values[schemaKey];
    });
  },
};
