import classNames from 'classnames';
import {
  array,
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useClickOutside } from 'sora-client/components/common/hooks';

import OptionCustom from './OptionCustom';
import Search from './Search';
import ToggleIcon from './ToggleIcon';

const SelectCustom = (props) => {
  const {
    children = null,
    className = null,
    CustomEntry,
    style = null,
    multiple,
    name,
    onChange,
    onSearch = null,
    options,
    optionRenderFn = null,
    placeholder,
    search,
    value,
    valueObj = null,
    initiallyOpen = false,
    includeSelectAll = false,
    includeDeselectAll = false,
  } = props;
  const [customSelectOpen, toggleCustomSelect] = useState(initiallyOpen);
  const [filteredOptions, updateFilteredOptions] = useState(options);
  const [defaultSearchValue, updateDefaultSearchValue] = useState('');
  const [lastKeyNavigation, setLastKeyNavigation] = useState({});

  const customEntryRef = useRef(/** @type {HTMLElement} */ (null));
  const searchRef = useRef(/** @type {HTMLElement} */ (null));
  const selectRef = useRef(/** @type {HTMLElement} */ (null));
  const toggleRef = useRef(/** @type {HTMLElement} */ (null));

  const onClickOutside = useCallback(
    (e) => {
      if (customSelectOpen && !selectRef.current?.contains(e.target)) {
        toggleCustomSelect(false);
      }
    },
    [customSelectOpen, toggleCustomSelect],
  );
  const clickOutsideRef = useClickOutside(onClickOutside);

  useEffect(() => {
    updateFilteredOptions(options);
  }, [options]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Escape') toggleCustomSelect(false);
    },
    [toggleCustomSelect],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleSelectButtonClick = (e) => {
    if (
      customEntryRef.current?.contains(e.target) ||
      searchRef.current?.contains(e.target) ||
      (multiple && customSelectOpen && !toggleRef.current?.contains(e.target))
    ) {
      // Don't close multi-select dropdown when a single item is selected, or
      // when clicking in search or custom entry components
      return;
    }
    toggleCustomSelect(!customSelectOpen);
  };

  const getToggleValue = () => {
    if (!value) {
      return placeholder;
    }
    if (multiple) {
      return `${placeholder} (${value.length})`;
    }

    return (
      options.find((opt) => opt.value === value)?.label ||
      valueObj?.label ||
      placeholder
    );
  };

  const handleSearchUpdate = (val) => {
    updateDefaultSearchValue(val);
    onSearch && onSearch(val);
  };

  const handleChange = (val, valObj) => {
    onChange(val, valObj);
  };

  const optionsArray = options.map((opt) => opt.value);
  const allValuesSelected = value?.length === optionsArray?.length;

  const handleSelectAll = (_val, valObj) => {
    onChange(optionsArray, valObj);
  };

  const handleDeselectAll = (_val, valObj) => {
    onChange([], valObj);
  };

  return (
    <div
      aria-hidden={true}
      className={classNames(
        'select-custom cursor-pointer bg-white border-a rounded',
        className,
        {
          'border-color-spring-sky-blue': customSelectOpen,
        },
      )}
      data-testid={`select-custom-${name}`}
      onClick={handleSelectButtonClick}
      ref={selectRef}
    >
      {/* Select */}
      <div
        className={classNames('select-custom-value width-100 height-100', {
          'text-midnight-navy-50':
            !value || (Array.isArray(value) && !value.length),
        })}
        data-testid={`select-custom-${name}-display`}
        ref={toggleRef}
      >
        <div className='overflow-hidden text-overflow-ellipsis width-80 margin-left-3'>
          {getToggleValue()}
        </div>
      </div>
      <div className='d-flex flex-1 align-items-center justify-content-end margin-right-3'>
        <ToggleIcon open={customSelectOpen} />
      </div>
      {/* Dropdown */}
      {customSelectOpen && (
        <div
          ref={clickOutsideRef}
          className='select-custom-options box-shadow-3 bg-white elevated-z-index rounded-large overflow-hidden'
          style={style}
        >
          {search && (
            <div
              className='padding-x-3 d-flex select-custom-search'
              ref={searchRef}
            >
              <Search
                allOptions={options}
                defaultSearchValue={defaultSearchValue}
                filteredOptions={filteredOptions}
                onChange={handleChange}
                onToggle={toggleCustomSelect}
                setLastKeyNavigation={setLastKeyNavigation}
                updateDefaultSearchValue={handleSearchUpdate}
                updateFilteredOptions={updateFilteredOptions}
              />
            </div>
          )}
          {(includeSelectAll || includeDeselectAll) && (
            <div className='border-bottom border-thickness-xl border-color-midnight-navy-50'>
              {includeDeselectAll && (
                <div>
                  <OptionCustom
                    option={{ label: 'Deselect all', value: 'Deselect all' }}
                    key={'Deselect all'}
                    onChange={handleDeselectAll}
                    selectName={name}
                    selectValue={value}
                    html={
                      <div className='margin-left-4 text-midnight-navy-50 margin-top-1'>
                        Deselect all
                      </div>
                    }
                  />
                </div>
              )}
              {includeSelectAll && (
                <div>
                  <OptionCustom
                    option={{ label: 'Select all', value: 'Select all' }}
                    key={'select all'}
                    multiple={multiple}
                    onChange={handleSelectAll}
                    selectName={name}
                    selectValue={value}
                    value='Select all'
                    label='Select all'
                    className='text-midnight-navy-50'
                    checked={allValuesSelected}
                  />
                </div>
              )}
            </div>
          )}

          {filteredOptions.map((opt, idx) => {
            return (
              <OptionCustom
                option={opt}
                {...opt}
                className={classNames(opt.className, {
                  'bg-gray-1': lastKeyNavigation.cursor === idx,
                })}
                data-testId={`select-custom-${name}-${opt.value}`}
                html={
                  opt.html
                    ? opt.html
                    : optionRenderFn
                    ? optionRenderFn(opt)
                    : null
                }
                key={opt.value}
                multiple={multiple}
                onChange={handleChange}
                selectName={name}
                selectValue={value}
              />
            );
          })}
          {children}
          {!filteredOptions.length && (
            <div
              className='select-custom-option text-muted pointer-events-none padding-x-3 cursor-default'
              style={{ minHeight: '40px' }}
            >
              None found
            </div>
          )}
          {CustomEntry && <div ref={customEntryRef}>{CustomEntry}</div>}
        </div>
      )}
    </div>
  );
};

SelectCustom.propTypes = {
  children: array,
  className: string,
  CustomEntry: object,
  disabled: bool,
  label: string,
  style: shape({
    bottom: string,
    left: string,
    right: string,
    top: string,
  }),
  multiple: bool,
  name: string.isRequired,
  onChange: func.isRequired,
  onSearch: func,
  options: arrayOf(
    oneOfType([
      shape({
        id: string,
        name: string,
        label: string,
        className: string,
        sortLevel: number,
        value: oneOfType([array, bool, number, string, oneOf([null])]),
      }),
      string,
    ]),
  ).isRequired,
  optionRenderFn: func,
  placeholder: string,
  search: bool,
  value: oneOfType([array, bool, number, string]),
  valueObj: shape({
    label: string,
    value: oneOfType([array, string]),
  }),
  initiallyOpen: bool,
};

export default SelectCustom;
