import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const ZENEFITS_INTEGRATION_ID = 39;

export const ZENEFITS_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: ZENEFITS_INTEGRATION_ID,
  name: 'zenefits',
  label: 'Zenefits',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/zenefits.png`,
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  categories: [CATEGORIES.HRIS],
  configSchema: {
    apiToken: {
      type: 'string',
      title: 'API token',
      nullable: true,
    },
    webhookKey: {
      type: 'string',
      title: 'Webhook key',
      autogenerate: true,
      uiSchema: {
        'ui:widget': 'ReadOnlyValueToCopy',
      },
    },
    webhookSecret: {
      type: 'string',
      title: 'Webhook secret',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
  },
  configured: (c) => c.values && c.values.apiToken,
};
