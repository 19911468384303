import distance from 'fast-levenshtein';
import { compact, flatten, isNumber, minBy, omit, some, uniq } from 'lodash';

function addSortInfo(
  item,
  searchInput = '',
  itemAliases = [],
  minInputLength = 1,
) {
  let newItem = omit(item, 'sortLevel');
  const lowercaseItemName = (
    newItem.name?.toString() ||
    newItem.label?.toString() ||
    newItem.value?.toString() ||
    ''
  ).toLowerCase();
  const lowercaseItemAliases = itemAliases.map((alias) => alias.toLowerCase());
  const input = searchInput.toLowerCase();

  const itemsToSearch = compact([lowercaseItemName, ...lowercaseItemAliases]);

  const startsWithInput = some(itemsToSearch, (el) => el.startsWith(input));
  const longEnoughMatch =
    input.length >= minInputLength &&
    some(itemsToSearch, (el) => el.includes(input));
  if (startsWithInput || longEnoughMatch) {
    // rank items that start with the input highest
    newItem.sortLevel = startsWithInput ? 1 : 2;
  } else {
    // for all words in the search input and for all words in the item name,
    // if there are any matches, include them and sort them by Levenshtein
    // distance, but sort them all below matches with sortLevel 1 or 2
    // (startsWithInput or longEnoughMatch) by adding 3 to Levenshtein distance
    const allItemWords = uniq(
      flatten(itemsToSearch.map((el) => el.split(' '))),
    );
    for (const itemWord of allItemWords) {
      for (const inputWord of input.split(` `)) {
        if (
          itemWord.startsWith(inputWord) &&
          inputWord.length > minInputLength
        ) {
          // get Levenshtein or "edit" distance of strings to sort among any matches
          newItem.sortLevel = 3 + distance.get(lowercaseItemName, searchInput);
        }
      }
    }
  }

  return newItem;
}

function filterAndSortItems(items, searchInput, maxDistance = null) {
  if (!searchInput) return items;

  // Add sort info to the provided items, based on relevance to the searchTerm.
  // Filter out any items that don't match the search term at all
  const itemsWithSortInfo = items
    .map((i) => addSortInfo(i, searchInput))
    .filter((i) => !!i.sortLevel);

  // Deterine the best sort level, where the lowest sort level indicates the
  // highest match
  // @ts-ignore
  const bestSortLevel = minBy(itemsWithSortInfo, 'sortLevel')?.sortLevel;

  // If no maxDistance is specified, impose a max allowed distance from the search
  // term of 2 * the best sort level. This ensures we filter out items that are very
  // unrelevant when we have a highly relevant match
  const maxDistanceImposed =
    maxDistance || bestSortLevel ? bestSortLevel * 2 : null;

  if (!isNumber(maxDistanceImposed))
    // If no max distance imposed, return the full list of items sorted by sort level
    return itemsWithSortInfo.sort((a, b) => a.sortLevel - b.sortLevel);

  return (
    itemsWithSortInfo
      // otherwise, filter out items whose sort level is lower than the max allowed disatnce
      .filter((i) => i.sortLevel <= maxDistanceImposed)
      .sort((a, b) => a.sortLevel - b.sortLevel)
  );
}

export { addSortInfo, filterAndSortItems };
