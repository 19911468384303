import { object } from 'prop-types';
import React from 'react';

import { EmailSvg } from 'sora-client/components/common/Svg';
import { userAuthorization } from 'sora-client/helpers/authorization';

const EmployeeListNullState = ({ currentUser }) => (
  <div className='padding-bottom-3'>
    <div>No employees found.</div>
    {userAuthorization(currentUser).can({
      resource: 'Employee',
      action: 'CREATE',
    }) && (
      <a
        className='d-inline-flex align-items-center margin-top-2'
        href='mailto:support@sora.co'
      >
        <div className='svg-parent-align-text'>
          <EmailSvg />
        </div>
        <div className='margin-left-2'>Get help</div>
      </a>
    )}
  </div>
);

EmployeeListNullState.propTypes = {
  currentUser: object.isRequired,
};

export default EmployeeListNullState;
