import classNames from 'classnames';
import { object } from 'prop-types';
import React from 'react';

const NavItemActiveHoverIndicator = ({ className, selectedBorderStyle }) => {
  return (
    <div
      className={classNames(
        'position-absolute position-bottom position-left position-right transition-opacity',
        className,
      )}
      style={{ height: '4px', ...selectedBorderStyle }}
    />
  );
};

NavItemActiveHoverIndicator.propTypes = {
  className: object,
  selectedBorderStyle: object.isRequired,
};

export default NavItemActiveHoverIndicator;
