/* eslint-disable react/display-name */
import classNames from 'classnames';
import { every, isEmpty, noop } from 'lodash';
import { array, bool, func, object, oneOfType, string } from 'prop-types';
import React, { useState } from 'react';
import { Column } from 'react-base-table';

import Tooltip from 'sora-client/components/common//Tooltip';
import { UserSvg } from 'sora-client/components/common/Svg';

import Checkbox from './Checkbox';
import Table, { generateKey } from './Table';

const EditableTable = ({
  selectable,
  onSelectChange = noop,
  changedMap = {},
  errorsMap = {},
  warningsMap = {},
  columns = [],
  data = [],
  ...props
}) => {
  const [editCellId, setEditCellId] = useState('');

  const columnMark = columns.find((c) => c.errorsExistMark);
  data = data.map((row) => {
    const errors = errorsMap[row.id] || [];
    const warnings = warningsMap[row.id] || [];

    const cellErrors = row.selected
      ? warnings.concat(errors).reduce((ce, e) => {
          ce[e.key] = {
            bordered: true,
            mark: true,
            message: e.error || e.warning,
            isWarning: !!e.warning,
          };

          return ce;
        }, {})
      : {};

    if (!isEmpty(cellErrors) && columnMark && !cellErrors[columnMark.key]) {
      const markKey = columnMark.key || generateKey(columnMark);
      cellErrors[markKey] = {
        mark: true,
        isWarning: every(cellErrors, (error) => error.isWarning),
      };
    }

    return {
      ...row,
      ...changedMap[row.id],
      editOptions: {
        editId: editCellId,
      },
      cellErrors,
    };
  });

  const onEditStart = ({ cellId }) => {
    setEditCellId(cellId);
  };

  const onEditStop = () => setEditCellId('');

  const changeSelect = (id, selected) => onSelectChange({ [id]: selected });
  const onBulkChange = ({ select, data }) => {
    onSelectChange(
      data.reduce((acc, v) => {
        acc[v.id] = select;
        return acc;
      }, {}),
    );
  };

  if (selectable && !columns.find((c) => c.key === 'selected')) {
    columns = [
      {
        key: 'selected',
        editable: false,
        width: 50,
        frozen: Column.FrozenDirection.LEFT,
        headerRenderer: (e) => {
          const data = e.container.props.data;

          return (
            <Checkbox
              checked={data.every((ie) => ie.selected)}
              name='bulk-change-select'
              onChange={(e) => onBulkChange({ select: e.target.checked, data })}
            />
          );
        },
        /* eslint-disable react/prop-types */
        cellRenderer: ({ cellData, rowData }) => {
          return (
            <Checkbox
              data-testId={`cell-select-${rowData.id}`}
              checked={!!cellData}
              onChange={(e) => changeSelect(rowData.id, e.target.checked)}
              name='bulk-change-select'
            />
          );
        },
        /* eslint-enable react/prop-types */
      },
      {
        key: 'existingEmployee',
        editable: false,
        width: 115,
        frozen: Column.FrozenDirection.LEFT,
        title: 'Existing employee',
        headerRenderer: (e) => {
          return (
            <div className='BaseTable__header-cell-text text-wrap padding-right-0'>
              Existing employee
            </div>
          );
        },
        cellRenderer: ({ cellData, rowData }) => {
          return (
            <div className='d-inline-flex justify-content-center flex-direction-column margin-0 padding-right-0 '>
              {rowData.existingEmployee && (
                <Tooltip
                  tooltip='Employee fields will be updated'
                  placement='right'
                >
                  <UserSvg className='svg-medium margin-left-4' />
                </Tooltip>
              )}
            </div>
          );
        },
        /* eslint-enable react/prop-types */
      },
      ...columns,
    ];
  }

  selectable = columns.find((c) => c.key === 'selected');

  let rowClassName = props.rowClassName;
  if (!rowClassName) {
    /* eslint-disable react/prop-types */
    rowClassName = ({ rowData }) =>
      classNames({
        'row-error': !isEmpty(rowData.cellErrors),
        'text-muted': selectable && !rowData.selected,
      });
    /* eslint-enable react/prop-types */
  }

  return (
    <Table
      fixed
      genericCell
      editable
      highlightErrors
      editCellId={editCellId}
      {...props}
      onEditStart={onEditStart}
      onEditStop={onEditStop}
      data={data}
      columns={columns}
      className={classNames(
        'zero-cell-padding default-header-padding',
        props.className,
      )}
      rowClassName={rowClassName}
    />
  );
};

EditableTable.propTypes = {
  columns: array.isRequired,
  selectable: bool,
  onSelectChange: func,
  changedMap: object,
  errorsMap: object,
  warningsMap: object,
  className: string,
  rowClassName: oneOfType([string, func]),
  data: array,
  onStateUpdate: func,
};

export default EditableTable;
