import classNames from 'classnames';
import { object, string } from 'prop-types';
import React from 'react';

const HomePageCard = ({ children, className = null }) => {
  return (
    <div
      className={classNames(
        'margin-bottom-4 margin-right-4 mobile-margin-right-4',
        className,
      )}
    >
      {children}
    </div>
  );
};

HomePageCard.propTypes = {
  children: object.isRequired,
  className: string,
};

export default HomePageCard;
