import { modelConstants } from 'sora-invariant/src/constants';

export const BUTTON_TYPES = [
  'primary',
  'secondary',
  'tertiary',
  'warning',
  'destructive',
  'info',
  'action',
  'actionSecondary',
  'link',
  'none',
];

export const BUTTON_SIZES = ['default', 'small', 'large', 'none', 'xsmall'];

export const COLORS = {
  GREEN: 'green',
  ORANGE: 'orange',
  YELLOW: 'yellow',
  RED: 'red',
  GRAY: 'gray',
};

export const BG_COLORS_TO_CLASSNAME = {
  [COLORS.GREEN]: 'bg-green-2',
  [COLORS.ORANGE]: 'bg-sunset',
  [COLORS.YELLOW]: 'bg-sunrise',
  [COLORS.RED]: 'bg-warning',
  [COLORS.GRAY]: 'bg-gray-2',
};

export const DEFAULT_AVATAR_COLORS = [
  '#640763', // sunset purple
  '#5936ae', // twilight indigo
  '#62b2e9', // spring sky blue
  '#a7ebd6', // winter mint
  '#323246', // midnight navy
  '#4779ce', // high noon indigo
  '#ff724e', // persimmon
];

export const WEBHOOK_EVENT_STATUS_TO_COLOR = {
  [modelConstants.webhookEvents.statuses.SUCCESS]: COLORS.GREEN,
  [modelConstants.webhookEvents.statuses.FAILURE]: COLORS.RED,
};

export const INTEGRATION_RUN_TRIGGER_STATE_TO_COLOR = {
  [modelConstants.integrationRunTrigger.states.ACTIVE]: COLORS.GREEN,
  [modelConstants.integrationRunTrigger.states.CREATED]: COLORS.YELLOW,
  [modelConstants.integrationRunTrigger.states.QUEUED]: COLORS.YELLOW,
  [modelConstants.integrationRunTrigger.states.LOADING]: COLORS.YELLOW,
  [modelConstants.integrationRunTrigger.states.PAUSED]: COLORS.ORANGE,
  [modelConstants.integrationRunTrigger.states.ENDED]: COLORS.RED,
  [modelConstants.integrationRunTrigger.states.ABORTED]: COLORS.RED,
};
