import classNames from 'classnames';
import { get, kebabCase, noop, orderBy } from 'lodash';
import { any, array, bool, element, func, number, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import {
  scrollContainerToFocusedElement,
  useEffectAfterMount,
} from 'sora-client/helpers';
import { addSortInfo } from 'sora-client/helpers/search';

import DropdownInnerContainer from './Dropdown/DropdownInnerContainer';
import DropdownOuterContainer from './Dropdown/DropdownOuterContainer';
import { KeyDownSearchBar } from './Search/KeyDownSearchBar';
import { useStateDebounce } from './hooks';

const EMPTY_ITEM = 'None';

const StaticDropdown = ({
  initiallyOpen = false,
  onOpenAndClose = noop,
  onClose = noop,
  dropdownToggle = null,
  dropdownItems,
  otherClasses = null,
  popperClassname = null,
  otherInnerDropdownClasses = null,
  onClickItem = noop,
  selectedItem = null,
  defaultSelected = null,
  disabled = false,
  search = false,
  allowEmptyValue = false,
  emptyDropdownItemsMessage = 'No items to choose from',
  placement = 'bottom-start',
  placeholder = 'Select',
  searchPlaceholder = null,
  clickOutsideOnClickDropdown = false,
  closeOnToggleClick = true,
  togglerClassName = null,
  portalTargetRef = null,
  closeOnItemClick = true,
  'data-testid': dataTestId = null,
  dropdownItemAliases = {},
  ...restProps
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [processedDropdownItems, setProcessedDropdownItems] = useStateDebounce(
    dropdownItems,
    100,
  );

  const [lastKeyNavigation, setLastKeyNavigation] = useState(
    /** @type {*} */ ({}),
  );
  const containerRef = useRef();

  let setOpenFn;

  if (defaultSelected && !selectedItem) {
    selectedItem = dropdownItems[0];
  }

  const onSelectDropdownItem = ({ item, index }) => {
    if (item.html) {
      get(item.html, 'props.onClick', noop)();
    } else {
      onClickItem(item === EMPTY_ITEM ? null : item, index);
    }

    closeOnItemClick && setOpenFn(false);
  };

  useEffectAfterMount(() => {
    const currentDropdownItem = document.querySelector(
      `.dropdown-item-${lastKeyNavigation.cursor}`,
    );

    scrollContainerToFocusedElement({
      container: containerRef.current,
      focusedElement: currentDropdownItem,
    });
  }, [lastKeyNavigation]);

  useEffect(() => {
    if (allowEmptyValue && !searchInput) {
      setProcessedDropdownItems([EMPTY_ITEM, ...dropdownItems]);
    } else {
      if (!searchInput) {
        setProcessedDropdownItems(dropdownItems);
      } else {
        // First filter out any items that don't match the searchInput at all
        const itemsWithMatch = dropdownItems.filter((item) => {
          const itemName = item.name || item || '';
          const sortInfo = addSortInfo(
            { name: itemName },
            searchInput,
            dropdownItemAliases[itemName] || [],
          );
          return item.noSelect || !!sortInfo.sortLevel;
        });
        setProcessedDropdownItems(
          // Then order the items that match the search input in some way by
          // the strength of the match
          orderBy(itemsWithMatch, (item) => {
            const itemName = item.name || item || '';
            const sortInfo = addSortInfo(
              { name: itemName },
              searchInput,
              dropdownItemAliases[itemName] || [],
            );
            return sortInfo.sortLevel;
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowEmptyValue, dropdownItemAliases, dropdownItems, searchInput]);

  return (
    <DropdownOuterContainer
      otherClasses={otherClasses}
      popperClassname={popperClassname}
      initiallyOpen={initiallyOpen}
      disabled={disabled}
      dropdownToggle={dropdownToggle}
      selectedItem={selectedItem}
      togglerClassName={togglerClassName}
      placement={placement}
      placeholder={placeholder}
      portalTargetRef={portalTargetRef}
      onOpenAndClose={onOpenAndClose}
      onClose={onClose}
      closeOnToggleClick={closeOnToggleClick}
      data-testid={dataTestId}
      {...restProps}
    >
      {({ open, setOpen }) => {
        setOpenFn = setOpen;
        return (
          <DropdownInnerContainer
            open={open}
            setOpen={setOpen}
            otherInnerDropdownClasses={otherInnerDropdownClasses}
            clickOutsideOnClickDropdown={clickOutsideOnClickDropdown}
          >
            {search && (
              <div className='border-bottom'>
                <div className='padding-x-4 padding-y-2'>
                  <KeyDownSearchBar
                    onSearchInputChange={(value) => {
                      setSearchInput(value);
                    }}
                    placeHolderText={searchPlaceholder || 'Search'}
                    customInputClassnames='flat font-size-inherit'
                    autoFocusAfterTimeout={true}
                    listSize={processedDropdownItems.length}
                    onKeyNavigation={({ activeCursor, lastKeyPress }) => {
                      setLastKeyNavigation({
                        cursor: activeCursor,
                        lastKeyPress,
                      });

                      if (
                        lastKeyPress === 'Enter' &&
                        processedDropdownItems[activeCursor]
                      ) {
                        onSelectDropdownItem({
                          item: processedDropdownItems[activeCursor],
                          index: activeCursor,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            )}
            <div
              className='dropdown-items-container overflow-auto'
              ref={containerRef}
            >
              {!processedDropdownItems.length && !!searchInput.length && (
                <div className='padding-y-2 padding-x-4 text-muted'>
                  No results for &quot;{searchInput}&quot;
                </div>
              )}
              {!processedDropdownItems.length && !searchInput.length && (
                <div className='padding-y-2 padding-x-4 text-muted'>
                  {emptyDropdownItemsMessage}
                </div>
              )}
              {processedDropdownItems.map((item, index) => {
                return item.html ? (
                  <div
                    key={index}
                    onClick={() => {
                      item.closeOnClick && setOpen(false);
                    }}
                    className={classNames(`dropdown-item-${index}`, {
                      'bg-gray-1': lastKeyNavigation.cursor === index,
                    })}
                  >
                    {item.html}
                  </div>
                ) : (
                  <div
                    className={classNames(
                      'dropdown-item',
                      `dropdown-item-${index}`,
                      {
                        'bg-gray-1': lastKeyNavigation.cursor === index,
                        selected: selectedItem
                          ? selectedItem === item
                          : EMPTY_ITEM === item,
                      },
                    )}
                    onClick={() => {
                      onSelectDropdownItem({ item, index });
                    }}
                    key={`${JSON.stringify(item)}-${index}`}
                    data-testid={`dropdown-item-${kebabCase(item)}`}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </DropdownInnerContainer>
        );
      }}
    </DropdownOuterContainer>
  );
};

StaticDropdown.propTypes = {
  dropdownToggle: element,
  otherClasses: string,
  popperClassname: string,
  otherInnerDropdownClasses: string,
  onClickItem: func,
  selectedItem: any,
  defaultSelected: bool,
  disabled: bool,
  search: bool,
  allowEmptyValue: bool,
  emptyDropdownItemsMessage: string,
  dropdownItems: array.isRequired,
  placement: string,
  placeholder: string,
  searchPlaceholder: string,
  clickOutsideOnClickDropdown: bool,
  togglerClassName: string,
  portalTargetRef: any,
  initiallyOpen: bool,
  onOpenAndClose: func,
  onClose: func,
  closeOnItemClick: bool,
  closeOnToggleClick: bool,
  'data-testid': string,
  tabIndex: number,
};

export default StaticDropdown;
