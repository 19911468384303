import classNames from 'classnames';
import { noop } from 'lodash';
import { bool, func, node, number, oneOfType, string } from 'prop-types';
import React from 'react';

const Checkbox = (props) => {
  const {
    checked = false,
    className = null,
    'data-testId': dataTestId = null,
    description = null,
    disabled = false,
    hideLabel = false,
    inline = false,
    name,
    label,
    labelClasses = null,
    onChange,
    stopPropagation,
    value = '',
    id,
  } = props;

  const inputId = `checkbox-${name}${value ? `-${value}` : ''}`;

  return (
    <div
      id={id}
      className={classNames(
        'checkbox position-relative align-items-center text-left',
        {
          'd-flex': !inline,
          'd-inline-flex margin-right-3': inline,
          'cursor-pointer': !disabled,
          'pointer-events-none': disabled,
          disabled,
        },
        className,
      )}
    >
      <input
        checked={checked}
        data-testid={dataTestId}
        id={inputId}
        name={name}
        onChange={disabled ? noop : onChange}
        onClick={(e) => {
          stopPropagation && e.stopPropagation();
        }}
        type='checkbox'
        value={value}
        className='position-absolute cursor-pointer'
      />
      <div className='checkbox-marker'>
        <svg
          width='10'
          height='10'
          viewBox='0 0 10 10'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='position-absolute'
        >
          <path
            d='M1 6.14286L4.5 9L9 1'
            stroke='#ffffff'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
      {!!label && !hideLabel && (
        <label
          className={classNames('padding-left-2 width-100', labelClasses, {
            'cursor-pointer': !disabled,
          })}
          htmlFor={inputId}
        >
          <span>
            {hideLabel ? '' : label ? label : value}
            {description && (
              <span className='d-block text-muted text-small margin-top-1'>
                {description}
              </span>
            )}
          </span>
        </label>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  checked: bool,
  className: string,
  description: oneOfType([string, node]),
  disabled: bool,
  hideLabel: bool,
  inline: bool,
  label: oneOfType([string, node]),
  labelClasses: string,
  name: string.isRequired,
  onChange: func.isRequired,
  stopPropagation: bool,
  value: oneOfType([number, string, bool]),
  id: string,
};

export default Checkbox;
