/**
 * Parses a given boolean into true/false.
 * Checks for 1/true/"true" and treats them as true.
 * Checks for 0/null/undefined/"false" and treats them as false.
 *
 * @param value
 * @return {boolean}
 */
export const parseBoolean = (value) => {
  if (typeof value === 'boolean') {
    return value;
  }

  if (typeof value === 'number') {
    return value === 1;
  }

  const lowercaseValue = String(value).toLowerCase();
  return lowercaseValue === 'true' || lowercaseValue === '1';
};
