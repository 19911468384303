import { values } from 'lodash';

import { modelConstants } from '../constants';
import { DEFAULT_INTEGRATION } from './common';
import {
  ADOBE_SIGN_INTEGRATION_CONFIG,
  ADOBE_SIGN_INTEGRATION_ID,
} from './domains/adobesign/config';
import {
  ADP_INTEGRATION_CONFIG,
  ADP_INTEGRATION_ID,
} from './domains/adp/config';
import {
  ADP_RUN_INTEGRATION_CONFIG,
  ADP_RUN_INTEGRATION_ID,
} from './domains/adprun/config';
import {
  ASHBY_INTEGRATION_CONFIG,
  ASHBY_INTEGRATION_ID,
} from './domains/ashby/config';
import {
  BAMBOO_INTEGRATION_CONFIG,
  BAMBOO_INTEGRATION_ID,
} from './domains/bamboohr/config';
import {
  CHARTHOP_INTEGRATION_CONFIG,
  CHARTHOP_INTEGRATION_ID,
} from './domains/charthop/config';
import {
  CHECKR_INTEGRATION_CONFIG,
  CHECKR_INTEGRATION_ID,
} from './domains/checkr/config';
import {
  DOCUSIGN_INTEGRATION_CONFIG,
  DOCUSIGN_INTEGRATION_ID,
} from './domains/docusign/config';
import {
  DROPBOX_SIGN_INTEGRATION_CONFIG,
  DROPBOX_SIGN_INTEGRATION_ID,
} from './domains/dropboxsign/config';
import {
  FRESHTEAM_INTEGRATION_CONFIG,
  FRESHTEAM_INTEGRATION_ID,
} from './domains/freshteam/config';
import {
  GOOGLE_CALENDAR_INTEGRATION_CONFIG,
  GOOGLE_CALENDAR_INTEGRATION_ID,
} from './domains/googlecalendar/config';
import {
  GOOGLE_CLOUD_STORAGE_INTEGRATION_CONFIG,
  GOOGLE_CLOUD_STORAGE_INTEGRATION_ID,
} from './domains/googlecloudstorage/config';
import {
  GREENHOUSE_INTEGRATION_CONFIG,
  GREENHOUSE_INTEGRATION_ID,
} from './domains/greenhouse/config';
import {
  GUSTO_INTEGRATION_CONFIG,
  GUSTO_INTEGRATION_ID,
} from './domains/gusto/config';
import {
  HTTP_INTEGRATION_CONFIG,
  HTTP_INTEGRATION_ID,
} from './domains/http/config';
import {
  ICIMS_INTEGRATION_CONFIG,
  ICIMS_INTEGRATION_ID,
} from './domains/icims/config';
import {
  JUSTWORKS_INTEGRATION_CONFIG,
  JUSTWORKS_INTEGRATION_ID,
} from './domains/justworks/config';
import {
  LEVER_INTEGRATION_CONFIG,
  LEVER_INTEGRATION_ID,
} from './domains/lever/config';
import {
  META_INTEGRATION_CONFIG,
  META_INTEGRATION_ID,
} from './domains/meta/config';
import {
  MICROSOFT_OUTLOOK_INTEGRATION_CONFIG,
  MICROSOFT_OUTLOOK_INTEGRATION_ID,
} from './domains/microsoftoutlook/config';
import {
  MS_TEAMS_INTEGRATION_CONFIG,
  MS_TEAMS_INTEGRATION_ID,
} from './domains/msteams/config';
import {
  OKTA_INTEGRATION_CONFIG,
  OKTA_INTEGRATION_ID,
} from './domains/okta/config';
import {
  PAYLOCITY_INTEGRATION_CONFIG,
  PAYLOCITY_INTEGRATION_ID,
} from './domains/paylocity/config';
import {
  SLACK_INTEGRATION_CONFIG,
  SLACK_INTEGRATION_ID,
} from './domains/slack/config';
import {
  SMARTRECRUITERS_INTEGRATION_CONFIG,
  SMARTRECRUITERS_INTEGRATION_ID,
} from './domains/smartrecruiters/config';
import {
  SORA_INTEGRATION_CONFIG,
  SORA_INTEGRATION_ID,
} from './domains/sora/config';
import {
  SUCCESSFACTORS_INTEGRATION_CONFIG,
  SUCCESSFACTORS_INTEGRATION_ID,
} from './domains/successfactors/config';
import {
  TIMER_INTEGRATION_CONFIG,
  TIMER_INTEGRATION_ID,
} from './domains/timer/config';
import {
  TRINET_INTEGRATION_CONFIG,
  TRINET_INTEGRATION_ID,
} from './domains/trinet/config.js';
import {
  TWILIO_INTEGRATION_CONFIG,
  TWILIO_INTEGRATION_ID,
} from './domains/twilio/config';
import {
  ULTIPRO_INTEGRATION_CONFIG,
  ULTIPRO_INTEGRATION_ID,
} from './domains/ultipro/config';
import {
  WEBEX_INTEGRATION_CONFIG,
  WEBEX_INTEGRATION_ID,
} from './domains/webex/config';
import {
  WORKDAY_INTEGRATION_CONFIG,
  WORKDAY_INTEGRATION_ID,
} from './domains/workday/config';
import {
  ZENEFITS_INTEGRATION_CONFIG,
  ZENEFITS_INTEGRATION_ID,
} from './domains/zenefits/config';

const { GOOGLE_CALENDAR, MICROSOFT_OUTLOOK } =
  modelConstants.calendarEvent.types;

const INTEGRATIONS = {
  /**************************************************
   * Virtual (non real 3rd party) domains (ID < 10) *
   **************************************************/

  // Meta: special triggers and recipes acting on workflow control flow
  [META_INTEGRATION_ID]: META_INTEGRATION_CONFIG,
  // Timer: date and time triggers and recipes
  [TIMER_INTEGRATION_ID]: TIMER_INTEGRATION_CONFIG,
  // Dummy: to test non availability
  9: {
    id: 9,
    ...DEFAULT_INTEGRATION,
    configurable: () => false,
    configured: () => false,
    name: 'reservednotavailable',
    availableUses: [],
  },

  /*****************************
   * Normal domains (ID >= 10) *
   *****************************/

  // Sora: triggers and events on graphQL Sora API
  [SORA_INTEGRATION_ID]: SORA_INTEGRATION_CONFIG,
  [GREENHOUSE_INTEGRATION_ID]: GREENHOUSE_INTEGRATION_CONFIG,
  [LEVER_INTEGRATION_ID]: LEVER_INTEGRATION_CONFIG,
  [CHECKR_INTEGRATION_ID]: CHECKR_INTEGRATION_CONFIG,

  [SLACK_INTEGRATION_ID]: SLACK_INTEGRATION_CONFIG,
  [ICIMS_INTEGRATION_ID]: ICIMS_INTEGRATION_CONFIG,
  [WORKDAY_INTEGRATION_ID]: WORKDAY_INTEGRATION_CONFIG,
  [BAMBOO_INTEGRATION_ID]: BAMBOO_INTEGRATION_CONFIG,
  [ULTIPRO_INTEGRATION_ID]: ULTIPRO_INTEGRATION_CONFIG,
  [SMARTRECRUITERS_INTEGRATION_ID]: SMARTRECRUITERS_INTEGRATION_CONFIG,
  [FRESHTEAM_INTEGRATION_ID]: FRESHTEAM_INTEGRATION_CONFIG,
  [PAYLOCITY_INTEGRATION_ID]: PAYLOCITY_INTEGRATION_CONFIG,
  [ADP_INTEGRATION_ID]: ADP_INTEGRATION_CONFIG,
  [GUSTO_INTEGRATION_ID]: GUSTO_INTEGRATION_CONFIG,
  [SUCCESSFACTORS_INTEGRATION_ID]: SUCCESSFACTORS_INTEGRATION_CONFIG,
  [HTTP_INTEGRATION_ID]: HTTP_INTEGRATION_CONFIG,
  [DOCUSIGN_INTEGRATION_ID]: DOCUSIGN_INTEGRATION_CONFIG,
  [OKTA_INTEGRATION_ID]: OKTA_INTEGRATION_CONFIG,
  [ZENEFITS_INTEGRATION_ID]: ZENEFITS_INTEGRATION_CONFIG,
  [TRINET_INTEGRATION_ID]: TRINET_INTEGRATION_CONFIG,
  [TWILIO_INTEGRATION_ID]: TWILIO_INTEGRATION_CONFIG,
  [MS_TEAMS_INTEGRATION_ID]: MS_TEAMS_INTEGRATION_CONFIG,
  [ASHBY_INTEGRATION_ID]: ASHBY_INTEGRATION_CONFIG,
  [ADOBE_SIGN_INTEGRATION_ID]: ADOBE_SIGN_INTEGRATION_CONFIG,
  [GOOGLE_CLOUD_STORAGE_INTEGRATION_ID]:
    GOOGLE_CLOUD_STORAGE_INTEGRATION_CONFIG,
  [ADP_RUN_INTEGRATION_ID]: ADP_RUN_INTEGRATION_CONFIG,
  [JUSTWORKS_INTEGRATION_ID]: JUSTWORKS_INTEGRATION_CONFIG,
  [CHARTHOP_INTEGRATION_ID]: CHARTHOP_INTEGRATION_CONFIG,
  [GOOGLE_CALENDAR_INTEGRATION_ID]: GOOGLE_CALENDAR_INTEGRATION_CONFIG,
  [MICROSOFT_OUTLOOK_INTEGRATION_ID]: MICROSOFT_OUTLOOK_INTEGRATION_CONFIG,
  [DROPBOX_SIGN_INTEGRATION_ID]: DROPBOX_SIGN_INTEGRATION_CONFIG,
  [WEBEX_INTEGRATION_ID]: WEBEX_INTEGRATION_CONFIG,
};

const DEFAULT_INTEGRATION_DOMAINS = ['meta', 'timer'];
const DEFAULT_CONFIG = { activated: false, values: {} };
const INTEGRATION_NAMES = values(INTEGRATIONS).map((int) => int.name);

/**
 * Constructs an object that maps integration names to their labels, like so:
 * {
 *  zenefits: 'Zenefits',
 *  bamboohr: 'Bamboo HR',
 *  adp: 'ADP',
 *  ...
 * }
 */
const INTEGRATION_LABELS_BY_NAME = values(INTEGRATIONS).reduce(
  (prevValue, currValue) => {
    prevValue[currValue.name] = currValue.label;
    return prevValue;
  },
  {},
);

const NATIVE_INTEGRATION_TASK_TYPES = {
  gusto_create_employee: {
    label: 'Create an employee in Gusto',
    description: 'Create an employee in Gusto',
    serviceName: GUSTO_INTEGRATION_CONFIG.name,
    integrationId: GUSTO_INTEGRATION_ID,
    logoUrl: GUSTO_INTEGRATION_CONFIG.logoUrl,
    functionName: 'gustoCreateEmployee',
    nativeIntegrationTaskType: 'gusto_create_employee',
  },
  checkr_create_background_check: {
    label: 'Run background check with Checkr',
    description: 'Create a candidate in Checkr and run a background check',
    serviceName: 'checkr',
    integrationId: CHECKR_INTEGRATION_ID,
    logoUrl: CHECKR_INTEGRATION_CONFIG.logoUrl,
    functionName: 'checkrCreateBackgroundCheck',
    nativeIntegrationTaskType: 'checkr_create_background_check',
  },
  adobe_sign_create_agreement: {
    label: 'Create an agreement in Adobe Sign',
    description: 'Create an agreement in Adobe Sign',
    serviceName: ADOBE_SIGN_INTEGRATION_CONFIG.name,
    integrationId: ADOBE_SIGN_INTEGRATION_ID,
    logoUrl: ADOBE_SIGN_INTEGRATION_CONFIG.logoUrl,
    functionName: 'adobeSignCreateAgreement',
    nativeIntegrationTaskType: 'adobe_sign_create_agreement',
  },
  docusign_create_envelope: {
    label: 'Create an envelope in DocuSign',
    description: 'Create an envelope in DocuSign',
    serviceName: DOCUSIGN_INTEGRATION_CONFIG.name,
    integrationId: DOCUSIGN_INTEGRATION_ID,
    logoUrl: DOCUSIGN_INTEGRATION_CONFIG.logoUrl,
    functionName: 'docuSignCreateEnvelope',
    nativeIntegrationTaskType: 'docusign_create_envelope',
  },
  dropbox_sign_create_signature_request: {
    label: 'Create a signature request in Dropbox Sign',
    description: 'Create a signature request in Dropbox Sign',
    serviceName: DROPBOX_SIGN_INTEGRATION_CONFIG.name,
    integrationId: DROPBOX_SIGN_INTEGRATION_CONFIG.id,
    logoUrl: DROPBOX_SIGN_INTEGRATION_CONFIG.logoUrl,
    functionName: 'dropboxSignCreateSignatureRequest',
    nativeIntegrationTaskType: 'dropbox_sign_create_signature_request',
  },
  slack_send_direct_message: {
    label: 'Send direct Slack message',
    description: 'Send a direct Slack message to one or more users',
    serviceName: SLACK_INTEGRATION_CONFIG.name,
    integrationId: SLACK_INTEGRATION_ID,
    logoUrl: SLACK_INTEGRATION_CONFIG.logoUrl,
    functionName: 'slackSendDirectMessage',
    nativeIntegrationTaskType: 'slack_send_direct_message',
  },
  slack_send_channel_message: {
    label: 'Send Slack message to channel',
    description: 'Send Slack message to a selected channel',
    serviceName: SLACK_INTEGRATION_CONFIG.name,
    integrationId: SLACK_INTEGRATION_ID,
    logoUrl: SLACK_INTEGRATION_CONFIG.logoUrl,
    functionName: 'slackSendChannelMessage',
    nativeIntegrationTaskType: 'slack_send_channel_message',
  },
  slack_add_employee_to_channel: {
    label: 'Add employee(s) to Slack channel',
    description: 'Add employee(s) to Slack channel',
    serviceName: SLACK_INTEGRATION_CONFIG.name,
    integrationId: SLACK_INTEGRATION_ID,
    logoUrl: SLACK_INTEGRATION_CONFIG.logoUrl,
    functionName: 'slackAddEmployeeToChannel',
    nativeIntegrationTaskType: 'slack_add_employee_to_channel',
  },
  slack_remove_employee_from_channel: {
    label: 'Remove employee(s) from Slack channel',
    description: 'Remove employee(s) from Slack channel',
    serviceName: SLACK_INTEGRATION_CONFIG.name,
    integrationId: SLACK_INTEGRATION_ID,
    logoUrl: SLACK_INTEGRATION_CONFIG.logoUrl,
    functionName: 'slackRemoveEmployeeFromChannel',
    nativeIntegrationTaskType: 'slack_remove_employee_from_channel',
  },
  slack_create_channel: {
    label: 'Create Slack channel',
    description: 'Create new Slack channel',
    serviceName: SLACK_INTEGRATION_CONFIG.name,
    integrationId: SLACK_INTEGRATION_ID,
    logoUrl: SLACK_INTEGRATION_CONFIG.logoUrl,
    functionName: 'slackCreateChannel',
    nativeIntegrationTaskType: 'slack_create_channel',
  },
  twilio_send_sms: {
    label: 'Send an SMS text message',
    description: 'Send an SMS text message',
    serviceName: TWILIO_INTEGRATION_CONFIG.name,
    integrationId: TWILIO_INTEGRATION_CONFIG.id,
    logoUrl: TWILIO_INTEGRATION_CONFIG.logoUrl,
    functionName: 'twilioSendSms',
    nativeIntegrationTaskType: 'twilio_send_sms',
  },
  webex_send_message_to_space: {
    label: 'Send Webex message to space',
    description: 'Send Webex message to a selected space',
    serviceName: WEBEX_INTEGRATION_CONFIG.name,
    integrationId: WEBEX_INTEGRATION_CONFIG.id,
    logoUrl: WEBEX_INTEGRATION_CONFIG.logoUrl,
    functionName: 'webexSendMessageToSapce',
    nativeIntegrationTaskType: 'webex_send_message_to_space',
  },
  webex_send_direct_meesage: {
    label: 'Send direct Webex message',
    description: 'Send a private 1:1 Webex message to a selected user',
    serviceName: WEBEX_INTEGRATION_CONFIG.name,
    integrationId: WEBEX_INTEGRATION_CONFIG.id,
    logoUrl: WEBEX_INTEGRATION_CONFIG.logoUrl,
    functionName: 'webexSendDirectMessage',
    nativeIntegrationTaskType: 'webex_send_direct_meesage',
  },
  webex_add_employee_to_space: {
    label: 'Add employee(s) to Webex space',
    description: 'Add employee(s) to Webex space',
    serviceName: WEBEX_INTEGRATION_CONFIG.name,
    integrationId: WEBEX_INTEGRATION_CONFIG.id,
    logoUrl: WEBEX_INTEGRATION_CONFIG.logoUrl,
    functionName: 'webexAddEmployeeToSpace',
    nativeIntegrationTaskType: 'webex_add_employee_to_space',
  },
  webex_remove_employee_from_space: {
    label: 'Remove employee(s) from Webex space',
    description: 'Remove employee(s) from Webex space',
    serviceName: WEBEX_INTEGRATION_CONFIG.name,
    integrationId: WEBEX_INTEGRATION_CONFIG.id,
    logoUrl: WEBEX_INTEGRATION_CONFIG.logoUrl,
    functionName: 'webexRemoveEmployeeFromSpace',
    nativeIntegrationTaskType: 'webex_remove_employee_from_space',
  },
};

const CALENDAR_EVENT_TYPES = {
  [GOOGLE_CALENDAR]: {
    serviceName: GOOGLE_CALENDAR_INTEGRATION_CONFIG.name,
    integrationId: GOOGLE_CALENDAR_INTEGRATION_ID,
    logoUrl: GOOGLE_CALENDAR_INTEGRATION_CONFIG.logoUrl,
    label: 'Google Calendar',
    functionName: 'createGCalEvent',
  },
  [MICROSOFT_OUTLOOK]: {
    serviceName: MICROSOFT_OUTLOOK_INTEGRATION_CONFIG.name,
    integrationId: MICROSOFT_OUTLOOK_INTEGRATION_ID,
    logoUrl: MICROSOFT_OUTLOOK_INTEGRATION_CONFIG.logoUrl,
    label: 'Outlook Calendar',
    functionName: 'createOutlookEvent',
  },
};

const getIntegrationByName = (integrationName) =>
  Object.values(INTEGRATIONS).find(
    (integration) => integration.name === integrationName,
  );

export {
  INTEGRATIONS,
  INTEGRATION_NAMES,
  INTEGRATION_LABELS_BY_NAME,
  DEFAULT_INTEGRATION_DOMAINS,
  DEFAULT_CONFIG,
  SORA_INTEGRATION_ID,
  GREENHOUSE_INTEGRATION_ID,
  BAMBOO_INTEGRATION_ID,
  ADP_INTEGRATION_ID,
  GUSTO_INTEGRATION_ID,
  SUCCESSFACTORS_INTEGRATION_ID,
  SLACK_INTEGRATION_ID,
  ICIMS_INTEGRATION_ID,
  DOCUSIGN_INTEGRATION_ID,
  OKTA_INTEGRATION_ID,
  ULTIPRO_INTEGRATION_ID,
  ASHBY_INTEGRATION_ID,
  NATIVE_INTEGRATION_TASK_TYPES,
  CALENDAR_EVENT_TYPES,
  LEVER_INTEGRATION_ID,
  getIntegrationByName,
};
