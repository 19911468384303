import classNames from 'classnames';
import { any, number, string, object } from 'prop-types';
import React from 'react';
import TooltipTrigger from 'react-popper-tooltip';

const Tooltip = ({
  children,
  tooltip,
  triggerClassName = '',
  triggerStyle = {},
  ...props
}) => {
  if (!tooltip) return children;
  return (
    <TooltipTrigger
      {...props}
      tooltip={({
        arrowRef,
        tooltipRef,
        getArrowProps,
        getTooltipProps,
        placement,
      }) => (
        <div
          {...getTooltipProps({
            ref: tooltipRef,
            className: `tooltip-container`,
            style: { maxWidth: `${props.maxWidth}px` },
          })}
        >
          <div
            {...getArrowProps({
              ref: arrowRef,
              className: 'tooltip-arrow',
              'data-placement': placement,
            })}
          />
          {tooltip}
        </div>
      )}
    >
      {({ getTriggerProps, triggerRef }) => (
        <span
          {...getTriggerProps({
            ref: triggerRef,
            className: classNames('trigger', {
              [triggerClassName]: !!triggerClassName,
            }),
            style: triggerStyle,
          })}
        >
          {children}
        </span>
      )}
    </TooltipTrigger>
  );
};

Tooltip.propTypes = {
  children: any.isRequired,
  tooltip: any,
  maxWidth: number,
  triggerClassName: string,
  triggerStyle: object,
};

export default Tooltip;
