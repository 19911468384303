import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const ADP_INTEGRATION_ID = 26;

export const ADP_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: ADP_INTEGRATION_ID,
  name: 'adp',
  label: 'ADP',
  description:
    'Payroll services and tax support for small to midsize companies',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/adp.png`,
  website: 'www.adp.com',
  helpDocs: 'https://help.sora.co/resources/adp',
  availableUses: [
    AVAILABLE_USES_ENUMS.WORKFLOWS,
    AVAILABLE_USES_ENUMS.DATA_SYNC,
  ],
  categories: [CATEGORIES.HRIS],
  configSchema: {
    organizationId: {
      type: 'string',
      title: 'Organization ID',
      searchable: true,
      nullable: true,
    },
    clientId: {
      type: 'string',
      title: 'Client ID',
      nullable: true,
    },
    clientSecret: {
      type: 'string',
      title: 'Client secret',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    outboundNewHireFieldMapping: {
      type: 'string',
      title:
        'Map outbound fields from Sora employees, to ADP new hire employees',
      nullable: true,
    },
  },
  configured: (c) => c.values && c.values.clientId && c.values.clientSecret,
  validation: { action: 'adp/getWorkers', parameters: { limit: 1 } },
};
