/**
 * @typedef {import('sora-invariant/src/constants/defaultCompanyGrants').Resource} Resource
 * @typedef {import('sora-invariant/src/constants/defaultCompanyGrants').Action} Action
 * @typedef {ReturnType<typeof userAuthorization>} UA
 *
 * @typedef  Scope
 * @property {Resource} resource
 * @property {Action} action
 * @property {*} [otherCtx]
 * @property {*} [instance] - if specified check is made to see can user permorm action for instances like instance
 * @property {boolean} [any] - if specified check is made to see can user performa action on any instance
 * @property {boolean} [anyNotSelf] - if specified check is made to see can user performa action on any instance does not blongs to him
 * */
import { memoize } from 'lodash';
import { AccessControl } from 'role-acl';

import customConditionFns from 'sora-invariant/src/customConditionFns';

export const userAuthorization = memoize(
  (currentUser) => {
    const ac = new AccessControl(
      currentUser.allGrants.map((e) => ({
        role: String(currentUser.id),
        resource: e.resource,
        action: e.action,
        condition: e.condition || null,
      })),
      customConditionFns,
    );
    const isSuperAdmin = !!currentUser.allGrants.find((grant) => {
      return grant.resource === '*' && grant.action === '*';
    });
    const isAdminPlus = ['superadministrators', 'administrators'].some((name) =>
      currentUser.permissionGroups.find((e) => e.name === name),
    );
    const isSoraDev =
      isSuperAdmin &&
      currentUser.permissionGroups.some((e) => e.name === 'developers');
    const isAdminOrCoordinator = [
      'superadministrators',
      'administrators',
      'coordinators',
    ].some((name) => currentUser.permissionGroups.find((e) => e.name === name));
    const isEmployee = currentUser.permissionGroups.every(
      (pg) => pg.name === 'everyone',
    );

    return {
      /**
       * *NOTE*: If any modifications are made to this method, please ensure that the equivalent modifications are made to
       * the server-side version of this function (the `can` instance method on the User model).
       */
      can: memoize(
        /**
         * Instance param will be ignored if any is true
         * @param {Scope} param0 */
        ({ resource, action, instance, any, anyNotSelf, otherCtx }) => {
          if (!currentUser.allGrants.length) return false;

          // If user does not have premium access, they are not allowed to create and delete Integrations
          // _even if they are technically associated with those permission grants in the DB_.
          // They are only allowed to VIEW and EDIT existing Integrations.
          if (
            !currentUser.hasPremiumAccess &&
            resource === 'Integration' &&
            action !== 'VIEW' &&
            action !== 'EDIT'
          ) {
            return false;
          }

          if (any || anyNotSelf) {
            return !!currentUser.allGrants.find(
              (g) =>
                [resource, '*'].includes(g.resource) &&
                [action, '*'].includes(g.action) &&
                (!anyNotSelf || !g.level.includes('OWN')),
            );
          }
          const permission = ac
            .can(String(currentUser.id))
            .context({
              ...otherCtx,
              instance,
              currentUser,
            })
            .execute(action)
            .sync()
            .on(resource);

          // @ts-ignore
          return permission.granted;
        },
        /**
         * @param {Scope} arg */
        (arg) => JSON.stringify(arg),
      ),
      isSuperAdmin,
      isSoraDev,
      isAdminPlus,
      isAdminOrCoordinator,
      isEmployee,
      isActive: currentUser.hasSoraAccount,
    };
  },
  (currentUser) => {
    return `${currentUser.id}${JSON.stringify(currentUser.allGrants)}`;
  },
);
