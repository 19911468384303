import classNames from 'classnames';
import { isFunction } from 'lodash';
import { func, object } from 'prop-types';
import React, { memo, useState } from 'react';

import Button from './Button';
import { InfoSvg, WarningSvg, XSvg } from './Svg';
import FormTextarea from './forms/FormTextarea';

const ConfirmationModal = ({ confirmation, dismissModal }) => {
  const [state, setState] = useState({
    confirmDisabled: isFunction(confirmation.confirmDisabled)
      ? confirmation.confirmDisabled()
      : confirmation.confirmDisabled,
    confirmLoading: confirmation.confirmLoading,
  });

  return (
    <div
      className={classNames(
        'confirmation-modal position-fixed position-left position-right position-top position-bottom',
        { 'closing-confirmation': confirmation.closingConfirmation },
      )}
    >
      <div
        className='overlay position-fixed position-top position-right position-bottom position-left'
        onClick={() => dismissModal()}
      />
      <div className='modal-content text-center bg-white word-break-break-word overflow-auto position-relative padding-5 mobile-padding-4 rounded-xlarge'>
        <div className='modal-icon'>
          {confirmation.type === `warning` && (
            <WarningSvg className='no-click svg-large' />
          )}
          {confirmation.type === `info` && (
            <InfoSvg className='no-click svg-large' />
          )}
        </div>
        {confirmation.body && (
          <div className='font-weight-500 text-large margin-y-3'>
            {confirmation.body}
          </div>
        )}
        {confirmation.BodyComponent && (
          <confirmation.BodyComponent
            state={state}
            setState={setState}
            dismissModal={dismissModal}
          />
        )}
        {confirmation.withInputText && (
          <FormTextarea
            className='margin-y-3'
            value={state.textValue}
            onChange={(e) =>
              setState((state) => ({ ...state, textValue: e.target.value }))
            }
          />
        )}
        <div className='d-inline-flex margin-top-2'>
          {confirmation.type !== 'info' && (
            <Button
              onClick={() => dismissModal()}
              label='Cancel'
              className='margin-right-3'
            />
          )}
          <Button
            category={confirmation.type === 'info' ? 'action' : 'warning'}
            label={confirmation.confirmButtonText || 'Confirm'}
            onClick={() => {
              confirmation.onConfirm(state, setState, dismissModal);
              if (confirmation.dismissOnConfirm) {
                dismissModal();
              }
            }}
            loading={state.confirmLoading}
            disabled={state.confirmDisabled}
          />
        </div>
        <XSvg
          className='close position-absolute position-top position-right margin-top-3 margin-right-3'
          onClick={() => dismissModal()}
        />
      </div>
    </div>
  );
};

ConfirmationModal.propTypes = {
  confirmation: object.isRequired,
  dismissModal: func.isRequired,
};

export default memo(ConfirmationModal);
