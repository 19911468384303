import gql from 'graphql-tag';
import moment from 'moment';
import React, { useContext } from 'react';
import { useMutation } from 'react-apollo';

import { OnboardingChecklistCard } from 'sora-client/components/HomePage/OnboardingChecklistCard';
import Loading from 'sora-client/components/common/Loading';
import useQueryWrapper from 'sora-client/components/common/useQueryWrapper';
import { ConfirmationContext } from 'sora-client/contexts';
import PreviewContext from 'sora-client/contexts/PreviewContext';
import SessionContext from 'sora-client/contexts/SessionContext';
import { userAuthorization } from 'sora-client/helpers/authorization';

import { Mobile, NotMobile } from '../common/MediaQueries';
import { DashboardCard } from './DashboardCard';
import { DefaultDashboardCards } from './DefaultDashboardCards';
import EmployeeTaskListCard from './EmployeeTaskListCard';
import HelpfulLinks from './HelpfulLinks';
import { WorkflowTemplatesDiscoveryCard } from './WorkflowTemplatesDiscoveryCard';

const HomePage = () => {
  const { currentUser } = useContext(SessionContext);
  const { showConfirmation } = useContext(ConfirmationContext);
  const { previewingAsEmployee } = useContext(PreviewContext);

  // TODO change back to .endOf('minute') once we figure out why
  // fetching the current user for validating auth token every 30
  // seconds is causing this page's query to run again
  const now = moment().startOf('day').toDate();
  const in7Days = moment().endOf('day').add(7, 'days').toDate();
  const in30Days = moment().endOf('day').add(30, 'days').toDate();

  const ua = userAuthorization(currentUser);

  const [dismissEmployeeDashboardCardFn] = useMutation(
    gql`
      mutation dismissDashboardCardOperation($dashboardCardId: ID!) {
        dismissEmployeeDashboardCard(dashboardCardId: $dashboardCardId) {
          id
          dismissed
        }
      }
    `,
  );
  const { data, loading, refetch } = useQueryWrapper(
    gql`
      query homePage(
        $employeeId: ID!
        $thisWeekDueDate: IsoDate
        $nextWeekDueDate: IsoDate
        $monthDueDate: IsoDate
        $overdueDueDate: IsoDate
        $taskTypes: [TaskTypeName]
        $overdueTaskTypes: [TaskTypeName]
        $fetchPremiumContent: Boolean!
        $showSelfEnrollmentCTA: Boolean
      ) {
        ...OnboardingChecklistCard_Query
        ...HomepageEmployeeTaskListCard
        ...HelpfulLinks_Query
        ...DashboardCard_Query
      }
      ${OnboardingChecklistCard.fragments.query}
      ${EmployeeTaskListCard.fragments.query}
      ${HelpfulLinks.fragments.query}
      ${DashboardCard.fragments.query}
    `,
    {
      // query params for EmployeeTaskListCard
      employeeId: previewingAsEmployee?.id || currentUser.employeeId,
      taskTypes: ua.isAdminOrCoordinator
        ? ['assignment', 'survey', 'email']
        : ['assignment', 'survey'],
      overdueTaskTypes: ['assignment', 'survey'],
      thisWeekDueDate: in7Days,
      nextWeekDueDate: in7Days,
      monthDueDate: in30Days,
      overdueDueDate: now,
      // query params for HelpfulLinks
      showSelfEnrollmentCTA: true,
      fetchPremiumContent: currentUser.company.enablePremiumFeatures,
    },
    { handleError: false },
  );

  const onDismissDashboardCard = async (dashboardCardId) => {
    showConfirmation({
      body: (
        <>
          <p>Are you sure you want to dismiss this message?</p>
          <p>
            It will not show up on your homepage again, even after you log back
            in.
          </p>
        </>
      ),
      onConfirm: async () => {
        await dismissEmployeeDashboardCardFn({
          variables: {
            dashboardCardId,
          },
        });
        refetch();
      },
    });
  };
  const canEditIntegrations = ua.can({
    resource: 'Integration',
    action: 'EDIT',
    instance: { companyId: currentUser.companyId },
  });

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <NotMobile>
            <>
              <div className='d-flex'>
                <div className='width-50'>
                  {ua.can({ resource: 'Timeline', action: 'CREATE' }) && (
                    <WorkflowTemplatesDiscoveryCard />
                  )}
                </div>
                <div className='width-50'>
                  {currentUser.hasPremiumAccess &&
                    currentUser.hasFullUIAccess &&
                    canEditIntegrations &&
                    !previewingAsEmployee && (
                      <OnboardingChecklistCard
                        integrationStates={data?.integrationStates}
                      />
                    )}
                </div>
              </div>
              <div className='d-flex'>
                <div className='d-flex flex-direction-column width-50'>
                  <DefaultDashboardCards
                    tasksDueThisWeek={data?.tasksDueThisWeek}
                    tasksDueUpNext={data?.tasksDueUpNext}
                    overdueTasksCount={data?.overdueTasksNum?.count || 0}
                    allTasksDueCount={data?.allTasksDueNum?.count || 0}
                    selfEnrollableWorkflows={data?.selfEnrollableWorkflows}
                  />
                </div>
                <div className='width-50'>
                  {data?.allCards?.rows.map((card) => (
                    <DashboardCard
                      key={card.id}
                      dashboardCard={card}
                      onDismiss={onDismissDashboardCard}
                    />
                  ))}
                </div>
              </div>
            </>
          </NotMobile>
          <Mobile>
            <>
              {currentUser.hasPremiumAccess &&
                currentUser.hasFullUIAccess &&
                canEditIntegrations &&
                !previewingAsEmployee && (
                  <OnboardingChecklistCard
                    integrationStates={data?.integrationStates}
                  />
                )}
              <div>
                <DefaultDashboardCards
                  tasksDueThisWeek={data?.tasksDueThisWeek}
                  tasksDueUpNext={data?.tasksDueUpNext}
                  overdueTasksCount={data?.overdueTasksNum?.count || 0}
                  selfEnrollableWorkflows={data?.selfEnrollableWorkflows}
                />
                {data?.allCards?.rows?.map((card) => (
                  <DashboardCard
                    key={card.id}
                    dashboardCard={card}
                    onDismiss={onDismissDashboardCard}
                  />
                ))}
              </div>
            </>
          </Mobile>
        </>
      )}
    </>
  );
};

export default HomePage;
