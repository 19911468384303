import moment from 'moment';
import { object } from 'prop-types';
import React, { useContext } from 'react';

import { Link } from 'sora-client/components/common/Link';
import { NewHireTaskDueDate } from 'sora-client/components/common/NewHireTaskDueDate';
import { NEW_HIRE_TASKS } from 'sora-client/constants/routes';
import { AnalyticsContext } from 'sora-client/contexts';

const EmployeeTaskListItem = ({ newHireTask }) => {
  const { track } = useContext(AnalyticsContext);
  const isTaskOverDue =
    !newHireTask.done &&
    !!newHireTask.dueDate &&
    newHireTask.state !== 'cancelled' &&
    moment().startOf('day').isAfter(newHireTask.dueDate);
  return (
    <Link
      className={'list-item border-top text-default'}
      to={`${NEW_HIRE_TASKS}?tid=${newHireTask.id}`}
      onClick={() =>
        track('Open task clicked', {
          'Task ID': newHireTask.id,
          'Is Task Overdue': isTaskOverDue,
        })
      }
    >
      <div className='text-truncate'>{newHireTask.displayedTaskName}</div>
      <div className='text-truncate'>{newHireTask.newHire.name}</div>
      <NewHireTaskDueDate newHireTask={newHireTask} />
    </Link>
  );
};

EmployeeTaskListItem.propTypes = {
  newHireTask: object.isRequired,
};

export { EmployeeTaskListItem };
