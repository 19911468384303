import { arrayOf, object, string } from 'prop-types';
import React from 'react';

import SelfServeWorkflows from 'sora-client/components/SelfServeWorkflows';
import BoxCard from 'sora-client/components/common/BoxCard';

import HomePageCard from '../HomePageCard';
import HomePageCardTitle from '../HomePageCardTitle';
import { GET_ENROLLABLE_WORKFLOWS } from './queries';

const HelpfulLinks = ({ className, selfEnrollableWorkflows = [] }) => {
  if (!selfEnrollableWorkflows.length) {
    return null;
  }

  return (
    <HomePageCard className='helpful-links-list-card'>
      <BoxCard className={className}>
        <div className='padding-y-5 padding-x-5 mobile-padding-3'>
          <HomePageCardTitle title='Helpful links' />
          <p className='margin-y-0 text-muted'>
            Need to kick off a request or start a process? Start here!
          </p>
          <SelfServeWorkflows workflows={selfEnrollableWorkflows} />
        </div>
      </BoxCard>
    </HomePageCard>
  );
};

HelpfulLinks.propTypes = {
  className: string,
  selfEnrollableWorkflows: arrayOf(object).isRequired,
};

HelpfulLinks.fragments = {
  query: GET_ENROLLABLE_WORKFLOWS,
};

export default HelpfulLinks;
