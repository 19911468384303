import { GoogleLogin } from '@react-oauth/google';
import { trim } from 'lodash';
import moment from 'moment-timezone';
import { object, string } from 'prop-types';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useApolloClient, useMutation } from 'react-apollo-hooks';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';

import BoxCard from 'sora-client/components/common/BoxCard';
import Button from 'sora-client/components/common/Button';
import DangerNoticeBox from 'sora-client/components/common/DangerNoticeBox';
import Loading from 'sora-client/components/common/Loading';
import { useMutationWrapper } from 'sora-client/components/common/MutationWrapper';
import Page from 'sora-client/components/common/Page';
import { LeftArrowSvg } from 'sora-client/components/common/Svg';
import FormInput from 'sora-client/components/common/forms/FormInput';
import FormLabel from 'sora-client/components/common/forms/FormLabel';
import { HOME } from 'sora-client/constants/routes';
import { MessagesContext } from 'sora-client/contexts';
import AnalyticsContext from 'sora-client/contexts/AnalyticsContext';
import LoadingContext from 'sora-client/contexts/LoadingContext';
import { handleResponseError } from 'sora-client/helpers/handleError';
import log from 'sora-client/logger';
import soraLogo from 'sora-client/static/images/sora.png';
import { LOG_IN_COMPANY } from 'sora-invariant/src/gql/mutations/user';

import MicrosoftLogin from './MicrosoftLogin';
import MicrosoftLoginButton from './MicrosoftLoginButton';
import OktaLoginButton from './OktaLoginButton';
import { LOG_IN_SIGN_UP, SIGN_UP_WITH_NEW_SANDBOX_COMPANY } from './queries';
import { parseBoolean } from 'sora-client/helpers/parse';

const PAGE_CATEGORY = 'Authentication';
const DEFAULT_USER_NAME = 'Not provided';

const handlePostLogin = ({ history, redirectTo }) => {
  const redirectUrl = decodeURIComponent(redirectTo);

  if (redirectUrl[0] === '/') {
    try {
      history.push(redirectUrl);
    } catch {
      history.push(HOME);
    }
  } else {
    history.push(HOME);
  }
};

const canUseEmailPasswordAuth = parseBoolean(
  process.env.REACT_APP_ALLOW_EMAIL_AUTH,
);

const AuthPage = ({ authType, location, history }) => {
  const [isProcessingRedirectAuth, setIsProcessingRedirectAuth] =
    useState(false);
  const [queryParams] = useQueryParams({
    isSandboxFlow: BooleanParam,
    redirectTo: StringParam,
  });
  const possibleToLoginWithEmailPassword =
    canUseEmailPasswordAuth || window.location.host.includes('app2');
  const [showEmailPasswordForm, setShowEmailPasswordForm] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loadingEmailPasswordAuth, setLoadingEmailPasswordAuth] =
    useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const { loading } = useContext(LoadingContext);
  const { identify, track, getCount, increment } = useContext(AnalyticsContext);
  /** @type {ReturnType<typeof import('../../helpers').augmentApolloClient>} */
  const client = useApolloClient();
  const auth = useMutation(LOG_IN_SIGN_UP);
  const logInCompany = useMutation(LOG_IN_COMPANY);
  const { addMessage } = useContext(MessagesContext);

  // support for "magic link" functionality and redirect to home when
  // user is already logged in
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const paramToken = params.get('token');
  const localStorageToken = localStorage.getItem('token');
  const switchToCompanyId = params.get('switchToCompanyId');

  const issuerParam = params.get('iss');

  let showOktaButton = false;
  let oktaDomain;

  if (issuerParam) {
    // Get the hostname. This can fail if the URL is malformed or incomplete, so try/catch
    try {
      oktaDomain = new URL(issuerParam).hostname;
      showOktaButton = !!oktaDomain.length;
    } catch {
      log.error(
        `Malformed or incomplete URL provided for issuer param "${issuerParam}"`,
      );
    }
  }

  const switchCompany = useCallback(
    async (companyId) => {
      const result = await logInCompany({
        variables: { companyId },
      });

      localStorage.setItem('token', result.data.logInCompany.token);

      setTimeout(() => {
        addMessage({
          body: `Currently viewing company number ${companyId}`,
          type: 'success',
        });
      }, 500);
    },
    [logInCompany, addMessage],
  );

  const { fn: createSandboxCompany, loading: createSandboxLoading } =
    useMutationWrapper(SIGN_UP_WITH_NEW_SANDBOX_COMPANY);

  // Check if a user is already authenticated and redirect them properly if so
  useEffect(() => {
    if (!(paramToken || localStorageToken)) return;

    async function handleAuthenticatedUser() {
      localStorage.setItem(
        'token',
        trim([paramToken, localStorageToken].join(' ')),
      );

      if (switchToCompanyId) {
        await switchCompany(switchToCompanyId);
      }

      handlePostLogin({ history, redirectTo: queryParams.redirectTo });
    }
    handleAuthenticatedUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramToken, localStorageToken, switchToCompanyId, switchCompany]);

  // Called after successful authentication with Sora
  const afterLogin = async ({ data }) => {
    const resp = data.logInSignUp;

    localStorage.setItem('token', resp.token);

    await client.logIn();

    if (switchToCompanyId) {
      await switchCompany(switchToCompanyId);
    }

    handlePostLogin({ history, redirectTo: queryParams.redirectTo });

    identify(resp.id, {}, {}, () => {
      if (resp.newUser) {
        track(`Signed Up`, {
          'New Company': false,
        });
        identify({ 'Signup Date': new Date().toISOString() });
      } else {
        // TODO this count resets when we call analytics.reset()
        // figure out a way to keep it consistent
        const count = getCount('Login');
        track(`Logged In`, { 'Login Count': count });
        increment('Login');
        // TODO fix count (see comment above)
        // identify({ 'Login Count': count });
      }
    });
  };

  // Called after successful authentication with 3rd party provider
  const onSuccess = ({ token, provider }) => {
    auth({
      variables: {
        token,
        provider,
        issuer: oktaDomain ? `https://${oktaDomain}` : null,
      },
    })
      .then(({ data }) => {
        afterLogin({ data });
      })
      .catch((error) => {
        const gqlError = error.graphQLErrors && error.graphQLErrors[0];

        /**
         * If the user is in the sandbox flow and they get a NOT_FOUND error,
         * that means the user is trying to create a sandbox company for the
         * first time
         */
        if (
          queryParams.isSandboxFlow &&
          gqlError?.extensions?.userData &&
          gqlError?.extensions?.code === 'NOT_FOUND'
        ) {
          const errorDetails = gqlError.extensions;
          handleCreateSandboxCompany(errorDetails.userData);
          return;
        }

        // Displays the error message to the user
        handleResponseError(error);
      });
  };

  const onFailure = (err) => {
    if (!(err instanceof Error) && err.error) {
      // create proper error so Sentry can properly merge them since
      // err passed as argument is not instanceof Error
      const newError = new Error(err.error);
      log.error(newError, err);
    } else {
      log.error(err);
    }
    track('Received auth error', { Error: err });
  };

  const handleCreateSandboxCompany = async ({
    domain,
    email,
    firstName,
    middleName,
    lastName,
  }) => {
    const companyName = domain;
    const userData = {
      email: email,
      firstName: firstName || DEFAULT_USER_NAME,
      middleName: middleName,
      lastName: lastName || DEFAULT_USER_NAME,
    };
    const companyData = {
      name: companyName,
      timezone: moment.tz.guess(),
      domain: {
        name: domain,
        isVerified: true,
      },
    };

    const { data } = await createSandboxCompany({
      variables: {
        userData,
        companyData,
      },
    });
    const resp = data.signUpWithNewSandboxCompany;

    localStorage.setItem('token', resp.token);
    await client.logIn();
    handlePostLogin({ history, redirectTo: queryParams.redirectTo });

    /*
     * Identify user properties with Segment Analytics
     * These properties will stick to the user throughout
     * their engagement on Sora's app
     */
    identify(
      resp.id,
      {
        ...userData,
        company: {
          id: resp.companyId,
          name: companyName,
        },
      },
      {},
      () => {
        /*
         * Calling `track` with ``New Company': true` will create a new Lead in Salesforce
         * via our Segment integration. We are including the userData and companyData to
         * attach that data to the Lead.
         *
         * There are more details in this Slab document about this technique here:
         * https://sora.slab.com/posts/tracking-new-sandbox-leads-9dbmpmjb
         */
        track('Signed Up', { 'New Company': true, userData, companyData });
        identify({ 'Signup Date': new Date().toISOString() });
      },
    );
  };

  // This block handles the scenario where we use a redirect pattern of login
  // from Google, rather than the popup. The redirect returns a hash with the
  // parameters we need to process the user's auth token
  useEffect(() => {
    if (location.hash) {
      const hashParams = new URLSearchParams(`?${location.hash.substr(1)}`);
      const tokenId = hashParams.get('id_token');

      if (tokenId) {
        setIsProcessingRedirectAuth(true);
        onSuccess({ token: tokenId, provider: 'GOOGLE' });
      }
    } else {
      setIsProcessingRedirectAuth(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  return (
    <Page id='auth-page' category={PAGE_CATEGORY} layout='no-navigation'>
      <BoxCard className='site-auth margin-x-auto padding-y-6 padding-x-5 text-center'>
        <img src={soraLogo} width={120} alt='Sora' />
        <h1>Sora</h1>
        {isProcessingRedirectAuth && <Loading />}
        {!isProcessingRedirectAuth && (
          <>
            {createSandboxLoading ? (
              <>
                <p className='text-xlarge text-muted margin-bottom-4'>
                  Creating your Sora account...
                </p>
                <Loading />
              </>
            ) : (
              <>
                <p className='text-xlarge text-muted margin-bottom-4'>
                  Log in using your work email address
                </p>
                {!showEmailPasswordForm && (
                  <>
                    <div className='d-flex justify-content-center'>
                      <GoogleLogin
                        onSuccess={(credentialResponse) =>
                          onSuccess({
                            // This is the ID token which is needed to verify the authenticity of the user
                            // on the backend.
                            token: credentialResponse.credential,
                            provider: 'GOOGLE',
                          })
                        }
                        onError={onFailure}
                        useOneTap
                        width='210px'
                      />
                    </div>
                    <MicrosoftLogin
                      onSuccess={({ token }) =>
                        onSuccess({
                          token,
                          provider: 'MICROSOFT',
                        })
                      }
                      onFailure={onFailure}
                    >
                      <MicrosoftLoginButton
                        disabled={loading}
                        authType={authType}
                      />
                    </MicrosoftLogin>
                    {showOktaButton && (
                      <OktaLoginButton
                        domain={oktaDomain}
                        onSuccess={(token) =>
                          onSuccess({ token, provider: 'OKTA' })
                        }
                        onFailure={onFailure}
                      ></OktaLoginButton>
                    )}
                  </>
                )}
              </>
            )}

            {possibleToLoginWithEmailPassword && showEmailPasswordForm && (
              <div className='text-left'>
                <div
                  className='d-inline-flex align-items-center link'
                  onClick={() => {
                    setShowEmailPasswordForm(false);
                  }}
                >
                  <div
                    className='rounded-circle bg-gray-1 bg-hover-gray-2 text-center'
                    style={{ width: 30, height: 30 }}
                  >
                    <div>
                      <LeftArrowSvg className='padding-1' />
                    </div>
                  </div>
                  <p className='margin-0 margin-left-2'>
                    <span className='link'>Back</span>
                  </p>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormLabel name='email' />
                  <FormInput
                    name='email'
                    placeholder='Work email address'
                    value={email}
                    onChange={(e) => {
                      setEmail(trim(e.target.value));
                    }}
                    autoComplete='email'
                  />
                  <FormLabel name='password' />
                  <FormInput
                    name='password'
                    placeholder='Password'
                    type='password'
                    value={password}
                    onChange={(e) => {
                      setPassword(trim(e.target.value));
                    }}
                    autoComplete='password'
                  />
                  {!!loginErrorMessage && (
                    <DangerNoticeBox
                      padding='padding-y-3 padding-x-4'
                      className='margin-top-3'
                    >
                      {loginErrorMessage}
                    </DangerNoticeBox>
                  )}
                  <div className='margin-top-3'>
                    <Button
                      type='submit'
                      category='primary'
                      size='large'
                      full={true}
                      disabled={!email || !password}
                      loading={loadingEmailPasswordAuth}
                      onClick={() => {
                        setLoadingEmailPasswordAuth(true);
                        setLoginErrorMessage('');
                        auth({
                          variables: {
                            email: email.toLowerCase(),
                            password,
                            provider: 'EMAIL',
                          },
                        })
                          .then(async ({ data }) => {
                            setLoadingEmailPasswordAuth(false);
                            afterLogin({ data });
                          })
                          .catch((e) => {
                            let formattedMessage = e.message;
                            if (e.message.indexOf('GraphQL error: ') === 0) {
                              formattedMessage = e.message.substr(15);
                            }
                            setLoginErrorMessage(formattedMessage);
                            setLoadingEmailPasswordAuth(false);
                            onFailure(e);
                          });
                      }}
                      label='Log in'
                    />
                  </div>
                </form>
              </div>
            )}
            {possibleToLoginWithEmailPassword && !showEmailPasswordForm && (
              <p className='text-muted margin-bottom-0'>
                or,{' '}
                <u
                  className='text-hover-blue cursor-pointer'
                  onClick={() => {
                    setShowEmailPasswordForm(true);
                  }}
                >
                  log in with email and password
                </u>
              </p>
            )}
            {loading && <Loading />}
          </>
        )}
      </BoxCard>
    </Page>
  );
};

AuthPage.propTypes = {
  history: object.isRequired,
  authType: string,
  location: object,
};

export default memo(AuthPage);
export { PAGE_CATEGORY };
