import { DEFAULT_INTEGRATION } from '../../common';

export const TIMER_INTEGRATION_ID = 1;

export const TIMER_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: TIMER_INTEGRATION_ID,
  configurable: () => false,
  name: 'timer',
  label: 'Timer',
  description: '',
  availableUses: [],
};
