import { useCallback, useLayoutEffect, useState } from 'react';

function getDimensionObject(node) {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    top: 'x' in rect ? rect.x : rect.top,
    left: 'y' in rect ? rect.y : rect.left,
    x: 'x' in rect ? rect.x : rect.left,
    y: 'y' in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom,
  };
}

const areSameDimensions = (d1, d2) => {
  return ['width', 'height', 'top', 'left', 'x', 'y', 'right', 'bottom'].every(
    (f) => d1[f] === d2[f],
  );
};

function useDimensions({
  liveMeasure = true,
  events = ['resize', 'scroll'],
} = {}) {
  const [dimensions, setDimensions] = useState({});
  const [node, setNode] = useState(null);

  const ref = useCallback((node) => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = () => {
        window.requestAnimationFrame(() => {
          setDimensions((prevDimensions) => {
            const dimensionsObj = getDimensionObject(node);

            if (!areSameDimensions(prevDimensions, dimensionsObj)) {
              return dimensionsObj;
            }
            return prevDimensions;
          });
        });
      };

      measure();

      if (liveMeasure) {
        events.forEach((e) => {
          window.addEventListener(e, measure);
        });

        return () => {
          events.forEach((e) => window.removeEventListener(e, measure));
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events.join(','), liveMeasure, node]);

  return [ref, dimensions, node];
}

export default useDimensions;
