import { clone, isArray, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

import { buildYupFieldValidator } from 'sora-invariant/src/validators/field';

// `formObject` is an object representing a form's keys/values,
// and has the following structure:
//
// {
//   inputKey: {
//     value:            input value,
//     required:         true/false,
//     customValidators: [validatorFunction1, validatorFunction2, ...],
//   },
//   ...
// }
//
// `inputKey`
// Unique identifier for the form input field.
//
// `value`
// The value of the input field.
//
// `required`
// If `true`, an error will be set for this input field
// if no value is provided.
//
// `customValidators`
// An array of arbitrary functions that will be run to
// validate the input value. The function must return true if value is valid,
// or false if invalid.
//
// `isFieldInput`
// If `true`, the input will be validated based on the field's properties,
// e.g. required, maxValue, etc.
//
// `field`
// The Field object associated with the input value.
// This is required if `isFieldInput` is set to true.
const useFormValidation = (formObject) => {
  const [visitedInputs, setVisitedInputs] = useState({});
  const [inputErrors, setInputErrors] = useState({});

  // Build Yup validation schema and values object
  const schemaObject = {};
  const formValues = {};
  Object.keys(formObject).forEach((inputKey) => {
    const { value, required, customValidators, isFieldInput, field } =
      formObject[inputKey];

    // Build values object
    formValues[inputKey] = value;

    // Build validation schema
    let inputValidator = yup.mixed();

    // 'required' validator
    //
    // NOTE: We cannot just use the yup.required() validator
    // because it will not handle empty strings/arrays/objects.
    if (required) {
      inputValidator = inputValidator.test({
        name: `${inputKey}-required-validator`,
        message: `Input value is required`,
        test: (value) => !isEmpty(value),
      });
    }

    // 'custom' validators
    if (customValidators && isArray(customValidators)) {
      customValidators.forEach((customValidator, index) => {
        inputValidator = inputValidator.test({
          name: `${inputKey}-custom-validator-${index + 1}`,
          message: `Custom validation error`,
          test: (value) => customValidator(value),
        });
      });
    }

    // 'field' validator
    if (isFieldInput) {
      inputValidator = inputValidator.concat(buildYupFieldValidator(field));
    }

    schemaObject[inputKey] = inputValidator;
  });
  const formSchema = yup.object().shape(schemaObject);

  const visitInput = (inputKey) => {
    // Don't bother setting visitedInputs and consequently
    // triggering a re-render unless necessary, i.e. only
    // if the input has never been visited yet.
    if (!visitedInputs[inputKey]) {
      const newVisitedInputs = clone(visitedInputs);
      newVisitedInputs[inputKey] = true;
      setVisitedInputs(newVisitedInputs);
    }
  };

  // NOTE: `yup` returns one error at a time per input,
  // i.e. if an input has two validators that should both
  // technically fail, it will raise an error as soon as
  // the first validator fails, so we only get the error
  // message for that first validator.
  const validateForm = () => {
    const newErrors = {};

    formSchema
      .validate(formValues, { abortEarly: false })
      .catch((yupError) => {
        // Build inputErrors object
        yupError.inner.forEach((error) => {
          const inputKey = error.path;
          const errorMessage = error.message;

          // Only add errors if input has been visited
          if (visitedInputs[inputKey]) {
            newErrors[inputKey] = errorMessage;
          }
        });
      })
      .finally(() => {
        // Set errors
        setInputErrors(newErrors);
      });
  };

  // Validate form whenever formObject changes
  // or inputs are visited.
  //
  // NOTE: We stringify formObject when checking for
  // changes since formObject is re-created upon re-render.
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      validateForm();
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [JSON.stringify(formObject), visitedInputs]);

  return {
    visitInput: visitInput,
    validate: validateForm,
    errors: inputErrors,
  };
};

export default useFormValidation;
