import { array, func, object, string } from 'prop-types';
import React from 'react';

import { KeyDownSearchBar } from 'sora-client/components/common/Search/KeyDownSearchBar';
import { filterAndSortItems } from 'sora-client/helpers/search';

const Search = (props) => {
  const {
    allOptions,
    className = null,
    defaultSearchValue,
    onChange,
    onToggle,
    filteredOptions,
    setLastKeyNavigation,
    style = null,
    updateDefaultSearchValue,
    updateFilteredOptions,
  } = props;

  return (
    <KeyDownSearchBar
      autoFocus
      className={className}
      customInputClassnames='flat'
      defaultSearchValue={defaultSearchValue}
      listSize={filteredOptions.length}
      onKeyNavigation={({ activeCursor, lastKeyPress }) => {
        setLastKeyNavigation({
          cursor: activeCursor,
          lastKeyPress,
        });

        if (lastKeyPress === 'Enter' && filteredOptions[activeCursor]) {
          onChange(
            filteredOptions[activeCursor].value,
            filteredOptions[activeCursor],
          );
          onToggle(false);
        }
      }}
      onSearchInputChange={(value) => {
        updateFilteredOptions(filterAndSortItems(allOptions, value));
        updateDefaultSearchValue(value);
      }}
      placeHolderText='Search'
      style={style}
    />
  );
};

Search.propTypes = {
  allOptions: array.isRequired,
  className: string,
  defaultSearchValue: string,
  onChange: func.isRequired,
  onToggle: func.isRequired,
  filteredOptions: array.isRequired,
  setLastKeyNavigation: func.isRequired,
  style: object,
  updateDefaultSearchValue: func.isRequired,
  updateFilteredOptions: func.isRequired,
};

export default Search;
