import gql from 'graphql-tag';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { NEW_HIRE_TASKS } from 'sora-client/constants/routes';
import { MessagesContext, SessionContext } from 'sora-client/contexts';
import log from 'sora-client/logger';

import { useMutationWrapper } from '../MutationWrapper';
import useFirstNhtInNewlyEnrolledWorkflow from './useFirstNhtInNewlyEnrolledWorkflow';

const ENROLL_IN_WORKFLOW = gql`
  mutation selfEnrollInWorkflow($timelineId: ID!, $viaExternalLink: Boolean) {
    selfEnrollInWorkflow(
      timelineId: $timelineId
      viaExternalLink: $viaExternalLink
    ) {
      id
      timeline {
        name
      }
    }
  }
`;

export const useSelfEnroll = ({ workflowId }) => {
  const session = useContext(SessionContext);
  const { addMessage } = useContext(MessagesContext);
  const history = useHistory();
  const [enrolledWorkflow, setEnrolledWorkflow] = useState(undefined);

  const [enrollError, setEnrollError] = useState(undefined);

  const { fn: enroll, loading: enrollLoading } = useMutationWrapper(
    ENROLL_IN_WORKFLOW,
    {
      showError: false,
    },
  );

  const enrollInWorkflow = async () => {
    try {
      const { data } = await enroll({
        variables: { timelineId: workflowId },
      });
      setEnrolledWorkflow(data?.selfEnrollInWorkflow);
    } catch (e) {
      log.error(e);
      setEnrollError(e);
    }
  };

  const {
    ewLoading,
    ewError,
    nhtData,
    nhtLoading,
    nhtError,
    firstNhtInNewlyEnrolledWorkflow,
  } = useFirstNhtInNewlyEnrolledWorkflow({
    workflowId,
    employeeId: session.currentUser.employeeId,
    isEnrollmentSuccessful: !!enrolledWorkflow,
  });

  const isLoading =
    enrollLoading ||
    ewLoading ||
    nhtLoading ||
    // Captures interstitial period after the ENROLL_IN_WORKFLOW mutation is complete
    // but the query to fetch NHTs for the new employee workflow hasn't begun yet
    !!(enrolledWorkflow && !nhtData?.newHireTasks && !ewError && !nhtError);

  useEffect(() => {
    const enrolledWorkflowName = enrolledWorkflow?.timeline?.name;

    if (firstNhtInNewlyEnrolledWorkflow) {
      addMessage({
        body: `You have successfully enrolled in the ${enrolledWorkflowName} workflow. Get started on your first task!`,
        type: 'success',
        timeout: 10000,
      });

      const { task, id } = firstNhtInNewlyEnrolledWorkflow;

      // If the first NHT in the newly created employee workflow is a survey
      // or an assignment that is triggered immediately by the "Added to workflow"
      // workflow stage, then redirect them to that task.
      if (
        !task.triggerTaskId &&
        !task.triggerWorkflowStage?.fieldId &&
        ['survey', 'assignment'].includes(task.type)
      ) {
        history.push(`/tasks/my-tasks?tid=${id}&self_enrollment_success=true`);
      } else {
        history.push(
          `${NEW_HIRE_TASKS}?workflow[id]=${workflowId}&workflow[name]=${enrolledWorkflowName}&show_future_tasks=true&self_enrollment_success=true`,
        );
      }
    }
    // Show notification when there are no tasks assigned to the user
    else if (enrolledWorkflow && !isLoading) {
      addMessage({
        body: `You have successfully enrolled in the ${enrolledWorkflowName} workflow. No tasks assigned to you at the moment, you'll get notified by email if any future tasks need your attention!`,
        type: 'success',
        timeout: 15000,
      });

      // Redirect to the list of tasks
      history.push(
        `${NEW_HIRE_TASKS}?workflow[id]=${workflowId}&workflow[name]=${enrolledWorkflowName}&show_future_tasks=true&self_enrollment_success=true`,
      );
    }
  }, [
    enrolledWorkflow,
    isLoading,
    firstNhtInNewlyEnrolledWorkflow,
    addMessage,
    workflowId,
    history,
  ]);

  return {
    enrollInWorkflow,
    enrollError,
    enrolledWorkflow,
    isLoading,
  };
};
