import { node, string } from 'prop-types';
import React, { useContext, useEffect } from 'react';

import Navigation from 'sora-client/components/common/Page/Navigation';
import SunsetSeparator from 'sora-client/components/common/SunsetSeparator';
import AnalyticsContext from 'sora-client/contexts/AnalyticsContext';
import SessionContext from 'sora-client/contexts/SessionContext';
import { userAuthorization } from 'sora-client/helpers/authorization';

// `not-mobile-page-nav-actions` is used to attach page-level actions with usePortal
const PageCore = ({ id, className, title, subtitle, children }) => (
  <>
    <div className={className} id={id} data-testid={id}>
      {title && (
        <div className='d-flex align-items-center margin-bottom-4 mobile-margin-bottom-3'>
          <div className='flex-grow-1'>
            <h2 className='margin-y-0 font-p22-mackinac'>{title}</h2>
            {subtitle && (
              <div className='margin-top-2'>
                <div className='text-muted'>{subtitle}</div>{' '}
                <SunsetSeparator verticalSpacing='padding-y-3' />
              </div>
            )}
          </div>
          <div id='not-mobile-page-nav-actions' /> {/* See note above */}
        </div>
      )}
      {children}
    </div>
  </>
);

PageCore.propTypes = {
  id: string.isRequired,
  className: string,
  title: string,
  subtitle: string,
  children: node,
};

const FlushLayout = (props) => (
  <>
    <Navigation currentNavItemCategory={props.category} />
    <PageCore {...props} className='flush-page-container' />
  </>
);

FlushLayout.propTypes = {
  category: string,
};

const DefaultLayout = (props) => (
  <>
    <Navigation currentNavItemCategory={props.category} />
    <PageCore {...props} className='padding-5 mobile-padding-3' />
  </>
);

DefaultLayout.propTypes = {
  category: string,
};

const NoNavLayout = (props) => <PageCore {...props} />;

const Page = ({
  id,
  category,
  title,
  subtitle,
  layout = 'default',
  children,
}) => {
  const { identify } = useContext(AnalyticsContext);
  const { currentUser } = useContext(SessionContext);

  useEffect(() => {
    if (currentUser?.id) {
      const ua = userAuthorization(currentUser);

      /**
       * We must call Segment's "identify" function on every logged in page to ensure that we are accurately
       * tracking where the user is on the site. This is particularly important for Intercom Product Tours
       * (which is integrated with Segment), because it tells Intercom which step of the Product Tour they
       * left off on if they navigate away from the app mid-tour and then re-enter the app later.
       *
       * See https://segment.com/docs/connections/spec/identify/ for more details.
       */
      identify(currentUser.id);
      window.DD_RUM &&
        window.DD_RUM.setUser({
          id: currentUser.id,
        });
      window.DD_RUM &&
        window.DD_RUM.setRumGlobalContext({
          companyId: currentUser.companyId,
          superAdmin: ua.isSuperAdmin,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (layout === 'no-navigation') {
    return (
      <NoNavLayout id={id} title={title}>
        {children}
      </NoNavLayout>
    );
  }

  if (layout === 'flush') {
    return (
      <FlushLayout id={id} category={category} title={title}>
        {children}
      </FlushLayout>
    );
  }

  return (
    <DefaultLayout
      id={id}
      title={title}
      subtitle={subtitle}
      category={category}
    >
      {children}
    </DefaultLayout>
  );
};

Page.propTypes = {
  id: string.isRequired,
  category: string.isRequired,
  title: string,
  subtitle: string,
  layout: string,
  children: node,
};

export default Page;
