import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  COMMON_OAUTH_CONFIG_PROPERTIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const ICIMS_INTEGRATION_ID = 16;

export const ICIMS_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: ICIMS_INTEGRATION_ID,
  name: 'icims',
  label: 'iCIMS',
  description:
    'iCIMS offers HR professionals with a web-based employment management system featuring a parsing tool to automate resume acquisition.',
  website: 'icims.com',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/icims.png`,
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  categories: [CATEGORIES.ATS],
  configSchema: {
    ...COMMON_OAUTH_CONFIG_PROPERTIES,
    webhookKey: {
      type: 'string',
      title: 'Webhook key',
      autogenerate: true,
      uiSchema: {
        'ui:widget': 'ReadOnlyValueToCopy',
      },
    },
    customerId: {
      type: 'string',
      title: 'Customer ID',
      nullable: true,
    },
  },
  configured: (c) => c.values && c.values.customerId && c.values.accessToken,
  validation: { action: 'icims/getCustomer' },
};
