import useQueryWrapper from 'sora-client/components/common/useQueryWrapper';
import useSubscriptionWrapper from 'sora-client/components/common/useSubscriptionWrapper';

import {
  EMPLOYEE_WORKFLOW_UPDATED_SUBSCRIPTION,
  GET_NEWLY_CREATED_EMPLOYEE_WORKFLOW,
  GET_NEW_HIRE_TASKS_FOR_NEWLY_ENROLLED_WORKFLOW,
} from './queries';

/**
 * This hook is used in conjunction with the mutation to enroll an employee in a workflow.
 *
 * Once an employee is successfully enrolled in a workflow, this hook does the following:
 * 1. Fetches the newly created employee workflow
 * 2. Subscribes to 'update' events on the newly created employee workflow, so that we know when
 * NHTs are finished being generated for that employee workflow
 * 3. Fetches the first new hire task for that newly created employee workflow once NHTs
 * are finished being generated
 *
 * It returns an object containing:
 * - the first NHT in the newly created employee workflow,
 * - the newly created employeeWorkflow,
 * - and all of the various loading, data, and error values returned from the queries
 *
 * @param {object} p0
 * @param {string} p0.workflowId
 * @param {string} p0.employeeId
 * @param {boolean} p0.isEnrollmentSuccessful
 */
const useFirstNhtInNewlyEnrolledWorkflow = ({
  workflowId,
  employeeId,
  isEnrollmentSuccessful,
}) => {
  // Only fetch the new employee workflow AFTER the employee has successfully
  // enrolled in the workflow
  const {
    data: ewData,
    loading: ewLoading,
    error: ewError,
  } = useQueryWrapper(
    GET_NEWLY_CREATED_EMPLOYEE_WORKFLOW,
    {
      timelineId: workflowId,
      employeeId,
    },
    {
      skip: !isEnrollmentSuccessful,
      showError: false,
      // Always fetch from the server rather than the cache. If an employee enrolls in the same workflow
      // multiple times in a row in rapid succession, the cache will be out of date.
      fetchPolicy: 'network-only',
    },
  );

  const employeeWorkflow =
    ewData?.employeeWorkflows?.rows?.length && ewData.employeeWorkflows.rows[0];

  // Subscribe to 'update' events on the newly created employee workflow so that we
  // are notified when `nhtsSyncState` is updated on the employee workflow
  // (i.e. when NHTs are finished being generated for that employee workflow)
  useSubscriptionWrapper(EMPLOYEE_WORKFLOW_UPDATED_SUBSCRIPTION, {
    variables: { ids: [employeeWorkflow?.id] },
    skip: !employeeWorkflow?.id,
  });

  // Only fetch the new hire tasks in the new employee workflow:
  // 1. AFTER the employee has successfully enrolled in the workflow, AND
  // 2. AFTER all NHTs have finished being generated for that new employee workflow
  const {
    data: nhtData,
    loading: nhtLoading,
    error: nhtError,
  } = useQueryWrapper(
    GET_NEW_HIRE_TASKS_FOR_NEWLY_ENROLLED_WORKFLOW,
    {
      employeeWorkflowId: employeeWorkflow?.id,
      employeeId,
    },
    {
      skip:
        !employeeWorkflow ||
        (employeeWorkflow?.nhtsSyncState &&
          employeeWorkflow.nhtsSyncState !== 'SYNCED'), // skip if employee workflow is not yet SYNCED
      showError: false,
    },
  );

  const firstNhtInNewlyEnrolledWorkflow =
    nhtData?.newHireTasks?.rows?.length && nhtData.newHireTasks.rows[0];

  return {
    employeeWorkflow,
    ewLoading,
    ewError,
    ewData,
    nhtData,
    nhtLoading,
    nhtError,
    firstNhtInNewlyEnrolledWorkflow,
  };
};

export default useFirstNhtInNewlyEnrolledWorkflow;
