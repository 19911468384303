import gql from 'graphql-tag';

export const GET_EMPLOYEES_STARTING_SOON = gql`
  query getEmployeesStartingInNextTwoWeeks(
    $beforeStartDate: IsoDate
    $afterStartDate: IsoDate
  ) {
    employees(
      filters: {
        startDate: { afterDate: $afterStartDate, beforeDate: $beforeStartDate }
        terminated: false
      }
      orderBy: "startDate"
      order: "ASC"
    ) {
      count
      rows {
        id
        name
        email
        title
        imageUrl
        startDate
        user {
          id
          hasSoraAccount
        }
      }
    }
  }
`;
