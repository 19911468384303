import { bool, func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import Button from 'sora-client/components/common/Button';
import EmployeeSelect from 'sora-client/components/common/EmployeeSelect';
import { modelConstants } from 'sora-invariant/src/constants';

import { GreenhouseAdditionalSelectors } from './GreenhouseAdditionalSelectors';

const { greenhouse } = modelConstants.webhookEvents.domains;

export const TestEmployeePicker = ({
  employeeIdentifiers = null,
  hasTestBeenExecuted = false,
  instructions = 'View sample data for:',
  onCancelRequest = null,
  onSubmit,
  rawData = null,
  serviceName,
  testLoading,
  isSampleDataStale,
  setIsSampleDataStale,
}) => {
  const [selectedEmployeeId, updateSelectedEmployeeId] = useState(null);
  const [testCandidateEmail, updateTestCandidateEmail] = useState('');
  const [employeeIdentifierOverrides, updateEmployeeIdentifierOverrides] =
    useState(employeeIdentifiers || {});

  useEffect(() => {
    if (employeeIdentifiers)
      updateEmployeeIdentifierOverrides(employeeIdentifiers);
  }, [employeeIdentifiers]);

  return (
    <div>
      <div className='d-flex align-items-center'>
        <span className='text-uppercase text-small font-weight-500 text-midnight-navy-75 text-nowrap margin-right-2'>
          {instructions}{' '}
        </span>
        <div className='margin-right-2'>
          <input
            type='text'
            placeholder='Enter candidate email address'
            value={testCandidateEmail}
            onChange={(e) => {
              updateTestCandidateEmail(e.target.value);
              updateSelectedEmployeeId(null);
              // Reset overrides when changing the test candidate
              updateEmployeeIdentifierOverrides({});
              setIsSampleDataStale(true);
            }}
            style={{ width: 350, height: 28 }}
          />
        </div>
        <span className='text-muted margin-right-2'>OR</span>
        <div className='margin-right-2'>
          <EmployeeSelect
            disabled={testLoading || !!testCandidateEmail.length}
            onChange={(id) => {
              updateSelectedEmployeeId(id);
              // Reset overrides when changing the test candidate
              updateEmployeeIdentifierOverrides({});
              setIsSampleDataStale(true);
            }}
            selectedEmployee={{ id: selectedEmployeeId }}
          />
        </div>
        <Button
          category='primary'
          loading={testLoading}
          label={hasTestBeenExecuted ? 'Refresh' : 'Go'}
          onClick={() =>
            onSubmit({
              email: testCandidateEmail,
              employeeId: selectedEmployeeId,
              employeeIdentifierOverrides,
            })
          }
        />
        {onCancelRequest && (
          <Button
            onClick={onCancelRequest}
            className='margin-left-2'
            label='Cancel'
            disabled={!testLoading}
          />
        )}
      </div>
      {/* Service-specific additional options can be included here */}
      {serviceName === greenhouse && rawData && !isSampleDataStale && (
        <GreenhouseAdditionalSelectors
          employeeIdentifiers={employeeIdentifierOverrides}
          onUpdate={updateEmployeeIdentifierOverrides}
          rawData={rawData}
        />
      )}
    </div>
  );
};

TestEmployeePicker.propTypes = {
  employeeIdentifiers: object,
  hasTestBeenExecuted: bool.isRequired,
  instructions: string,
  isSampleDataStale: bool.isRequired,
  onCancelRequest: func,
  onSubmit: func.isRequired,
  rawData: object,
  serviceName: string.isRequired,
  setIsSampleDataStale: func.isRequired,
  testLoading: bool.isRequired,
};
