import classNames from 'classnames';
import { node, string } from 'prop-types';
import React from 'react';

const CodeBlock = ({ className, children }) => {
  return (
    <pre
      className={classNames(
        'bg-gray padding-1 overflow-scroll-x rounded-xlarge',
        className,
      )}
      style={{ whiteSpace: 'pre-wrap' }}
    >
      {children}
    </pre>
  );
};

CodeBlock.propTypes = {
  className: string,
  children: node.isRequired,
};

export { CodeBlock };
