import * as Sentry from '@sentry/browser';

import { error } from 'sora-invariant/src/constants';

const dsn = window.location.host.includes('app2')
  ? process.env.REACT_APP_SENTRY_DSN2
  : process.env.REACT_APP_SENTRY_DSN;

const IGNORED_CLIENT_ERROR_MESSAGES = Object.values(error.messages);

Sentry.init({
  dsn,
  environment: process.env.REACT_APP_REAL_ENV || process.env.NODE_ENV,
  release: process.env.REACT_APP_SCHEMA_VERSION,
  beforeSend(event) {
    if (
      event.exception?.values?.some((err) => {
        const errVal = err?.value || '';
        return IGNORED_CLIENT_ERROR_MESSAGES.some((msg) => {
          return errVal.includes(msg);
        });
      })
    ) {
      return null;
    }
    return event;
  },
});

export default {
  error(err, extra) {
    // if err is string Sentry will record synthetic exception without any message
    if (typeof err === 'string') {
      err = { message: err };
    }
    if (err && !err.message && typeof err.error === 'string') {
      err.message = err.error;
    }
    Sentry.withScope((scope) => {
      Object.keys(err).forEach((key) => {
        scope.setExtra(key, err[key]);
      });
      if (extra) {
        scope.setExtra('extra', extra);
      }
      Sentry.captureException(err);
    });
  },
  captureMessage(mess) {
    return Sentry.captureMessage(mess);
  },
  addBreadcrumb(breadcrumb) {
    return Sentry.addBreadcrumb(breadcrumb);
  },
  configureScope(setScope) {
    return Sentry.configureScope(setScope);
  },
};
