import { useEffect, useState } from 'react';

/**
 * This hook provides you with a state variable to store a `notice` object, which is useful in tandem with
 * the `WarningNoticeBoxWithIcon` and `SuccessNoticeBoxWithIcon` components.
 *
 * This hook will by default clear a 'success' notice after 2.5 seconds.
 * If clearErrorNotice is true, all notices will be cleared after 2.5 seconds.
 */
const useSuccessErrorNotice = (clearNoticeAfterTimeout = false) => {
  const [notice, setNotice] = useState({
    body: null,
    type: null, // generally expects 'success' or 'error'
  });

  useEffect(() => {
    let timeout;

    if (notice.type === 'success' || clearNoticeAfterTimeout) {
      // Clear notice after 2.5 seconds
      timeout = setTimeout(() => {
        setNotice({
          body: null,
          type: null,
        });
      }, 2500);
    }

    return function cleanupTimeout() {
      clearTimeout(timeout);
    };
  }, [notice.type, clearNoticeAfterTimeout]);

  return [notice, setNotice];
};

export default useSuccessErrorNotice;
