import gql from 'graphql-tag';

const LOG_IN_SIGN_UP = gql`
  mutation logInSignUpOperation(
    $token: String
    $email: String
    $password: String
    $provider: AuthProviders!
    $issuer: String
  ) {
    logInSignUp(
      token: $token
      email: $email
      password: $password
      provider: $provider
      issuer: $issuer
    ) {
      id
      token
      newUser
      userData {
        email
        domain
        firstName
        middleName
        lastName
      }
    }
  }
`;

const SIGN_UP_WITH_NEW_SANDBOX_COMPANY = gql`
  mutation signUpWithNewSandboxCompanyOperation(
    $userData: UserInput!
    $companyData: CompanyInput!
  ) {
    signUpWithNewSandboxCompany(
      userData: $userData
      companyData: $companyData
    ) {
      id
      companyId
      token
    }
  }
`;

export { LOG_IN_SIGN_UP, SIGN_UP_WITH_NEW_SANDBOX_COMPANY };
