import { useMutation, useQuery } from '@apollo/react-hooks';
import { capitalize, lowerCase, upperFirst, words } from 'lodash';
import moment from 'moment';
import { func, string } from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import EmployeeAvatar from 'sora-client/components/common/Avatar';
import { Badge } from 'sora-client/components/common/Badge';
import { CodeBlock } from 'sora-client/components/common/CodeBlock';
import DangerNoticeBoxWithIcon from 'sora-client/components/common/DangerNoticeBoxWithIcon';
import Flyout from 'sora-client/components/common/Flyout';
import { IntegrationLogo } from 'sora-client/components/common/IntegrationLogo';
import Loading from 'sora-client/components/common/Loading';
import NoticeBox from 'sora-client/components/common/NoticeBox';
import { WEBHOOK_EVENT_STATUS_TO_COLOR } from 'sora-client/constants/components';
import { MessagesContext, SessionContext } from 'sora-client/contexts';
import { prettyPrintJson } from 'sora-client/helpers';
import { userAuthorization } from 'sora-client/helpers/authorization';
import { getIntegrationByName } from 'sora-invariant/src/integrations/constants';

import { DataField } from './DataField';
import { ReplayButton } from './ReplayButton';
import { WebhookEventErrors } from './WebhookEventErrors';
import { GET_WEBHOOK_EVENT, REPLAY_WEBHOOK_EVENT } from './queries';

export const WebhookEventFlyout = ({ onReplayComplete, webhookEventId }) => {
  const [, setWebhookEventId] = useQueryParam('webhookEventId', StringParam);
  const { addMessage } = useContext(MessagesContext);
  const { currentUser } = useContext(SessionContext);

  const ua = userAuthorization(currentUser);

  const {
    data,
    loading: webhookLoading,
    error,
  } = useQuery(GET_WEBHOOK_EVENT, {
    variables: {
      id: webhookEventId,
    },
  });

  const [replayWebhookEvent, { loading: replayLoading, error: replayError }] =
    useMutation(REPLAY_WEBHOOK_EVENT, {
      onCompleted: (data) => {
        const hasError = !!data.replayWebhookEvent.errors;
        const hasIntegrationRun =
          !!data.replayWebhookEvent.integrationV2Run?.id;

        addMessage({
          type: hasError ? 'failure' : hasIntegrationRun ? 'info' : 'success',
          body: hasError
            ? 'Replay completed with error'
            : hasIntegrationRun
            ? 'Replay in progress. You may need to refresh the page in a few moments to see updated data.'
            : 'Replay successful',
        });
        setWebhookEventId(data.replayWebhookEvent.id);
      },
    });

  const { webhookEvent = {} } = data || {};
  const {
    affectedEmployees,
    affectedEmployeeWorkflows,
    createdAt,
    serviceName,
    errors,
    eventData,
    eventFilters,
    eventName,
    eventType,
    externalId,
    integrationV2Run,
    isPing,
    replayOfWebhookEventId,
    replayedByUser,
    status,
  } = webhookEvent;

  const integration = serviceName ? getIntegrationByName(serviceName) : null;

  const loading = webhookLoading || replayLoading;

  const isWorkflowTriggerType = eventType === 'workflowTrigger';

  const replayButtonValidators = [
    () => {
      if (replayOfWebhookEventId) {
        return 'Please navigate to the originating webhook event to trigger a replay';
      }

      if (isPing) {
        return 'Ping events cannot be replayed';
      }

      if (!isWorkflowTriggerType) {
        return 'Only workflow trigger events can be replayed';
      }

      return true;
    },
  ];

  const handleRunReplay = async () => {
    try {
      await replayWebhookEvent({
        variables: { id: webhookEventId },
      });
      onReplayComplete();
    } catch {
      // Catch error to prevent page from crashing. Errors are displayed in the DangerNoticeBox below
    }
  };

  return (
    <Flyout onClickClose={() => setWebhookEventId(undefined)}>
      {() => {
        return (
          <div className='padding-5'>
            {loading && <Loading />}
            {error && <NoticeBox type='danger'>Error: {error}</NoticeBox>}
            {!loading && !error && (
              <>
                <div className='d-flex margin-bottom-2 justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <IntegrationLogo
                      name={serviceName}
                      className='margin-right-4'
                    />
                    <h2 className='margin-right-3'>
                      {capitalize(words(eventName).join(' '))}
                    </h2>
                  </div>
                </div>
                {replayError && (
                  <DangerNoticeBoxWithIcon className='margin-bottom-3'>
                    Error replaying event: {replayError.message}
                  </DangerNoticeBoxWithIcon>
                )}
                <DataField
                  label='Status'
                  valueRendered={
                    <Badge
                      className='margin-top-1 d-inline-block'
                      color={WEBHOOK_EVENT_STATUS_TO_COLOR[status]}
                    >
                      {capitalize(status)}
                    </Badge>
                  }
                />
                <DataField
                  label='Type'
                  value={upperFirst(lowerCase(eventType))}
                />
                {isWorkflowTriggerType && (
                  <DataField
                    label='Employees'
                    valueRendered={
                      !!affectedEmployees?.length && (
                        <>
                          {affectedEmployees.map((emp) => (
                            <Link
                              key={emp.id}
                              className='d-flex align-items-center stealth-link'
                              to={`/employees?eid=${emp.id}`}
                            >
                              <EmployeeAvatar
                                widthHeight={24}
                                imageSrc={emp.imageUrl}
                                employeeName={emp.name}
                                employeeId={emp.id}
                              />
                              <div className='margin-left-3 font-weight-500'>
                                {emp.name}
                              </div>
                            </Link>
                          ))}
                        </>
                      )
                    }
                  />
                )}
                {isWorkflowTriggerType && (
                  <DataField
                    label='Enrolled workflows'
                    valueRendered={
                      !!affectedEmployeeWorkflows?.length && (
                        <>
                          {affectedEmployeeWorkflows.map((ew) => (
                            <Link
                              key={ew.id}
                              className='d-flex align-items-center stealth-link'
                              to={`/workflow/${ew.timeline.id}?eIds=${ew.employee.id}&ewIds=${ew.id}`}
                            >
                              <EmployeeAvatar
                                widthHeight={24}
                                imageSrc={ew.employee.imageUrl}
                                employeeName={ew.employee.name}
                                employeeId={ew.employee.id}
                              />
                              <div className='margin-left-3'>
                                <span className='font-weight-500'>
                                  {ew.employee.name}
                                </span>{' '}
                                - {ew.timeline.name}
                              </div>
                            </Link>
                          ))}
                        </>
                      )
                    }
                  />
                )}
                {integrationV2Run && ua.isSuperAdmin && (
                  <DataField
                    label='Triggered integration runs'
                    valueRendered={
                      <>
                        <Link
                          to={`/admin/companies/${currentUser.companyId}/integrations/v2_runs/${integrationV2Run.id}`}
                        >
                          Run {integrationV2Run.id}
                        </Link>
                        <Badge
                          color={
                            integrationV2Run.isInProgress
                              ? 'yellow'
                              : integrationV2Run.state === 'FAILED'
                              ? 'red'
                              : 'green'
                          }
                          className='margin-left-2'
                        >
                          {capitalize(integrationV2Run.state)}
                        </Badge>
                      </>
                    }
                  />
                )}
                <DataField
                  label={
                    replayOfWebhookEventId ? 'Triggered at' : 'Received at'
                  }
                  value={moment(createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                />
                {replayedByUser && (
                  <DataField
                    label='Triggered by'
                    valueRendered={
                      <div className='d-flex align-items-center'>
                        <EmployeeAvatar
                          imageSrc={replayedByUser.employee.imageUrl}
                          widthHeight={24}
                          employeeName={replayedByUser.employee.name}
                          employeeId={replayedByUser.employee.id}
                        />{' '}
                        <div className='margin-left-2'>
                          {replayedByUser.employee.name}
                        </div>
                      </div>
                    }
                  />
                )}
                {replayOfWebhookEventId && (
                  <DataField
                    label='Replay of'
                    valueRendered={
                      <Link
                        to={`${window.location.pathname}?webhookEventId=${replayOfWebhookEventId}`}
                      >
                        Webhook event {replayOfWebhookEventId}
                      </Link>
                    }
                  />
                )}
                <DataField
                  label={`${integration.label} ID`}
                  value={externalId}
                />
                {!!errors?.length && <WebhookEventErrors errors={errors} />}
                {isWorkflowTriggerType && (
                  <DataField
                    label='Event filters'
                    valueRendered={
                      eventFilters && (
                        <CodeBlock>{prettyPrintJson(eventFilters)}</CodeBlock>
                      )
                    }
                  />
                )}
                <DataField
                  label='Event data'
                  valueRendered={
                    <CodeBlock>{prettyPrintJson(eventData)}</CodeBlock>
                  }
                />
                <ReplayButton
                  onRunReplay={handleRunReplay}
                  loading={replayLoading}
                  validators={replayButtonValidators}
                />
              </>
            )}
          </div>
        );
      }}
    </Flyout>
  );
};

WebhookEventFlyout.propTypes = {
  onReplayComplete: func.isRequired,
  webhookEventId: string.isRequired,
};
