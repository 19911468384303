import { node, string } from 'prop-types';
import React from 'react';

import DangerNoticeBox from 'sora-client/components/common/DangerNoticeBox';

const DangerNoticeBoxWithIcon = ({
  children,
  className = '',
  ...restProps
}) => (
  <DangerNoticeBox className={className} {...restProps}>
    <DangerNoticeBox.Icon />
    <div className='margin-left-4 flex-1'>{children}</div>
  </DangerNoticeBox>
);

DangerNoticeBoxWithIcon.propTypes = {
  children: node.isRequired,
  className: string,
};

export default DangerNoticeBoxWithIcon;
