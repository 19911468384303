/* eslint-disable react/no-unknown-property */
import React from 'react';

const CheckIcon = ({ className }) => (
  <svg
    className={className}
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    data-testid='check-icon'
  >
    <mask
      id='mask0'
      mask-type='alpha'
      maskUnits='userSpaceOnUse'
      x='1'
      y='3'
      width='14'
      height='10'
    >
      <path
        d='M13.3333 4L5.99999 11.3333L2.66666 8'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </mask>
    <g mask='url(#mask0)'>
      <rect width='16' height='16' fill='#323246' />
    </g>
  </svg>
);

export default CheckIcon;
