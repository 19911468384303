import {
  AVAILABLE_USES_ENUMS,
  BASE_INTEGRATION_LOGO_URL,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const OKTA_INTEGRATION_ID = 37;

export const OKTA_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: OKTA_INTEGRATION_ID,
  name: 'okta',
  label: 'Okta',
  helpDocs: 'https://help.sora.co/resources/okta',
  logoUrl: `${BASE_INTEGRATION_LOGO_URL}/okta.png`,
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  categories: [CATEGORIES.OTHER],
  configSchema: {
    apiToken: {
      type: 'string',
      title: 'API token',
      nullable: true,
      uiSchema: {
        'ui:widget': 'Password',
      },
    },
    domain: {
      type: 'string',
      title: 'Domain',
      description: 'Example: yourcompany.okta.com (omit https://)',
      nullable: true,
      searchable: true,
      public: true,
    },
    soraClientId: {
      type: 'string',
      title: 'Sora app client ID',
      nullable: true,
      description:
        'Find this in the general settings for the Sora app in your Okta admin UI',
      public: true,
    },
  },
  configured: (c) => c.values && c.values.domain && c.values.apiToken,
  validation: { action: 'okta/getUserTypes' },
};
