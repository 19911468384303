import React, { useContext } from 'react';

import TabsNav from 'sora-client/components/common/TabsNav';
import {
  SETTINGS_AUDIT_LOGS,
  SETTINGS_AUDIT_LOGS_DEPRECATED,
  SETTINGS_WEBHOOK_EVENTS,
} from 'sora-client/constants/routes';
import { SessionContext } from 'sora-client/contexts';
import { userAuthorization } from 'sora-client/helpers/authorization';

const getTabs = (currentUser) => {
  const ua = userAuthorization(currentUser);
  const tabs = [];

  tabs.push({
    to: SETTINGS_WEBHOOK_EVENTS,
    title: 'Webhook events',
  });

  const canViewAuditLogs = ua.can({ resource: 'Company', action: '*' });
  if (canViewAuditLogs) {
    tabs.push({
      to: SETTINGS_AUDIT_LOGS,
      title: 'Audit logs',
      requireExactMatch: true,
    });
    tabs.push({
      to: SETTINGS_AUDIT_LOGS_DEPRECATED,
      title: 'Audit logs (V1 - deprecated)',
    });
  }

  return tabs;
};

export const SettingsLogTabNavigator = () => {
  const { currentUser } = useContext(SessionContext);
  return (
    <div className='margin-bottom-4 d-flex tabs-nav'>
      <TabsNav items={getTabs(currentUser)} />
    </div>
  );
};
