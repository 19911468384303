import gql from 'graphql-tag';
import { arrayOf, bool, shape } from 'prop-types';
import React, { useState } from 'react';

import HomePageCardTitle from 'sora-client/components/HomePage/HomePageCardTitle';
import BoxCard from 'sora-client/components/common/BoxCard';
import Button from 'sora-client/components/common/Button';
import { LinkButton } from 'sora-client/components/common/LinkButton';

import HomePageCard from './HomePageCard';

const DISMISSAL_SLUG = 'onboarding-card-dismissed';

export const OnboardingChecklistCard = ({ integrationStates }) => {
  const previouslyDismissed = localStorage.getItem(DISMISSAL_SLUG) || false;
  const allConfiguredIntegrations = (integrationStates || []).filter(
    (intState) => {
      return intState.config.activated;
    },
  );
  const configuredAtsIntegrations = allConfiguredIntegrations.filter(
    (intState) => {
      return intState.integration.categories?.includes('ats');
    },
  );

  // Card is only dismissable if company has configured at least one integration
  const dismissable = !!allConfiguredIntegrations.length;

  const [dismissed, setDismissed] = useState(previouslyDismissed);

  if (dismissable && (dismissed || previouslyDismissed)) {
    return null;
  }

  // Unless dismissed, the card will show if company has not yet configured any
  // ATS integration
  if (configuredAtsIntegrations.length) {
    return null;
  }

  const onClick = () => {
    setDismissed(true);
    localStorage.setItem(DISMISSAL_SLUG, 'true');
  };

  return (
    <HomePageCard className='onboarding-checklist-card'>
      <BoxCard style={{ minHeight: 150 }}>
        <div className='padding-5 mobile-padding-3'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='flex-grow-1'>
              <HomePageCardTitle title='Welcome to Sora' />
              <div className='text-muted'>
                Get started by connecting your ATS to import new employees
                automatically.
                <br />
                Employee import will begin once you launch your first workflow.
              </div>
            </div>
            <LinkButton
              to='/settings/integrations?category=ats'
              label='Connect your ATS'
              category='primary'
              className='margin-right-2 margin-left-4 flex-shrink-0'
            />
            {dismissable && (
              <Button
                labelHTML='Dismiss'
                onClick={onClick}
                analyticsEventName='Onboarding Card Dismissed'
              />
            )}
          </div>
        </div>
      </BoxCard>
    </HomePageCard>
  );
};

OnboardingChecklistCard.fragments = {
  query: gql`
    fragment OnboardingChecklistCard_Query on Query {
      integrationStates(configurable: true) {
        config {
          uuid
          activated
        }
        integration {
          categories
          name
        }
      }
    }
  `,
};

OnboardingChecklistCard.propTypes = {
  integrationStates: arrayOf(
    shape({
      config: shape({ activated: bool.isRequired }).isRequired,
    }),
  ).isRequired,
};
