import gql from 'graphql-tag';

export const GET_ENROLLABLE_WORKFLOWS = gql`
  fragment HelpfulLinks_Query on Query {
    selfEnrollableWorkflows(
      filters: { showSelfEnrollmentCTA: $showSelfEnrollmentCTA }
    ) @include(if: $fetchPremiumContent) {
      id
      name
      selfEnrollmentHeader
      selfEnrollmentDescription
      allowMultipleEnrollmentsPerEmployee
    }
  }
`;
