import {
  AVAILABLE_USES_ENUMS,
  CATEGORIES,
  DEFAULT_INTEGRATION,
} from '../../common';

export const MS_TEAMS_INTEGRATION_ID = 42;

export const MS_TEAMS_INTEGRATION_CONFIG = {
  ...DEFAULT_INTEGRATION,
  id: MS_TEAMS_INTEGRATION_ID,
  availableUses: [AVAILABLE_USES_ENUMS.WORKFLOWS],
  name: 'msteams',
  label: 'Microsoft Teams',
  description:
    'Microsoft Teams is a cloud-based team collaboration software that is part of the Microsoft 365 and Office 365 suite of applications.',
  website: 'https://www.microsoft.com/microsoft-teams',
  logoUrl:
    'https://honu-app.s3-us-west-1.amazonaws.com/static/media/service-logos/teams.png',
  categories: [CATEGORIES.OTHER],
  configSchema: {},
  configured: () => true,
};
