import { string } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Button from 'sora-client/components/common/Button';
import Page from 'sora-client/components/common/Page';
import * as routes from 'sora-client/constants/routes';
import AnalyticsContext from 'sora-client/contexts/AnalyticsContext';
import soraLogo from 'sora-client/static/images/sora.png';

const TaskCompletePage = ({ id }) => {
  const { track } = useContext(AnalyticsContext);
  const history = useHistory();

  const { hideNewHireTaskButton } = /** @type {*} */ (
    history.location.state || {}
  );

  useEffect(() => {
    track('Task completed', { taskId: id });
  }, [track, id]);

  return (
    <Page id='task-complete-page' category='Tasks' layout='no-navigation'>
      <div className='task-completed-wrapper margin-x-auto box-shadow rounded bg-white padding-y-6 padding-x-4 text-center'>
        <img src={soraLogo} width={120} alt='Sora' />
        <h1>Task completed</h1>
        <Link
          to={routes.NEW_HIRE_TASKS}
          className='d-inline-block margin-top-3'
        >
          {!hideNewHireTaskButton && (
            <Button label='View your other tasks' category='primary' />
          )}
        </Link>

        <h1 className='h3 text-muted margin-top-6 padding-x-5 mobile-padding-x-0'>
          <em>
            &quot;I&apos;ve learned that people will forget what you said,
            people will forget what you did, but people will never forget how
            you made them feel. &quot;
          </em>
          <div className='margin-top-4'>
            <div className='d-inline-block border-top padding-top-3 h5'>
              Maya Angelou
            </div>
          </div>
        </h1>
      </div>
    </Page>
  );
};

TaskCompletePage.propTypes = {
  id: string.isRequired,
};

export default TaskCompletePage;
