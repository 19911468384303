import classNames from 'classnames';
import { noop } from 'lodash';
import { any, bool, func } from 'prop-types';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';

import FlyoutContext from 'sora-client/contexts/FlyoutContext';
import {
  triggerWindowResizeEvent,
  useEffectWithoutUnmount,
} from 'sora-client/helpers';

import { BackArrowSvg, ForwardArrowSvg, MaxSvg, MinSvg, XSvg } from './Svg';
import { usePortal } from './hooks';

const Flyout = ({
  attachTo,
  children,
  onClickClose = noop,
  scrollable = true,
}) => {
  const [siteNavOffset, setSiteNavOffset] = useState(0);
  const [collapsed, setCollapsed] = useState(false);
  const [maximized, setMaximized] = useState(false);
  const target = usePortal(attachTo || 'flyouts');

  useEffectWithoutUnmount(() => {
    const siteNav = document.getElementById('site-nav');

    if (siteNav) {
      const boundingRect = siteNav.getBoundingClientRect();
      const totalOffset = boundingRect.top + boundingRect.height;
      if (totalOffset !== siteNavOffset) {
        setSiteNavOffset(boundingRect.top + boundingRect.height);
      }
    }
  });

  return createPortal(
    <>
      <div
        className={classNames(
          'flyout mobile-nav-offset bg-white border-left position-fixed position-bottom margin-3 rounded-xlarge',
          { collapsed, maximized, 'overflow-auto': scrollable },
        )}
        style={{
          top: `${siteNavOffset}px`,
        }}
      >
        <FlyoutContext.Provider value={{ setCollapsed, close: onClickClose }}>
          {children({ collapsed, setCollapsed })}
        </FlyoutContext.Provider>
      </div>
      <div
        className={classNames(
          'flyout-close position-fixed margin-right-4 margin-top-3',
          {
            collapsed,
            maximized,
          },
        )}
        style={{
          top: `${siteNavOffset}px`,
        }}
      >
        <div className='bg-gray bg-hover-midnight-navy-15 transition-background rounded-circle d-flex align-items-center justify-content-center'>
          <XSvg
            className='padding-2 stroke-twilight-indigo no-hover'
            onClick={() => onClickClose()}
          />
        </div>
        <div className='maximize-icon margin-top-2 bg-gray bg-hover-midnight-navy-15 transition-background rounded-circle d-flex align-items-center justify-content-center'>
          {!maximized && (
            <MaxSvg
              className='padding-2 stroke-twilight-indigo no-hover'
              onClick={() => {
                setMaximized(true);
                setTimeout(() => {
                  triggerWindowResizeEvent();
                }, 110);
              }}
            />
          )}
          {maximized && (
            <MinSvg
              className='padding-2 stroke-twilight-indigo no-hover'
              onClick={() => {
                setMaximized(false);
                setTimeout(() => {
                  triggerWindowResizeEvent();
                }, 110);
              }}
            />
          )}
        </div>
        <div className='margin-top-2 bg-twilight-indigo-hover-indicator transition-background rounded-circle d-flex align-items-center justify-content-center'>
          {collapsed && (
            <BackArrowSvg
              className='padding-2'
              onClick={() => setCollapsed(false)}
            />
          )}
          {!collapsed && (
            <ForwardArrowSvg
              className='padding-2'
              onClick={() => setCollapsed(true)}
            />
          )}
        </div>
      </div>
    </>,
    target,
  );
};

Flyout.propTypes = {
  attachTo: any,
  children: any.isRequired,
  expanded: bool,
  onClickClose: func,
  scrollable: bool,
};

export default Flyout;
